import React, { useState, useEffect } from "react";
import { Button, Card, Form, Grid } from "tabler-react";

//hooks
import useUsers from "../../../../../hooks/api/Users/useUsers";
import useQueryURL from "../../../../../hooks/services/useQueryURL";
import useBulkEdit from "../../../../../hooks/api/AMS/useBulkEdit";


const BulkEdit = () => {
  const { telemarketers, user } = useUsers();
  const { bulkEditByTele } = useBulkEdit();
  const { getQuery } = useQueryURL();
  const [bulkEditType, setBulkEditType] = useState(
    "Bulk Edit By Telemarketers"
  );

  const [accessLevel, setAccessLevel] = useState("none");
  const [oldTele, setOldTele] = useState("");
  const [newTele, setNewTele] = useState("");

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "711-account-management")
            .access
        : "none"
    );
  }, [user]);

  return (
    <div className="col-dashboard-container" style={{ margin: "5px" }}>
      <Card>
        <Card.Header>
          Store Bulk Edit
          <Card.Options>
            <Button
              color="secondary"
              onClick={() =>
                window.location.replace(window.atob(getQuery("backURL")))
              }
            >
              Back
            </Button>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={4}>
              <Form.Label>Bulk Edit Type</Form.Label>
              <Form.Select
                value={bulkEditType}
                onChange={(e) => setBulkEditType(e.target.value)}
              >
                <option value="">Select One</option>
                <option value="Bulk Edit By Telemarketers">
                  Bulk Edit By Telemarketers
                </option>
                <option value="Bulk Edit By Store Number">
                  Bulk Edit By Store Number
                </option>
              </Form.Select>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        {bulkEditType === "Bulk Edit By Telemarketers" ? (
          <Card.Body>
            <Form.Label>Bulk Edit By Telemarketer</Form.Label>
            <Grid.Row>
              <Grid.Col md={4}>
                <Form.Group>
                  <Form.Label>Choose the Old Telemarketer</Form.Label>
                  <Form.Select
                    value={oldTele}
                    onChange={(e) => setOldTele(e.target.value)}
                  >
                    <option value="">Select One</option>
                    {telemarketers.map((tele) => (
                      <option value={tele.full_name}>{tele.full_name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Choose the New Telemarketer</Form.Label>
                  <Form.Select
                    value={newTele}
                    onChange={(e) => setNewTele(e.target.value)}
                  >
                    <option value="">Select One</option>
                    {telemarketers
                      .filter((tele) => tele.full_name !== oldTele && tele.role === "Telemarketer")
                      .map((tele) => (
                        <option value={tele.full_name}>{tele.full_name}</option>
                      ))}
                  </Form.Select>
                </Form.Group>
                {oldTele === "" && newTele === "" ? (
                  ""
                ) : (
                  <Button
                    color="cyan"
                    onClick={() =>
                      bulkEditByTele(
                        { oldTele: oldTele, newTele: newTele },
                        () => alert("saved!")
                      )
                    }
                  >
                    Update
                  </Button>
                )}
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};

export default BulkEdit;
