import React, { useEffect, useState } from "react";
import { Card, Form, Grid, Button, Icon } from "tabler-react";

const PreferencesAddForm = (props) => {
  const { data } = props;
  const {
    preferences,
    type,
    getPreferences,
    setSuccessForm,
    addPreferences,
    updatePreferences,
    setSelectedPreferences,
    selectedPreferences,
    setOpenForm,
  } = data;

  const [formData, setFormData] = useState({
    type: "",
    value: "",
    store_type: "",
    active: "",
  });

  useEffect(() => {
    if (selectedPreferences) {
      setFormData({
        type: selectedPreferences.type,
        value: selectedPreferences.value,
        store_type: selectedPreferences.store_type,
        active: selectedPreferences.active,
      });
    }
  }, [selectedPreferences]);

  return (
    <>
      <Card>
        <Card.Header>
          {selectedPreferences.length != 0
            ? "Preferences Update Form"
            : "Preferences Add Form"}
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={3}>
              <Form.Label>Type</Form.Label>
              <Form.Select
                value={formData.type}
                onChange={(e) =>
                  setFormData({ ...formData, type: e.target.value })
                }
              >
                <option value="">All</option>
                {preferences
                  .map((p) => p.type)
                  .filter(
                    (v, i) => preferences.map((p) => p.type).indexOf(v) == i
                  )
                  .map((pref) => (
                    <option value={pref}>
                      {pref.replace("_", " ").toUpperCase()}
                    </option>
                  ))}
              </Form.Select>
            </Grid.Col>
            <Grid.Col md={3}>
              <Form.Label>Value</Form.Label>
              <Form.Input
                value={formData.value}
                onChange={(e) =>
                  setFormData({ ...formData, value: e.target.value })
                }
                placeholder="value"
              />
            </Grid.Col>
            <Grid.Col md={3}>
              <Form.Label>Store Type</Form.Label>
              <Form.Select
                value={formData.store_type}
                onChange={(e) =>
                  setFormData({ ...formData, store_type: e.target.value })
                }
              >
                <option value="">Select One</option>
                <option value="1">Other Accounts</option>
                <option value="0">7/11</option>
              </Form.Select>
            </Grid.Col>
            <Grid.Col md={3}>
              <Form.Label>Active</Form.Label>
              <Form.Select
                value={formData.active}
                onChange={(e) =>
                  setFormData({ ...formData, active: e.target.value })
                }
              >
                <option value="">Select One</option>
                <option value="1">Active</option>
                <option value="0">Disabled</option>
              </Form.Select>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={2}>
              <br />
              {selectedPreferences.length != 0 ? (
                <Button
                  color="azure"
                  icon="save"
                  onClick={() =>
                    updatePreferences(
                      selectedPreferences.id,
                      formData,
                      () =>
                        setSuccessForm(true) ||
                        setTimeout(() => {
                          setSuccessForm(false);
                          setSelectedPreferences([]) ||
                            setFormData({
                              type: "",
                              value: "",
                              store_type: 0,
                              active: 1,
                            }) ||
                            setOpenForm(false) ||
                            getPreferences();
                        }, 1000)
                    )
                  }
                >
                  UPDATE
                </Button>
              ) : (
                <Button
                  color="azure"
                  icon="save"
                  onClick={() =>
                    addPreferences(
                      formData,
                      () =>
                        setSuccessForm(true) ||
                        setTimeout(() => {
                          setSuccessForm(false);
                          setSelectedPreferences([]) ||
                            setFormData({
                              type: "",
                              value: "",
                              store_type: 0,
                              active: 1,
                            }) ||
                            setOpenForm(false) ||
                            getPreferences();
                        }, 1000)
                    )
                  }
                >
                  SAVE
                </Button>
              )}

              <Button
                color="secondary"
                onClick={() =>
                  setSelectedPreferences([]) ||
                  setFormData({
                    type: "",
                    value: "",
                    store_type: 0,
                    active: 1,
                  }) ||
                  setOpenForm(false)
                }
              >
                <Icon prefix="fa" name="close" />
                Cancel
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default PreferencesAddForm;
