import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import dateFormat from "dateformat";
import moment from "moment";
import SideMenu from "./SideMenu";
import DatePicker from "react-datepicker";
import useExtractLeaves from "../../hooks/api/HR/useExtractLeaves";

//hooks
import useHRTool from "../../hooks/api/HR/useHRTool";
import { Button, Card, Form, Grid, Icon } from "tabler-react";
const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const {
    employees,
    report,
    generateReport,
    getApprovedLeaves,
    getApprovedOB,
    getApprovedCTO,
    getApprovedOT,
    getApprovedROT,
    approvedLeaves,
    approvedOB,
    approvedOT,
    approvedCTO,
    approvedROT,
  } = useHRTool();

  const { downloadLeaveReport } = useExtractLeaves();

  const [events, setEvents] = useState([]);

  const [month, setMonth] = useState(dateFormat(new Date(), "m"));
  const [startDate, setStartDate] = useState(new Date("01/01/2022"));
  const [endDate, setEndDate] = useState(new Date("12/31/2022"));

  useEffect(() => {
    getApprovedLeaves(startDate, endDate);
    getApprovedOB(startDate, endDate);
    getApprovedOT(startDate, endDate);
    getApprovedCTO(startDate, endDate);
    getApprovedROT(startDate, endDate);
  }, []);

  console.log(approvedLeaves);

  useEffect(() => {
    let data = [
      ...approvedLeaves.map((leaves) => leaves.approvedLeaves),
      ...approvedOB.map((leaves) => leaves.approvedOB),
      ...approvedOT.map((leaves) => leaves.approvedOT),
      ...approvedCTO.map((leaves) => leaves.approvedCTO),
      ...approvedROT.map((leaves) => leaves.approvedROT),
    ];

    let leavesList = [];

    data.forEach((d1) => {
      if (d1.length != 0) {
        d1.forEach((d2) => leavesList.push(d2));
      } else {
        leavesList.push(d1);
      }
    });

    setEvents(
      leavesList.map((leave) => ({
        ...leave,
        id: (Math.random() + 1).toString(36).substring(7),
        title: `${
          employees.find(
            (employee) => employee.employee_id === leave.employee_id
          )?.full_name
        } ${leave.employee_id} - ${leave.type}`,
        start: new Date(leave.date),
        end: new Date(leave.date),
        type: leave.type,
      }))
    );

    console.log(leavesList);
  }, [approvedLeaves, approvedOB, approvedOT, approvedCTO, approvedROT]);

  const eventStyleGetter = (event) => {
    // console.log(event.type);
    let backgroundColor = "";
    if (event.type === "LEAVE W/O PAY") {
      backgroundColor = "#D6B72D";
    } else if (event.type === "VACATION LEAVE") {
      backgroundColor = "#5AC228";
    } else if (event.type === "FORCED LEAVE") {
      backgroundColor = "#D83515";
    } else if (event.type === "SICK LEAVE") {
      backgroundColor = "#2BB2D6";
    } else if (
      event.type === "RD OT" ||
      event.type == "OT" ||
      event.type == "OB"
    ) {
      backgroundColor = "#01327A";
    }

    return { style: { backgroundColor } };
  };

  const [extractReport, setExtractReport] = useState([]);
  const [extractMonth, setExtractMonth] = useState(
    dateFormat(new Date(), "mmmm")
  );

  const [extractStart, setExtractStart] = useState(new Date());
  const [extractEnd, setExtractEnd] = useState(new Date());

  useEffect(() => {
    setExtractReport(
      events
        .map((event) => ({
          ...event,
          last_name: event.title.split("-")[0].split(",")[0],
          first_name: event.title.split("-")[0].split(",")[1],
          leave_start_date: dateFormat(
            new Date(event.start),
            "paddedShortDate"
          ),
          leave_end_date: dateFormat(new Date(event.end), "paddedShortDate"),
          type: event.title.split("-")[1],
          year: dateFormat(new Date(event.start), "yyyy"),
          month: dateFormat(new Date(event.start), "mmmm"),
        }))
    );
  }, [events, extractStart,extractEnd]);

  console.log(extractReport);

  return (
    <>
      <div style={{ margin: "5px" }}>
        <Grid.Row>
          <Grid.Col md={2}>
            <SideMenu data={{ setTab: false }} />
          </Grid.Col>
          <Grid.Col md={10}>
            <Card>
              <Card.Body>
                <p>Legends:</p>
                <span
                  style={{
                    backgroundColor: "#D83515",
                    padding: "5px",
                    color: "white",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  FORCE LEAVE
                </span>

                <span
                  style={{
                    backgroundColor: "#2BB2D6",
                    padding: "5px",
                    color: "white",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  SICK LEAVE
                </span>

                <span
                  style={{
                    backgroundColor: "#5AC228",
                    padding: "5px",
                    color: "white",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  VACATION LEAVE
                </span>

                <span
                  style={{
                    backgroundColor: "#D6B72D",
                    padding: "5px",
                    color: "white",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  LEAVE W/O PAY
                </span>

                <span
                  style={{
                    backgroundColor: "#01327A",
                    padding: "5px",
                    color: "white",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  OB, OT, RD OT
                </span>

                <span
                  style={{
                    backgroundColor: "#3174ad",
                    padding: "5px",
                    color: "white",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  BEREAVEMENT
                </span>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body>
                <Grid.Row>
                  <Grid.Col md={12}>Extract Report Here</Grid.Col>
                  <Grid.Col md={4}>
                    {/* <Form.Select
                      value={extractMonth}
                      onChange={(e) => setExtractMonth(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </Form.Select> */}
                    <DatePicker
                      selected={extractStart}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        getApprovedLeaves(start, end);
                        getApprovedOB(start, end);
                        getApprovedOT(start, end);
                        getApprovedCTO(start, end);
                        getApprovedROT(start, end);

                        setExtractStart(start);
                        setExtractEnd(end);
                      }}
                      startDate={extractStart}
                      endDate={extractEnd}
                      className="form-control"
                      selectsRange
                      monthsShown={1}
                      withPortal
                    />
                  </Grid.Col>
                  <Grid.Col md={2}>
                    <Button
                      color="cyan"
                      onClick={() => downloadLeaveReport(extractReport)}
                    >
                      EXtract Report
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>
            <Card>
              {/* <Card.Body>
                <Button
                  color="cyan"
                  onClick={() => window.location.replace("/HR/DTR")}
                >
                  <Icon prefix="fa" name="print" /> Generate DTR
                </Button>

                <Button
                  color="cyan"
                  onClick={() => window.location.replace("/HR/Manager")}
                >
                  <Icon prefix="fa" name="user" /> Manage Employee and Holidays
                </Button>
              </Card.Body> */}
              <Card.Body>
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: "100vh" }}
                  // onSelectEvent={handleSelectEvent}
                  views={[Views.MONTH, Views.AGENDA, Views.DAY]}
                  eventPropGetter={eventStyleGetter}
                />
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </div>
    </>
  );
};

export default MyCalendar;
