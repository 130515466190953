import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import MenuHeader from "../../components/MenuHeader";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DTR from "./DTR";
import Calendar from "./MyCalendar";
import Manager from "./Manager";

import useUsers from "../../hooks/api/Users/useUsers";

const Index = () => {
  const { user } = useUsers();
  return (
    <>
      <Helmet>
        <title>GMS - HR & Timekeeping Tool</title>
      </Helmet>
      <MenuHeader data={{ user }} />
      <Router>
        <Switch>
          <Route exact path="/HR" component={Calendar} />
          <Route exact path="/HR/Calendar" component={Calendar} />
          <Route exact path="/HR/DTR" component={DTR} />
          <Route exact path="/HR/Manager" component={Manager} />
        </Switch>
      </Router>
    </>
  );
};

export default Index;
