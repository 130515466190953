import React from "react";
import { Form } from "tabler-react";
import CSVReader from "react-csv-reader";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const UploadForm = (props) => {
  const {
    onUpload,
    label,
    type,
    // value,
    fileName,
    customLabel,
    setFileName,
    setCustomLabel,
    orderIDs,
    setOrderIDs,
    variantsDirectory,
    setVariantDirectory,
    shippingOptions,
    jnt,
    setJNT,
    spx,
    setSPX,
  } = props;

  return (
    <Form.FieldSet>
      <Form.Group label={label}>
        {type === "upload" ? (
          <CSVReader
            onFileLoaded={(data, fileInfo) =>
              onUpload(data.slice(0, data.length - 1))
            }
          />
        ) : (
          ""
        )}
        {type === "checker" ? (
          <CSVReader
            onFileLoaded={(data, fileInfo) =>
              onUpload(data.slice(0, data.length - 1))
            }
          />
        ) : (
          ""
        )}
        {type === "serials" ? (
          <CSVReader
            onFileLoaded={(data, fileInfo) =>
              onUpload(data.slice(0, data.length - 1))
            }
          />
        ) : (
          ""
        )}
        {type === "text" || orderIDs ? (
          <Form.Textarea
            name="drawItems"
            placeholder="Please input your data"
            value={orderIDs}
            onChange={(e) => setOrderIDs(e.target.value)}
            rows={7}
          />
        ) : (
          ""
        )}
        {type === "directory" || variantsDirectory ? (
          <Form.Textarea
            name="drawItems"
            placeholder="Please input your data"
            value={variantsDirectory}
            onChange={(e) => setVariantDirectory(e.target.value)}
            rows={7}
          />
        ) : (
          ""
        )}
      </Form.Group>
      {type === "text" ||
      type === "directory" ||
      type === "checker" ||
      type === "serials" ? (
        ""
      ) : (
        <>
          <Form.Group label="File Name" isRequired>
            <Form.Input
              name="fileName"
              type="text"
              placeholder="Please enter the file name"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
            />
          </Form.Group>
          {shippingOptions.map((option) =>
            option === "lex" ? (
              <Form.Group label="LEX PH PICK UP" isRequired>
                <DatePicker
                  selected={customLabel}
                  onChange={(date) => setCustomLabel(date)}
                  className="form-control"
                />
              </Form.Group>
            ) : (
              ""
            )
          )}

          {shippingOptions.map((option) =>
            option === "spx" ? (
              <Form.Group label="SHOPEE XPRESS PICK UP" isRequired>
                <DatePicker
                  selected={spx}
                  onChange={(date) => setSPX(date)}
                  className="form-control"
                />
              </Form.Group>
            ) : (
              ""
            )
          )}

          {shippingOptions.map((option) =>
            option === "jnt" ? (
              <Form.Group label="JT & T EXPRESS PICK UP" isRequired>
                <DatePicker
                  selected={jnt}
                  onChange={(date) => setJNT(date)}
                  className="form-control"
                />
              </Form.Group>
            ) : (
              ""
            )
          )}
        </>
      )}
    </Form.FieldSet>
  );
};

export default UploadForm;
