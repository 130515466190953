import React from "react";
import { Button, Card, Form, Grid } from "tabler-react";
import useExtractPaymentRelease from "../../../../hooks/api/TiktokGOMOShop/extractions/useExtractPaymentRelease";

const Filter = (props) => {
  const { downloadPayments } = useExtractPaymentRelease();
  const { data } = props;
  const {
    paymentRelease,
    newPaymentRelease,
    src,
    month,
    year,
    search,
    setSearch,
    search2,
    setSearch2,
    search3,
    setSearch3,
    search4,
    setSearch4,
    search5,
    setSearch5,
  } = data;

  return (
    <>
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={2}>
            <Form.Label>Month</Form.Label>
            <Form.Select
              value={month}
              onChange={(e) =>
                window.location.replace(
                  `/TiktokGOMOShop/${src}?month=${e.target.value}&year=${year}`
                )
              }
            >
              <option value="">SELECT MONTH</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </Form.Select>
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Label>Year</Form.Label>
            <Form.Select
              value={year}
              onChange={(e) =>
                window.location.replace(
                  `/TiktokGOMOShop/${src}?month=${month}&year=${e.target.value}`
                )
              }
            >
              <option value="">SELECT YEAR</option>
              <option value="2020">2020</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
            </Form.Select>
          </Grid.Col>

          <Grid.Col md={2}>
            <Form.Label>DR / SI Number</Form.Label>
            <Form.Input
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid.Col>

          <Grid.Col md={2}>
            <Form.Label>Order ID</Form.Label>
            <Form.Input
              placeholder="Search..."
              value={search2}
              onChange={(e) => setSearch2(e.target.value)}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={12}>
            <Form.Label></Form.Label>
            <Button
              color="cyan"
              icon="download"
              onClick={() => downloadPayments(newPaymentRelease, month, year)}
            >
              Extract
            </Button>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </>
  );
};

export default Filter;
