import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import { Button, Card, Grid, Form, Icon, Alert } from "tabler-react";
import DatePicker from "react-datepicker";

import useNotifications from "../../hooks/api/useNotifications";

const NotificationForm = (props) => {
  const { sendNotification, updateNotification } = useNotifications();

  const { data } = props;
  const {
    selectedAchievement,
    setSelectedAchievement,
    messageData,
    setMessageData,
  } = data;
  const { id, type, rank, message, title, user, expiration, status } =
    selectedAchievement;

  useEffect(() => {
    if (selectedAchievement.length != 0) {
      setMessageData({
        id,
        type,
        rank,
        message,
        title,
        user,
        expiration,
        status,
      });
    }
  }, [
    type,
    rank,
    message,
    title,
    user,
    expiration,
    status,
    selectedAchievement,
  ]);

  return (
    <>
      <div className="modal-achievement">
        <Card className="modal-achievement-content-container">
          {messageData.type === "announcements" ? (
            <Card.Body>
              <Alert
                type={messageData.rank}
                // isDismissible
                className="alert-css"
              >
                <Icon
                  prefix="fa"
                  name={
                    messageData.type === "announcements" ? "bullhorn" : "trophy"
                  }
                />
                <b> {messageData.title}:</b> <span>{messageData.message}</span>
              </Alert>
            </Card.Body>
          ) : (
            ""
          )}
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={messageData.type === "achievements" ? 8 : 8}>
                <Form.Group>
                  <Form.Label>Message Type</Form.Label>
                  <Form.Select
                    value={messageData.type}
                    onChange={(e) =>
                      setMessageData({ ...messageData, type: e.target.value })
                    }
                  >
                    <option value="">Select One</option>
                    <option value="achievements">Achievement</option>
                    <option value="announcements">Announcement</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              {messageData.type === "achievements" ? (
                <Grid.Col md={4}>
                  <Form.Group>
                    <Form.Label>Rank</Form.Label>
                    <Form.Select
                      value={messageData.rank}
                      onChange={(e) =>
                        setMessageData({ ...messageData, rank: e.target.value })
                      }
                    >
                      <option value="">Select One</option>
                      <option value="1">1 - Gold</option>
                      <option value="2">2 - Silver</option>
                      <option value="3">3 - Bronze</option>
                      <option value="4">4 - Bronze</option>
                      <option value="5">5 - Bronze</option>
                      <option value="6">6 - Bronze</option>
                    </Form.Select>
                  </Form.Group>
                </Grid.Col>
              ) : (
                <Grid.Col md={4}>
                  <Form.Group>
                    <Form.Label>Color</Form.Label>
                    <Form.Select
                      value={messageData.rank}
                      onChange={(e) =>
                        setMessageData({ ...messageData, rank: e.target.value })
                      }
                    >
                      <option value="">Select One</option>
                      <option value="danger">Danger | Error</option>
                      <option value="warning">Warning</option>
                      <option value="success">Success</option>
                      <option value="info">Information</option>
                    </Form.Select>
                  </Form.Group>
                </Grid.Col>
              )}
              {messageData.type === "announcements" ? (
                <>
                  <Grid.Col md={6}>
                    <Form.Group>
                      <Form.Label>Announcement Effectivity Duration</Form.Label>
                      <DatePicker
                        selected={new Date(messageData.expiration)}
                        onChange={(date) =>
                          setMessageData({ ...messageData, expiration: date })
                        }
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={3}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        minDate={new Date()}
                        className="form-control"
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={6}>
                    <Form.Group>
                      <Form.Label>Active</Form.Label>
                      <Form.Select
                        value={messageData.status}
                        onChange={(e) =>
                          setMessageData({
                            ...messageData,
                            status: e.target.value,
                          })
                        }
                      >
                        <option value="">Select One</option>
                        <option value="0">Active</option>
                        <option value="1">Not Active</option>
                      </Form.Select>
                    </Form.Group>
                  </Grid.Col>
                </>
              ) : (
                ""
              )}

              <Grid.Col md={6}>
                <Form.Group>
                  <Form.Label>Message To</Form.Label>
                  <Form.Input
                    value={messageData.user}
                    placeholder="Message to"
                    onChange={(e) =>
                      setMessageData({ ...messageData, user: e.target.value })
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6}>
                <Form.Group>
                  <Form.Label>Message Title</Form.Label>
                  <Form.Input
                    value={messageData.title}
                    placeholder="Message Title"
                    onChange={(e) =>
                      setMessageData({ ...messageData, title: e.target.value })
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12}>
                <Form.Group>
                  <Form.Textarea
                    value={messageData.message}
                    rows={7}
                    placeholder="Message"
                    onChange={(e) =>
                      setMessageData({
                        ...messageData,
                        message: e.target.value,
                      })
                    }
                  ></Form.Textarea>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12}>
                {id ? (
                  <Button
                    color="cyan"
                    onClick={() =>
                      updateNotification(
                        messageData,
                        () =>
                          alert("Notification Posted Successfully!") ||
                          setSelectedAchievement([]) ||
                          setMessageData({
                            type: "",
                            rank: "",
                            message: "",
                            title: "",
                            user: "",
                            expiration: new Date(),
                            status: 1,
                          })
                      )
                    }
                  >
                    <Icon prefix="fa" name="envelope" /> Update
                  </Button>
                ) : (
                  <Button
                    color="cyan"
                    onClick={() =>
                      sendNotification(
                        messageData,
                        () =>
                          alert("Notification Posted Successfully!") ||
                          setSelectedAchievement([]) ||
                          setMessageData({
                            type: "",
                            rank: "",
                            message: "",
                            title: "",
                            user: "",
                            expiration: new Date(),
                            status: 1,
                          })
                      )
                    }
                  >
                    <Icon prefix="fa" name="envelope" /> Send
                  </Button>
                )}

                <Button
                  color="secondary"
                  onClick={() =>
                    setSelectedAchievement([]) ||
                    setMessageData({
                      type: "",
                      rank: "",
                      message: "",
                      title: "",
                      user: "",
                      expiration: new Date(),
                      status: 1,
                    })
                  }
                >
                  Cancel
                </Button>
              </Grid.Col>
              {/* <Grid.Col md={12}>
                <Form.Textarea
                  value={editorState.replace(/\n/g, "<br/>")}
                  onChange={(e) => setEditorState(e.target.value)}
                  rows={12}
                ></Form.Textarea>
              </Grid.Col> */}
            </Grid.Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default NotificationForm;
