import dateFormat from "dateformat";

function useCustomFunctions() {
  function formatNumberComma(value) {
    let v = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return v;
  }

  function generateRandomCodes() {
    let code = (Math.random() + 1).toString(36).substring(7);
    return code;
  }

  function generateMonth(date, value, type) {
    var makeDate = new Date(date);
    type === "prev"
      ? makeDate.setMonth(makeDate.getMonth() - value)
      : makeDate.setMonth(makeDate.getMonth() + value);

    return makeDate;
  }

  function getDateDifference(start, end) {
    let Difference_In_Time = end.getTime() - start.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Difference_In_Days;
  }

  function getTimeDifference(start, end) {
    let Difference_In_Time = end.getTime() - start.getTime();
    let Difference = (Difference_In_Time / 60 / 1000) / 60;
    return Difference > 8 ? 8 : Difference.toFixed(2);
  }

  function getMinutesDifference(start, end) {
    let Difference_In_Time = end.getTime() - start.getTime();
    let Difference = Math.round(Difference_In_Time / 60 / 1000);

    return Difference;
  }

  function getInBetweenDates(start, end, type) {
    let nStart = dateFormat(new Date(start), "paddedShortDate");
    let nEnd = dateFormat(new Date(end), "paddedShortDate");

    let curr = new Date(start);
    let dates =
      type === "dtr" ? [dateFormat(new Date(start), "paddedShortDate")] : [];

    let length =
      type === "dtr"
        ? getDateDifference(start, end) - 1
        : getDateDifference(start, end);

    for (let i = 0; i <= length; i++) {
      let first = curr.getDate() + 1;
      let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      dates.push(dateFormat(day, "paddedShortDate"));
    }

    return nStart === nEnd
      ? [dateFormat(new Date(start), "paddedShortDate")]
      : dates;
  }

  function getOneLessDay(date){
    let curr = new Date(date);
    let first = curr.getDate() - 1;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);

    return day
  }

  function getOnePlusDay(date){
    let curr = new Date(date);
    let first = curr.getDate() + 2;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);

    return day
  }

  function getCurrentWeek(curDate, action) {
    let curr = new Date(curDate);
    let week = [];

    for (let i = 0; i <= 6; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      week.push(dateFormat(day, "paddedShortDate"));
    }

    if (week.length == 7) {
      action(week);
    }
  }

  return {
    getCurrentWeek,
    formatNumberComma,
    getInBetweenDates,
    generateRandomCodes,
    generateMonth,
    getDateDifference,
    getTimeDifference,
    getMinutesDifference,
    getOneLessDay,
    getOnePlusDay
  };
}

export default useCustomFunctions;
