import { useState, useEffect } from "react";
import config from "../../../config";
import axios from "axios";
import useUsers from "../Users/useUsers";

function useTimeKeeping() {
  const { loginSession } = useUsers();

  const [employees, setEmployees] = useState([]);
  const [holidays, setHolidays] = useState([]);

  function getEmployees() {
    if (loginSession.length != 0) {
      axios
        .get(`${config.host}:${config.port}/api/v2/hr/employees`)
        .then(function (response) {
          let data = response.data;
          setEmployees(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  function addEmployees(data, actions) {
    console.log(data);
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/hr/add_employees`, {
          employee_id: data.employee_id,
          full_name: data.full_name,
          position: data.position,
          shift: data.shift,
          type: data.type,
          active: data.active,
        })
        .then(function (response) {
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function updateEmployees(data, actions) {
    console.log(data);
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/hr/update_employee`, {
          id: data.id,
          employee_id: data.employee_id,
          full_name: data.full_name,
          position: data.position,
          shift: data.shift,
          type: data.type,
          active: data.active,
        })
        .then(function (response) {
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  //Holidays
  function getHolidays() {
    if (loginSession.length != 0) {
      axios
        .get(`${config.host}:${config.port}/api/v2/hr/holidays`)
        .then(function (response) {
          let data = response.data;
          setHolidays(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function addHolidays(data, actions) {
    console.log(data);
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/hr/add_holidays`, {
          title: data.title,
          date: data.date,
          type: data.type,
        })
        .then(function (response) {
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function updateHoliday(data, actions) {
  
    if (loginSession.length != 0) {
      // console.log(data);
      axios
        .post(`${config.host}:${config.port}/api/v2/hr/update_holiday`, {
          id: data.id,
          type: data.type,
          title: data.title,
          date: data.date,
        })
        .then(function (response) {
          getHolidays();
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    getEmployees();
    getHolidays();
  }, []);

  return { employees, addEmployees,updateEmployees, holidays, addHolidays, updateHoliday };
}

export default useTimeKeeping;
