import dateFormat from "dateformat";
import useConvertToCSV from "../../services/useConvertToCSV";

function useExtractLeaves() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadLeaveReport(leaves) {
    console.log(leaves);
    var headers = {
      
      last_name: "LAST NAME",
      first_name: "FIRST NAME",
      leave_start_date: "START DATE",
      leave_end_date: "END DATE",
      numberOfHours: "NUMBER OF HOURS",
      type: "TYPE",
      year: "YEAR",
      month: "MONTH",
    };

    var itemsNotFormatted = leaves;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      // console.log(item);
      itemsFormatted.push({
        last_name: item.last_name,
        first_name: item.first_name,
        leave_start_date: item.leave_start_date,
        leave_end_date: item.leave_end_date,
        numberOfHours: item.numberOfHours ? item.numberOfHours : item.numberofhrs,
        type: item.type,
        year: item.year,
        month: item.month,
      });
    });

    var fileTitle = `hrsync_leave_report_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return { downloadLeaveReport };
}

export default useExtractLeaves;
