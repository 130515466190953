import { useState, useEffect } from "react";
import config from "./../../../config";
import axios from "axios";

function useBoardsManager() {
  let loginSession = window.localStorage.getItem("session")
    ? JSON.parse(window.atob(window.localStorage.getItem("session")))
    : [];

  const [board, setBoard] = useState([]);
  const [boardTask, setBoardTask] = useState([]);

  function getBoard(id) {
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/boards/get_board`, {
          id: id,
        })
        .then(function (response) {
          let data = response.data.map((d) => ({
            ...d,
            categories: JSON.parse(d.categories),
            items: JSON.parse(window.atob(d.items)),
          }))
          .map((d) => ({
            ...d,
            items: d.items.map((i, n) => ({ ...i, index: n })),
          }));
          setBoard(data);

          // axios
          //   .post(`${config.host}:${config.port}/api/v2/boards/tasks`, {
          //     id: id,
          //   })
          //   .then(function (response) {
          //     let data = response.data.map((d, index) => ({
          //       ...d,
          //       id: `items-${d.id}`,
          //       // items: JSON.parse(d.items),
          //     }));
          //     setBoardTask(data);
          //   })
          //   .catch(function (error) {
          //     console.log(error);
          //   });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function updateBoard(data, actions) {
    if (loginSession.length != 0) {
      // console.log(data.items);
      // console.log("test");
      axios
        .post(`${config.host}:${config.port}/api/v2/boards/update_board_tasks`, {
          id: data.id,
          items: window.btoa(JSON.stringify(data.items))
        })
        .then(function (response) {
          actions()
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  return {
    updateBoard,
    getBoard,
    board,
    boardTask,
  };
}

export default useBoardsManager;
