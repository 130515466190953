import React, { useState, useEffect } from "react";
import { Card, Table, Button, Icon, Loader, Grid } from "tabler-react";
import dateFormat from "dateformat";
import Tooltip from "react-simple-tooltip";
import Pagination from "../../../../../components/Pagination";
import Filters from "../../components/Filters";

//hooks
import useUsers from "../../../../../hooks/api/Users/useUsers";
import useQueryURL from "../../../../../hooks/services/useQueryURL";
import use711Dashboard from "../../../../../hooks/api/AMS/use711Dashboard";
import useCustomFunctions from "../../../../../hooks/services/useCustomFunctions";
import useCreateBackURL from "../../../../../hooks/services/useCreateBackURL";

const PendingCalls = () => {
  const { user } = useUsers();
  const { backURL } = useCreateBackURL();
  const { formatNumberComma } = useCustomFunctions();
  const { getQuery } = useQueryURL();

  const [accessLevel, setAccessLevel] = useState("none");
  const [rangeSelection, setRangeSelection] = useState(
    getQuery("range") ? getQuery("range") : "Today"
  );
  const { telemarketers } = use711Dashboard(rangeSelection);

  //variables
  const [stores, setStores] = useState([]);
  const [list, setList] = useState([]);
  const [curWeek, setCurWeek] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  //SideEffectes
  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "711-account-management")
            .access
        : "none"
    );
    setRangeSelection(getQuery("range") ? getQuery("range") : "Today");
  }, [user]);

  useEffect(() => {
    if (telemarketers != 0) {
      let storeList = telemarketers.find(
        (tele) => tele.full_name === getQuery("tele")
      ).all_stores;
      setStores(
        telemarketers
          .find((tele) => tele.full_name === getQuery("tele"))
          .pending_calls.map((store) => ({
            ...store,
            courier_status: storeList.find(
              (s) =>
                parseInt(s.store_number.replace("*", "")) == store.store_number
            )
              ? storeList.find(
                  (s) =>
                    parseInt(s.store_number.replace("*", "")) ==
                    store.store_number
                ).courier_status
              : "--",
            location: storeList.find(
              (s) =>
                parseInt(s.store_number.replace("*", "")) == store.store_number
            )
              ? storeList.find(
                  (s) =>
                    parseInt(s.store_number.replace("*", "")) ==
                    store.store_number
                ).location
              : "",
          }))
      );
      setList(
        telemarketers
          .find((tele) => tele.full_name === getQuery("tele"))
          .pending_calls.map((store) => ({
            ...store,
            courier_status: storeList.find(
              (s) =>
                parseInt(s.store_number.replace("*", "")) == store.store_number
            )
              ? storeList.find(
                  (s) =>
                    parseInt(s.store_number.replace("*", "")) ==
                    store.store_number
                ).courier_status
              : "--",
            location: storeList.find(
              (s) =>
                parseInt(s.store_number.replace("*", "")) == store.store_number
            )
              ? storeList.find(
                  (s) =>
                    parseInt(s.store_number.replace("*", "")) ==
                    store.store_number
                ).location
              : "",
          }))
      );
      setCurWeek(
        telemarketers.find((tele) => tele.full_name === getQuery("tele"))
          .curWeek
      );
    }
  }, [telemarketers]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [stores]);

  return (
    <>
      <div style={{ margin: "5px" }}>
        {telemarketers.length != 0 || !loading ? (
          <Grid.Row>
            <Grid.Col md={12} className="col-dashboard-container">
              <div style={{ margin: "5px" }}>
                <Card>
                  <Card.Header>
                    PENDING 711 CALL LOGS
                    <Card.Options>
                      <Button icon="download" color="cyan">
                        Export
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() =>
                          window.location.replace(getQuery("backURL"))
                        }
                      >
                        BACK
                      </Button>
                    </Card.Options>
                  </Card.Header>
                  <Filters
                    data={{
                      setLoading,
                      setStores,
                      list,
                      rangeSelection,
                      curWeek,
                      status: "pending",
                    }}
                  />

                  {stores.length != 0 ? (
                    <>
                      <Card.Body>
                        {loading ? (
                          <div style={{ padding: "5px" }}>
                            <Loader className="loader" />
                          </div>
                        ) : (
                          <>
                            <div style={{ overflowX: "scroll" }}>
                              <Table
                                headerItems={[
                                  { content: "No." },
                                  { content: "Store Number" },
                                  { content: "Store Name" },
                                  { content: "Schedule" },
                                  { content: "Telemarketer" },
                                  { content: "Date Order" },
                                  { content: "Amount" },
                                  { content: "Received By" },
                                  { content: "Courier Status" },
                                  { content: "Remarks" },
                                  { content: "Actions" },
                                ]}
                              >
                                <Table.Body>
                                  {stores
                                    .sort(function (a, b) {
                                      return a.id - b.id;
                                    })
                                    .slice(
                                      activePage == 1
                                        ? 0
                                        : (activePage - 1) * 15,
                                      activePage * 15
                                    )
                                    .map((d, index) => (
                                      <Table.Row key={index}>
                                        <Table.Col>
                                          <b>
                                            {activePage == 1
                                              ? index + 1
                                              : (activePage - 1) * 10 +
                                                index +
                                                1}
                                            .
                                          </b>
                                        </Table.Col>
                                        <Table.Col>
                                          {d.store_number.length === 1
                                            ? `00${d.store_number}`
                                            : d.store_number.length === 2
                                            ? `0${d.store_number}`
                                            : d.store_number}
                                        </Table.Col>
                                        <Table.Col>{d.store_name}</Table.Col>
                                        <Table.Col>{d.schedule}</Table.Col>
                                        {/* <Table.Col>{d.account_executive}</Table.Col> */}
                                        <Table.Col>{d.telemarketer}</Table.Col>
                                        <Table.Col>
                                          {dateFormat(
                                            d.date_order,
                                            "mediumDate"
                                          )}
                                        </Table.Col>
                                        <Table.Col>
                                          {formatNumberComma(d.amount)}
                                        </Table.Col>
                                        {/* <Table.Col>{d.remarks}</Table.Col> */}
                                        {/* <Table.Col>{d.notes}</Table.Col>
                                <Table.Col>{d.allocation}</Table.Col>
                                <Table.Col>{d.allocation_remarks}</Table.Col> */}
                                        <Table.Col>{d.received_by}</Table.Col>
                                        <Table.Col>
                                          {d.courier_status}
                                        </Table.Col>
                                        <Table.Col>{d.remarks}</Table.Col>

                                        <Table.Col>
                                          {/* <Button color="primary">
                                            <Icon prefix="fa" name="undo" />
                                          </Button> */}
                                          <Tooltip
                                            content="Call Back"
                                            radius={5}
                                            padding={5}
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            <a
                                              href={`/AMS/Account/711/Profile?id=${
                                                d.store_number.length === 1
                                                  ? `00${d.store_number}`
                                                  : d.store_number.length === 2
                                                  ? `0${d.store_number}`
                                                  : d.store_number
                                              }&backURL=${window.btoa(
                                                backURL
                                              )}`}
                                            >
                                              <Button color="teal">
                                                <Icon
                                                  prefix="fa"
                                                  name="mail-reply"
                                                />
                                              </Button>
                                            </a>
                                          </Tooltip>
                                        </Table.Col>
                                      </Table.Row>
                                    ))}
                                </Table.Body>
                              </Table>
                            </div>
                          </>
                        )}
                      </Card.Body>
                      <Card.Body>
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={15}
                          // changePage={changePage}
                          totalItemsCount={stores.length}
                          pageRangeDisplayed={pageRangeDisplayed}
                          setActivePage={setActivePage}
                          setPageRangeDisplayed={setPageRangeDisplayed}
                        />
                      </Card.Body>
                    </>
                  ) : (
                    <Card.Body>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "40px",
                          color: "#b9b9b9",
                        }}
                      >
                        <Icon prefix="fa" name="folder-open" />
                        <p
                          style={{
                            fontSize: "20px",
                            margin: 0,
                            fontWeight: "bold",

                            textTransform: "uppercase",
                          }}
                        >
                          NO DATA AVAILABLE
                        </p>
                      </div>
                    </Card.Body>
                  )}
                </Card>
              </div>
            </Grid.Col>
          </Grid.Row>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default PendingCalls;
