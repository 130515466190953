import React, { useState, useEffect } from "react";
import { Button, Card, Form, Grid, Table } from "tabler-react";

const ProfileForm = (props) => {
  const {
    otherAccount,
    updateAccount,
    getOtherAccountByID,
    accessLevel,
    formType,
    openAddForm,
    setOpenAddForm,
  } = props.data;

  const [editAccount, setEditAccount] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (otherAccount) {
      setFormData({
        id: otherAccount.id,
        tin_id: otherAccount.tin_id,
        client_id: otherAccount.client_id,
        account_holder: otherAccount.account_holder,
        address: otherAccount.address,
        contact_person: otherAccount.contact_person,
        mobile_phone: otherAccount.mobile_phone,
        email_address: otherAccount.email_address,
        nature_of_business: otherAccount.nature_of_business,
        particulars: otherAccount.particulars,
        terms: otherAccount.terms,
        price_class: otherAccount.price_class,
        ae_name: otherAccount.ae_name,
        active: otherAccount.active,
      });
    }
  }, [otherAccount]);

  // console.log(formData);

  return (
    <>
      {formData ? (
        <Card>
          <Card.Header>
            {formData.account_holder} Profile
            {["admin", "edit"].includes(accessLevel) && formType !== "new" ? (
              <Card.Options>
                {editAccount ? (
                  <>
                    <Button
                      color="cyan"
                      icon="save"
                      onClick={() =>
                        updateAccount(
                          formData,
                          () =>
                            alert("Profile Successfully Saved!") ||
                            getOtherAccountByID(formData.id)
                        )
                      }
                    >
                      SAVE CHANGES
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => setEditAccount(false)}
                    >
                      CANCEL
                    </Button>
                  </>
                ) : (
                  <Button
                    color="azure"
                    icon="edit"
                    onClick={() => setEditAccount(true)}
                  >
                    EDIT
                  </Button>
                )}
              </Card.Options>
            ) : (
              ""
            )}
            {["admin", "edit"].includes(accessLevel) && formType === "new" ? (
              <Card.Options>
                {formData?.client_id ? (
                  <Button
                    color="cyan"
                    icon="save"
                    onClick={() =>
                      updateAccount(formData, () =>
                        alert("New Profile Successfully Added!")
                      )
                    }
                  >
                    SAVE CHANGES
                  </Button>
                ) : (
                  ""
                )}
                <Button color="secondary" onClick={() => setOpenAddForm(false)}>
                  CANCEL
                </Button>
              </Card.Options>
            ) : (
              ""
            )}
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={1}>
                <Form.Group>
                  <Form.Label>Client ID</Form.Label>
                  <Form.Input
                    value={formData.client_id?.replace("*", "")}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        client_id: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Client Name</Form.Label>
                  <Form.Input
                    value={formData.account_holder}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        account_holder: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                    valid={editAccount && ["edit"].includes(accessLevel)}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={3}>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Input
                    value={formData.address}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        address: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Contact Person</Form.Label>
                  <Form.Input
                    value={formData.contact_person}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        contact_person: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                    valid={editAccount && ["edit"].includes(accessLevel)}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>TIN Number</Form.Label>
                  <Form.Input
                    value={formData.tin_id}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        tin_id: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Input
                    value={formData.mobile_phone}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        mobile_phone: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                    valid={editAccount && ["edit"].includes(accessLevel)}
                  />
                </Form.Group>
              </Grid.Col>

              {/*new row*/}
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Input
                    value={formData.email_address}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        email_address: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                    valid={editAccount && ["edit"].includes(accessLevel)}
                  />
                </Form.Group>
              </Grid.Col>
              {/* <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Nature of Business</Form.Label>
                  <Form.Input
                    value={formData.nature_of_business}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        nature_of_business: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col> */}

              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Account Executive</Form.Label>
                  <Form.Input
                    value={formData.ae_name}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ae_name: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col>

              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Particulars</Form.Label>
                  <Form.Input
                    value={formData.particulars}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        particulars: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col>
              {/* <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>ECash sheme Type</Form.Label>
                  <Form.Input value={formData.ecash_scheme_type} />
                </Form.Group>
              </Grid.Col> */}
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Terms</Form.Label>
                  <Form.Input
                    value={formData.terms}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        terms: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Price Class</Form.Label>
                  <Form.Input
                    value={formData.price_class}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        price_class: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={formData.active}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        active: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                    valid={editAccount && ["edit"].includes(accessLevel)}
                  >
                    <option value="">SELECT ONE</option>
                    <option value="0">Active</option>
                    <option value="1">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        </Card>
      ) : (
        ""
      )}
    </>
  );
};

export default ProfileForm;
