import React, { useEffect, useState } from "react";
import { Table, Card, Avatar, Text, Grid, Button, Icon } from "tabler-react";
import Tooltip from "react-simple-tooltip";
import useOADashboard from "../../../hooks/api/AMS_sales/useOADashboard";
import useUsers from "../../../hooks/api/Users/useUsers";
import Pagination from "../../../components/Pagination";
import useOtherAccountsCalls from "../../../hooks/api/AMS_sales/useOtherAccountsCalls";
import dateFormat from "dateformat";
import { CURRENT_YEAR } from "../../../config";

const Dashboard = () => {
  const { dashboard, todayScheduled, getScheduledToday } = useOADashboard();
  const { monthlyPO } = useOtherAccountsCalls();
  const { user } = useUsers();

  useEffect(() => {
    getScheduledToday(user);
  }, [user]);

  console.log(monthlyPO);

  const [activePage, setActivePage] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const [myAgents, setAgents] = useState([]);

  useEffect(() => {
    setAgents(
      monthlyPO
        .map((month) => month.received_by)
        .filter(
          (v, i) => monthlyPO.map((month) => month.received_by).indexOf(v) == i
        )
    );
  }, [monthlyPO]);

  console.log(todayScheduled);

  return (
    <>
      {dashboard.length !== 0 ? (
        <Card>
          <Card.Body>
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Telemarketer OA</Table.ColHeader>
                  {/* <Table.ColHeader>Completed Stores</Table.ColHeader> */}
                  <Table.ColHeader>Pending Calls</Table.ColHeader>
                  <Table.ColHeader>Actions</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <>
                  {dashboard
                    .map((tele) => ({
                      ...tele,
                      initials: tele.full_name.match(/\b(\w)/g).slice(0, 2),
                    }))
                    .map((tele, index) => (
                      <Table.Row key={index}>
                        <Table.Col>
                          <Grid.Row>
                            <Grid.Col md={3}>
                              <Avatar size="md" color="cyan">
                                {tele.initials}
                              </Avatar>
                            </Grid.Col>
                            <Grid.Col md={9}>
                              <Text size="md">{tele.full_name}</Text>
                              <Text size="sm" muted>
                                {tele.role}
                              </Text>
                            </Grid.Col>
                          </Grid.Row>
                        </Table.Col>
                        <Table.Col>{tele.pending_calls.length}</Table.Col>
                        <Table.Col>
                          {tele.pending_calls.length == 0 ? (
                            ""
                          ) : (
                            <Tooltip
                              content="Pending Calls"
                              radius={5}
                              padding={5}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <Button
                                color="teal"
                                onClick={() =>
                                  window.location.replace(
                                    `/AMS_sales/AllCallLogs?call_status=Pending&dates=${tele.start_date}|${tele.end_date}&ea_name=${user.full_name}`
                                  )
                                }
                              >
                                <Icon prefix="fa" name="mail-reply" />
                              </Button>
                            </Tooltip>
                          )}
                        </Table.Col>
                      </Table.Row>
                    ))}
                </>
              </Table.Body>
            </Table>
          </Card.Body>
        </Card>
      ) : (
        ""
      )}

      <Card>
        <Card.Header>Call Schedule Today</Card.Header>
        {todayScheduled.filter((today) => today.active === 0).length == 0 ? (
          <Card.Body>
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          </Card.Body>
        ) : (
          <Card.Body>
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Client ID</Table.ColHeader>
                  <Table.ColHeader>Client Name</Table.ColHeader>
                  <Table.ColHeader>Contact Person</Table.ColHeader>
                  <Table.ColHeader>Mobile Number</Table.ColHeader>
                  <Table.ColHeader>Acount</Table.ColHeader>
                  {/* <Table.ColHeader>AE Name</Table.ColHeader> */}
                  <Table.ColHeader>Status</Table.ColHeader>
                  <Table.ColHeader>Actions</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {todayScheduled
                  .filter((today) => today.active === 0)
                  .slice(
                    activePage == 1 ? 0 : (activePage - 1) * pageLimit,
                    activePage * pageLimit
                  )
                  .map((account, index) => (
                    <Table.Row key={index}>
                      <Table.Col>
                        {account.client_id?.replace("*", "")}
                      </Table.Col>
                      <Table.Col>{account.account_holder}</Table.Col>
                      <Table.Col>{account.contact_person}</Table.Col>
                      <Table.Col>{account.mobile_number}</Table.Col>
                      <Table.Col>{account.paticulars}</Table.Col>
                      {/* <Table.Col>{account.ae_name}</Table.Col> */}
                      <Table.Col>
                        {account.active == 0 ? "Active" : "Inactive"}
                      </Table.Col>
                      <Table.Col>
                        <Button
                          color="cyan"
                          icon="home"
                          onClick={() =>
                            window.location.replace(
                              `/AMS_sales/Account?id=${account.id}`
                            )
                          }
                        >
                          View Profile
                        </Button>
                        {/* <Button color="teal" icon="edit"></Button> */}
                      </Table.Col>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Card.Body>
        )}
        {todayScheduled.length <= pageLimit ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={pageLimit}
            totalItemsCount={todayScheduled.length}
            pageRangeDisplayed={pageRangeDisplayed}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>

      {monthlyPO.length !== 0 &&
      monthlyPO.filter((month) => month.received_by === user.full_name)
        .length !== 0 &&
      user.length !== 0 &&
      user.apps_list.find((app) => app.app === "ams-sales-accounts").access ===
        "edit" ? (
        <Card>
          <Card.Body>
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Month</Table.ColHeader>
                  <Table.ColHeader>Total PO</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {monthlyPO
                  .filter((month) => month.received_by === user.full_name)
                  .sort((a, b) => b.month - a.month)
                  .map((month) => (
                    <Table.Row>
                      <Table.Col>
                        {dateFormat(new Date(`${month.month}/01/${CURRENT_YEAR}`), "mmmm")}
                      </Table.Col>
                      <Table.Col>
                        {numberWithCommas(month.total_PO.toFixed(2))}
                      </Table.Col>
                    </Table.Row>
                  ))}
                <Table.Row>
                  <Table.Col>TOTAL P.O</Table.Col>
                  <Table.Col>
                    {numberWithCommas(
                      monthlyPO
                        .filter((month) => month.received_by === user.full_name)
                        .reduce(
                          (a, b) => parseFloat(a) + parseFloat(b.total_PO),
                          0
                        )
                        .toFixed(2)
                    )}
                  </Table.Col>
                </Table.Row>
              </Table.Body>
            </Table>
          </Card.Body>
        </Card>
      ) : (
        <>
          {user.length !== 0 &&
          user.apps_list.find((app) => app.app === "ams-sales-accounts")
            .access === "admin" ? (
            <Grid.Row>
              {myAgents.map((agent) => (
                <Grid.Col md={4}>
                  <Card>
                    <Card.Header>{agent}</Card.Header>
                    <Card.Body>
                      <Table
                        responsive
                        highlightRowOnHover
                        hasOutline
                        verticalAlign="center"
                        cards
                        className="text-nowrap"
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.ColHeader>Month</Table.ColHeader>
                            <Table.ColHeader>Total PO</Table.ColHeader>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {monthlyPO
                            .filter((month) => month.received_by === agent)
                            .sort((a, b) => b.month - a.month)
                            .map((month) => (
                              <Table.Row>
                                <Table.Col>
                                  {dateFormat(
                                    new Date(`${month.month}/01/${CURRENT_YEAR}`),
                                    "mmmm"
                                  )}
                                </Table.Col>
                                <Table.Col>
                                  {numberWithCommas(month.total_PO.toFixed(2))}
                                </Table.Col>
                              </Table.Row>
                            ))}
                          <Table.Row>
                            <Table.Col>TOTAL P.O</Table.Col>
                            <Table.Col>
                              {numberWithCommas(
                                monthlyPO
                                  .filter(
                                    (month) => month.received_by === agent
                                  )
                                  .reduce(
                                    (a, b) =>
                                      parseFloat(a) + parseFloat(b.total_PO),
                                    0
                                  )
                                  .toFixed(2)
                              )}
                            </Table.Col>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Card.Body>
                  </Card>
                </Grid.Col>
              ))}
            </Grid.Row>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
