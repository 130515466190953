import React, { useState, useEffect } from "react";
import { Card, Progress, Loader } from "tabler-react";

const ProgressBar = (props) => {
  const { classname, header, content, progressColor, progressWidth, color } =
    props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
    }, 500);
  }, [content, progressColor, progressWidth, color]);
  
  return (
    <Card>
      <Card.Body className="text-center">
        {loading ? (
          <div style={{ padding: "5px" }}>
            <Loader className="loader" />
          </div>
        ) : (
          <>
            <strong style={{ fontSize: "11px" }}>{header}</strong>
            <div
              className="display-4 font-weight-bold mb-2"
              style={{ color: color, fontSize: "35px" }}
            >
              {content}
            </div>
            <Progress size="sm">
              <Progress.Bar color={progressColor} width={progressWidth} />
            </Progress>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProgressBar;
