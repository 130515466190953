import React, { useState, useEffect } from "react";
import { Card, Table, Button, Icon, Loader, Grid } from "tabler-react";
import Tooltip from "react-simple-tooltip";
import Pagination from "../../../../../components/Pagination";
import Filters from "../../components/Filters";

//hooks
import useUsers from "../../../../../hooks/api/Users/useUsers";
import useQueryURL from "../../../../../hooks/services/useQueryURL";
import use711Dashboard from "../../../../../hooks/api/AMS/use711Dashboard";
import useCreateBackURL from "../../../../../hooks/services/useCreateBackURL";
import useExport711Stores from "../../../../../hooks/api/AMS/extractions/useExport711Stores";

const UncalledStores = () => {
  const { user } = useUsers();
  const { getQuery } = useQueryURL();
  const { backURL } = useCreateBackURL();
  const { downloadStores } = useExport711Stores();

  const [accessLevel, setAccessLevel] = useState("none");
  const [rangeSelection, setRangeSelection] = useState("");
  const { telemarketers } = use711Dashboard(rangeSelection);

  //variables
  const [stores, setStores] = useState([]);
  const [telemarketer, setTelemarketer] = useState([]);
  const [list, setList] = useState([]);
  const [curWeek, setCurWeek] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  //SideEffectes
  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "711-account-management")
            .access
        : "none"
    );
    setRangeSelection(getQuery("range") ? getQuery("range") : "Today");
    setRangeSelection(getQuery("range") ? getQuery("range") : "Today");
  }, [user]);

  useEffect(() => {
    if (telemarketers.length != 0) {
      setTelemarketer(
        telemarketers.find((tele) => tele.full_name === getQuery("tele"))
      );
    }
  }, [telemarketers]);

  useEffect(() => {
    if (telemarketer.length != 0) {
      setStores(
        telemarketer.uncalled_stores.filter((store) =>
          rangeSelection === "Today"
            ? store.schedule === telemarketer.schedule
            : store
        )
      );

      setList(telemarketer.uncalled_stores);
      setCurWeek(telemarketer.curWeek);
    }
  }, [telemarketers, telemarketer, rangeSelection]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [stores]);

  let ORDER_RATE = getQuery("order_rate") ? getQuery("order_rate") : "";

  console.log(stores);

  return (
    <>
      <div style={{ margin: "5px" }}>
        {user.length != 0 || !loading ? (
          <Grid.Row>
            <Grid.Col md={12} className="col-dashboard-container">
              <div style={{ margin: "5px" }}>
                <Card>
                  <Card.Header>
                    UNCALLED 711 STORES
                    <Card.Options>
                      <Button
                        icon="download"
                        color="cyan"
                        onClick={() => downloadStores(stores)}
                      >
                        Export
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() =>
                          window.location.replace(getQuery("backURL"))
                        }
                      >
                        BACK
                      </Button>
                    </Card.Options>
                  </Card.Header>
                  <Filters
                    data={{
                      setLoading,
                      setStores,
                      list,
                      rangeSelection,
                      curWeek,
                      status: "uncalled",
                    }}
                  />

                  {stores.length != 0 ? (
                    <>
                      <Card.Body>
                        {loading ? (
                          <div style={{ padding: "5px" }}>
                            <Loader className="loader" />
                          </div>
                        ) : (
                          <>
                            <div style={{ overflowX: "scroll" }}>
                              <Table
                                headerItems={[
                                  { content: "No." },
                                  { content: "Store Number" },
                                  { content: "Store Name" },
                                  { content: "Schedule" },
                                  { content: "Telemarketer" },
                                  { content: "Courier Status" },
                                  { content: "Actions" },
                                ]}
                              >
                                <Table.Body>
                                  {stores
                                    .sort(function (a, b) {
                                      if (ORDER_RATE !== "") {
                                        return ORDER_RATE === "high"
                                          ? b.order_count - a.order_count
                                          : a.order_count - b.order_count;
                                      } else {
                                        return a.id - b.id;
                                      }
                                    })
                                    .slice(
                                      activePage == 1
                                        ? 0
                                        : (activePage - 1) * 15,
                                      activePage * 15
                                    )
                                    .map((d, index) => (
                                      <Table.Row key={index}>
                                        <Table.Col>
                                          <b>
                                            {activePage == 1
                                              ? index + 1
                                              : (activePage - 1) * 10 +
                                                index +
                                                1}
                                            .
                                          </b>
                                        </Table.Col>
                                        {/* <Table.Col>
                                          {
                                            d.order_count
                                          }
                                        </Table.Col> */}
                                        <Table.Col>
                                          {d.store_number.replace("*", "")}
                                        </Table.Col>
                                        <Table.Col>{d.store_name}</Table.Col>
                                        <Table.Col>{d.schedule}</Table.Col>
                                        {/* <Table.Col>{d.account_executive}</Table.Col> */}
                                        <Table.Col>{d.telemarketer}</Table.Col>
                                        <Table.Col>
                                          {d.courier_status}
                                        </Table.Col>
                                        <Table.Col>
                                          <Tooltip
                                            content="Call the Store"
                                            radius={5}
                                            padding={5}
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            <a
                                              href={`/AMS/Account/711/Profile?id=${d.store_number.replace(
                                                "*",
                                                ""
                                              )}&backURL=${window.btoa(
                                                backURL
                                              )}`}
                                            >
                                              <Button color="teal">
                                                <Icon
                                                  prefix="fa"
                                                  name="phone"
                                                />{" "}
                                                Call
                                              </Button>
                                            </a>
                                          </Tooltip>
                                          <Tooltip
                                            content="Edit Store Details"
                                            radius={5}
                                            padding={5}
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            <a
                                              href={`/AMS/UpdateForm/711?id=${d.store_number.replace(
                                                "*",
                                                ""
                                              )}&backURL=${window.btoa(
                                                backURL
                                              )}`}
                                            >
                                              <Button color="azure">
                                                <Icon prefix="fa" name="edit" />{" "}
                                                Edit
                                              </Button>
                                            </a>
                                          </Tooltip>
                                        </Table.Col>
                                      </Table.Row>
                                    ))}
                                  {/* {newCallLogs.length === 0
                                ? "No Result for this Search"
                                : ""} */}
                                </Table.Body>
                              </Table>
                            </div>
                          </>
                        )}
                      </Card.Body>
                      <Card.Body>
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={15}
                          // changePage={changePage}
                          totalItemsCount={stores.length}
                          pageRangeDisplayed={pageRangeDisplayed}
                          setActivePage={setActivePage}
                          setPageRangeDisplayed={setPageRangeDisplayed}
                        />
                      </Card.Body>
                    </>
                  ) : (
                    <Card.Body>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "40px",
                          color: "#b9b9b9",
                        }}
                      >
                        <Icon prefix="fa" name="folder-open" />
                        <p
                          style={{
                            fontSize: "20px",
                            margin: 0,
                            fontWeight: "bold",

                            textTransform: "uppercase",
                          }}
                        >
                          NO DATA AVAILABLE
                        </p>
                      </div>
                    </Card.Body>
                  )}
                </Card>
              </div>
            </Grid.Col>
          </Grid.Row>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default UncalledStores;
