import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractReportShopee2() {
  const { exportCSVFile } = useConvertToCSV();

  function download(order, dr_number) {
    var headers = {
      id: "id",
      dr_number: "dr_number",
      SI_number: "SI_number",
      SI_Date: "SI_Date",
      order_date: "order_date",
      order_id: "order_id",
      shipout_date: "shipout_date",
      product_name: "product_name",
      // tracking_number: "tracking_number",
      gate_price: "gate_price",
      shopee_price: "shopee_price",
      quantity: "quantity",
      status: "status",
      remarks: "remarks",
    };

    var itemsNotFormatted = order;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        id: item.id,
        dr_number: item.dr_number,
        SI_number: item.SI_number,
        SI_Date: item.SI_Date,
        order_date: dateFormat(item.order_date, "shortDate"),
        order_id: item.order_id,
        shipout_date: dateFormat(item.shipout_date, "shortDate"),
        product_name: item.product_name,
        // tracking_number: tracking_number,
        gate_price: item.gate_price,
        shopee_price: item.shopee_price,
        quantity: item.quantity,
        status: item.status,
        remarks: item.remarks,
      });
    });

    var fileTitle = `Shopee orders_${dr_number}_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return download;
}

export default useExtractReportShopee2;
