import React from "react";
import { Card, Grid, Button, Icon } from "tabler-react";

const SideMenu = (props) => {
  const { setTab } = props.data;
  return (
    <>
      <Card>
        <Card.Body>
          <Button
            color="teal"
            className="menu-button"
            onClick={() => window.location.replace("/HR")}
          >
            <Icon prefix="fa" name="calendar" /> Calendar
          </Button>
          <Button
            color="teal"
            className="menu-button"
            onClick={() => window.location.replace("/HR/DTR")}
          >
            <Icon prefix="fa" name="print" /> Genrate DTR
          </Button>
          {!setTab ? (
            <Button
            color="teal"
            className="menu-button"
            onClick={() => window.location.replace("/HR/Manager")}
          >
            <Icon prefix="fa" name="user" /> Employees & Holidays
          </Button>
          ) : (
            <>
              <Button
                color="teal"
                className="menu-button"
                onClick={() => setTab(0)}
              >
                <Icon prefix="fa" name="user" /> Employees
              </Button>
              <Button
                color="teal"
                className="menu-button"
                onClick={() => setTab(1)}
              >
                <Icon prefix="fa" name="tasks" /> Holidays
              </Button>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default SideMenu;
