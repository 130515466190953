import config from "../../../config";
import axios from "axios";
import useUsers from "../Users/useUsers";

function useShopeeEdit() {
  const { loginSession } = useUsers();
  console.log();

  function updateOrderDetails(order) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/tiktok_gomo/updated_order_shop_name`,
          {
            id: order.id,
            product_sku: order.sku,
            last_edit: loginSession.username,
          }
        )
        .then(function (response) {
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  return {
    updateOrderDetails,
  };
}

export default useShopeeEdit;
