import { useState, useEffect } from "react";
import config from "../../../config";
import axios from "axios";
import useUsers from "../Users/useUsers";

function useLazadaPaymentReleaseAPI() {
  const { loginSession } = useUsers();

  function uploadPaymentRelease(data, action) {
    if (loginSession.length !== 0) {
      axios
      .post(`${config.host}:${config.port}/api/v2/lazada/insert_payment_release`, {
        payments: data,
      })
      .then(function (response) {
        // handle success
        action()
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    }
    
  }

  return { uploadPaymentRelease };
}
export default useLazadaPaymentReleaseAPI;
