import React, { useState, useEffect } from "react";
import { Card, Table, Button, Icon, Loader, Grid } from "tabler-react";
import dateFormat from "dateformat";
import Tooltip from "react-simple-tooltip";
import Pagination from "../../../../../components/Pagination";
import Filters from "../../components/Filters";

//hooks
import useUsers from "../../../../../hooks/api/Users/useUsers";
import useQueryURL from "../../../../../hooks/services/useQueryURL";
import use711Dashboard from "../../../../../hooks/api/AMS/use711Dashboard";
import useCustomFunctions from "../../../../../hooks/services/useCustomFunctions";
import useCreateBackURL from "../../../../../hooks/services/useCreateBackURL";
import useExportCallLogs from "../../../../../hooks/api/AMS/extractions/useExportCallLogs";
import use711Calls from "../../../../../hooks/api/AMS/use711Calls";
import { CURRENT_YEAR } from "../../../../../config";

const CompletedCalls = () => {
  const { user } = useUsers();
  const { backURL } = useCreateBackURL();
  const { formatNumberComma } = useCustomFunctions();
  const { getQuery } = useQueryURL();
  const { downloadCallLogs } = useExportCallLogs();

  const [accessLevel, setAccessLevel] = useState("none");
  const [rangeSelection, setRangeSelection] = useState(
    getQuery("range") ? getQuery("range") : "Today"
  );
  const { telemarketers } = use711Dashboard(rangeSelection);
  const { allCallLogs, getAllCallLogs } = use711Calls();

  //variables
  const [stores, setStores] = useState([]);
  const [list, setList] = useState([]);
  const [curWeek, setCurWeek] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);
  const [telemarketer, setTelemarketer] = useState("");

  const [year, setYear] = useState(CURRENT_YEAR);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    setYear(getQuery("year") ? getQuery("year") : CURRENT_YEAR);
    setStartDate(
      getQuery("dates") ? new Date(getQuery("dates").split("|")[0]) : new Date()
    );
    setEndDate(
      getQuery("dates") ? new Date(getQuery("dates").split("|")[1]) : new Date()
    );
  }, [user, accessLevel]);

  useEffect(() => {
    getAllCallLogs({ startDate, endDate });
  }, [year, startDate, endDate]);

  //SideEffectes
  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "711-account-management")
            .access
        : "none"
    );
    setRangeSelection(getQuery("range") ? getQuery("range") : "Today");
    setTelemarketer(getQuery("tele") ? getQuery("tele") : "");
  }, [user]);

  useEffect(() => {
    if (allCallLogs.length != 0) {
      setStores(allCallLogs);
      setList(allCallLogs);
    }
  }, [allCallLogs, user]);

  console.log(stores);

  useEffect(() => {
    setLoading(true);
    if (stores.length != 0) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [stores, list, telemarketers, allCallLogs]);

  console.log(list);

  return (
    <>
      <div style={{ margin: "5px" }}>
        {telemarketers.length != 0 || !loading ? (
          <Grid.Row>
            <Grid.Col md={12} className="col-dashboard-container">
              <div style={{ margin: "5px" }}>
                <Card>
                  <Card.Header>
                    ALL 711 CALL LOGS
                    <Card.Options>
                      <Button
                        icon="download"
                        color="cyan"
                        onClick={() => downloadCallLogs(stores)}
                        disabled={accessLevel === "view"}
                      >
                        Export
                      </Button>
                      {/* <Button
                        icon="download"
                        color="cyan"
                        onClick={() => download(allCallLogs)}
                        disabled={accessLevel === "view"}
                      >
                        Export All
                      </Button> */}
                    </Card.Options>
                  </Card.Header>
                  <Filters
                    data={{
                      setLoading,
                      setStores,
                      list,
                      rangeSelection: "weekly",
                      curWeek,
                      status: "all",
                    }}
                  />

                  {stores.length != 0 ? (
                    <>
                      <Card.Body>
                        {loading ? (
                          <div style={{ padding: "5px" }}>
                            <Loader className="loader" />
                          </div>
                        ) : (
                          <>
                            <div style={{ overflow: "scroll" }}>
                              <Table>
                                <Table.Header>
                                  <Table.Row>
                                    <Table.ColHeader>No.</Table.ColHeader>
                                    <Table.ColHeader>
                                      Store Number
                                    </Table.ColHeader>
                                    <Table.ColHeader>
                                      Store Name
                                    </Table.ColHeader>
                                    <Table.ColHeader>Schedule</Table.ColHeader>
                                    <Table.ColHeader>
                                      Telemarketer
                                    </Table.ColHeader>
                                    <Table.ColHeader>
                                      Date Order
                                    </Table.ColHeader>
                                    <Table.ColHeader>Amount</Table.ColHeader>
                                    <Table.ColHeader>
                                      Received By
                                    </Table.ColHeader>
                                    <Table.ColHeader>
                                      Courier Status
                                    </Table.ColHeader>
                                    <Table.ColHeader>Remarks</Table.ColHeader>
                                    <Table.ColHeader>
                                      Call Status
                                    </Table.ColHeader>
                                    {accessLevel === "report" ? (
                                      ""
                                    ) : (
                                      <Table.ColHeader>Actions</Table.ColHeader>
                                    )}
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {stores
                                    .sort(function (a, b) {
                                      return a.id - b.id;
                                    })
                                    .slice(
                                      activePage == 1
                                        ? 0
                                        : (activePage - 1) * 15,
                                      activePage * 15
                                    )
                                    .map((d, index) => (
                                      <Table.Row key={index}>
                                        <Table.Col>
                                          <b>
                                            {activePage == 1
                                              ? index + 1
                                              : (activePage - 1) * 10 +
                                                index +
                                                1}
                                            .
                                          </b>
                                        </Table.Col>
                                        <Table.Col>
                                          {d.store_number.length === 1
                                            ? `00${d.store_number}`
                                            : d.store_number.length === 2
                                            ? `0${d.store_number}`
                                            : d.store_number}
                                        </Table.Col>
                                        <Table.Col>{d.store_name}</Table.Col>
                                        <Table.Col>{d.schedule}</Table.Col>
                                        {/* <Table.Col>{d.account_executive}</Table.Col> */}
                                        <Table.Col>{d.telemarketer}</Table.Col>
                                        <Table.Col>
                                          {dateFormat(
                                            d.date_order,
                                            "mediumDate"
                                          )}
                                        </Table.Col>
                                        <Table.Col>
                                          {formatNumberComma(d.amount)}
                                        </Table.Col>
                                        {/* <Table.Col>{d.remarks}</Table.Col> */}
                                        {/* <Table.Col>{d.notes}</Table.Col>
                                <Table.Col>{d.allocation}</Table.Col>
                                <Table.Col>{d.allocation_remarks}</Table.Col> */}
                                        <Table.Col>{d.received_by}</Table.Col>
                                        <Table.Col>
                                          {d.courier_status}
                                        </Table.Col>
                                        <Table.Col>{d.remarks}</Table.Col>
                                        <Table.Col>{d.call_status}</Table.Col>
                                        {accessLevel === "report" ? (
                                          ""
                                        ) : (
                                          <Table.Col>
                                            <Tooltip
                                              content="Visit Store"
                                              radius={5}
                                              padding={5}
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              <a
                                                href={`/AMS/Account/711/Profile/?id=${
                                                  d.store_number.length === 1
                                                    ? `00${d.store_number}`
                                                    : d.store_number.length ===
                                                      2
                                                    ? `0${d.store_number}`
                                                    : d.store_number
                                                }&backURL=${window.btoa(
                                                  backURL
                                                )}`}
                                              >
                                                <Button color="azure">
                                                  <Icon
                                                    prefix="fa"
                                                    name="home"
                                                  />
                                                </Button>
                                              </a>
                                            </Tooltip>
                                          </Table.Col>
                                        )}
                                      </Table.Row>
                                    ))}
                                </Table.Body>
                              </Table>
                            </div>
                          </>
                        )}
                      </Card.Body>
                      <Card.Body>
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={15}
                          // changePage={changePage}
                          totalItemsCount={stores.length}
                          pageRangeDisplayed={pageRangeDisplayed}
                          setActivePage={setActivePage}
                          setPageRangeDisplayed={setPageRangeDisplayed}
                        />
                      </Card.Body>
                    </>
                  ) : (
                    <Card.Body>
                      {loading ? (
                        <div style={{ padding: "5px" }}>
                          <Loader className="loader" />
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "40px",
                            color: "#b9b9b9",
                          }}
                        >
                          <Icon prefix="fa" name="folder-open" />
                          <p
                            style={{
                              fontSize: "20px",
                              margin: 0,
                              fontWeight: "bold",

                              textTransform: "uppercase",
                            }}
                          >
                            NO DATA AVAILABLE
                          </p>
                        </div>
                      )}
                    </Card.Body>
                  )}
                </Card>
              </div>
            </Grid.Col>
          </Grid.Row>
        ) : (
          <div style={{ padding: "5px" }}>
            <Loader className="loader" />
          </div>
        )}
      </div>
    </>
  );
};

export default CompletedCalls;
