import React, { useState } from "react";
import { Icon } from "tabler-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./style/notification.css";

const CopyBTN = (props) => {
  const [showNotif, setShowNotif] = useState(false);
  return (
    <>
      <CopyToClipboard
        text={props.value}
        onCopy={() =>
          setShowNotif(true) ||
          setTimeout(() => {
            setShowNotif(false);
          }, 2000)
        }
      >
        <button
          style={{
            padding: "5px",
            border: "0px solid transparent",
            boarderRadius: "3px",
            textAlign: "center",
          }}
        >
          <Icon prefix="fa" name="copy" />
        </button>
      </CopyToClipboard>

      <div id="myAlert" className={showNotif ? "show" : ""}>
        <div class="myAlert-text-icon">
          <div class="myAlert-message">Copied to Clipboard</div>
        </div>
      </div>
    </>
  );
};

export default CopyBTN;
