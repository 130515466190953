import React, { useState, useEffect } from "react";
import { Button, Card, Table, Loader, Icon } from "tabler-react";
import Pagination from "../../../../components/Pagination";
import Filter from "./Filter";

//hooks
import useLazadaAPI from "../../../../hooks/api/LazadaShop/useLazadaAPI";
import useQueryURL from "../../../../hooks/services/useQueryURL";
import useCustomFunctions from "../../../../hooks/services/useCustomFunctions";
import useUsers from "../../../../hooks/api/Users/useUsers";

const Dashboard = () => {
  const { user } = useUsers();
  const { getQuery } = useQueryURL();
  const { formatNumberComma } = useCustomFunctions();

  let DR_month = getQuery("month");
  let page = getQuery("page");

  const [month, setMonth] = useState(DR_month ? DR_month : "January");
  const [year, setYear] = useState(
    getQuery("year") ? getQuery("year") : "2021"
  );

  const { drList } = useLazadaAPI(month, "");

  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(page ? page : 1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");

  const [newDrList, setNewDrList] = useState([]);
  

  const [accessLevel, setAccessLevel] = useState("none");

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "lazada-shop").access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (drList.length !== 0) {

        // setActivePage(1);
        setNewDrList(
          drList
            .filter((o) =>
              search
                ? o.dr_number.includes(search) || o.SI_number.includes(search)
                : o
            )
            .filter((o) =>
              search2
                ? o.balance === 0
                  ? "Ready"
                  : "Not Ready" === search2
                : o
            )
        );
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      }
    }, 1000);
  }, [drList, search, search2, month]);

  const changePage = (p) => {
    window.location.replace(`/LazadaShop/Dashboard?month=${month}&page=${p}`);
  };

  

  return (
    <>
      <Card>
        <Card.Header>DR Dashboard</Card.Header>
        <Filter
          data={{
            src: "Dashboard",
            month,
            year,
            search,
            setSearch,
            search2,
            setSearch2,
          }}
        />
        <Card.Body>
          {loading ? (
            <Loader />
          ) : (
            <>
              {newDrList.length != 0 ? (
                <Table
                  responsive
                  highlightRowOnHover
                  hasOutline
                  verticalAlign="center"
                  cards
                  className="text-nowrap"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>#</Table.ColHeader>
                      <Table.ColHeader>DR Number</Table.ColHeader>
                      <Table.ColHeader>SI Number</Table.ColHeader>
                      <Table.ColHeader>Gate Price Gross</Table.ColHeader>
                      {/* 
                <Table.ColHeader>Globe Discount</Table.ColHeader>
                <Table.ColHeader>Smart Discount</Table.ColHeader> */}
                      <Table.ColHeader>Payment Release</Table.ColHeader>
                      <Table.ColHeader>Commission Fee</Table.ColHeader>
                      <Table.ColHeader>Transaction Fee</Table.ColHeader>
                      <Table.ColHeader>Over Payment</Table.ColHeader>
                      <Table.ColHeader>Status</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {newDrList
                      .sort(function (a, b) {
                        return a.id - b.id;
                      })
                      .slice(
                        activePage == 1 ? 0 : (activePage - 1) * 30,
                        activePage * 30
                      )
                      .map((list, index) => (
                        <>
                          {list.SI_number === "--" ? (
                            <Table.Row>
                              <Table.Col>
                                {activePage == 1
                                  ? index + 1
                                  : index + 1 + (activePage - 1) * 10}
                              </Table.Col>
                              <Table.Col>
                                {list.dr_number.replace("*", "")}
                              </Table.Col>
                              <Table.Col>
                                {list.SI_number.replace("*", "")}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.gate_price)}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.amount_release)}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.commissionFee)}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.paymentFee)}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.overPay)}
                              </Table.Col>
                              <Table.Col>
                                <a
                                  href={`/LazadaShop/Orders?dr_number=${list.dr_number.replace(
                                    "*",
                                    ""
                                  )}&SI_number=${
                                    list.SI_number
                                  }&month=${month}&year=${list.DR_year}`}
                                  target="_blank"
                                >
                                  <Button
                                    color={
                                      list.balance === 0 ? "cyan" : "secondary"
                                    }
                                  >
                                    {list.balance === 0 ? "Ready" : "Not Ready"}
                                  </Button>
                                </a>
                              </Table.Col>
                            </Table.Row>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                  </Table.Body>
                </Table>
              ) : (
                ""
              )}
            </>
          )}
          {newDrList.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          ) : (
            ""
          )}
        </Card.Body>
        {newDrList.length <= 30 || loading ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={30}
            totalItemsCount={newDrList.length}
            pageRangeDisplayed={pageRangeDisplayed}
            changePage={changePage}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default Dashboard;
