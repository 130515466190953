import React, { useState, useEffect } from "react";
import { Button, Card, Form, Grid, Table } from "tabler-react";
import useUsers from "../../../hooks/api/Users/useUsers";
import useOtherAccounts from "../../../hooks/api/AMS_sales/useOtherAccounts";

const ProfileForm = (props) => {
  const {telemarketerOA} = useOtherAccounts()
  const { user } = useUsers();
  const {
    otherAccount,
    updateAccount,
    getOtherAccountByID,
    accessLevel,
    formType,
    openAddForm,
    setOpenAddForm,
  } = props.data;

  const [editAccount, setEditAccount] = useState(false);
  const [formData, setFormData] = useState({
    client_id: `*${Math.floor(Math.random() * 1000000 + 1)}`,
  });

  useEffect(() => {
    if (otherAccount) {
      setFormData({
        id: otherAccount.id,
        tin_id: otherAccount.tin_id,
        client_id:
          otherAccount.client_id ||
          `*${Math.floor(Math.random() * 1000000 + 1)}`,
        account_holder: otherAccount.account_holder,
        address: otherAccount.address,
        contact_person: otherAccount.contact_person,
        mobile_phone: otherAccount.mobile_phone,
        email_address: otherAccount.email_address,
        nature_of_business: otherAccount.nature_of_business,
        particulars: otherAccount.particulars,
        terms: otherAccount.terms,
        price_class: otherAccount.price_class,
        operating_hours: otherAccount.operating_hours,
        ae_name: otherAccount.ae_name,
        active: otherAccount.active,
      });
    }
  }, [otherAccount]);

  console.log(formData);

  const [categoryFilter, setCategoryFilter] = useState("");

  const LIST = [
    "Accessories Store",
    "Aesthetics and Wellness",
    "App",
    "Appliance Center",
    "Automotive Repair Shop",
    "Bags & Luggage Company",
    "Bake Shop",
    "Beauty Store",
    "Bike Store",
    "Bonjour CVS",
    "Book Shop",
    "Business Solutions",
    "Cake Shop",
    "Car Parts and Service",
    "Clinic",
    "Coffee Shop",
    "Computer Shop",
    "Construction",
    "Convenience Store",
    "Courier Services",
    "Crafts Store",
    "Dental Clinic",
    "Department Store",
    "Diagnostic Center/Clinic",
    "Distributor",
    "Electronics Shop",
    "Equipment Store",
    "Fast Food",
    "Financing",
    "Flower Shop",
    "Food Delivery Service",
    "Foodmart",
    "Furniture Store",
    "General Merchandise",
    "Government",
    "Grocery Store",
    "Gym",
    "Hair Salon",
    "Hardware Distributor",
    "Hardware Store",
    "Health and Beauty Salon",
    "Health and Beauty Store",
    "Health Food Store",
    "Ice Cream Shop",
    "Internet Café",
    "Jewelry Store",
    "Korean Mart/ Supermarket",
    "Mall/Supermarket",
    "Medical Center",
    "Merchadise Store",
    "Merchandising",
    "Milktea Shop",
    "Money Changer",
    "Multi-purpose Cooperative",
    "Online Shop - Merch",
    "Optical Clinic",
    "Other Services",
    "Payment Center",
    "Personal",
    "Pet Shop",
    "Pharmacy",
    "POS Provider",
    "Printing Shop",
    "Real Estate",
    "Restaurant",
    "Retail Store",
    "School",
    "Shell Select",
    "Shopping Mall",
    "SPA",
    "SPED",
    "Sports Equipment Store",
    "Supermarket",
    "Tattoo Shop",
    "Ticketing Services",
    "Travel Agency",
    "Upholstery Services",
    "Veterinary Clinic",
  ];

  return (
    <>
      {formData ? (
        <Card>
          <Card.Header>
            {formData.account_holder} Profile
            {["admin", "edit"].includes(accessLevel) && formType !== "new" ? (
              <Card.Options>
                {editAccount ? (
                  <>
                    <Button
                      color="cyan"
                      icon="save"
                      onClick={() =>
                        updateAccount(
                          formData,
                          () =>
                            alert("Profile Successfully Saved!") ||
                            getOtherAccountByID(formData.id)
                        )
                      }
                    >
                      SAVE CHANGES
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => setEditAccount(false)}
                    >
                      CANCEL
                    </Button>
                  </>
                ) : (
                  <>
                    {[user.full_name, "", "Mary Ann"].includes(
                      formData.ae_name
                    ) ? (
                      <Button
                        color="azure"
                        icon="edit"
                        onClick={() => setEditAccount(true)}
                      >
                        EDIT
                      </Button>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Card.Options>
            ) : (
              ""
            )}
            {["admin", "edit"].includes(accessLevel) && formType === "new" ? (
              <Card.Options>
                {formData?.client_id ? (
                  <Button
                    color="cyan"
                    icon="save"
                    onClick={() =>
                      updateAccount(formData, () =>
                        alert("New Profile Successfully Added!")
                      )
                    }
                  >
                    SAVE CHANGES
                  </Button>
                ) : (
                  ""
                )}
                <Button color="secondary" onClick={() => setOpenAddForm(false)}>
                  CANCEL
                </Button>
              </Card.Options>
            ) : (
              ""
            )}
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={1}>
                <Form.Group>
                  <Form.Label>Client ID</Form.Label>
                  <Form.Input
                    value={formData.client_id?.replace("*", "")}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        client_id: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Client Name</Form.Label>
                  <Form.Input
                    value={formData.account_holder}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        account_holder: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                    // valid={editAccount && ["edit"].includes(accessLevel)}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={3}>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Input
                    value={formData.address}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        address: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Contact Person</Form.Label>
                  <Form.Input
                    value={formData.contact_person}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        contact_person: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                    // valid={editAccount && ["edit"].includes(accessLevel)}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>TIN Number</Form.Label>
                  <Form.Input
                    value={formData.tin_id}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        tin_id: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Input
                    value={formData.mobile_phone}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        mobile_phone: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                    // valid={editAccount && ["edit"].includes(accessLevel)}
                  />
                </Form.Group>
              </Grid.Col>

              {/*new row*/}
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Input
                    value={formData.email_address}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        email_address: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                    // valid={editAccount && ["edit"].includes(accessLevel)}
                  />
                </Form.Group>
              </Grid.Col>
              {/* <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Nature of Business</Form.Label>
                  <Form.Input
                    value={formData.nature_of_business}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        nature_of_business: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  />
                </Form.Group>
              </Grid.Col> */}

              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Account Executive</Form.Label>
                  {/* <Form.Input
                    value={formData.ae_name}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ae_name: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  /> */}
                  <Form.Select
                    value={formData.ae_name}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ae_name: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                  >
                    <option value="">SELECT ONE</option>
                    <option value="Butz De Castro">Butz De Castro</option>
                    {telemarketerOA.map((ae) => (
                      <option value={ae.full_name}>{ae.full_name}</option>
                    ))}
                    {/* <option value="Pamela Decendio">Pamela Decendio</option>
                    <option value="Vanessa Lomibao">Vanessa Lomibao</option>
                    <option value="Derick Flor De Lis">
                      Derick Flor De Lis
                    </option>
                    <option value="John Paul Marquez">John Paul Marquez</option>
                    <option value="Patrick Sayo">Patrick Sayo</option>
                    <option value="Eldon Anselmo">Eldon Anselmo</option>
                    <option value="Jobelyn Ocampo">Jobelyn Ocampo</option> */}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>

              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>ACCOUNT</Form.Label>
                  {/* <Form.Input
                    value={formData.particulars}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        particulars: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  /> */}
                  <Form.Select
                    value={formData.particulars}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        particulars: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                  >
                    <option value="">SELECT ONE</option>
                    <option value="OLD">OLD</option>
                    <option value="NEW">NEW</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              {/* <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>ECash sheme Type</Form.Label>
                  <Form.Input value={formData.ecash_scheme_type} />
                </Form.Group>
              </Grid.Col> */}
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Category</Form.Label>

                  <Form.Input
                    value={categoryFilter || formData.nature_of_business}
                    onChange={(e) =>
                      setCategoryFilter(e.target.value) ||
                      setFormData({
                        ...formData,
                        nature_of_business: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                  />
                  {categoryFilter !== "" &&
                  !LIST.find((l) => l === categoryFilter) ? (
                    <div
                      className="dropdown-menu dropdown-menu-left show"
                      data-placement="bottom"
                      style={{
                        position: "absolute",
                        inset: "0px auto auto 0px",
                        transform: "translate(5px, 63px)",
                      }}
                    >
                      {LIST.filter((l) =>
                        l.toLowerCase().includes(categoryFilter.toLowerCase())
                      ).map((l) => (
                        <Button
                          className="dropdown-item"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              nature_of_business: l,
                            }) || setCategoryFilter(l)
                          }
                        >
                          {l}
                        </Button>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Call Schedule</Form.Label>
                  {/* <Form.Input
                    value={formData.price_class}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        price_class: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin"].includes(accessLevel)
                    }
                  /> */}

                  <Form.Select
                    value={formData.operating_hours}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        operating_hours: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                    // valid={editAccount && ["edit"].includes(accessLevel)}
                  >
                    <option value="">SELECT ONE</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={formData.active}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        active: e.target.value,
                      })
                    }
                    readOnly={formType === "new" ? false : !editAccount}
                    disabled={
                      formType === "new"
                        ? false
                        : !["admin", "edit"].includes(accessLevel)
                    }
                    // valid={editAccount && ["edit"].includes(accessLevel)}
                  >
                    <option value="">SELECT ONE</option>
                    <option value="0">Active</option>
                    <option value="1">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        </Card>
      ) : (
        ""
      )}
    </>
  );
};

export default ProfileForm;
