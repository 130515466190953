import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Grid, Alert, Icon } from "tabler-react";
import { Helmet } from "react-helmet";
import Dashboard from "./pages/Dashboard";
import AccountsList from "./pages/AccountsList";
import Account from "./pages/Account";
import Calls from "./pages/Calls"
import SideBarMenu from "./components/SideBarMenu";

//hooks
import useUsers from "../../hooks/api/Users/useUsers";

const Index = () => {
  const { user } = useUsers();

  const [accessLevel, setAccessLevel] = useState("none");

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "ams-sales-accounts")
            .access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  return (
    <>
      <Helmet>
        <title>GMS - 711 Account Management Tool</title>
      </Helmet>
      <div style={{ margin: "5px" }}>
        <Grid.Row>
          <Grid.Col md={1}>
            <SideBarMenu />
          </Grid.Col>
          <Grid.Col md={11}>
            <Router>
              <Switch>
                <Route exact path="/AMS_sales" component={Dashboard} />
                <Route
                  exact
                  path="/AMS_sales/Dashboard"
                  component={Dashboard}
                />
                <Route
                  exact
                  path="/AMS_sales/Accounts"
                  component={AccountsList}
                />
                <Route path="/AMS_sales/Account" component={Account} />
                <Route path="/AMS_sales/AllCallLogs" component={Calls} />
              </Switch>
            </Router>
          </Grid.Col>
        </Grid.Row>
      </div>
    </>
  );
};

export default Index;
