import { useState, useEffect } from "react";

function useCreateBackURL() {
  const [backURL, setBackURL] = useState("");

  useEffect(() => {
    setBackURL(window.location.href);
  }, []);

  return {
    backURL,
  };
}

export default useCreateBackURL;
