import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Alert } from "tabler-react";
import MenuHeader from "../../components/MenuHeader";
import List from "./List";
import VariantForm from "./VariantForm";
import Loading from "../../components/Loading/Loading";

//hooks
import useDirectoryAPI from "../../hooks/api/GVD/useDirectoryAPI";
import useUsers from "../../hooks/api/Users/useUsers";

const Index = () => {
  const { user, loginSession } = useUsers();
  const {
    directory,
    telcoList,
    getVariantList,
    getVariant,
    seledtedVariant,
    setSelectedVariant,
    addVariant,
    updateVariant,
  } = useDirectoryAPI();



  const [accessLevel, setAccessLevel] = useState("none");
  const [openForm, setOpenForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loginSession.length == 0) {
      window.location.replace("/");
    }
  }, []);

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "gate-variant-directory")
            .access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Helmet>
        <title>GMS - 711 Account Management Tool</title>
      </Helmet>
      {loading ? (
        <div style={{ paddingTop: "10%" }}>
          <Loading />
        </div>
      ) : (
        <>
          <MenuHeader data={{ user }} />
          <div style={{ margin: "5px" }}>
            {successForm ? (
              <Alert type="success" icon="check-circle" hasExtraSpace>
                Data added/ updated <strong>SUCCESSFULLY</strong>
              </Alert>
            ) : (
              ""
            )}
            {openForm || seledtedVariant.length != 0 ? (
              <VariantForm
                data={{
                  telcoList,
                  getVariantList,
                  seledtedVariant,
                  setSelectedVariant,
                  openForm,
                  setOpenForm,
                  addVariant,
                  updateVariant,
                  setSuccessForm,
                }}
              />
            ) : (
              ""
            )}

            <List
              data={{
                directory,
                telcoList,
                accessLevel,
                getVariant,
                seledtedVariant,
                openForm,
                setOpenForm,
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Index;
