import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractDashboardData() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadDashboardSummaryReport(rangeSelection, data) {
    var headers = {
      telemarketer: "telemarketer",
      assignedStores: "assignedStores",
      completedStores: "completedStores",
      pendingStores: "pendingStores",
      unCalledStores: "uncalledStores",
    };

    var itemsNotFormatted = data;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        telemarketer: item.full_name,
        assignedStores: item.stores.length,
        completedStores: item.completed_count.length,
        pendingStores: item.pending_count.length,
        unCalledStores: item.uncalled_stores_count.length,
      });
    });

    var fileTitle = `711_Calls_${rangeSelection}_Report_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  function exportOrderContribution(rangeSelection, data) {
    var headers = {
      receivedBy: "TELEMARKETER",
      stores: "ORDERED RECEIVED - NOT ASSIGNED",
      amount: "AMOUNT",
      assigned_store_only: "ORDERED RECEIVED - ASSIGNED",
      order_amount_assigned_only: "AMOUNT",
      assigned_store: "	TOTAL ORDERED ASSIGNED",
      order_amount: "AMOUNT",
    };

    var itemsNotFormatted = data;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        receivedBy: item.full_name,
        stores: item.ordered_calls.length,
        amount: item.order_amount,
        assigned_store_only: item.ordered_calls_assigned_only.length,
        order_amount_assigned_only: item.order_amount_assigned_only,
        assigned_store: item.ordered_calls_by_assigned_store.length,
        order_amount: item.order_amount_by_assigned_store,
      });
    });

    var fileTitle = `711_Order_Contribution_${rangeSelection}_Report_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  function exportOrderContributionStats(data) {
    var headers = {
      receivedBy: "TELEMARKETER",
      stores: "ORDERED RECEIVED - NOT ASSIGNED",
      amount: "AMOUNT",
      assigned_store_only: "ORDERED RECEIVED - ASSIGNED",
      order_amount_assigned_only: "AMOUNT",
      assigned_store: "	TOTAL ORDERED ASSIGNED",
      order_amount: "AMOUNT",
      total_calls: "TOTAL CALLS",
      total_received_stores: "TOTAL RECEIVED STORES",
      total_received_amount: "TOTAL RECEIVED AMOUNT",
    };

    var itemsNotFormatted = data;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        receivedBy: item.receiver,
        stores: item.ordered_calls.length,
        amount: item.order_amount,
        assigned_store_only: item.ordered_calls_assigned_only.length,
        order_amount_assigned_only: item.order_amount_assigned_only,
        assigned_store: item.ordered_calls_by_assigned_store.length,
        order_amount: item.order_amount_by_assigned_store,
        total_calls: item.called_store.length,
        total_received_stores: item.ordered_calls_received.length,
        total_received_amount: item.order_amount_recieved,
      });
    });

    var fileTitle = `711_Order_Contribution_Report`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return {
    downloadDashboardSummaryReport,
    exportOrderContribution,
    exportOrderContributionStats,
  };
}

export default useExtractDashboardData;
