import { useEffect, useState } from "react";
import config from "../../../config";
import axios from "axios";
import useUsers from "../Users/useUsers";

function useAppList() {
  const { loginSession } = useUsers();
  const [apps, setApps] = useState([]);

  function getAppList() {
    if (loginSession.length != 0) {
      axios
        .get(`${config.host}:${config.port}/api/v2/main_directory/apps`)
        .then(function (response) {
          let data = response.data;
          setApps(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    getAppList();
  }, []);

  return {
      apps
  }
}

export default useAppList;
