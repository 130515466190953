import React, { useState, useEffect } from "react";
import { Button, Card, Table, Loader, Icon } from "tabler-react";
import Pagination from "../../../../components/Pagination";
import Filter from "./Filter";

//hooks
import useShopeeAPI from "../../../../hooks/api/ShopeeShop/useShopeeAPI";
import useQueryURL from "../../../../hooks/services/useQueryURL";
import useCustomFunctions from "../../../../hooks/services/useCustomFunctions";

const Dashboard = () => {
  const { getQuery } = useQueryURL();
  const { formatNumberComma } = useCustomFunctions();

  const [month, setMonth] = useState(
    getQuery("month") ? getQuery("month") : "January"
  );
  const [year, setYear] = useState(
    getQuery("year") ? getQuery("year") : "2021"
  );

  const { drList } = useShopeeAPI(month, "", "");

  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(
    getQuery("page") ? getQuery("page") : 1
  );
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);
  const [pageLimit, setPageLimit] = useState(10)
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");

  const [newDrList, setNewDrList] = useState([]);

  const changePage = (p) => {
    window.location.replace(`/ShopeeShop?month=${month}&page=${p}`);
  };

  useEffect(() => {
    setLoading(true);
    if (drList.length != 0) {
      // setActivePage(1);
      setNewDrList(
        drList
          .filter((o) =>
            search
              ? o.dr_number.includes(search) || o.SI_number.includes(search)
              : o
          )
          .filter((o) =>
            search2
              ? o.totalGross == o.totalShopee
                ? "Ready"
                : "Not Ready" === search2
              : o
          )
      );

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [drList, search, search2, month]);

  return (
    <>
      <Card>
        <Card.Header>DR Dashboard</Card.Header>
        <Filter
          data={{
            src: "Dashboard",
            month,
            year,
            search,
            setSearch,
            search2,
            setSearch2,
          }}
        />
        <Card.Body>
          {loading ? (
            <Loader />
          ) : (
            <>
              {drList.length != 0 ? (
                <Table
                  responsive
                  highlightRowOnHover
                  hasOutline
                  verticalAlign="center"
                  cards
                  className="text-nowrap"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>#</Table.ColHeader>
                      <Table.ColHeader>DR Number</Table.ColHeader>
                      <Table.ColHeader>DR Month</Table.ColHeader>
                      <Table.ColHeader>SI Number</Table.ColHeader>
                      <Table.ColHeader>Gate Price Gross</Table.ColHeader>
                      <Table.ColHeader>Payment Release</Table.ColHeader>
                      <Table.ColHeader>Shipping SubTotal</Table.ColHeader>
                      {/* <Table.ColHeader>Gate Balance</Table.ColHeader> */}
                      <Table.ColHeader>Status</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {newDrList
                      .sort(function (a, b) {
                        return a.id - b.id;
                      })
                      .slice(
                        activePage == 1 ? 0 : (activePage - 1) * pageLimit,
                        activePage * pageLimit
                      )
                      .map((list, index) => (
                        <>
                          {list.SI_number === "--" || list.SI_number === "*" ? (
                            <Table.Row key={index}>
                              <Table.Col>
                                {activePage == 1
                                  ? index + 1
                                  : index + 1 + (activePage - 1) * pageLimit}
                              </Table.Col>
                              <Table.Col>
                                {list.dr_number.replace("*", "")}
                              </Table.Col>
                              <Table.Col>{list.DR_month}</Table.Col>
                              <Table.Col>
                                {list.SI_number.replace("*", "")}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.gate_price)}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.paymentRelease)}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.shipping_sub_total)}
                              </Table.Col>
                              {/* <Table.Col>
                              {list.balance.gateTotal}
                            </Table.Col> */}
                              <Table.Col>
                                <a
                                  href={`/ShopeeShop/Orders?dr_number=${list.dr_number.replace(
                                    "*",
                                    ""
                                  )}&SI_number=${list.SI_number}&month=${
                                    list.DR_month
                                  }&year=${list.DR_year}`}
                                  target="_blank"
                                >
                                  {list.totalGross == list.totalShopee ? (
                                    <Button color="cyan">Ready</Button>
                                  ) : (
                                    <Button color="secondary">Not Ready</Button>
                                  )}
                                </a>
                              </Table.Col>
                            </Table.Row>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                  </Table.Body>
                </Table>
              ) : (
                ""
              )}
            </>
          )}
          {newDrList.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          ) : (
            ""
          )}
        </Card.Body>
        {newDrList.length <= pageLimit ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={pageLimit}
            totalItemsCount={newDrList.length}
            pageRangeDisplayed={pageRangeDisplayed}
            changePage={changePage}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default Dashboard;
