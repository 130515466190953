import React, { useState } from "react";
import { Button, Card, Table, Icon } from "tabler-react";
import Pagination from "../../components/Pagination";
import Filter from "./Filter";

//hooks
import useExtractDirectory from "../../hooks/api/GVD/extractions/useExtractDirectory";

const List = (props) => {
  const { data } = props;
  const {
    directory,
    telcoList,
    accessLevel,
    getVariant,
    seledtedVariant,
    openForm,
    setOpenForm,
  } = data;

  const { downloadList } = useExtractDirectory();

  const [activePage, setActivePage] = useState(1);
  const [pageLimit, setPageLimit] = useState(15);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  const [newList, setNewList] = useState([]);

  return (
    <>
      <Card>
        <Card.Header>
          Gate Variant List
          <Card.Options>
            {accessLevel === "view" || accessLevel === "report" ? (
              ""
            ) : (
              <Button
                color="cyan"
                icon="plus"
                onClick={() => setOpenForm(true)}
                disabled={openForm || seledtedVariant.length != 0}
              >
                ADD VARIANT
              </Button>
            )}
            {accessLevel === "report" ? (
              <Button
                color="azure"
                icon="download"
                onClick={() => downloadList(newList)}
              >
                EXTRACT
              </Button>
            ) : (
              ""
            )}

            {/* <Button color="teal" icon="plus">
              ADD TELCO
            </Button> */}
          </Card.Options>
        </Card.Header>
        <Filter data={{ directory, telcoList, setNewList, setActivePage }} />
        {newList.length != 0 ? (
          <Card.Body>
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-wrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Supplier</Table.ColHeader>
                  <Table.ColHeader>Item Code</Table.ColHeader>
                  <Table.ColHeader>SKU Code</Table.ColHeader>
                  <Table.ColHeader>Gate Variant Name</Table.ColHeader>
                  <Table.ColHeader>Shop Name</Table.ColHeader>
                  <Table.ColHeader>Gross Price</Table.ColHeader>
                  <Table.ColHeader>Selling Price</Table.ColHeader>
                  <Table.ColHeader>Shopee Price</Table.ColHeader>
                  <Table.ColHeader>Laz Price</Table.ColHeader>
                  <Table.ColHeader>Aquisition</Table.ColHeader>
                  <Table.ColHeader>Status</Table.ColHeader>
                  {accessLevel === "admin" || accessLevel === "edit" ? (
                    <Table.ColHeader>Actions</Table.ColHeader>
                  ) : (
                    ""
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {newList
                  .sort(function (a, b) {
                    return a.id - b.id;
                  })
                  .slice(
                    activePage == 1 ? 0 : (activePage - 1) * pageLimit,
                    activePage * pageLimit
                  )
                  .map((d, index) => (
                    <Table.Row key={index}>
                      <Table.Col>{d.telco}</Table.Col>
                      <Table.Col>{d.item_code}</Table.Col>
                      <Table.Col>{d.item_sku}</Table.Col>
                      <Table.Col>{d.variant_name}</Table.Col>
                      <Table.Col>{d.shop_name}</Table.Col>
                      <Table.Col>{d.gross_price}</Table.Col>
                      <Table.Col>{d.selling_price}</Table.Col>
                      <Table.Col>{d.shopee_price}</Table.Col>
                      <Table.Col>{d.laz_price}</Table.Col>
                      <Table.Col>{d.aquisition}</Table.Col>
                      <Table.Col>
                        {d.active == 1 ? "Active" : "Disabled"}
                      </Table.Col>
                      {accessLevel === "admin" || accessLevel === "edit" ? (
                        <Table.Col>
                          <Button
                            color="azure"
                            icon="edit"
                            onClick={() => getVariant(d.id)}
                          >
                            EDIT
                          </Button>
                        </Table.Col>
                      ) : (
                        ""
                      )}
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Card.Body>
        ) : (
          <Card.Body>
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          </Card.Body>
        )}

        {newList.length == 0 ? (
          ""
        ) : (
          <Card.Body>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={pageLimit}
              // changePage={changePage}
              totalItemsCount={newList.length}
              pageRangeDisplayed={pageRangeDisplayed}
              setActivePage={setActivePage}
              setPageRangeDisplayed={setPageRangeDisplayed}
            />
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default List;
