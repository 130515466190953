import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractOnlineStore() {
  const { exportCSVFile } = useConvertToCSV();


  function downloadOrders(order, fileName) {
    var headers = {
        order_number: "ORDER NUMBER",
        customer_name: "CUSTOMER NAME",
        address: "ADDRESS",
        postal_code: "POSTAL CODE",
        phone_1: "PHONE 1",
        phone_2: "PHONE 2",
        cod_amount: "COD",
        type: "ITEM TYPE",
        weight: "WEIGHT",
        length: "LENGTH",
        width: "WIDTH",
        height: "HEIGHT",
    };

    var itemsNotFormatted = order;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        order_number:item.order_number,
          customer_name: item.customer_name,
          address: item.address.replace(/,/g, " "),
          postal_code: item.postal_code,
          phone_1: item.phone_1,
          phone_2: item.phone_2,
          cod_amount: item.cod_amount,
          type: item.type,
          weight: item.weight,
          length: item.length,
          width: item.width,
          height: item.height,
      });
    });

    var fileTitle = `${fileName}_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  function downloadOrderMonitoring(order, fileName) {
    var headers = {
        order_number: "ORDER NUMBER",
        delivery_option: "DELIVERY OPTION",
        item_code: "ITEM CODE",
        variant_name: "VARIANT NAME",
        cod_amount: "COD",
    };

    var itemsNotFormatted = order;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        order_number:item.order_number,
          customer_name: item.customer_name,
          address: item.address.replace(/,/g, " "),
          postal_code: item.postal_code,
          phone_1: item.phone_1,
          phone_2: item.phone_2,
          cod_amount: item.cod_amount,
          type: item.type,
          weight: item.weight,
          length: item.length,
          width: item.width,
          height: item.height,
      });
    });

    var fileTitle = `${fileName}_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  function downloadSummary(summary, fileName) {
    var headers = {
      variant_name: "VARIANT NAME",
      item_code: "ITEM CODE",
      quantity: "QUANTITY"
    };

    var itemsNotFormatted = summary;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        variant_name: item.variant_name,
        item_code: item.item_code,
        quantity: item.quantity
        
      });
    });

    // var fileTitle = `${dateFormat(new Date())}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, `SUMMARY - ${fileName}`); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return {
    downloadOrders,
    downloadSummary
  };
}

export default useExtractOnlineStore;
