import React, { useState, useEffect } from "react";
import { Card, Table, Button, Icon, Loader } from "tabler-react";
import Tooltip from "react-simple-tooltip";
import Pagination from "../../../../components/Pagination";
import Filters from "../components/Filters";

//hooks
import useUsers from "../../../../hooks/api/Users/useUsers";
import use711Stores from "../../../../hooks/api/AMS/use711Stores";
import useCreateBackURL from "../../../../hooks/services/useCreateBackURL";
import useQueryURL from "../../../../hooks/services/useQueryURL";
import useExport711Stores from "../../../../hooks/api/AMS/extractions/useExport711Stores";

const StoreList711 = () => {
  const { user } = useUsers();
  const { stores } = use711Stores();
  const { backURL } = useCreateBackURL();
  const { getQuery, changeQueryParam } = useQueryURL();
  const { downloadStores } = useExport711Stores();

  let page = getQuery("page");

  const [accessLevel, setAccessLevel] = useState("none");
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(page ? page : 1);
  const [pageLimit, setPageLimit] = useState(25);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  const [newList, setNewList] = useState([]);

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "711-account-management")
            .access
        : "none"
    );
  }, [user]);

  useEffect(() => {
    setNewList(stores);
  }, [stores]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [newList, stores]);

  const onPageChange = (page) => {
    page
      ? window.location.replace(
          changeQueryParam(window.location.href, "page", page)
        )
      : window.location.replace(`${window.location.href}?page=${page}`);
  };

  return (
    <div className="col-dashboard-container" style={{ margin: "5px" }}>
      <Card>
        <Card.Header>
          List of 711 Stores
          <Card.Options>
            <Button
              color="cyan"
              icon="plus"
              onClick={() =>
                window.location.replace(
                  `/AMS/AddForm/711?backURL=${window.btoa(backURL)}`
                )
              }
              disabled={accessLevel === "view" || accessLevel === "report"}
            >
              Add Store
            </Button>
            <Button
              color="info"
              icon="download"
              onClick={() => downloadStores(newList)}
              disabled={accessLevel === "view"}
            >
              Export
            </Button>
            {/* <a href="/AMS/711/AssignedSchedule">
              <Button color="info" icon="calendar" disabled>
                Assign Schedule
              </Button>
            </a>
            <a href="/AMS/711/BulkEditCompleted">
              <Button color="info" icon="edit" disabled>
                Bulk Edit
              </Button>
            </a> */}
            <Button color="info" icon="calendar" disabled>
              Assign Schedule
            </Button>
            <Button
              color="info"
              icon="edit"
              onClick={() =>
                window.location.replace(
                  `/AMS/BulkEdit/711?backURL=${window.btoa(backURL)}`
                )
              }
              disabled={accessLevel !== "admin"}
            >
              Bulk Edit
            </Button>
          </Card.Options>
        </Card.Header>
        <Filters
          data={{
            setLoading,
            setStores: setNewList,
            list: stores,
            rangeSelection: "",
            curWeek: [],
            status: "stores",
          }}
        />
        {newList.length != 0 && !loading ? (
          <Card.Body>
            {loading ? (
              <div style={{ padding: "5px" }}>
                <Loader className="loader" />
              </div>
            ) : (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Store Number</Table.ColHeader>
                    <Table.ColHeader>Store Name</Table.ColHeader>
                    <Table.ColHeader>Schedule</Table.ColHeader>
                    <Table.ColHeader>Courier Status</Table.ColHeader>
                    <Table.ColHeader>Account Executive</Table.ColHeader>
                    <Table.ColHeader>Telemarketer</Table.ColHeader>
                    <Table.ColHeader>Active</Table.ColHeader>
                    <Table.ColHeader>Actions</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {newList
                    .slice(
                      activePage == 1 ? 0 : (activePage - 1) * pageLimit,
                      activePage * pageLimit
                    )
                    .map((d, index) => (
                      <Table.Row key={index}>
                        <Table.Col>{d.store_number.replace("*", "")}</Table.Col>
                        <Table.Col>{d.store_name}</Table.Col>
                        <Table.Col>{d.schedule}</Table.Col>
                        <Table.Col>{d.courier_status}</Table.Col>
                        <Table.Col>{d.account_executive}</Table.Col>
                        <Table.Col>{d.telemarketer}</Table.Col>
                        <Table.Col>{d.active}</Table.Col>
                        <Table.Col>
                          <Tooltip
                            content={
                              accessLevel === "view" || accessLevel === "report"
                                ? "View Store Profile"
                                : "Call Store"
                            }
                            radius={5}
                            padding={5}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <Button
                              color="teal"
                              onClick={() =>
                                window.location.replace(
                                  `/AMS/Account/711/Profile?id=${d.store_number.replace(
                                    "*",
                                    ""
                                  )}&backURL=${window.btoa(backURL)}`
                                )
                              }
                            >
                              {accessLevel === "view" ||
                              accessLevel === "report" ? (
                                <Icon prefix="fa" name="home" />
                              ) : (
                                <Icon prefix="fa" name="phone" />
                              )}
                            </Button>
                          </Tooltip>
                          <Tooltip
                            content="Edit Store"
                            radius={5}
                            padding={5}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <Button
                              color="azure"
                              onClick={() =>
                                window.location.replace(
                                  `/AMS/UpdateForm/711?id=${d.store_number.replace(
                                    "*",
                                    ""
                                  )}&backURL=${window.btoa(backURL)}`
                                )
                              }
                              disabled={
                                accessLevel === "view" ||
                                accessLevel === "report"
                              }
                            >
                              <Icon prefix="fa" name="edit" />
                            </Button>
                          </Tooltip>

                          {/* {accessLevel === "admin" ||
                          user.role === "Account Executive" ? (
                            <Tooltip
                              content="Disable Store"
                              radius={5}
                              padding={5}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <Button
                                color="danger"
                                //   onClick={() => deleteStore(d.store_number)}
                              >
                                <Icon prefix="fa" name="trash" />
                              </Button>
                            </Tooltip>
                          ) : (
                            ""
                          )} */}
                        </Table.Col>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            )}
          </Card.Body>
        ) : (
          ""
        )}

        {newList.length === 0 ? (
          <Card.Body>
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          </Card.Body>
        ) : (
          <Card.Body>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={pageLimit}
              totalItemsCount={newList.length}
              pageRangeDisplayed={pageRangeDisplayed}
              setActivePage={onPageChange}
              setPageRangeDisplayed={setPageRangeDisplayed}
            />
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default StoreList711;
