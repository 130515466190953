import { useState, useEffect } from "react";
import dateFormat from "dateformat";
import config from "../../../config";
import axios from "axios";
import useTimeKeeping from "./useTimeKeeping";

import useCustomFunctions from "./../../services/useCustomFunctions";
import useUsers from "../Users/useUsers";

function useHRTool() {
  const { loginSession } = useUsers();
  const { employees, holidays } = useTimeKeeping();

  const { getInBetweenDates, getTimeDifference, getMinutesDifference } =
    useCustomFunctions();

  const [report, setReport] = useState([]);

  const [approvedLeaves, setApprovedLeaves] = useState([]);
  const [approvedOB, setApprovedOB] = useState([]);
  const [approvedOT, setApprovedOT] = useState([]);
  const [approvedCTO, setApprovedCTO] = useState([]);
  const [approvedROT, setApprovedROT] = useState([]);

  console.log(approvedOB);
  console.log("leaves",approvedLeaves)

  function getApprovedLeaves(startDate, endDate) {
    if (loginSession.length !== 0) {
      axios
        .post(`https://admin.hrsync.me/admin/api/hr_tool_leaves.php`, {
          startDate: startDate,
          endDate: endDate,
          key: "yf3pYj875FtmQZCu",
        })
        .then(function (response) {
          let data = response.data.data;

          console.log("l",response);

          let newData = !data
            ? []
            : data
            .map((d) => ({
              ...d,
              EmployeeID: d.EmployeeID === "G0177" ? "G00177" : d.EmployeeID,
            }))
                .map((d) => ({
                  ...d,
                  approvedLeaves: {
                    employee_id: d.EmployeeID,
                    type: d.LeaveType,
                    leave_dates: getInBetweenDates(
                      new Date(d.StartDate),
                      new Date(d.EndDate),
                      "leave"
                    ),
                    numberOfHours: parseFloat(d.NumberOfDays) * 8,
                  },
                }))
                .map((d) => ({
                  ...d,
                  approvedLeaves: d.approvedLeaves.leave_dates.map((date) => ({
                    employee_id: d.EmployeeID,
                    date: date,
                    type: d.approvedLeaves.type,
                    numberOfHours:
                      d.approvedLeaves.type === "LEAVE W/O PAY"
                        ? 0
                        : d.approvedLeaves.numberOfHours /
                          d.approvedLeaves.leave_dates.length,
                  })),
                }));

          let output = newData.reduce(function (o, cur) {
            // Get the index of the key-value pair.
            var occurs = o.reduce(function (n, item, i) {
              return item.EmployeeID === cur.EmployeeID ? i : n;
            }, -1);

            // If the name is found,
            if (occurs >= 0) {
              // append the current value to its list of values.
              o[occurs].approvedLeaves = o[occurs].approvedLeaves.concat(
                cur.approvedLeaves
              );
              // Otherwise,
            } else {
              // add the current item to o (but make sure the value is an array).
              var obj = {
                EmployeeID: cur.EmployeeID,
                approvedLeaves: cur.approvedLeaves,
              };
              o = o.concat([obj]);
            }

            return o;
          }, []);

          setApprovedLeaves(output);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getApprovedOB(startDate, endDate) {
    if (loginSession.length != 0) {
      axios
        .post(`https://admin.hrsync.me/admin/api/hr_tool_ob.php`, {
          startDate: startDate,
          endDate: endDate,
          key: "yf3pYj875FtmQZCu",
        })
        .then(function (response) {
          let data = response.data.data;
          console.log(data);

          let newData = data
            .map((d) => ({
              ...d,
              EmployeeID: d.EmployeeID === "G0177" ? "G00177" : d.EmployeeID,
            }))
            .map((d) => ({
              ...d,
              approvedOB: {
                employee_id: d.EmployeeID,
                type: "OB",
                leave_dates: getInBetweenDates(
                  new Date(d.datefrom),
                  new Date(d.dateto),
                  "leave"
                ),
                // numberofhrs: getTimeDifference(
                //   new Date(`${d.datefrom} ${d.timedeparture}`),
                //   new Date(`${d.dateto} ${d.timearrival}`)
                // ),
                numberofhrs: 8,
                time_in: d.timedeparture,
                time_out: d.timearrival,
                hour_in: d.timedeparture.includes("PM")
                  ? parseInt(d.timedeparture.split(":")[0]) + 12
                  : d.timedeparture.split(":")[0],
                hour_out: d.timearrival.includes("PM")
                  ? parseInt(d.timearrival.split(":")[0]) + 12
                  : d.timearrival.split(":")[0],
              },
            }))
            .map((d) => ({
              ...d,
              approvedOB: d.approvedOB.leave_dates.map((date) => ({
                employee_id: d.EmployeeID,
                date: date,
                type: d.approvedOB.type,
                numberofhrs: d.approvedOB.numberofhrs,
                time_in: d.approvedOB.time_in,
                time_out: d.approvedOB.time_out,
                hour_in: parseInt(d.approvedOB.hour_in),
                hour_out: parseInt(d.approvedOB.hour_out),
              })),
            }));

          let output = newData.reduce(function (o, cur) {
            // Get the index of the key-value pair.
            var occurs = o.reduce(function (n, item, i) {
              return item.EmployeeID === cur.EmployeeID ? i : n;
            }, -1);

            // If the name is found,
            if (occurs >= 0) {
              // append the current value to its list of values.
              o[occurs].approvedOB = o[occurs].approvedOB.concat(
                cur.approvedOB
              );
              // Otherwise,
            } else {
              // add the current item to o (but make sure the value is an array).
              var obj = {
                EmployeeID: cur.EmployeeID,
                approvedOB: cur.approvedOB,
              };
              o = o.concat([obj]);
            }

            return o;
          }, []);

          setApprovedOB(output);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getApprovedOT(startDate, endDate) {
    if (loginSession.length != 0) {
      axios
        .post(`https://admin.hrsync.me/admin/api/hr_tool_ot.php`, {
          startDate: startDate,
          endDate: endDate,
          key: "yf3pYj875FtmQZCu",
        })
        .then(function (response) {
          console.log(response);
          let data = response.data.data;

          let newData = data
          .map((d) => ({
            ...d,
            EmployeeID: d.EmployeeID === "G0177" ? "G00177" : d.EmployeeID,
          }))
            .map((d) => ({
              ...d,
              approvedOT: {
                employee_id: d.EmployeeID,
                type: "OT",
                leave_dates: getInBetweenDates(
                  new Date(d.datefrom),
                  new Date(d.dateto),
                  "leave"
                ),
                numberofhrs: d.numberofhrs,
              },
            }))
            .map((d) => ({
              ...d,
              approvedOT: d.approvedOT.leave_dates.map((date) => ({
                employee_id: d.EmployeeID,
                date: date,
                type: d.approvedOT.type,
                numberofhrs: d.approvedOT.numberofhrs,
              })),
            }));

          let output = newData.reduce(function (o, cur) {
            // Get the index of the key-value pair.
            var occurs = o.reduce(function (n, item, i) {
              return item.EmployeeID === cur.EmployeeID ? i : n;
            }, -1);

            // If the name is found,
            if (occurs >= 0) {
              // append the current value to its list of values.
              o[occurs].approvedOT = o[occurs].approvedOT.concat(
                cur.approvedOT
              );
              // Otherwise,
            } else {
              // add the current item to o (but make sure the value is an array).
              var obj = {
                EmployeeID: cur.EmployeeID,
                approvedOT: cur.approvedOT,
              };
              o = o.concat([obj]);
            }

            return o;
          }, []);

          setApprovedOT(output);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getApprovedCTO(startDate, endDate) {
    if (loginSession.length != 0) {
      axios
        .post(`https://admin.hrsync.me/admin/api/hr_tool_cto.php`, {
          startDate: startDate,
          endDate: endDate,
          key: "yf3pYj875FtmQZCu",
        })
        .then(function (response) {
          let data = response.data.data;
          let newData = data
            .map((d) => ({
              ...d,
              approvedCTO: {
                employee_id: d.EmployeeID,
                type: "OFFSET",
                leave_dates: getInBetweenDates(
                  new Date(d.DateFrom),
                  new Date(d.DateTo),
                  "leave"
                ),
                numberofhrs: d.NumberofHrs,
              },
            }))
            .map((d) => ({
              ...d,
              approvedCTO: d.approvedCTO.leave_dates.map((date) => ({
                employee_id: d.EmployeeID,
                date: date,
                type: d.approvedCTO.type,
                numberofhrs: d.approvedCTO.numberofhrs,
              })),
            }));

          let output = newData.reduce(function (o, cur) {
            // Get the index of the key-value pair.
            var occurs = o.reduce(function (n, item, i) {
              return item.EmployeeID === cur.EmployeeID ? i : n;
            }, -1);

            // If the name is found,
            if (occurs >= 0) {
              // append the current value to its list of values.
              o[occurs].approvedCTO = o[occurs].approvedCTO.concat(
                cur.approvedCTO
              );
              // Otherwise,
            } else {
              // add the current item to o (but make sure the value is an array).
              var obj = {
                EmployeeID: cur.EmployeeID,
                approvedCTO: cur.approvedCTO,
              };
              o = o.concat([obj]);
            }

            return o;
          }, []);

          setApprovedCTO(output);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getApprovedROT(startDate, endDate) {
    if (loginSession.length != 0) {
      axios
        .post(`https://admin.hrsync.me/admin/api/hr_tool_rot.php`, {
          startDate: startDate,
          endDate: endDate,
          key: "yf3pYj875FtmQZCu",
        })
        .then(function (response) {
          let data = response.data.data;

          let newData = [];
          let output = [];

          if (data.length != 0) {
            newData = data
            .map((d) => ({
              ...d,
              EmployeeID: d.EmployeeID === "G0177" ? "G00177" : d.EmployeeID,
            }))
              .map((d) => ({
                ...d,
                approvedROT: {
                  employee_id: d.EmployeeID,
                  type: "RD OT",
                  leave_dates: getInBetweenDates(
                    new Date(d.datefrom),
                    new Date(d.dateto),
                    "leave"
                  ),
                  numberofhrs: d.numberofhrs,
                },
              }))
              .map((d) => ({
                ...d,
                approvedROT: d.approvedROT.leave_dates.map((date) => ({
                  employee_id: d.EmployeeID,
                  date: date,
                  type: d.approvedROT.type,
                  numberofhrs: d.approvedROT.numberofhrs,
                })),
              }));

            output = newData.reduce(function (o, cur) {
              // Get the index of the key-value pair.
              var occurs = o.reduce(function (n, item, i) {
                return item.EmployeeID === cur.EmployeeID ? i : n;
              }, -1);

              // If the name is found,
              if (occurs >= 0) {
                // append the current value to its list of values.
                o[occurs].approvedROT = o[occurs].approvedROT.concat(
                  cur.approvedROT
                );
                // Otherwise,
              } else {
                // add the current item to o (but make sure the value is an array).
                var obj = {
                  EmployeeID: cur.EmployeeID,
                  approvedROT: cur.approvedROT,
                };
                o = o.concat([obj]);
              }

              return o;
            }, []);
          }

          setApprovedROT(output);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  // console.log(employees);

  function formatReport(data) {
    var list = [];
    let ids = [];

    for (var i = 0; i < data.length; ++i) {
      if (data[i][0] !== "") {
        let reDate = data[i][1].split(" ")[0].split("/");
        let newTime = data[i][1].split(" ")[1];
        let newDate = `${reDate[1]}/${reDate[0]}/${reDate[2]} ${newTime}`;

        ids.push(data[i][0]);

        list.push({
          id: data[i][0],
          employee_id: data[i][0].split(""),
          date: dateFormat(newDate, "paddedShortDate"),
          time: dateFormat(newDate, "h:MM TT"),
          hour: parseInt(dateFormat(newDate, "HH")),
          seconds: new Date(newDate).getTime(),
          minutes: parseInt(dateFormat(newDate, "MM")),
          fullDate: dateFormat(newDate, "mm/dd/yyyy HH:MM:ss"),
          //   date: dateFormat(new Date(data[i][1].split(" ")[0]), "paddedShortDate"),
          //   time: dateFormat(new Date(data[i][1]), "HH:MM:ss")
        });
      }
    }

    return [
      list.map((l) => ({
        ...l,
        employee_id: `G0${l.employee_id
          .slice(1, l.employee_id.length)
          .join("")}`,
      })),
      ids.filter((v, i) => ids.indexOf(v) == i),
    ];
  }

  function generateReport(data, dates) {
    data = formatReport(data);
    console.log(data);

    let list = [];

    function getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    }

    function getUniqueListByTime(array, key) {
      // return [...new Map(arr.map((item) => [item[hour], item])).values()];

      return array.reduce((arr, item) => {
        const removed = arr.filter((i) => i[key] !== item[key]);
        return [...removed, item];
      }, []);
    }

    for (let index = 0; index < data[1].length; index++) {
      let timekeeping = getUniqueListBy(
        data[0].filter(
          (d) => d.id === data[1][index] && dates.includes(d.date)
        ),
        "fullDate"
      );

      function uniqueValues(array) {
        let hours = array
          .map((t) => t.hour)
          .filter((v, i) => array.map((t) => t.hour).indexOf(v) == i);

        let c_in = array.filter((t) => t.hour === hours[0])[0];

        let b_out = array.filter((t) => t.hour === hours[1])[0];

        let b_in = array.filter(
          (t) => t.hour === hours[hours.length > 3 ? 2 : 1]
        )[
          array.filter((t) => t.hour === hours[hours.length > 3 ? 2 : 1])
            .length - 1
        ];
        let c_out =
          hours.length > 1
            ? array.filter((t) => t.hour === hours[hours.length - 1])[0]
            : "";

        return {
          c_in,
          b_out,
          b_in,
          c_out,
        };
      }

      let records = dates
        .map((date) => ({
          id: (Math.random() + 1).toString(36).substring(7),
          date: date,
          time_in_out: uniqueValues(
            timekeeping.filter((time) => time.date === date)
          ),
          employee_id:
            employees.length != 0 && timekeeping.length != 0
              ? timekeeping[0].employee_id
              : "",
        }))
        .map((date) => ({
          ...date,
          hour: date.time_in_out[0] ? date.time_in_out[0].hour : "",
        }))
        .map((date) => ({
          ...date,
          type:
            employees.length != 0
              ? employees.find((e) => e.employee_id === date.employee_id)
                ? employees.find((e) => e.employee_id === date.employee_id).type
                : ""
              : "",
        }))
        .map((date) => ({
          ...date,
          date: date.date,
          day: dateFormat(date.date, "ddd"),
          time_in: date.time_in_out.c_in ? date.time_in_out.c_in.time : "",
          time_out: date.time_in_out.c_out ? date.time_in_out.c_out.time : "",
          break_out:
            date.type !== "C1"
              ? ""
              : date.time_in_out.b_out
              ? date.time_in_out.b_out.time
              : "",
          break_in:
            date.type !== "C1"
              ? ""
              : date.time_in_out.b_in
              ? date.time_in_out.b_in.time
              : "",
          hour_in: date.time_in_out.c_in ? date.time_in_out.c_in.hour : "",
          hour_out: date.time_in_out.c_out ? date.time_in_out.c_out.hour : "",
        }))
        .map((date) => ({
          ...date,
          time_in:
            approvedOB.length != 0
              ? approvedOB.find((a) => a.EmployeeID === date.employee_id)
                ? approvedOB
                    .find((a) => a.EmployeeID === date.employee_id)
                    .approvedOB.find(
                      (a) => dateFormat(a.date) === dateFormat(date.date)
                    )
                  ? approvedOB
                      .find((a) => a.EmployeeID === date.employee_id)
                      .approvedOB.find(
                        (a) => dateFormat(a.date) === dateFormat(date.date)
                      ).hour_in < date.hour_in
                    ? approvedOB
                        .find((a) => a.EmployeeID === date.employee_id)
                        .approvedOB.find(
                          (a) => dateFormat(a.date) === dateFormat(date.date)
                        ).time_in
                    : date.time_in === ""
                    ? approvedOB
                        .find((a) => a.EmployeeID === date.employee_id)
                        .approvedOB.find(
                          (a) => dateFormat(a.date) === dateFormat(date.date)
                        ).time_in
                    : date.time_in
                  : date.time_in
                : date.time_in
              : date.time_in,
          time_out:
            date.shift === "HOLIDAY"
              ? ""
              : approvedOB.length != 0
              ? approvedOB.find((a) => a.EmployeeID === date.employee_id)
                ? approvedOB
                    .find((a) => a.EmployeeID === date.employee_id)
                    .approvedOB.find(
                      (a) => dateFormat(a.date) === dateFormat(date.date)
                    )
                  ? approvedOB
                      .find((a) => a.EmployeeID === date.employee_id)
                      .approvedOB.find(
                        (a) => dateFormat(a.date) === dateFormat(date.date)
                      ).hour_out > date.hour_out
                    ? approvedOB
                        .find((a) => a.EmployeeID === date.employee_id)
                        .approvedOB.find(
                          (a) => dateFormat(a.date) === dateFormat(date.date)
                        ).time_out
                    : date.time_out
                  : date.time_out
                : date.time_out
              : date.time_out,
        }))
        .map((date) => ({
          ...date,
          time_in: date.time_in === date.time_out ? "" : date.time_in,
          woking_hours:
            getTimeDifference(
              new Date(`${date.date} ${date.time_in}`),
              new Date(`${date.date} ${date.time_out}`)
            ) <= 0
              ? 0
              : getTimeDifference(
                  new Date(`${date.date} ${date.time_in}`),
                  new Date(`${date.date} ${date.time_out}`)
                ),
          time_in_diff:
            getMinutesDifference(
              new Date(
                `${date.date} ${
                  employees.length != 0
                    ? employees.find((e) => e.employee_id === date.employee_id)
                      ? employees.find(
                          (e) => e.employee_id === date.employee_id
                        ).shift_in
                      : "8"
                    : "8"
                }:00 AM`
              ),
              new Date(`${date.date} ${date.time_in}`)
            ) <= 0
              ? 0
              : getMinutesDifference(
                  new Date(
                    `${date.date} ${
                      employees.length != 0
                        ? employees.find(
                            (e) => e.employee_id === date.employee_id
                          )
                          ? employees.find(
                              (e) => e.employee_id === date.employee_id
                            ).shift_in
                          : "8"
                        : "8"
                    }:00 AM`
                  ),
                  new Date(`${date.date} ${date.time_in}`)
                ),
          shift:
            date.day === "Sat" || date.day === "Sun"
              ? "RESTDAY"
              : employees.length != 0
              ? employees.find((e) => e.employee_id === date.employee_id)
                ? employees.find((e) => e.employee_id === date.employee_id)
                    .shift
                : ""
              : "",
        }))
        .map((date) => ({
          ...date,
          reg_hours:
            date.shift === "RESTDAY"
              ? 0
              : parseFloat(date.woking_hours).toFixed(2),
          over_time_hours: 0,
        }))
        .map((date) => ({
          ...date,
          shift:
            holidays.length != 0
              ? holidays.find((h) => h.date === dateFormat(date.date, "mm/dd"))
                ? "HOLIDAY"
                : date.shift
              : "",
          remarks:
            holidays.length != 0
              ? holidays.find((h) => h.date === dateFormat(date.date, "mm/dd"))
                ? holidays.find(
                    (h) => h.date === dateFormat(date.date, "mm/dd")
                  ).title
                : ""
              : "",
          holiday_id:
            holidays.length != 0
              ? holidays.find((h) => h.date === dateFormat(date.date, "mm/dd"))
                ? holidays.find(
                    (h) => h.date === dateFormat(date.date, "mm/dd")
                  ).type
                : ""
              : "",
        }))
        .map((date) => ({
          ...date,
          remarks:
            date.shift === "HOLIDAY"
              ? date.remarks
              : approvedLeaves.length != 0
              ? approvedLeaves.find((a) => a.EmployeeID === date.employee_id)
                ? approvedLeaves
                    .find((a) => a.EmployeeID === date.employee_id)
                    .approvedLeaves.find(
                      (a) => dateFormat(a.date) === dateFormat(date.date)
                    )
                  ? `${approvedLeaves
                      .find((a) => a.EmployeeID === date.employee_id)
                      .approvedLeaves.filter(
                        (a) => dateFormat(a.date) === dateFormat(date.date)
                      )
                      .map((a) => a.type)
                      .join(" ")}`
                  : date.remarks
                : date.remarks
              : date.remarks,
        }))
        .map((date) => ({
          ...date,
          reg_hours:
            date.shift === "HOLIDAY"
              ? 0
              : approvedLeaves.length != 0
              ? approvedLeaves.find((a) => a.EmployeeID === date.employee_id)
                ? approvedLeaves
                    .find((a) => a.EmployeeID === date.employee_id)
                    .approvedLeaves.find(
                      (a) => dateFormat(a.date) === dateFormat(date.date)
                    )
                  ? (
                      parseFloat(
                        approvedLeaves
                          .find((a) => a.EmployeeID === date.employee_id)
                          .approvedLeaves.filter(
                            (a) => dateFormat(a.date) === dateFormat(date.date)
                          )
                          .map((a) => a.numberOfHours)
                          .reduce((a, b) => a + b, 0)
                      ) + parseFloat(date.reg_hours)
                    ).toFixed(2)
                  : date.reg_hours
                : date.reg_hours
              : date.reg_hours,
        })) /// END of LEAVES
        .map((date) => ({
          ...date,
          remarks:
            date.shift === "HOLIDAY"
              ? date.remarks
              : approvedOB.length != 0
              ? approvedOB.find((a) => a.EmployeeID === date.employee_id)
                ? approvedOB
                    .find((a) => a.EmployeeID === date.employee_id)
                    .approvedOB.find(
                      (a) => dateFormat(a.date) === dateFormat(date.date)
                    )
                  ? approvedOB
                      .find((a) => a.EmployeeID === date.employee_id)
                      .approvedOB.filter(
                        (a) => dateFormat(a.date) === dateFormat(date.date)
                      )[0].type
                  : date.remarks
                : date.remarks
              : date.remarks,
        })) ///END of OB
        .map((date) => ({
          ...date,
          remarks:
            date.shift === "HOLIDAY"
              ? date.remarks
              : approvedOT.length != 0 && ["C1", "C"].includes(date.type)
              ? approvedOT.find((a) => a.EmployeeID === date.employee_id)
                ? approvedOT
                    .find((a) => a.EmployeeID === date.employee_id)
                    .approvedOT.find(
                      (a) => dateFormat(a.date) === dateFormat(date.date)
                    )
                  ? `${date.remarks} ${
                      approvedOT
                        .find((a) => a.EmployeeID === date.employee_id)
                        .approvedOT.filter(
                          (a) => dateFormat(a.date) === dateFormat(date.date)
                        )[0].type
                    }`
                  : date.remarks
                : date.remarks
              : date.remarks,
        })) /// END of OT
        .map((date) => ({
          ...date,
          remarks:
            date.shift === "HOLIDAY"
              ? date.remarks
              : approvedCTO.length != 0
              ? approvedCTO.find((a) => a.EmployeeID === date.employee_id)
                ? approvedCTO
                    .find((a) => a.EmployeeID === date.employee_id)
                    .approvedCTO.find(
                      (a) => dateFormat(a.date) === dateFormat(date.date)
                    )
                  ? approvedCTO
                      .find((a) => a.EmployeeID === date.employee_id)
                      .approvedCTO.filter(
                        (a) => dateFormat(a.date) === dateFormat(date.date)
                      )[0].type
                  : date.remarks
                : date.remarks
              : date.remarks,
        }))
        .map((date) => ({
          ...date,
          reg_hours:
            date.shift === "HOLIDAY"
              ? 0
              : approvedCTO.length != 0
              ? approvedCTO.find((a) => a.EmployeeID === date.employee_id)
                ? approvedCTO
                    .find((a) => a.EmployeeID === date.employee_id)
                    .approvedCTO.find(
                      (a) => dateFormat(a.date) === dateFormat(date.date)
                    )
                  ? (
                      parseFloat(
                        approvedCTO
                          .find((a) => a.EmployeeID === date.employee_id)
                          .approvedCTO.filter(
                            (a) => dateFormat(a.date) === dateFormat(date.date)
                          )[0].numberofhrs
                      ) + parseFloat(date.reg_hours)
                    ).toFixed(2)
                  : date.reg_hours
                : date.reg_hours
              : date.reg_hours,
        })) /// END of CTO
        .map((date) => ({
          ...date,
          remarks:
            date.shift === "HOLIDAY"
              ? date.remarks
              : approvedROT.length != 0
              ? approvedROT.find((a) => a.EmployeeID === date.employee_id)
                ? approvedROT
                    .find((a) => a.EmployeeID === date.employee_id)
                    .approvedROT.find(
                      (a) => dateFormat(a.date) === dateFormat(date.date)
                    )
                  ? approvedROT
                      .find((a) => a.EmployeeID === date.employee_id)
                      .approvedROT.filter(
                        (a) => dateFormat(a.date) === dateFormat(date.date)
                      )[0].type
                  : date.remarks
                : date.remarks
              : date.remarks,
        }))
        // .map((date) => ({
        //   ...date,
        //   reg_hours:
        //     date.shift === "HOLIDAY"
        //       ? 0
        //       : approvedROT.length != 0
        //       ? approvedROT.find((a) => a.EmployeeID === date.employee_id)
        //         ? approvedROT
        //             .find((a) => a.EmployeeID === date.employee_id)
        //             .approvedROT.find(
        //               (a) => dateFormat(a.date) === dateFormat(date.date)
        //             )
        //           ? (
        //               parseFloat(
        //                 approvedROT
        //                   .find((a) => a.EmployeeID === date.employee_id)
        //                   .approvedROT.filter(
        //                     (a) => dateFormat(a.date) === dateFormat(date.date)
        //                   )[0].numberofhrs
        //               ) + parseFloat(date.reg_hours)
        //             ).toFixed(2)
        //           : date.reg_hours
        //         : date.reg_hours
        //       : date.reg_hours,
        // })) /// END of RD OT

        .map((date) => ({
          ...date,
          reg_hours:
            parseFloat(date.reg_hours) > 8 && date.shift !== "RESTDAY"
              ? (8).toFixed(2)
              : parseFloat(date.reg_hours).toFixed(2),
          under_time:
            8 - date.reg_hours <= 0 || 8 - date.reg_hours >= 8
              ? 0
              : 8 - date.reg_hours < 1
              ? 0
              : (8 - date.reg_hours).toFixed(2),
          late: date.time_in_diff.toFixed(2),
        }))
        .map((date) => ({
          ...date,
          late: date.hour >= 12 || date.shift === "RESTDAY" ? 0 : date.late,
          under_time:
            date.shift === "RESTDAY" ||
            [
              "OFFSET",
              "OB",
              "OT",
              "RD OT",
              "FORCED LEAVE",
              "SICK LEAVE",
              "LEAVE W/O PAY",
              "VACATION LEAVE",
              "LEAVE W/O PAY SICK LEAVE",
            ].includes(date.remarks)
              ? 0
              : date.under_time,
        }))
        .map((date) => ({
          ...date,
          reg_hours: (
            parseFloat(date.reg_hours) -
            parseFloat(date.late) / 100
          ).toFixed(2),
        }))
        .map((date) => ({
          ...date,
          absent:
            (date.time_in !== "" &&
              date.time_out !== "" &&
              date.time_in !== date.time_out) ||
            [
              "OFFSET",
              "OB",
              "OT",
              "OB OT",
              "RD OT",
              "FORCED LEAVE",
              "SICK LEAVE",
              "LEAVE W/O PAY",
              "VACATION LEAVE",
              "LEAVE W/O PAY SICK LEAVE",
            ].includes(date.remarks) ||
            date.shift === "HOLIDAY" ||
            date.shift === "RESTDAY"
              ? ""
              : "ABSENT",
        }))
        .map((date) => ({
          ...date,
          over_time_hours: !["OB OT", "OT"].includes(date.remarks)
            ? date.over_time_hours
            : approvedOT.length != 0
            ? approvedOT.find((a) => a.EmployeeID === date.employee_id)
              ? approvedOT
                  .find((a) => a.EmployeeID === date.employee_id)
                  .approvedOT.find(
                    (a) => dateFormat(a.date) === dateFormat(date.date)
                  )
                ? Math.round(
                    approvedOT
                      .find((a) => a.EmployeeID === date.employee_id)
                      .approvedOT.filter(
                        (a) => dateFormat(a.date) === dateFormat(date.date)
                      )[0].numberofhrs
                  ).toFixed(2)
                : date.over_time_hours
              : date.over_time_hours
            : date.over_time_hours,
        }))
        .map((date) => ({
          ...date,
          over_time_hours:
            date.remarks !== "RD OT"
              ? date.over_time_hours
              : approvedROT.length != 0
              ? approvedROT.find((a) => a.EmployeeID === date.employee_id)
                ? approvedROT
                    .find((a) => a.EmployeeID === date.employee_id)
                    .approvedROT.find(
                      (a) => dateFormat(a.date) === dateFormat(date.date)
                    )
                  ? Math.round(
                      approvedROT
                        .find((a) => a.EmployeeID === date.employee_id)
                        .approvedROT.filter(
                          (a) => dateFormat(a.date) === dateFormat(date.date)
                        )[0].numberofhrs
                    ).toFixed(2)
                  : date.over_time_hours
                : date.over_time_hours
              : date.over_time_hours,
        }));

      if (employees.length != 0) {
        list.push({
          id: data[1][index],
          employee_id: timekeeping[0] ? timekeeping[0].employee_id : "",
          records,
        });
      }
    }

    setReport(
      list
        .map((l) => ({
          ...l,
          full_name:
            employees.length != 0
              ? employees.find((e) => e.employee_id === l.employee_id)
                ? employees.find((e) => e.employee_id === l.employee_id)
                    .full_name
                : ""
              : "",

          position:
            employees.length != 0
              ? employees.find((e) => e.employee_id === l.employee_id)
                ? employees.find((e) => e.employee_id === l.employee_id)
                    .position
                : ""
              : "",
          absents: l.records.filter((l) => l.absent === "ABSENT").length,
          lates: l.records.filter((l) => l.late != 0).length,
          under_times: l.records.filter((l) => l.under_time != 0).length,
          leaves: l.records.filter((l) =>
            [
              "FORCED LEAVE",
              "SICK LEAVE",
              "LEAVE W/O PAY",
              "VACATION LEAVE",
              "LEAVE W/O PAY SICK LEAVE",
            ].includes(l.remarks)
          ).length,
          offset: l.records.filter((l) => ["OFFSET"].includes(l.remarks))
            .length,
          working_days: l.records.filter((l) => l.shift !== "RESTDAY").length,
          holidays_count: l.records.filter((l) =>
            ["SH", "CO", "RH", "NH"].includes(l.holiday_id)
          ).length,
        }))
        .map((date) => ({
          ...date,
          working_days:
            date.working_days - date.leaves - date.offset - date.holidays_count,
        }))
        .filter(
          (l) => l.records.reduce((a, b) => a + b.time_in_out.length, 0) != 0
        )
    );
  }

  return {
    employees,
    report,
    generateReport,
    getApprovedLeaves,
    approvedLeaves,
    getApprovedOB,
    approvedOB,
    approvedOT,
    approvedCTO,
    getApprovedOT,
    getApprovedCTO,
    approvedROT,
    getApprovedROT,
  };
}

export default useHRTool;
