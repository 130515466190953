import React, { useState, useEffect } from "react";
import PreferencesList from "./PreferencesList";
import PreferencesAddForm from "./PreferencesAddForm";
import { Alert } from "tabler-react";

//hooks
import usePreferences from "../../../../../hooks/api/AMS/usePreferences";
import useQueryURL from "../../../../../hooks/services/useQueryURL";
import useUsers from "../../../../../hooks/api/Users/useUsers";

const Index = () => {
  const {
    preferences,
    getPreferences,
    addPreferences,
    updatePreferences,
    getPreference,
    setSelectedPreferences,
    selectedPreferences,
  } = usePreferences();

  const { user } = useUsers();

  const [accessLevel, setAccessLevel] = useState("none");

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "711-account-management")
            .access
        : "admin"
    );
  }, [user]);

  const [openForm, setOpenForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);

  const { getQuery, changeQueryParam } = useQueryURL();
  let type = getQuery("type");

  useEffect(() => {
    if(user.length != 0 && accessLevel !== "admin"){
      window.location.replace("/AMS")
    }
  }, [user, accessLevel])

  return (
    <div className="col-header-dashboard">

      {successForm ? (
        <Alert type="success" icon="check-circle" hasExtraSpace>
          You added/ updated <strong>SUCCESSFULLY</strong>
        </Alert>
      ) : (
        ""
      )}

      {selectedPreferences.length != 0 || openForm ? (
        <PreferencesAddForm
          data={{
            preferences,
            type,
            addPreferences,
            getPreferences,
            setSuccessForm,
            updatePreferences,
            setSelectedPreferences,
            selectedPreferences,
            openForm,
            setOpenForm,
          }}
        />
      ) : (
        ""
      )}

      <PreferencesList
        data={{
          preferences: preferences.filter(
            (p) =>
              p.type !== "department" &&
              p.type !== "access_level" &&
              p.type !== "role"
          ),
          getQuery,
          changeQueryParam,
          type,
          getPreference,
          selectedPreferences,
          openForm,
          setOpenForm,
        }}
      />
    </div>
  );
};

export default Index;
