import { useState, useEffect } from "react";
import config from "./../../config";
import axios from "axios";

function useTagList() {
  const [tagList, setTagList] = useState([]);

  function getTagList() {
    axios
      .post(`${config.host}:${config.port}/api/v2/tags/list`)
      .then(function (response) {
        let data = response.data;
        setTagList(
          data.map((d) => ({
            ...d,
            gate_status: d.gate_status.split("|"),
            remarks: d.remarks.split("|"),
            example: d.example.split("|"),
            platforms: d.platforms.split(",")
          }))
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getTagList();
  });
  return { tagList };
}

export default useTagList;
