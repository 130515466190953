import React, { useState, useEffect } from "react";
import { Card, Grid, Form, Button } from "tabler-react";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";

//hooks
import useUsers from "../../../../hooks/api/Users/useUsers";
import usePreferences from "../../../../hooks/api/AMS/usePreferences";
import useQueryURL from "../../../../hooks/services/useQueryURL";
import useCustomFunctions from "../../../../hooks/services/useCustomFunctions";
import { CURRENT_YEAR } from "../../../../config";

const Filters = (props) => {
  const { telemarketers } = useUsers();
  const { data } = props;
  const { status, setStores, list, rangeSelection, curWeek, setLoading } = data;
  const { getInBetweenDates } = useCustomFunctions();

  const { preferences } = usePreferences();
  const { getQuery, changeQueryParam, changeQueryParams } = useQueryURL();

  const [remarksList, setRemarksList] = useState([]);
  //filters
  const [filter1, setFilter1] = useState("");
  const [filter2, setFilter2] = useState("");
  const [filter3, setFilter3] = useState(
    rangeSelection === "Today"
      ? new Date()
      : curWeek.length != 0
      ? new Date(curWeek[1])
      : new Date()
  );

  const [filter3a, setFilter3a] = useState(
    rangeSelection === "Today"
      ? new Date()
      : curWeek.length != 0
      ? new Date(curWeek[1])
      : new Date()
  );

  const [selectedDates, setSelectedDates] = useState([]);

  const [filter3b, setFilter3b] = useState("By Date");
  const [filter3c, setFilter3c] = useState(new Date());
  const [filter3d, setFilter3d] = useState(new Date());
  const [filter3e, setFilter3e] = useState(CURRENT_YEAR);
  const [filter4, setFilter4] = useState("");
  const [filter5, setFilter5] = useState("");
  const [filter6, setFilter6] = useState("");
  const [filter7, setFilter7] = useState("");
  const [filter8, setFilter8] = useState("");
  const [filter9, setFilter9] = useState("");
  const [filter10, setFilter10] = useState("");
  const [filter11, setFilter11] = useState("");
  const [filter12, setFilter12] = useState("high");
  const [filter13, setFilter13] = useState("");
  const [filter14, setFilter14] = useState("");

  //sideEffects
  useEffect(() => {
    setRemarksList(
      preferences
        .filter((pref) => pref.type === "remarks" && pref.active == 1)
        .map((pref) => pref.value)
    );
  }, [preferences]);

  useEffect(() => {
    setFilter10(getQuery("year") ? getQuery("year") : CURRENT_YEAR);
    setFilter4(getQuery("courier_status") ? getQuery("courier_status") : "");
    setFilter5(getQuery("remarks") ? getQuery("remarks") : "");
    setFilter6(getQuery("schedule") ? getQuery("schedule") : "");
    setFilter7(getQuery("telemarketer") ? getQuery("telemarketer") : "");
    setFilter8(getQuery("active") ? getQuery("active") : "");
    setFilter3c(
      getQuery("dates") ? new Date(getQuery("dates").split("|")[0]) : new Date()
    );
    setFilter3d(
      getQuery("dates") ? new Date(getQuery("dates").split("|")[1]) : new Date()
    );
    setFilter9(getQuery("call_status") ? getQuery("call_status") : "");
    setFilter11(getQuery("received_by") ? getQuery("received_by") : "");
    setFilter12(getQuery("order_rate") ? getQuery("order_rate") : "");

    setFilter13(getQuery("order_source") ? getQuery("order_source") : "");
    setFilter14(getQuery("location") ? getQuery("location") : "");
  }, [list]);

  useEffect(() => {
    setSelectedDates(
      getInBetweenDates(new Date(filter3c), new Date(filter3d), "")
    );
  }, [filter3c, filter3d]);

  console.log(
    list
      .map((l) => l.location)
      .filter((v, i) => list.map((l) => l.location).indexOf(v) == i)
  );

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setStores(
        list
          .filter((store) =>
            filter1 !== ""
              ? store.store_name.toLowerCase().includes(filter1.toLowerCase())
              : store
          )
          .filter((store) =>
            filter2 !== ""
              ? store.store_number.replace("*", "") == filter2
              : store
          )
          .filter((store) =>
            filter5 !== "" ? store.remarks === filter5 : store
          )
          .filter((store) =>
            filter3c && filter3d
              ? selectedDates.includes(
                  dateFormat(store.date_order, "paddedShortDate")
                )
              : store
          )
          .filter((store) =>
            filter7 !== "" ? store.telemarketer === filter7 : store
          )
          .filter((store) =>
            filter6 !== "" ? store.schedule === filter6 : store
          )
          .filter((store) =>
            filter4 !== "" ? store.courier_status === filter4 : store
          )
          .filter((store) =>
            filter8 !== "" ? store.active === filter8 : store
          )
          .filter((store) =>
            filter9 !== "" ? store.call_status === filter9 : store
          )
          .filter((store) =>
            filter11 !== "" ? store.received_by === filter11 : store
          )
          .filter((store) =>
            filter13 !== "" ? store.callSource === filter13 : store
          )
          .filter((store) =>
            filter14 !== "" ? store.location === filter14 : store
          )
      );
      setLoading(false);
    }, 500);
  }, [
    selectedDates,
    filter1,
    filter2,
    filter3,
    filter3a,
    filter3b,
    filter3c,
    filter3d,
    filter4,
    filter5,
    filter6,
    filter7,
    filter8,
    filter9,
    filter10,
    filter11,
    filter12,
    filter13,
    filter14,
    list,
    preferences,
    telemarketers,
    status,
  ]);

  return (
    <>
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={2}>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                fontWeight: "bold",
                color: "#b9b9b9",
                textTransform: "uppercase",
              }}
            >
              Store Name
            </p>
            <Form.Input
              value={filter1}
              onChange={(e) => setFilter1(e.target.value)}
              placeholder="Search store name"
            />
          </Grid.Col>
          <Grid.Col md={2}>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                fontWeight: "bold",
                color: "#b9b9b9",
                textTransform: "uppercase",
              }}
            >
              Store Number Only
            </p>
            <Form.Input
              value={filter2}
              onChange={(e) => setFilter2(e.target.value)}
              placeholder="Search store number only ..."
            />
          </Grid.Col>
          {status === "all" ? (
            <Grid.Col md={2}>
              <p
                style={{
                  fontSize: "12px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#b9b9b9",
                  textTransform: "uppercase",
                }}
              >
                Year
              </p>
              <Form.Select
                value={filter10}
                onChange={(e) => {
                  let nStartDate = dateFormat(
                    filter3c,
                    "paddedShortDate"
                  ).split("/");
                  let nEndDate = dateFormat(filter3d, "paddedShortDate").split(
                    "/"
                  );
                  if (
                    filter10 ||
                    filter10 === "" ||
                    getQuery("dates") ||
                    getQuery("year")
                  ) {
                    window.location.replace(
                      changeQueryParams(
                        window.location.href,
                        ["dates", "year"],
                        [
                          `${nStartDate[0]}/${nStartDate[1]}/${e.target.value}|${nEndDate[0]}/${nEndDate[1]}/${e.target.value}`,
                          e.target.value,
                        ]
                      )
                    );
                  } else {
                    window.location.replace(
                      window.location.href +
                        `?dates=${nStartDate[0]}/${nStartDate[1]}/${e.target.value}|${nEndDate[0]}/${nEndDate[1]}/${e.target.value}&year=${e.target.value}`
                    );
                  }
                }}
              >
                <option value="">SELECT ONE</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
              </Form.Select>
            </Grid.Col>
          ) : (
            ""
          )}
          {status !== "uncalled" && status !== "stores" ? (
            <>
              <Grid.Col md={2}>
                <p
                  style={{
                    fontSize: "12px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#b9b9b9",
                    textTransform: "uppercase",
                  }}
                >
                  Order Dates
                </p>
                {status !== "all" ? (
                  <DatePicker
                    selected={filter3c}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      setFilter3c(start);
                      setFilter3d(end);
                      if (end !== null) {
                        filter3c ||
                        filter3c === "" ||
                        filter3d ||
                        filter3d === ""
                          ? window.location.replace(
                              changeQueryParam(
                                window.location.href,
                                "dates",
                                `${dateFormat(
                                  start,
                                  "paddedShortDate"
                                )}|${dateFormat(end, "paddedShortDate")}`
                              )
                            )
                          : window.location.replace(
                              window.location.href +
                                `&dates=${dateFormat(
                                  start,
                                  "paddedShortDate"
                                )}|${dateFormat(end, "paddedShortDate")}`
                            );
                      }
                    }}
                    startDate={filter3c}
                    endDate={filter3d}
                    minDate={
                      rangeSelection === "Today"
                        ? new Date()
                        : curWeek.length != 0
                        ? new Date(curWeek[1])
                        : new Date()
                    }
                    maxDate={new Date()}
                    disabled={filter3b === "All"}
                    className="form-control"
                    selectsRange
                    monthsShown={1}
                  />
                ) : (
                  <>
                    <DatePicker
                      selected={filter3c}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        setFilter3c(start);
                        setFilter3d(end);
                        if (end !== null) {
                          filter3c ||
                          filter3c === "" ||
                          filter3d ||
                          filter3d === ""
                            ? window.location.replace(
                                changeQueryParam(
                                  window.location.href,
                                  "dates",
                                  `${dateFormat(
                                    start,
                                    "paddedShortDate"
                                  )}|${dateFormat(end, "paddedShortDate")}`
                                )
                              )
                            : window.location.replace(
                                window.location.href +
                                  `&dates=${dateFormat(
                                    start,
                                    "paddedShortDate"
                                  )}|${dateFormat(end, "paddedShortDate")}`
                              );
                        }
                      }}
                      startDate={filter3c}
                      endDate={filter3d}
                      minDate={new Date(`01/01/${filter10}`)}
                      maxDate={new Date()}
                      className="form-control"
                      selectsRange
                      monthsShown={1}
                    />
                  </>
                )}
              </Grid.Col>
            </>
          ) : (
            ""
          )}

          <Grid.Col md={2}>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                fontWeight: "bold",
                color: "#b9b9b9",
                textTransform: "uppercase",
              }}
            >
              Location
            </p>
            <Form.Select
              value={filter14}
              onChange={(e) =>
                filter14 || filter14 === ""
                  ? window.location.replace(
                      changeQueryParam(
                        window.location.href,
                        "location",
                        e.target.value
                      )
                    )
                  : window.location.replace(
                      window.location.href + `&location=${e.target.value}`
                    )
              }
            >
              <option value="">ALL</option>
              {list
                .map((l) => l.location)
                .filter((v, i) => list.map((l) => l.location).indexOf(v) == i)
                .map((location) => (
                  <option value={location}>{location}</option>
                ))}
            </Form.Select>
          </Grid.Col>

          <Grid.Col md={2}>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                fontWeight: "bold",
                color: "#b9b9b9",
                textTransform: "uppercase",
              }}
            >
              Courier Status
            </p>
            <Form.Select
              value={filter4}
              onChange={(e) =>
                filter4 || filter4 === ""
                  ? window.location.replace(
                      changeQueryParam(
                        window.location.href,
                        "courier_status",
                        e.target.value
                      )
                    )
                  : window.location.replace(
                      window.location.href + `&courier_status=${e.target.value}`
                    )
              }
            >
              <option value="">ALL</option>
              <option value="Serviceable">Serviceable</option>
              <option value="OSA">OSA</option>
            </Form.Select>
          </Grid.Col>
          {status === "stores" || status === "all" ? (
            <Grid.Col md={2}>
              <p
                style={{
                  fontSize: "12px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#b9b9b9",
                  textTransform: "uppercase",
                }}
              >
                Telemarketers
              </p>
              <Form.Select
                value={filter7}
                onChange={(e) =>
                  filter7 || filter7 === ""
                    ? window.location.replace(
                        changeQueryParam(
                          window.location.href,
                          "telemarketer",
                          e.target.value
                        )
                      )
                    : window.location.replace(
                        window.location.href + `&telemarketer=${e.target.value}`
                      )
                }
              >
                <option value="">ALL</option>
                {telemarketers.map((value, index) => (
                  <option value={value.full_name} key={index}>
                    {value.full_name}
                  </option>
                ))}
              </Form.Select>
            </Grid.Col>
          ) : (
            ""
          )}
          {status === "all" ? (
            <Grid.Col md={2}>
              <p
                style={{
                  fontSize: "12px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#b9b9b9",
                  textTransform: "uppercase",
                }}
              >
                Received By
              </p>
              <Form.Select
                value={filter11}
                onChange={(e) =>
                  filter11 || filter11 === ""
                    ? window.location.replace(
                        changeQueryParam(
                          window.location.href,
                          "received_by",
                          e.target.value
                        )
                      )
                    : window.location.replace(
                        window.location.href + `&received_by=${e.target.value}`
                      )
                }
              >
                <option value="">ALL</option>
                {telemarketers.map((value, index) => (
                  <option value={value.full_name} key={index}>
                    {value.full_name}
                  </option>
                ))}
              </Form.Select>
            </Grid.Col>
          ) : (
            ""
          )}
          {status === "all" || status === "stores" || status === "uncalled" ? (
            <>
              {rangeSelection === "Weekly" ? (
                <Grid.Col md={2}>
                  <p
                    style={{
                      fontSize: "12px",
                      margin: 0,
                      fontWeight: "bold",
                      color: "#b9b9b9",
                      textTransform: "uppercase",
                    }}
                  >
                    Schedule
                  </p>
                  <Form.Select
                    value={filter6}
                    onChange={(e) =>
                      filter6 || filter6 === ""
                        ? window.location.replace(
                            changeQueryParam(
                              window.location.href,
                              "schedule",
                              e.target.value
                            )
                          )
                        : window.location.replace(
                            window.location.href + `&schedule=${e.target.value}`
                          )
                    }
                  >
                    <option value="">ALL</option>
                    <option value="Monday">MONDAY</option>
                    <option value="Tuesday">TUESDAY</option>
                    <option value="Wednesday">WEDNESDAY</option>
                    <option value="Thursday">THURSDAY</option>
                    <option value="Friday">FRIDAY</option>
                  </Form.Select>
                </Grid.Col>
              ) : (
                ""
              )}

              <Grid.Col md={2}>
                <p
                  style={{
                    fontSize: "12px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#b9b9b9",
                    textTransform: "uppercase",
                  }}
                >
                  Order Rate
                </p>
                <Form.Select
                  value={filter12}
                  onChange={(e) =>
                    filter12 || filter12 === ""
                      ? window.location.replace(
                          changeQueryParam(
                            window.location.href,
                            "order_rate",
                            e.target.value
                          )
                        )
                      : window.location.replace(
                          window.location.href + `&order_rate=${e.target.value}`
                        )
                  }
                >
                  <option value="">ALL</option>
                  <option value="high">High</option>
                  <option value="low">Low</option>
                </Form.Select>
              </Grid.Col>
            </>
          ) : (
            ""
          )}
          {status !== "uncalled" && status !== "stores" ? (
            <>
              <Grid.Col md={2}>
                <p
                  style={{
                    fontSize: "12px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#b9b9b9",
                    textTransform: "uppercase",
                  }}
                >
                  Remarks
                </p>
                <Form.Select
                  value={filter5}
                  onChange={(e) =>
                    filter5 || filter5 === ""
                      ? window.location.replace(
                          changeQueryParam(
                            window.location.href,
                            "remarks",
                            e.target.value
                          )
                        )
                      : window.location.replace(
                          window.location.href + `&remarks=${e.target.value}`
                        )
                  }
                >
                  <option value="">ALL</option>
                  {remarksList.map((remark) => (
                    <option value={remark}>{remark}</option>
                  ))}
                </Form.Select>
              </Grid.Col>

              <Grid.Col md={2}>
                <p
                  style={{
                    fontSize: "12px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#b9b9b9",
                    textTransform: "uppercase",
                  }}
                >
                  Order Source
                </p>
                <Form.Select
                  value={filter13}
                  onChange={(e) =>
                    filter13 || filter13 === ""
                      ? window.location.replace(
                          changeQueryParam(
                            window.location.href,
                            "order_source",
                            e.target.value
                          )
                        )
                      : window.location.replace(
                          window.location.href +
                            `&order_source=${e.target.value}`
                        )
                  }
                >
                  <option value="">SELECT ONE</option>
                  <option value="Call">Call</option>
                  <option value="Email">Email</option>
                  <option value="SMS">SMS</option>
                </Form.Select>
              </Grid.Col>
            </>
          ) : (
            <>
              <Grid.Col md={1}>
                <p
                  style={{
                    fontSize: "12px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#b9b9b9",
                    textTransform: "uppercase",
                  }}
                >
                  Status
                </p>
                <Form.Select
                  value={filter8}
                  onChange={(e) =>
                    filter8 || filter8 === ""
                      ? window.location.replace(
                          changeQueryParam(
                            window.location.href,
                            "active",
                            e.target.value
                          )
                        )
                      : window.location.replace(
                          window.location.href + `&active=${e.target.value}`
                        )
                  }
                >
                  <option value="">ALL</option>
                  <option value="Yes">Active</option>
                  <option value="No">Closed</option>
                </Form.Select>
              </Grid.Col>
            </>
          )}
          {status === "all" ? (
            <>
              <Grid.Col md={2}>
                <p
                  style={{
                    fontSize: "12px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#b9b9b9",
                    textTransform: "uppercase",
                  }}
                >
                  Call Status
                </p>
                <Form.Select
                  value={filter9}
                  onChange={(e) =>
                    filter9 || filter9 === ""
                      ? window.location.replace(
                          changeQueryParam(
                            window.location.href,
                            "call_status",
                            e.target.value
                          )
                        )
                      : window.location.replace(
                          window.location.href +
                            `&call_status=${e.target.value}`
                        )
                  }
                >
                  <option value="">ALL</option>
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                </Form.Select>
              </Grid.Col>
              <Grid.Col md={2}>
                <Button onClick={() => window.location.reload()}>Search</Button>
              </Grid.Col>
            </>
          ) : (
            ""
          )}
        </Grid.Row>
      </Card.Body>
    </>
  );
};

export default Filters;
