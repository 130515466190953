import React, { useState, useEffect } from "react";
import { Card, Alert, Loader } from "tabler-react";

//components
import Profile from "./Profile";
import CallHistory from "./CallHistory";
import CallLogForm from "./CallLogForm";

//hooks
import use711Stores from "../../../../../hooks/api/AMS/use711Stores";
import useQueryURL from "../../../../../hooks/services/useQueryURL";
import use711Calls from "../../../../../hooks/api/AMS/use711Calls";
import useUsers from "../../../../../hooks/api/Users/useUsers";
import useReminders from "../../../../../hooks/api/AMS/useReminders";

const Index = () => {
  const { user } = useUsers();
  const { store, getStore } = use711Stores();
  const { reminder, addReminder, getReminder, removeReminder } = useReminders();
  const {
    calls,
    getStoreCallLogs,
    addCallLogs,
    updateCallLogs,
    deleteCallLogs,
  } = use711Calls();
  
  const { getQuery } = useQueryURL();

  const [loading, setLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState("none");
  const [storeID, setStoreID] = useState("");
  const [backURL, setBackURL] = useState("");

  //Reminder
  const [reminderSuccess, setReminderSuccess] = useState(false);
  const [reminderRemove, setReminderRemove] = useState(false);

  //Call Logs Form
  const [viewCall, setViewCall] = useState(false);
  const [editCall, setEditCall] = useState(false);
  const [addCall, setAddCall] = useState(false);
  const [selectedCallLog, setSelectedCallLog] = useState("");
  const [callLogSuccess, setCallLogSuccess] = useState(false);
  const [callLogRemove, setCallLogRemove] = useState(false);

  //SideEffectes
  

  useEffect(() => {
    setStoreID(getQuery("id") ? getQuery("id") : "");
    setBackURL(getQuery("backURL") ? getQuery("backURL") : "");
  }, []);

  useEffect(() => {
    if (storeID) {
      getStore(storeID, getStoreCallLogs);
      getReminder(storeID);
    }
  }, [storeID]);

  // useEffect(() => {
  //   if (store) {
  //     ;
  //   }
  // }, [store, storeID])
  

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "711-account-management")
            .access
        : "none"
    );
  }, [user]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [user, calls, store, reminder]);

  return (
    <div className="col-header-dashboard">
      {store.length != 0 ? (
        <>
          <Profile
            data={{
              accessLevel,
              store: store[0],
              backURL,
              calls
            }}
          />
        </>
      ) : (
        <div style={{ padding: "5px" }}>
          <Loader className="loader" />
        </div>
      )}

      {reminderSuccess ? (
        <Alert type="success" icon="check-circle" hasExtraSpace>
          You added this to your <strong>REMINDER LIST</strong>
        </Alert>
      ) : (
        ""
      )}

      {reminderRemove ? (
        <Alert type="danger" icon="check-circle" hasExtraSpace>
          You removed this to your <strong>REMINDER LIST</strong>
        </Alert>
      ) : (
        ""
      )}

      {callLogSuccess ? (
        <Alert type="success" icon="check-circle" hasExtraSpace>
          Call Log Successfully <strong>ADDED!</strong>
        </Alert>
      ) : (
        ""
      )}

      {callLogRemove ? (
        <Alert type="danger" icon="check-circle" hasExtraSpace>
          Call Log Successfully <strong>REMOVED!</strong>
        </Alert>
      ) : (
        ""
      )}

      {store.length != 0 ? (
        <CallLogForm
          data={{
            accessLevel,
            store: store[0],
            callLog:
              selectedCallLog !== ""
                ? calls.filter((call) => call.id === selectedCallLog)[0]
                : [],
            addCallLogs,
            calls,
            updateCallLogs,
            selectedCallLog,
            setSelectedCallLog,
            viewCall,
            setViewCall,
            editCall,
            setEditCall,
            addCall,
            setAddCall,
            storeID,
            getStoreCallLogs,
            setCallLogSuccess,
          }}
        />
      ) : (
        <></>
      )}

      { store.length != 0 && !loading? (
        <>
          {viewCall || editCall || addCall ? (
            ""
          ) : (
            <CallHistory
              data={{
                store: store[0],
                calls,
                accessLevel,
                setReminderSuccess,
                setReminderRemove,
                setSelectedCallLog,
                user,
                viewCall,
                setViewCall,
                editCall,
                setEditCall,
                addCall,
                setAddCall,
                reminder:
                  reminder.length != 0
                    ? reminder.find((r) => r.reminder === user.username)
                      ? reminder.find((r) => r.reminder === user.username)
                      : []
                    : [],
                addReminder,
                removeReminder,
                deleteCallLogs,
                getStoreCallLogs,
                getReminder,
                storeID,
              }}
            />
          )}
        </>
      ) : (
        <div style={{ padding: "5px" }}>
          <Loader className="loader" />
        </div>
      )}
    </div>
  );
};

export default Index;
