import React, { useState, useEffect } from "react";
import { Card, Form, Table, Button, Icon } from "tabler-react";
import useShopAPI from "../../../../hooks/api/TiktokGOMOShop/useShopAPI";
import useExtractReportShopee2 from "../../../../hooks/api/TiktokGOMOShop/extractions/useExtractShopeeReport2";
import Pagination from "../../../../components/Pagination";
import { useLocation } from "react-router";

const OrderItemList = () => {

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  let SI_Number = query.get("SI_number");
  let DR_month = query.get("month");
  let DR_number = query.get("dr_number");
  let ORDER_id = query.get("order_id");

  const [month, setMonth] = useState(DR_month !== "" ? DR_month : "June");

  const { rawOrders } = useShopAPI(month, DR_number !== "" ? DR_number : "");
  const download = useExtractReportShopee2();

  //Pagination
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  const [search, setSearch] = useState(ORDER_id !== "" ? ORDER_id : "");
  const [newOrders, setNewOrders] = useState([]);

  useEffect(() => {
    if (rawOrders.length !== 0) {
      setLoading(false);
      setActivePage(1);
      setNewOrders(
        rawOrders.filter((o) =>
          search
            ? o.dr_number.includes(search) ||
              o.order_id.includes(search) ||
              o.SI_number.includes(search) ||
              o.product_name.includes(search)
            : o
        )
      );
    }
  }, [rawOrders, search]);

  
  return (
    <>
      <Card>
        <Card.Header>
          Order List
          <Card.Options>
            <Button
              onClick={() =>
                download(newOrders, search === "" ? "all" : search)
              }
              color="azure"
            >
              <Icon prefix="fa" name="download" />
              Extract
            </Button>
          </Card.Options>
          <Card.Options>
            <Form.Input
              type="text"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Table
            responsive
            highlightRowOnHover
            hasOutline
            verticalAlign="center"
            cards
            className="text-nowrap"
          >
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>No.</Table.ColHeader>
                <Table.ColHeader>DR Number</Table.ColHeader>
                <Table.ColHeader>SI Number</Table.ColHeader>
                <Table.ColHeader>Order Date</Table.ColHeader>
                <Table.ColHeader>Order ID</Table.ColHeader>
                <Table.ColHeader>Product Name</Table.ColHeader>
                <Table.ColHeader>Gate Price</Table.ColHeader>
                <Table.ColHeader>Tiktok Price</Table.ColHeader>
                <Table.ColHeader>Quantity</Table.ColHeader>
                <Table.ColHeader>Remarks</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {newOrders
                .sort(function (a, b) {
                  return a.id - b.id;
                })
                .slice(
                  activePage == 1 ? 0 : (activePage - 1) * 30,
                  activePage * 30
                )
                .map((order, index) => (
                  <Table.Row>
                    <Table.Col>
                      <b>{index + 1}.</b>
                    </Table.Col>
                    <Table.Col>{order.dr_number.replace("*", "")}</Table.Col>
                    <Table.Col>
                      {order.SI_number ? order.SI_number.replace("*", "") : "--"}
                    </Table.Col>
                    <Table.Col>{order.order_date}</Table.Col>
                    <Table.Col>{order.order_id.replace("*", "")}</Table.Col>
                    <Table.Col>{order.product_name}</Table.Col>
                    <Table.Col>{order.gate_price}</Table.Col>
                    <Table.Col>{order.shopee_price}</Table.Col>
                    <Table.Col>{order.quantity}</Table.Col>
                    <Table.Col>{order.remarks}</Table.Col>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Card.Body>
      </Card>
      {newOrders.length <= 30 ? (
        ""
      ) : (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={30}
          totalItemsCount={newOrders.length}
          pageRangeDisplayed={pageRangeDisplayed}
          setActivePage={setActivePage}
          setPageRangeDisplayed={setPageRangeDisplayed}
        />
      )}
    </>
  );
};

export default OrderItemList;
