import React from "react";
import { Card, Table } from "tabler-react";

const StoreScheduleSummary = (props) => {
  const { scheduleWeeklySummary } = props.data;
  return (
    <>
      <Card>
        <Card.Status color="cyan" side />
        <Card.Header>Scheduled Store Summary</Card.Header>
        <Card.Body>
          <Table
            responsive
            highlightRowOnHover
            hasOutline
            verticalAlign="center"
            cards
            className="text-nowrap"
          >
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>Day</Table.ColHeader>
                <Table.ColHeader>Scheduled Stores</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {scheduleWeeklySummary.map((schedule) => (
                <Table.Row>
                  <Table.Col>{schedule.day}</Table.Col>
                  <Table.Col>{schedule.count}</Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default StoreScheduleSummary;
