import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Card, Button, Grid } from "tabler-react";
import StoreOrderedList from "./StoreOrderedList";
import Filter from "./Filter";
import StoreScheduleSummary from "./StoreScheduleSummary";
import StoreTeleListSchedule from "./StoreTeleListSchedule";
//hooks
import use711Statistics from "../../../../../hooks/api/AMS/use711Statistics";
import useCustomFunctions from "../../../../../hooks/services/useCustomFunctions";
import useUsers from "../../../../../hooks/api/Users/useUsers";
import useExtractDashboardData from "../../../../../hooks/api/AMS/extractions/useExtractDashboardData";

const Index = () => {
  const { user } = useUsers();
  const { exportOrderContributionStats } = useExtractDashboardData();
  const {
    calls,
    summary,
    generateSummary,
    scheduleSummary,
    scheduleWeeklySummary,
  } = use711Statistics();
  const { formatNumberComma, getInBetweenDates } = useCustomFunctions();

  const [newList, setNewList] = useState([]);
  const [filter1, setFilter1] = useState(new Date());
  const [filter2, setFilter2] = useState(new Date());
  const [accessLevel, setAccessLevel] = useState("--");

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "711-account-management")
            .access
        : "--"
    );
  }, [user]);

  useEffect(() => {
    if (
      user.length != 0 &&
      (accessLevel === "none" ||
        accessLevel === "view")
    ) {
      window.location.replace("/AMS");
    }
  }, [user, accessLevel]);

  useEffect(() => {
    if (filter1 !== "" && filter2 !== "") {
      // console.log(getInBetweenDates(new Date(filter1), new Date(filter2), "dtr"));
      setNewList(
        calls.filter((call) =>
          dateFormat(filter1, "paddedShortDate") ===
          dateFormat(filter2, "paddedShortDate")
            ? dateFormat(new Date(call.date_order), "paddedShortDate") ===
              dateFormat(new Date(filter1), "paddedShortDate")
            : getInBetweenDates(
                new Date(filter1),
                new Date(filter2),
                "dtr"
              ).includes(dateFormat(call.date_order, "paddedShortDate"))
        )
      );
    }
  }, [calls, filter1, filter2]);

  useEffect(() => {
    generateSummary(newList);
  }, [newList, filter1, filter2]);

  return (
    <>
      <div style={{ margin: "5px" }}>
        <Grid.Row>
          <Grid.Col md={12}>
            <Filter data={{ filter1, filter2, setFilter1, setFilter2 }} />
          </Grid.Col>
          <Grid.Col md={12}>
            {" "}
            <Card>
              <Card.Header>
                Call Logs Order Contributions -{" "}
                {`${dateFormat(filter1, "mmm d")} to ${dateFormat(
                  filter2,
                  "mmm d, yyyy"
                )}`}
                <Card.Options>
                  <Button
                    icon="download"
                    color="azure"
                    onClick={() =>
                      exportOrderContributionStats(
                        summary.filter(
                          (tele) =>
                            tele.order_amount_recieved != 0 ||
                            tele.order_amount_by_assigned_store != 0
                        )
                      )
                    }
                    disabled={accessLevel === "view"}
                  >
                    Export Report
                  </Button>
                </Card.Options>
              </Card.Header>
              <StoreOrderedList
                data={{
                  calls: newList,
                  summary,
                  generateSummary,
                  formatNumberComma,
                  accessLevel,
                  filter1,
                  filter2,
                }}
              />
            </Card>
          </Grid.Col>
          <Grid.Col md={3}>
            <StoreScheduleSummary data={{ scheduleWeeklySummary }} />
          </Grid.Col>
          <Grid.Col md={9}>
            <StoreTeleListSchedule data={{ scheduleSummary }} />
          </Grid.Col>
        </Grid.Row>
      </div>
    </>
  );
};

export default Index;
