import { useState, useEffect } from "react";
import useDirectoryAPI from "../GVD/useDirectoryAPI";

function useSerializationTool(listOfSerials, extractedReport) {
  const { directory } = useDirectoryAPI();

  const [formattedSerials, setFormattedSerials] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderSummary, setOrderSummary] = useState([]);

  let used = [];
  let summarySerial = [];

  function formattedSerial() {
    var list = [];
    for (var i = 0; i < listOfSerials.length; ++i) {
      if (listOfSerials[i][0] !== "") {
        list.push({
          [listOfSerials[i][0]]: listOfSerials[i].filter(
            (value) => value !== listOfSerials[i][0] && value !== ""
          ),
        });
      }
    }
    return list;
  }

  function assignedSerial(variant, quantity) {
    if (formattedSerials.find((v) => v[variant])) {
      let list = formattedSerials.find((v) => v[variant])[variant];
      let serials = [];

      for (var i = 0; i < quantity; ++i) {
        serials.push(
          list.filter((val) => !used.includes(val))[i]
            ? list.filter((val) => !used.includes(val))[i]
            : "No Serial Available"
        );
      }

      if (serials.length == quantity) {
        for (let index = 0; index < serials.length; index++) {
          used.push(serials[index]);
        }
        summarySerial.push(
          serials.length > 1
            ? `${serials[0]} - ${serials[serials.length - 1]}`
            : serials[0]
        );
        return serials;
      }
    } else {
      let warning = [];

      for (let index = 0; index < quantity; index++) {
        warning.push("No Serial Available");
      }
      return warning;
    }
  }

  function generateOrders(data) {
    let list = [];
    for (let index = 1; index < data.length; index++) {
      list.push({
        order_id: data[index][2],
        tracking_number: data[index][3],
        variant_name: data[index][6],
        quantity: data[index][7],
        serials: assignedSerial(data[index][6], data[index][7]).join(", "),
      });
    }
    return list;
  }

  function checkSerials(variant) {
    let data = {}
    let expected = extractedReport
        .filter((v) => v[6] === variant)
        .reduce((a, b) => parseInt(a) + parseInt(b[7]), 0);
    if (formattedSerials.find((v) => v[variant])) {
      
      let list = formattedSerials.find((v) => v[variant])[variant];

      data = {
        variant_name: variant,
        match: list.length === expected,
        item_code: directory.find((v) => v.variant_name === variant)
          ? directory.find((v) => v.variant_name === variant).item_code
          : "--",
        totalSerials: list.length,
        totalOrders: expected,
      };
    }else{
      data = {
        variant_name: variant,
        match: "NO RECORDS",
        item_code: "--",
        totalSerials: "NO RECORDS",
        totalOrders:  expected,
      };
    }

    return data;
  }

  function generateSummary(orders) {
    let variantList = [];
    for (let index = 0; index < orders.length; index++) {
      variantList.push(orders[index].variant_name);
    }

    let filter_variant_name = variantList.filter(
      (v, i) => variantList.indexOf(v) == i
    );

    let list = [];
    for (let index = 0; index < filter_variant_name.length; index++) {
      list.push(checkSerials(filter_variant_name[index]));
    }

    return list;
  }

  useEffect(() => {
    setFormattedSerials(formattedSerial());
    setOrders(generateOrders(extractedReport));
  }, [listOfSerials, extractedReport]);

  useEffect(() => {
    setOrderSummary(generateSummary(orders));
  }, [orders]);


  return {
    formattedSerials,
    orders,
    orderSummary
  };
}

export default useSerializationTool;
