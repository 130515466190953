import React, { useRef } from "react";
import { Card, Button, Icon, Grid } from "tabler-react";
import dateFormat from "dateformat";
import ReactToPrint from "react-to-print";

const Results = (props) => {
  const { filter1, employees, report, startDate, endDate, user } = props.data;

  const componentRef = useRef();

  return (
    <>
      {report.length == 0 && employees.length != 0 ? (
        ""
      ) : (
        <>
          {report
            .sort((a, b) => a.id - b.id)
            .filter((r) =>
              filter1 !== ""
                ? r.employee_id.includes(filter1) ||
                  r.employee_id.includes(filter1.toLowerCase())
                : r
            )
            .map((r, index) => (
              <div>
                <Card ref={componentRef}>
                  <Card.Body>
                    <p className="tabler_header_hr">
                      GATE DISTRIBUTION ENETERPRISE, INC.
                    </p>
                    <p className="tabler_header_hr">DAILY TIME RECORD</p>
                    <p className="tabler_header_hr">
                      {dateFormat(startDate, "mmmm d")} -{" "}
                      {dateFormat(endDate, "mmmm d, yyyy")}
                    </p>
                    <br />
                    <Grid.Row>
                      <Grid.Col md={1} sm={1}>
                        <p className="tabler_title_hr">Name</p>
                      </Grid.Col>
                      <Grid.Col md={6} sm={6}>
                        <p className="tabler_title_hr">
                          : {r.employee_id} {r.full_name}
                        </p>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={1} sm={1}>
                        <p className="tabler_title_hr">Position</p>
                      </Grid.Col>
                      <Grid.Col md={6} sm={6}>
                        <p className="tabler_title_hr">: {r.position}</p>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={1} sm={1}>
                        <p className="tabler_title_hr">Office</p>
                      </Grid.Col>
                      <Grid.Col md={6} sm={6}>
                        <p className="tabler_title_hr">: Gate</p>
                      </Grid.Col>
                    </Grid.Row>
                    <br />
                    <table className="table_hr">
                      <tr>
                        <th className="th_hr th_sp_btm"></th>
                        <th className="th_hr th_sp_btm"></th>
                        <th className="th_hr th_sp_btm">Shift</th>
                        <th className="th_hr th_sp_btm">Hol. </th>
                        <th className="th_hr th_sp_btm">TIME</th>
                        <th colspan="2" className="th_hr">
                          BREAK
                        </th>
                        <th className="th_hr th_sp_btm">TIME</th>
                        {/* <th className="th_hr th_sp_btm"></th> */}
                        <th className="th_hr th_sp_btm">LATE</th>
                        <th className="th_hr th_sp_btm">UT</th>
                        <th className="th_hr th_sp_btm">OT</th>
                        <th className="th_hr th_sp_btm">REG</th>
                        <th className="th_hr th_sp_btm"></th>
                      </tr>
                      <tr>
                        <th
                          className="th_hr th_sp_top"
                          style={{ width: "50px" }}
                        >
                          Date
                        </th>
                        <th
                          className="th_hr th_sp_top"
                          style={{ width: "60px" }}
                        >
                          Day
                        </th>
                        <th
                          className="th_hr th_sp_top"
                          style={{ width: "200px" }}
                        >
                          Code Description
                        </th>
                        <th
                          className="th_hr th_sp_top"
                          style={{ width: "50px" }}
                        >
                          ID
                        </th>
                        <th
                          className="th_hr th_sp_top"
                          style={{ width: "100px" }}
                        >
                          IN
                        </th>
                        <th className="th_hr" style={{ width: "100px" }}>
                          OUT
                        </th>
                        <th className="th_hr" style={{ width: "100px" }}>
                          IN
                        </th>
                        <th
                          className="th_hr th_sp_top"
                          style={{ width: "100px" }}
                        >
                          OUT
                        </th>
                        {/* <th
                      className="th_hr th_sp_top"
                      style={{ width: "80px" }}
                    ></th> */}
                        <th
                          className="th_hr th_sp_top"
                          style={{ width: "80px" }}
                        >
                          MINS
                        </th>
                        <th
                          className="th_hr th_sp_top"
                          style={{ width: "80px" }}
                        >
                          HRS
                        </th>
                        <th
                          className="th_hr th_sp_top"
                          style={{ width: "80px" }}
                        >
                          HRS
                        </th>
                        <th
                          className="th_hr th_sp_top"
                          style={{ width: "80px" }}
                        >
                          HRS
                        </th>
                        <th
                          className="th_hr th_sp_top"
                          style={{ width: "250px" }}
                        >
                          Remarks
                        </th>
                      </tr>

                      {r.records.map((t, index) => (
                        <tr>
                          <td className="td_hr">{dateFormat(t.date, "d")}</td>
                          <td className="td_hr">{t.day}</td>
                          <td className="td_hr">
                            {t.shift === "RESTDAY" || t.shift === "HOLIDAY" ? (
                              <b style={{ fontWeight: "bold" }}>X {t.shift}</b>
                            ) : (
                              <>
                                {t.type} {t.shift}
                              </>
                            )}
                          </td>
                          <td className="td_hr">{t.holiday_id}</td>
                          <td className="td_hr" style={{ textAlign: "right" }}>
                            {t.time_in !== "RESTDAY" ? t.time_in : ""}
                          </td>
                          <td className="td_hr" style={{ textAlign: "right" }}>
                            {t.break_out}
                          </td>
                          <td className="td_hr" style={{ textAlign: "right" }}>
                            {t.break_in}
                          </td>
                          <td className="td_hr" style={{ textAlign: "right" }}>
                            {t.time_out}
                          </td>
                          {/* <td className="td_hr"></td> */}
                          <td className="td_hr" style={{ textAlign: "right" }}>
                            {t.late == 0 && t.shift !== "FULL FLEXI"
                              ? ""
                              : t.late}
                          </td>
                          <td className="td_hr" style={{ textAlign: "right" }}>
                            {t.under_time == 0 ? "" : t.under_time}
                          </td>
                          <td className="td_hr" style={{ textAlign: "right" }}>
                            {(t.shift === "RESTDAY" || t.shift === "HOLIDAY") &&
                            t.over_time_hours <= 0
                              ? ""
                              : t.over_time_hours}
                          </td>
                          <td className="td_hr" style={{ textAlign: "right" }}>
                            {t.shift === "RESTDAY" ||
                            t.shift === "HOLIDAY" ||
                            t.reg_hours == 0
                              ? ""
                              : (
                                  parseFloat(t.reg_hours) +
                                  parseFloat(
                                    t.shift !== "FULL FLEXI" ? 0 : t.late / 100
                                  )
                                ).toFixed(2)}
                          </td>
                          <td className="td_hr">
                            {t.shift === "RESTDAY" ? "" : t.absent}{" "}
                            {t.shift === "RESTDAY" ? "DAYOFF" : t.remarks}{" "}
                            {t.under_time == 0 ? "" : "UT"}{" "}
                            {t.late == 0 ? "" : "LATE"}
                            {t.over_time_hours != 0 ? t.remarks : ""}{" "}
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <td className="td_hr_footer" colspan={3}>
                          Total Working Days :{" "}
                        </td>
                        {/* <td className="td_hr_footer"></td> */}
                        {/* <td className="td_hr_footer"></td> */}
                        <td
                          className="td_hr_footer"
                          colspan={2}
                          style={{ textAlign: "right" }}
                        >
                          {r.working_days} DAYS
                        </td>
                        {/* <td className="td_hr_footer" style={{ textAlign: "right" }}></td> */}
                        <td
                          className="td_hr_footer"
                          style={{ textAlign: "right" }}
                          colspan={3}
                        >
                          TOTAL:
                        </td>
                        {/* <td className="td_hr_footer" style={{ textAlign: "right" }}></td>
                    <td className="td_hr_footer" style={{ textAlign: "right" }}></td> */}
                        {/* <td className="td_hr"></td> */}
                        <td
                          className="td_hr_footer"
                          style={{ textAlign: "right" }}
                        >
                          {r.records.reduce(
                            (a, b) => a + parseFloat(b.late),
                            0
                          )}{" "}
                        </td>
                        <td
                          className="td_hr_footer"
                          style={{ textAlign: "right" }}
                        >
                          {r.records
                            .reduce((a, b) => a + parseFloat(b.under_time), 0)
                            .toFixed(2)}{" "}
                        </td>
                        <td
                          className="td_hr_footer"
                          style={{ textAlign: "right" }}
                        >
                          {r.records
                            .reduce(
                              (a, b) => a + parseFloat(b.over_time_hours),
                              0
                            )
                            .toFixed(2)}{" "}
                        </td>
                        <td
                          className="td_hr_footer"
                          style={{ textAlign: "right" }}
                        >
                          {(
                            r.records.reduce(
                              (a, b) => a + parseFloat(b.reg_hours),
                              0
                            ) +
                            parseFloat(
                              r.records
                                .filter((e) => e.shift === "FULL FLEXI")
                                .reduce(
                                  (a, b) => a + parseFloat(b.late) / 100,
                                  0
                                )
                            )
                          ).toFixed(2)}{" "}
                        </td>
                        <td className="td_hr_footer"></td>
                      </tr>
                    </table>

                    <br />
                    <Grid.Row>
                      <Grid.Col md={3} sm={3}>
                        <p className="tabler_title_hr">Total No. Absences</p>
                      </Grid.Col>
                      <Grid.Col md={1} sm={1}>
                        <p className="tabler_title_hr">: {r.absents}</p>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={3} sm={3}>
                        <p className="tabler_title_hr">Frequency of Lates</p>
                      </Grid.Col>
                      <Grid.Col md={1} sm={1}>
                        <p className="tabler_title_hr">: {r.lates}</p>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={3} sm={3}>
                        <p className="tabler_title_hr">
                          Frequency of Undertime
                        </p>
                      </Grid.Col>
                      <Grid.Col md={1} sm={1}>
                        <p className="tabler_title_hr">: {r.under_times}</p>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={3} sm={3}>
                        <p className="tabler_title_hr">Frequency of Leave</p>
                      </Grid.Col>
                      <Grid.Col md={1} sm={1}>
                        <p className="tabler_title_hr">: {r.leaves}</p>
                      </Grid.Col>
                    </Grid.Row>
                    <br />
                    {/* <br />
                    <Grid.Row>
                      <Grid.Col><h4>Other Remarks</h4></Grid.Col>
                    </Grid.Row> */}
                    {/* <Grid.Row>
                      <Grid.Col md={8}>
                        Other Remarks and Reasons (
                        <i style={{ fontSize: "12px" }}>
                          Please check the appropriate remarks, provide date/
                          reason and have it verified by someone.
                        </i>
                        )
                      </Grid.Col>
                      <Grid.Col md={4}>Verified By</Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={8}>
                        <b>O Late Filing:</b>{" "}
                        ______________________________________________________________________________________________
                      </Grid.Col>
                      <Grid.Col md={4}>
                        _____________________________________
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={8}>
                        <b>O Late Approval:</b>{" "}
                        __________________________________________________________________________________________
                      </Grid.Col>
                      <Grid.Col md={4}>
                        _____________________________________
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={8}>
                        <b>O No punch out/in:</b>{" "}
                        _______________________________________________________________________________________
                      </Grid.Col>
                      <Grid.Col md={4}>
                        _____________________________________
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={8}>
                        <b>O Others:</b>{" "}
                        __________________________________________________________________________________________________
                      </Grid.Col>
                      <Grid.Col md={4}>
                        _____________________________________
                      </Grid.Col>
                    </Grid.Row> */}

                    <br />
                    <br />
                    <Grid.Row>
                      <Grid.Col md={6} sm={6}>
                        <p
                          className="tabler_title_hr"
                          style={{
                            textAlign: "center",
                            fontWeight: "normal",
                            padding: "10px",
                          }}
                        >
                          I <b>CERTIFY</b> that above is a true and correct
                          report of the hours of work performed, record of which
                          was made daily at the time of arrival at and departure
                          from office.
                          <br />
                          <br />
                          ______________________________________________
                          <br />
                          {r.full_name}
                        </p>
                      </Grid.Col>
                      <Grid.Col md={6} sm={6}>
                        <p
                          className="tabler_title_hr"
                          style={{
                            textAlign: "center",
                            fontWeight: "normal",
                            padding: "10px",
                          }}
                        >
                          I hereby certify that I have checked the entries
                          herein and found them to be true and correct.{" "}
                          <b>VERIFIED</b> as to the prescribed office hours.
                          <br />
                          <br />
                          ______________________________________________
                          <br />
                          Signature of Department Head
                        </p>
                      </Grid.Col>
                    </Grid.Row>
                    {/* <p>Page {index + 1}</p> */}

                    <br />
                    <br />
                    <p style={{ textAlign: "center" }}>
                      -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                    </p>
                    <p style={{ textAlign: "center" }}>
                      Date Printed: {dateFormat(new Date())}
                    </p>
                  </Card.Body>
                </Card>

                <p style={{ pageBreakAfter: "always" }}></p>
              </div>
            ))}
        </>
      )}
    </>
  );
};

export default Results;
