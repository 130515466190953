import { useEffect, useState } from "react";
import config from "./../../../config";
import axios from "axios";
import useQueryURL from "../../services/useQueryURL";
import useUsers from "../Users/useUsers"

function use711Stores() {

  // const { getQuery } = useQueryURL();
  const { loginSession } = useUsers()

  // let page = getQuery("page");

  const [stores, setStores] = useState([]);
  const [store, setStore] = useState([]);

  // console.log(stores);

  function getStores() {
    if (loginSession) {
      axios
        .post(`${config.host}:${config.port}/api/v2/711/stores`)
        .then(function (response) {
          let data = response.data;
          setStores(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getStore(id, actions) {
    if (loginSession) {
      axios
        .post(`${config.host}:${config.port}/api/v2/711/store`, {
          id: id,
          user: loginSession.username,
        })
        .then(function (response) {
          let data = response.data;
          setStore(data);
          actions(id);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function addStore(data, actions) {
    axios
      .post(`${config.host}:${config.port}/api/v2/711/add_store`, {
        store_number: data.storeNumber,
        store_name: data.storeName,
        store_manager: data.storeManager,
        schedule: data.schedule,
        location: data.location,
        status: data.status,
        area_manager: data.areaManager,
        full_address: data.fullAddress,
        contact_number: data.contactNumber,
        email_address: data.emailAddress,
        telemarketer: data.telemarketer,
        account_executive: data.accountExecutive,
        courier_status: data.courierStatus,
        area: data.area,
        district: data.district,
        cluster: data.cluster,
        zone: data.zone,
        monthly_target: data.monthlyTarget,
        date_open: data.dateOpen,
        active: data.active,
        networkPreference: data.networkPreference,
        last_edit: loginSession.username,
      })
      .then(function (response) {
        actions();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function updateStore(data, actions) {
    axios
      .post(`${config.host}:${config.port}/api/v2/711/update_store`, {
        id: data.id,
        store_number: data.storeNumber,
        store_name: data.storeName,
        store_manager: data.storeManager,
        schedule: data.schedule,
        location: data.location,
        status: data.status,
        area_manager: data.areaManager,
        full_address: data.fullAddress,
        contact_number: data.contactNumber,
        email_address: data.emailAddress,
        telemarketer: data.telemarketer,
        account_executive: data.accountExecutive,
        courier_status: data.courierStatus,
        area: data.area,
        district: data.district,
        cluster: data.cluster,
        zone: data.zone,
        monthly_target: data.monthlyTarget,
        date_open: data.dateOpen,
        active: data.active,
        networkPreference: data.networkPreference,
        last_edit: loginSession.username,
      })
      .then(function (response) {
        // console.log(response);
        actions();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getStores();
  }, []);

  return { stores, store, getStore, addStore, updateStore };
}

export default use711Stores;
