import React, { useState, useEffect } from "react";
import { Button, Card, Form, Grid } from "tabler-react";

import usePreferences from "../../../hooks/api/AMS/usePreferences";

const CallLogForm = (props) => {
  const {
    otherAccount,
    addCallLogs,
    deleteCallLogs,
    addNewCall,
    updateCallLogs,
    setAddNewCall,
    client_id,
    getOtherAccountCallByID,
    setSelectedCallLog,
    selectedCallLog,
    selectType,
    setSelectType,
  } = props.data;
  const { preferences } = usePreferences();

  let blankOrder = {
    amount: 0,
    remarks: "",
    notes: "",
    status: "Pending",
    // client_id: "",
    account_executive: "",
  };
  const [formData, setFormData] = useState(blankOrder);
  const [remarkPref, setRemarksPref] = useState([]);

  useEffect(() => {
    setRemarksPref(
      preferences
        ? preferences.filter(
            (ref) => ref.type === "remarks" && ref.store_type == 1
          )
        : []
    );
  }, [preferences]);

  useEffect(() => {
    if (otherAccount.length != 0 && selectedCallLog.length == 0) {
      setFormData({
        ...formData,
        client_id: otherAccount.client_id,
        account_executive: otherAccount.ae_name,
      });
    }
  }, [otherAccount, addNewCall]);

  useEffect(() => {
    if (selectedCallLog.length != 0) {
      setFormData({
        ...formData,
        id: selectedCallLog.id,
        amount: selectedCallLog.amount,
        remarks: selectedCallLog.remarks,
        notes: selectedCallLog.notes,
        status: selectedCallLog.call_status,
        client_id: selectedCallLog.client_id,
        account_executive: selectedCallLog.account_executive,
      });
    }
  }, [selectedCallLog]);

  return (
    <>
      {addNewCall || selectedCallLog.length != 0 ? (
        <Card>
          <Card.Header>
            Add Call Log Form
            <Card.Options>
              <Button
                color="secondary"
                onClick={() =>
                  setAddNewCall(false) ||
                  setSelectedCallLog([]) ||
                  setFormData(blankOrder)
                }
              >
                Cancel
              </Button>
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={6}>
                <Form.Group>
                  <Form.Label>Order Amount</Form.Label>
                  <Form.Input
                    value={formData.amount}
                    onChange={(e) =>
                      setFormData({ ...formData, amount: e.target.value })
                    }
                    readOnly={selectType === "view"}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Remarks</Form.Label>
                  <Form.Select
                    value={formData.remarks}
                    onChange={(e) =>
                      setFormData({ ...formData, remarks: e.target.value })
                    }
                    disabled={selectType === "view"}
                  >
                    <option value="">Choose One</option>
                    {remarkPref.map((remark, index) => (
                      <>
                        {remark.active == 1 ? (
                          <option value={remark.value} key={index}>
                            {remark.value}
                          </option>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Notes</Form.Label>
                  <Form.Textarea
                    value={formData.notes}
                    onChange={(e) =>
                      setFormData({ ...formData, notes: e.target.value })
                    }
                    disabled={selectType === "view"}
                  ></Form.Textarea>
                </Form.Group>
              </Grid.Col>

              <Grid.Col md={6}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={formData.status}
                    onChange={(e) =>
                      setFormData({ ...formData, status: e.target.value })
                    }
                    disabled={selectType === "view"}
                  >
                    <option value="">Choose One</option>
                    <option value="Completed">Completed</option>
                    <option value="Pending">Pending</option>
                  </Form.Select>
                </Form.Group>
                {selectedCallLog.length != 0 && selectType === "edit" ? (
                  <>
                  <Button
                    color="cyan"
                    icon="save"
                    onClick={() =>
                      updateCallLogs(
                        formData,
                        () =>
                          setAddNewCall(false) ||
                          setFormData(blankOrder) ||
                          getOtherAccountCallByID(client_id) ||
                          setSelectedCallLog([])
                      )
                    }
                  >
                    Update
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() =>
                      deleteCallLogs(
                        formData,
                        () =>
                          setAddNewCall(false) ||
                          setFormData(blankOrder) ||
                          alert("Call Log Successfully Deleted!") ||
                          getOtherAccountCallByID(client_id) ||
                          setSelectedCallLog([])
                      )
                    }
                  >
                    DELETE THIS CALL LOG
                  </Button>
                  </>
                ) : (
                  ""
                )}

                {selectedCallLog.length == 0 ? (
                  <Button
                    color="cyan"
                    icon="save"
                    onClick={() =>
                      addCallLogs(
                        formData,
                        () =>
                          setAddNewCall(false) ||
                          setFormData(blankOrder) ||
                          getOtherAccountCallByID(client_id)
                      )
                    }
                  >
                    Save
                  </Button>
                ) : (
                  ""
                )}
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        </Card>
      ) : (
        ""
      )}
    </>
  );
};

export default CallLogForm;
