import { useState, useEffect } from "react";
import dateFormat from "dateformat";
import useDirectoryAPI from "../GVD/useDirectoryAPI";

function useShopeeTool(data, orderIDs, spx, jnt) {
  const { directory } = useDirectoryAPI();

  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [orderSummary, setOrderSummary] = useState([]);

  function getShipoutDate(shipping_options) {
    let d;

    if (shipping_options === "StandardLocalJTExpress") {
      d = new Date(jnt);
    } else if (shipping_options === "StandardLocalStandardDelivery") {
      d = new Date(spx);
    } else {
      d = new Date();
    }

    return d;
  }

  function generateOrders(data) {
    let excludeIDs = orderIDs.split("\n");

    let list = [];
    let orderIds = [];

    console.log(data.map(d => d[8]));

    const generateBundle = (orderData, itemcodes) => {
      let itemCodes = itemcodes
        .split(",")
        .filter((v, i) => itemcodes.split(",").indexOf(v) == i);
      let items = [];

      console.log(itemCodes);

      let count = itemcodes.split(",");

      for (let index = 0; index < itemCodes.length; index++) {
        let gvdItem = directory.filter((v) => v.item_code === itemCodes[index]);

        if (gvdItem.length !== 0) {
          items.push({
            order_id: `*${orderData[0]}`,
            order_date: dateFormat(orderData[8], "longDate"),
            shipout_date: dateFormat(
              getShipoutDate(orderData[6].replace(/\s|-|&/g, "")),
              "longDate"
            ),
            tracking_number: `*${orderData[3]}`,
            shipping_option: orderData[4],
            product_name: `${orderData[11]}${orderData[13]} - ${gvdItem[0].variant_name}`,
            variation_name: orderData[13] ? orderData[13] : "--",
            gate_price: orderData[14],
            shopee_price: orderData[15],
            quantity:count.filter((c) => c === itemCodes[index]).length *  orderData[16],
            product_subtotal: orderData[18],
            buyer_paid_shipping_fee: orderData[34],
            seller_voucher: orderData[25],
            seller_coins: orderData[26],
            shopee_voucher: orderData[27],
            shopee_coins: orderData[31] / 100,
            variant_name: gvdItem[0].variant_name,
            item_code: itemCodes[index],
            aquisition: gvdItem[0].aquisition,
            gross_price: gvdItem[0].gross_price,
          });
        }
      }

      return items;
    };

    for (let index = 1; index < data.length; index++) {
      console.log(data[index][8]);
      if (data[index][3] !== "") {
        if (!excludeIDs.find((id) => id.replace("*", "") === data[index][0])) {
          if (
            `${data[index][11]}${data[index][13]}` ===
            "Smart Sim With Up To 21gb Data + LoadSIM + 100 Load"
          ) {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][16]; i++) {
              list.push(
                {
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 100 Load - Smart Prepaid CallCard 100",
                  variation_name: "--",
                  gate_price: "100",
                  shopee_price: "100",
                  quantity: 1,
                  variant_name: "OA SMART BUDDY GSM 100",
                  item_code: "116010103",
                  aquisition: "0.08",
                  gross_price: "100",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][31] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][8], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][6].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][3]}`,
                  shipping_option: data[index][4],
                },
                {
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 100 Load - Smart 5G Tri-Cut Sim",
                  variation_name: "--",
                  gate_price: "40",
                  shopee_price: "40",
                  quantity: 1,
                  variant_name: "OA SMART REGULAR SIM",
                  item_code: "117010101",
                  aquisition: "0.05",
                  gross_price: "40",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][31] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][8], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][6].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][3]}`,
                  shipping_option: data[index][4],
                }
              );
            }
          } else if (
            `${data[index][11]}${data[index][13]}` ===
            "Smart Sim With Up To 21gb Data + LoadSIM + 200 Load"
          ) {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][16]; i++) {
              list.push(
                {
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 200 Load - Smart Prepaid CallCard 100",
                  variation_name: "--",
                  gate_price: "100",
                  shopee_price: "100",
                  quantity: 2,
                  variant_name: "OA SMART BUDDY GSM 100",
                  item_code: "116010103",
                  aquisition: "0.08",
                  gross_price: "100",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][31] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][8], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][6].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][3]}`,
                  shipping_option: data[index][4],
                },
                {
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 200 Load - Smart 5G Tri-Cut Sim",
                  variation_name: "--",
                  gate_price: "40",
                  shopee_price: "40",
                  quantity: 1,
                  variant_name: "OA SMART REGULAR SIM",
                  item_code: "117010101",
                  aquisition: "0.05",
                  gross_price: "40",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][31] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][8], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][6].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][3]}`,
                  shipping_option: data[index][4],
                }
              );
            }
          } else if (
            `${data[index][11]}${data[index][13]}` ===
            "Smart Sim With Up To 21gb Data + LoadSIM + 300 Load"
          ) {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][16]; i++) {
              list.push(
                {
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 300 Load - Smart Prepaid CallCard 300",
                  variation_name: "--",
                  gate_price: "300",
                  shopee_price: "300",
                  quantity: 1,
                  variant_name: "OA SMART BUDDY GSM 300",
                  item_code: "116010101",
                  aquisition: "0.08",
                  gross_price: "300",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][31] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][8], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][6].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][3]}`,
                  shipping_option: data[index][4],
                },
                {
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 300 Load - Smart 5G Tri-Cut Sim",
                  variation_name: "--",
                  gate_price: "40",
                  shopee_price: "40",
                  quantity: 1,
                  variant_name: "OA SMART REGULAR SIM",
                  item_code: "117010101",
                  aquisition: "0.05",
                  gross_price: "40",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][31] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][8], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][6].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][3]}`,
                  shipping_option: data[index][4],
                }
              );
            }
          } else if (
            `${data[index][11]}${data[index][13]}` ===
            "TNT Sim With Up To 21gb Data + LoadSIM + 100 Load"
          ) {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][16]; i++) {
              list.push(
                {
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 100 Load - Talk 'n Text Prepaid CallCard 100",
                  variation_name: "--",
                  gate_price: "100",
                  shopee_price: "100",
                  quantity: 1,
                  variant_name: "OA TALK 'N TEXT 100",
                  item_code: "116050102",
                  aquisition: "0.08",
                  gross_price: "100",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][32] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][9], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][7].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][4]}`,
                  shipping_option: data[index][5],
                },
                {
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 100 Load - TNT 5G Ready Tri-Cut Sim",
                  variation_name: "--",
                  gate_price: "30",
                  shopee_price: "30",
                  quantity: 1,
                  variant_name: "OA TALK 'N TEXT SIMPACK	",
                  item_code: "117050101",
                  aquisition: "0.05",
                  gross_price: "30",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][32] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][9], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][7].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][4]}`,
                  shipping_option: data[index][5],
                }
              );
            }
          } else if (
            `${data[index][11]}${data[index][13]}` ===
            "TNT Sim With Up To 21gb Data + LoadSIM + 200 Load"
          ) {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][16]; i++) {
              list.push(
                {
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 200 Load - Talk 'n Text Prepaid CallCard 100",
                  variation_name: "--",
                  gate_price: "100",
                  shopee_price: "100",
                  quantity: 2,
                  variant_name: "OA TALK 'N TEXT 100",
                  item_code: "116050102",
                  aquisition: "0.08",
                  gross_price: "100",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][32] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][9], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][7].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][4]}`,
                  shipping_option: data[index][5],
                },
                {
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 200 Load - TNT 5G Ready Tri-Cut Sim",
                  variation_name: "--",
                  gate_price: "30",
                  shopee_price: "30",
                  quantity: 1,
                  variant_name: "OA TALK 'N TEXT SIMPACK	",
                  item_code: "117050101",
                  aquisition: "0.05",
                  gross_price: "30",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][32] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][9], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][7].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][4]}`,
                  shipping_option: data[index][5],
                }
              );
            }
          } else if (
            `${data[index][11]}${data[index][13]}` ===
            "TNT Sim With Up To 21gb Data + LoadSIM + 300 Load"
          ) {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][16]; i++) {
              list.push(
                {
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 300 Load - Talk 'n Text Prepaid CallCard 300",
                  variation_name: "--",
                  gate_price: "300",
                  shopee_price: "300",
                  quantity: 1,
                  variant_name: "OA TALK 'N TEXT 300",
                  item_code: "116050101",
                  aquisition: "0.08",
                  gross_price: "300",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][32] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][9], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][7].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][4]}`,
                  shipping_option: data[index][5],
                },
                {
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 300 Load - TNT 5G Ready Tri-Cut Sim",
                  variation_name: "--",
                  gate_price: "30",
                  shopee_price: "30",
                  quantity: 1,
                  variant_name: "OA TALK 'N TEXT SIMPACK	",
                  item_code: "117050101",
                  aquisition: "0.05",
                  gross_price: "30",
                  product_subtotal: data[index][18],
                  buyer_paid_shipping_fee: data[index][34],
                  seller_voucher: data[index][25],
                  seller_coins: data[index][26],
                  shopee_voucher: data[index][27],
                  shopee_coins: data[index][32] / 100,
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][9], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][7].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][4]}`,
                  shipping_option: data[index][5],
                }
              );
            }
          } else {
            orderIds.push(data[index][0]);

            let bundle = directory.find(
              (v) =>
                `${v.shop_name} - ${v.shopee_price}` ===
                `${data[index][11]}${data[index][13]} - ${data[index][15]}`
            )
              ? directory.find(
                  (v) =>
                    `${v.shop_name} - ${v.shopee_price}` ===
                    `${data[index][11]}${data[index][13]} - ${data[index][15]}`
                ).item_code
              : "";

            if (bundle.split(",").length >= 2) {
             
              list.push(...generateBundle(data[index], bundle));
            } else {
              list.push({
                order_id: `*${data[index][0]}`,
                order_date: dateFormat(data[index][8], "longDate"),
                shipout_date: dateFormat(
                  getShipoutDate(data[index][6].replace(/\s|-|&/g, "")),
                  "longDate"
                ),
                tracking_number: `*${data[index][3]}`,
                shipping_option: data[index][4],
                product_name: `${data[index][11]}${data[index][13]}`,
                variation_name: data[index][13] ? data[index][13] : "--",
                gate_price: data[index][14],
                shopee_price: data[index][15],
                quantity: data[index][16],
                product_subtotal: data[index][18],
                buyer_paid_shipping_fee: data[index][34],
                seller_voucher: data[index][25],
                seller_coins: data[index][26],
                shopee_voucher: data[index][27],
                shopee_coins: data[index][31] / 100,
                variant_name: directory.find(
                  (v) =>
                    `${v.shop_name} - ${v.shopee_price}` ===
                    `${data[index][11]}${data[index][13]} - ${data[index][15]}`
                )
                  ? directory.find(
                      (v) =>
                        `${v.shop_name} - ${v.shopee_price}` ===
                        `${data[index][11]}${data[index][13]} - ${data[index][15]}`
                    ).variant_name
                  : "No Variant Name",
                item_code: directory.find(
                  (v) =>
                    `${v.shop_name} - ${v.shopee_price}` ===
                    `${data[index][11]}${data[index][13]} - ${data[index][15]}`
                )
                  ? directory.find(
                      (v) =>
                        `${v.shop_name} - ${v.shopee_price}` ===
                        `${data[index][11]}${data[index][13]} - ${data[index][15]}`
                    ).item_code
                  : "No Item Code",
                aquisition: directory.find(
                  (v) =>
                    `${v.shop_name} - ${v.shopee_price}` ===
                    `${data[index][11]}${data[index][13]} - ${data[index][15]}`
                )
                  ? directory.find(
                      (v) =>
                        `${v.shop_name} - ${v.shopee_price}` ===
                        `${data[index][11]}${data[index][13]} - ${data[index][15]}`
                    ).aquisition
                  : "No Aquisition",
                gross_price: directory.find(
                  (v) =>
                    `${v.shop_name} - ${v.shopee_price}` ===
                    `${data[index][11]}${data[index][13]} - ${data[index][15]}`
                )
                  ? directory.find(
                      (v) =>
                        `${v.shop_name} - ${v.shopee_price}` ===
                        `${data[index][11]}${data[index][13]} - ${data[index][15]}`
                    ).gross_price
                  : "No Gross Price",
              });
            }
          }
        }
      }
    }

    let orderCount = orderIds.filter((v, i) => orderIds.indexOf(v) == i).length;

    // console.log(list);

    return { list, orderCount };
  }

  function generateSummaryReport(orders) {
    // console.log(orders);
    let item_codes = [];
    let summary = [];

    for (let index = 0; index < orders.length; index++) {
      if (parseInt(orders[index].quantity) > 1) {
        for (let index1 = 0; index1 < orders[index].quantity; index1++) {
          item_codes.push(orders[index].item_code);
        }
      } else {
        item_codes.push(orders[index].item_code);
      }
    }

    let filtered_item_codes = item_codes.filter(
      (v, i) => item_codes.indexOf(v) == i
    );

    for (let index = 0; index < filtered_item_codes.length; index++) {
      summary.push({
        variant_name: orders.filter(
          (order) => order.item_code === filtered_item_codes[index]
        )
          ? orders.filter(
              (order) => order.item_code === filtered_item_codes[index]
            )[0].variant_name
          : "--",
        item_code: filtered_item_codes[index],
        quantity: item_codes.filter(
          (order) => order === filtered_item_codes[index]
        ).length,
      });
    }

    // console.log(summary);

    return summary;
  }

  useEffect(() => {
    setOrders(generateOrders(data) ? generateOrders(data).list : "");
    setOrdersCount(generateOrders(data) ? generateOrders(data).orderCount : 0);
    setOrderSummary(
      generateOrders(data)
        ? generateSummaryReport(generateOrders(data).list)
        : ""
    );
  }, [data, orderIDs, spx, jnt]);

  return {
    orders,
    ordersCount,
    orderSummary,
  };
}

export default useShopeeTool;
