import React from "react";
import { Button, Card, Form, Grid, Loader } from "tabler-react";
import useShopReportView from "../../../../../hooks/api/TiktokGOMOShop/useShopReportView";

const JanuaryReport = (props) => {
  const { data } = props;
  const { month, year } = data;
  const { reports1 } = useShopReportView(month, year);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      {reports1.length != 0 ? (
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={1}>
              <p style={{ fontSize: "10px", margin: 0 }}>MONTH</p>
              <a
                href={`/TiktokGOMOShop/Orders?dr_number=&SI_number=--&month=${reports1.month.replace(
                  "-report",
                  ""
                )}&order_id=&year=${year}`}
                target="_blank"
                style={{ textDecoration: "none", color: "#17a2b8" }}
              >
                <p style={{ fontSize: "18px", fontWeight: "bold", margin: 0 }}>
                  {reports1.month.replace("-report", "").toUpperCase()}
                </p>
              </a>
            </Grid.Col>
            <Grid.Col md={1}>
              <p style={{ fontSize: "10px", margin: 0 }}>TOTAL GROSS SALES</p>
              <p style={{ fontSize: "18px", fontWeight: "bold", margin: 0 }}>
                P {numberWithCommas(reports1.gross_price)}
              </p>
            </Grid.Col>
            <Grid.Col md={1}>
              <p style={{ fontSize: "10px", margin: 0 }}>TOTAL DR COUNTS</p>
              <p style={{ fontSize: "18px", fontWeight: "bold", margin: 0 }}>
                {reports1.drs.length}
              </p>
            </Grid.Col>
            <Grid.Col md={1}>
              <p style={{ fontSize: "10px", margin: 0 }}>TOTAL SI COUNTS</p>
              <p style={{ fontSize: "18px", fontWeight: "bold", margin: 0 }}>
                {reports1.SI.length}
              </p>
            </Grid.Col>
            <Grid.Col md={1}>
              <p style={{ fontSize: "10px", margin: 0 }}>TOTAL PENDING DR</p>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  margin: 0,
                  color: reports1.pending.length == 0 ? "black" : "red",
                }}
              >
                {reports1.pending.length}
              </p>
            </Grid.Col>
            <Grid.Col md={2}>
              <p style={{ fontSize: "10px", margin: 0 }}>
                ORDER WITH MKTG CONCERNS
              </p>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  margin: 0,
                  color: reports1.marketing_concerns == 0 ? "black" : "red",
                }}
              >
                {reports1.marketing_concerns}
              </p>
            </Grid.Col>

            <Grid.Col md={2}>
              <p style={{ fontSize: "10px", margin: 0 }}>STATUS</p>
              {reports1.drs.length == 0 ? (
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    margin: 0,
                    color: "#2bcbba",
                  }}
                >
                  NO ORDERS
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    margin: 0,
                    color: reports1.pending.length == 0 ? "#17a2b8" : "#2bcbba",
                  }}
                >
                  {reports1.pending.length == 0 ? "COMPLETED" : "NOT COMPLETED"}
                </p>
              )}
            </Grid.Col>
            <Grid.Col md={3}>
              <a
                href={`/TiktokGOMOShop/Dashboard?month=${reports1.month.replace(
                  "-report",
                  ""
                )}&year=${year}`}
                target="_blank"
              >
                <Button
                  color="teal"
                  disabled={reports1.pending.length == 0}
                >
                  View DR's
                </Button>
              </a>
              <a
                href={`/TiktokGOMOShop/SIDashboard?month=${reports1.month.replace(
                  "-report",
                  ""
                )}&year=${year}`}
                target="_blank"
              >
                <Button color="cyan" disabled={reports1.SI.length == 0}>
                  View SI's
                </Button>
              </a>
              <a
                href={`/TiktokGOMOShop/RTSDashboard?month=${reports1.month.replace(
                  "-report",
                  ""
                )}&year=${year}`}
                target="_blank"
              >
                <Button
                  color="azure"
                  disabled={reports1.marketing_concerns == 0}
                >
                  View RTS
                </Button>
              </a>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default JanuaryReport;
