import config from "./../../../config";
import axios from "axios";
import useUsers from "../Users/useUsers";

function useBulkEdit() {
  const { loginSession } = useUsers()

  function bulkEditByTele(data, actions) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/711/bulk_edit_telemarketer`,
          {
              oldTele: data.oldTele,
              newTele: data.newTele,
              last_edit: loginSession.username
          }
        )
        .then(function (response) {
          actions()
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  return { bulkEditByTele };
}

export default useBulkEdit;
