module.exports = {
  PRODUCTS: [
    "GLOBE_GLOBE HOME PREPAID WIFI (HPW)  999",
    "SMART_SMART BRO LTE POCKET WIFI 888",
    "SMART_SMART BRO HOME WIFI 495",
    "GLOBE_GLOBE LTE MOBILE WIFI 799",
    "GLOBE_GLOBE REGULAR SIM 40",
    "GLOBE_TOUCH MOBILE SIMPACK 30",
    "GLOBE_GOMO SIM 399",
    "SMART_SMART TINY TAN SIM 45",
    "SMART_SMART REGULAR SIM 40",
    "SMART_SMART BRO ROCKET SIM (7 days unli data) 199",
    "SMART_SMART RETAILER SIM 190",
    "SMART_SMART DSA SIM 10",
    "SMART_TALK N TEXT SIMPACK 30",
    "DITO_DITO SIM STARTER PACK 39",
    "GLOBE_GLOBE HANDYPHONE 50",
    "GLOBE_GLOBE HANDYPHONE 100",
    "GLOBE_GLOBE HANDYPHONE 300",
    "GLOBE_GLOBE HANDYPHONE 500",
    "GLOBE_TOUCH MOBILE CARD 50",
    "GLOBE_TOUCH MOBILE CARD 100",
    "GLOBE_TOUCH MOBILE CARD 300",
    "SMART_SMART BUDDY GSM 100",
    "SMART_SMART BUDDY GSM 300",
    "SMART_SMART BUDDY GSM 500",
    "SMART_SMART SULIT IDD 100",
    "SMART_SMART BUDDY TICKET 15",
    "SMART_SMART BUDDY TICKET 50",
    "SMART_TALK N TEXT 100",
    "SMART_TALK N TEXT 300",
    "SMART_TALK N TEXT TICKET 10",
    "SMART_TALK N TEXT TICKET 15",
    "SMART_TALK N TEXT 50",
    "CIGNAL_CIGNAL RELOAD CARD 300",
    "CIGNAL_CIGNAL RELOAD CARD 450",
    "CIGNAL_CIGNAL RELOAD CARD 600",
    "CIGNAL_CIGNAL SATLITE  199",
    "CIGNAL_CIGNAL SATLITE  299",
    "EPINS_GAME CLUB E-COIN 20",
    "EPINS_GAME CLUB E-COIN 50",
    "EPINS_GAME CLUB E-COIN 100",
  ],
};
