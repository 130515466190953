import { useState, useEffect } from "react";
import config from "../../../config";
import axios from "axios";
import useUsers from "./../Users/useUsers";

function useLogs() {
  //   const { loginSession } = useUsers();

  const [ loginLogs, setLoginLogs] = useState([])

  function login_logs(data, status) {
    axios
      .post(`${config.host}:${config.port}/api/v2/logs/login_logs`, {
        username: data.username,
        status: status,
      })
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
        window.location.replace("/login?error_code=1");
      });
  }

  function insert_login_logs(data) {
    axios
      .post(`${config.host}:${config.port}/api/v2/logs/insert_login_logs`, {
        username: data.username,
      })
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  function get_login_logs() {
    axios
      .post(`${config.host}:${config.port}/api/v2/logs/get_login_logs`)
      .then(function (response) {
        let data = response.data
        setLoginLogs(data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    get_login_logs()
  }, [])
  return { login_logs, insert_login_logs, loginLogs };
}

export default useLogs;
