import React, { useEffect, useState } from "react";
import { Card, Form, Grid } from "tabler-react";

const Filter = (props) => {
  const { data } = props;
  const { directory, telcoList, setNewList, setActivePage } = data;

  const [filter1, setFilter1] = useState("");
  const [filter2, setFilter2] = useState("");
  const [filter3, setFilter3] = useState("");
  const [filter4, setFilter4] = useState("");
  const [filter5, setFilter5] = useState("");

  useEffect(() => {
    setActivePage(1);
    setNewList(
      directory
        .filter((d) => (filter1 !== "" ? d.telco === filter1 : d))
        .filter((d) => (filter2 !== "" ? d.item_code === filter2 : d))
        .filter((d) => (filter3 !== "" ? d.variant_name.toLowerCase().includes(filter3.toLowerCase()) : d))
        .filter((d) => (filter4 !== "" ? d.shop_name.toLowerCase().includes(filter4.toLowerCase()) : d))
        .filter((d) => (filter5 !== "" ? d.item_sku === filter5 : d))
    );
  }, [directory, filter1, filter2, filter3, filter4, filter5]);

  return (
    <>
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={2}>
            <Form.Label>Telco/ Supplier</Form.Label>
            <Form.Select
              value={filter1}
              onChange={(e) => setFilter1(e.target.value)}
              placeholder="search by telco/ brand/ supplier"
            >
              <option value="">ALL</option>
              {telcoList.map((t, index) => (
                <option value={t.brand} key={index}>
                  {t.brand}
                </option>
              ))}
            </Form.Select>
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Label>Item Code</Form.Label>
            <Form.Input
              value={filter2}
              onChange={(e) => setFilter2(e.target.value)}
              placeholder="search by item code"
            />
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Label>Item SKU</Form.Label>
            <Form.Input
              value={filter5}
              onChange={(e) => setFilter5(e.target.value)}
              placeholder="search by item sku"
            />
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Label>Gate Variant Name</Form.Label>
            <Form.Input
              value={filter3}
              onChange={(e) => setFilter3(e.target.value)}
              placeholder="search by gate variant name"
            />
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Label>Shop Name</Form.Label>
            <Form.Input
              value={filter4}
              onChange={(e) => setFilter4(e.target.value)}
              placeholder="search by shop name"
            />
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </>
  );
};

export default Filter;
