import React from "react";
import { Button, Card, Form, Grid, Icon } from "tabler-react";
import DatePicker from "react-datepicker";

//hooks
import useQueryURL from "../../../../hooks/services/useQueryURL";
import useDirectoryAPI from "../../../../hooks/api/GVD/useDirectoryAPI";

const Filter1 = (props) => {
  const { getQuery, changeQueryParams } = useQueryURL();
  const { telcoList } = useDirectoryAPI();
  const { data } = props;
  const {
    src,
    accessLevel,
    orders,
    readyStatus,
    downloadOrders,
    newOrders,
    assignedSI,
    SINumber,
    setSINumber,
    SIDate,
    setSIDate,
    setAssignedSI,
    viewReport,
    setViewReport,
    assignedSINumber,
    filter,
    setFilter,
    filter1,
    setFilter1,
    filter2,
    setFilter2,
    filter3,
    setFilter3,
    filter4,
    setFilter4,
    filter5,
    setFilter5,
    filter6,
    setFilter6,
    filter7,
    setFilter7,
    filter8,
    setFilter8,
  } = data;

  const createSearchFilter = () => {
    if (
      filter ||
      filter === "" ||
      filter1 ||
      filter1 === "" ||
      filter2 ||
      filter2 === "" ||
      filter3 ||
      filter3 === "" ||
      filter4 ||
      filter4 === "" ||
      filter6 ||
      filter6 === "" ||
      filter7 ||
      filter7 === "" ||
      filter8 ||
      filter8 === ""
    ) {
      window.location.replace(
        changeQueryParams(
          window.location.href,
          [
            "month",
            "year",
            "dr_number",
            "order_id",
            "remarks",
            "shop_status",
            "gate_status",
            "product_name",
          ],
          [
            filter,
            filter1,
            filter2,
            filter3,
            filter4,
            filter6,
            filter7,
            filter8,
          ]
        )
      );
    } else {
      window.location.replace(
        window.location.href +
          `?month=${filter}&year=${filter1}&dr_number=${filter2}&order_id=${filter3}&reamrks=${filter4}&shop_status=${filter6}&gate_status=${filter7}&product_name=${filter8}`
      );
    }
  };

  return (
    <>
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={1}>
            <Form.Label>Month</Form.Label>
            <Form.Select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </Form.Select>
          </Grid.Col>
          <Grid.Col md={1}>
            <Form.Label>Year</Form.Label>
            <Form.Select
              value={filter1}
              onChange={(e) => setFilter1(e.target.value)}
            >
              <option value="">All</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
            </Form.Select>
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Label>DR ONLY</Form.Label>
            <Form.Input
              placeholder="Search DR ONLY . . ."
              type="text"
              value={filter2}
              onChange={(e) => setFilter2(e.target.value)}
              // disabled={SI_Number !== "--" ? true : false}
            />
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Label>Order ID/ Number</Form.Label>
            <Form.Input
              placeholder="Search Order Number . . ."
              type="text"
              value={filter3}
              onChange={(e) => setFilter3(e.target.value)}
              // disabled={SI_Number !== "--" ? true : false}
            />
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Label>Shop/ Product Name</Form.Label>
            <Form.Input
              placeholder="SearchProduct name . . ."
              type="text"
              value={filter8}
              onChange={(e) => setFilter8(e.target.value)}
              // disabled={SI_Number !== "--" ? true : false}
            />
          </Grid.Col>
          <Grid.Col md={1}>
            <Form.Label>Shop Status</Form.Label>
            <Form.Select
              value={filter6}
              onChange={(e) => setFilter6(e.target.value)}
            >
              {orders
                .map((o) => o.delivery_status)
                .filter(
                  (v, i) => orders.map((o) => o.delivery_status).indexOf(v) == i
                )
                .sort()
                .map((status, index) => (
                  <option value={status.replace("--", "")} key={index}>
                    {status === "" || status === "--" ? "ALL" : status}
                  </option>
                ))}
              <option value="Request for Payment">Request for Payment</option>
            </Form.Select>
          </Grid.Col>
          <Grid.Col md={1}>
            <Form.Label>Inv. Status</Form.Label>
            <Form.Select
              value={filter7}
              onChange={(e) => setFilter7(e.target.value)}
            >
              {orders
                .map((o) => o.gate_status.replace("--", ""))
                .filter(
                  (v, i) =>
                    orders
                      .map((o) => o.gate_status.replace("--", ""))
                      .indexOf(v) == i
                )
                .sort()
                .map((status, index) => (
                  <option value={status} key={index}>
                    {status === "" || status === "--" ? "ALL" : status}
                  </option>
                ))}
            </Form.Select>
          </Grid.Col>
          <Grid.Col md={1}>
            <Form.Label>Remarks</Form.Label>
            <Form.Select
              value={filter4}
              onChange={(e) => setFilter4(e.target.value)}
            >
              {orders
                .map((o) => o.remarks.replace("--", ""))
                .filter(
                  (v, i) =>
                    orders.map((o) => o.remarks.replace("--", "")).indexOf(v) ==
                    i
                )
                .map((remark, index) => (
                  <option value={remark} key={index}>
                    {remark === "" || remark === "--" ? "ALL" : remark}
                  </option>
                ))}
            </Form.Select>
          </Grid.Col>
          {src === "ShopeeShop" ? (
            ""
          ) : (
            <Grid.Col md={2} sm={12}>
              <Form.Label>Supplier</Form.Label>
              <Form.Select
                value={filter5}
                onChange={(e) => setFilter5(e.target.value)}
              >
                <option value="">All</option>
                {telcoList.map((t, index) => (
                  <option value={t.brand} key={index}>
                    {t.brand}
                  </option>
                ))}
              </Form.Select>
            </Grid.Col>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={2} sm={12}>
            <Form.Label></Form.Label>
            <Button
              icon="search"
              className="search-btn"
              color="cyan"
              onClick={() => createSearchFilter()}
            >
              Search
            </Button>
          </Grid.Col>
          <Grid.Col md={10} sm={12}>
            <Form.Label></Form.Label>
            <Button
              onClick={() =>
                downloadOrders(newOrders, filter2 === "" ? "all" : filter2)
              }
              color="cyan"
              disabled={accessLevel === "view"}
            >
              <Icon prefix="fa" name="download" /> Extract
            </Button>

            {filter2 ? (
              <>
                {viewReport ? (
                  <Button
                    color="secondary"
                    onClick={() => setViewReport(false)}
                  >
                    <Icon prefix="fa" name="close" /> HIDE SUMMARY
                  </Button>
                ) : (
                  <Button color="azure" onClick={() => setViewReport(true)}>
                    <Icon prefix="fa" name="print" /> VIEW SUMMARY
                  </Button>
                )}
              </>
            ) : (
              ""
            )}

            {readyStatus.length != 0 && readyStatus.SI_Number === "--" ? (
              readyStatus.shopee == readyStatus.gate_gross ? (
                <Button
                  onClick={() => setAssignedSI(true)}
                  color="teal"
                  disabled={accessLevel === "view" || accessLevel === "report"}
                >
                  <Icon prefix="fa" name="list" /> ASSIGNED SI
                </Button>
              ) : (
                <Button
                  onClick={() => setAssignedSI(true)}
                  color="secondary"
                  disabled={accessLevel === "view" || accessLevel === "report"}
                >
                  <Icon prefix="fa" name="list" /> FORCED ASSIGNED SI
                </Button>
              )
            ) : (
              ""
            )}

            {assignedSI ? (
              <Grid.Row>
                <Grid.Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Assigned SI Number</Form.Label>
                    <Form.Input
                      value={SINumber}
                      placeholder="Input SI Number"
                      onChange={(e) => setSINumber(e.target.value)}
                    />
                    <Form.Label>Date</Form.Label>
                    <DatePicker
                      selected={SIDate}
                      onChange={(date) => setSIDate(date)}
                      className="form-control"
                    />
                    <br />
                    <br />
                    {SINumber && filter2 && readyStatus.length != 0 ? (
                      <>
                        {readyStatus.shopee == readyStatus.gate_gross ? (
                          <Button
                            icon="save"
                            color="azure"
                            onClick={() =>
                              assignedSINumber(filter2, SINumber, SIDate)
                            }
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            icon="save"
                            color="azure"
                            onClick={() =>
                              assignedSINumber(
                                filter2,
                                `${SINumber} (forced)`,
                                SIDate
                              )
                            }
                          >
                            Force Assign
                          </Button>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    <Button
                      color="secondary"
                      onClick={() => setAssignedSI(false)}
                    >
                      <Icon prefix="fa" name="close" /> Cancel
                    </Button>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            ) : (
              ""
            )}
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </>
  );
};

export default Filter1;
