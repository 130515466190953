import { useState, useEffect } from "react";
import config from "../../../config";
import axios from "axios";
import useUsers from "../Users/useUsers";

function useDirectoryAPI() {
  const { loginSession } = useUsers()

  const [directory, setDirectory] = useState([]);
  const [telcoList, setTelcoList] = useState([]);

  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [seledtedVariant, setSelectedVariant] = useState("");

  function getVariantList() {
    axios
      .get(`${config.host}:${config.port}/api/v2/directory/directory`)
      .then(function (response) {
        // handle success
        setDirectory(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  function getTelcoList() {
    axios
      .get(`${config.host}:${config.port}/api/v2/telco/telco_all`)
      .then(function (response) {
        // handle success
        setTelcoList(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  function getVariant(id) {
    axios
      .post(
        `${config.host}:${config.port}/api/v2/directory/variant_directory_id`,
        { id: id }
      )
      .then(function (response) {
        // handle success
        let data = response.data;
        setSelectedVariant(data[0]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  function addVariant(data, actions) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/directory/add_variant_directory`,
          {
            telco: data.telco,
            item_code: data.item_code,
            variant_name: data.variant_name,
            shop_name: data.shop_name,
            gross_price: data.gross_price,
            shopee_price: data.shopee_price,
            laz_price: data.laz_price,
            selling_price: data.selling_price,
            item_sku: data.item_sku,
            aquisition: data.aquisition,
            active: data.active,
            last_edit: loginSession.username,
          }
        )
        .then(function (response) {
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function updateVariant(id, data, actions) {
    axios
      .post(`${config.host}:${config.port}/api/v2/directory/update_variant_directory`, {
        id: id,
        telco: data.telco,
        item_code: data.item_code,
        variant_name: data.variant_name,
        shop_name: data.shop_name,
        gross_price: data.gross_price,
        shopee_price: data.shopee_price,
        laz_price: data.laz_price,
        selling_price: data.selling_price,
        item_sku: data.item_sku,
        aquisition: data.aquisition,
        active: data.active,
        last_edit: loginSession.username,
      })
      .then(function (response) {
        actions();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function addTelco(telco, corporate_name) {
    axios
      .post(`${config.host}:${config.port}/api/v2/telco/add_telco`, {
        brand: telco,
        corporate_name: corporate_name,
      })
      .then(function (response) {
        setAdd(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function deleteVariant(id) {
    axios
      .post(`${config.host}:${config.port}/api/v2/delete_variant_directory`, {
        id: id,
      })
      .then(function (response) {
        setEdit(false);
        setSelectedVariant("");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getVariantList();
    getTelcoList();
  }, []);

  return {
    directory,
    telcoList,
    getVariantList,
    getVariant,
    seledtedVariant,
    setSelectedVariant,
    addVariant,
    updateVariant
  };
}

export default useDirectoryAPI;
