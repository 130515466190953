import React, { useEffect, useState } from "react";
import { Button, Card, Form, Grid, Icon } from "tabler-react";
import Pagination from "../../../components/Pagination";
import UserForm from "./UserForm";

const UserList = (props) => {
  const { data } = props;
  const {
    loginLogs,
    addUsers,
    users,
    selectedUserID,
    setSelectedUserID,
    editUser,
    setEditUser,
    departmentList,
    roleList,
    accessLevelList,
    updateUsers,
  } = data;

  const [editApps, setEditApps] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  const [newList, setNewList] = useState([]);
  const [filter1, setFilter1] = useState("");
  const [filter2, setFilter2] = useState("");

  useEffect(() => {
    setEditApps(editUser ? editUser.apps_list : []);
  }, [editUser]);

  useEffect(() => {
    setNewList(
      users
        .map((user) => ({
          ...user,
          status:
            loginLogs.filter((login) => login.username === user.username)
              ?.length != 0
              ? loginLogs.find((login) => login.username === user.username)
                  .status
              : 1,
        }))
        .filter((user) =>
          filter1 !== "" ? user.full_name.toLowerCase().includes(filter1) : user
        )
        .filter((user) => (filter2 !== "" ? user.status == filter2 : user))
    );
  }, [loginLogs, users, filter1, filter2]);

  const [openUserForm, setOpenUserForm] = useState(false);

  // console.log(newList);

  return (
    <>
      {openUserForm ? (
        <UserForm
          data={{
            addUsers,
            openUserForm,
            setOpenUserForm,
            departmentList,
            roleList,
            accessLevelList,
          }}
        />
      ) : (
        ""
      )}

      <Card>
        <Card.Header>
          GMS USER LIST
          <Card.Options>
            {openUserForm ? (
              ""
            ) : (
              <Button
                color="cyan"
                icon="plus"
                onClick={() => setOpenUserForm(true)}
              >
                ADD NEW USER
              </Button>
            )}
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={2}>
              <Form.Label>Status</Form.Label>
              <Form.Select
                placeholder="search full name ..."
                value={filter2}
                onChange={(e) => setFilter2(e.target.value)}
              >
                <option value="">Select All</option>
                <option value="0">Online</option>
                <option value="1">Offline</option>
              </Form.Select>
            </Grid.Col>

            <Grid.Col md={2}>
              <Form.Label>Full Name</Form.Label>
              <Form.Input
                placeholder="search full name ..."
                value={filter1}
                onChange={(e) => setFilter1(e.target.value)}
              />
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        {newList
          .sort(function (a, b) {
            return a.id - b.id;
          })
          .slice(activePage == 1 ? 0 : (activePage - 1) * 15, activePage * 15)
          .map((user, index) => (
            <React.Fragment key={index}>
              <Card.Body>
                <Grid.Row>
                  <Grid.Col md={1}>
                    <p
                      style={{
                        fontSize: "10px",
                        margin: 0,
                        fontWeight: "bold",
                        color: "#B8B8B8",
                      }}
                    >
                      No.
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                      }}
                    >
                      {index + 1}
                    </p>
                  </Grid.Col>
                  <Grid.Col md={1}>
                    <p
                      style={{
                        fontSize: "10px",
                        margin: 0,
                        fontWeight: "bold",
                        color: "#B8B8B8",
                      }}
                    >
                      STATUS
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        color: user.status == 0 ? "#17a2b8" : "#db211d",
                      }}
                    >
                      {user.status == 0 ? "ONLINE" : "OFFLINE"}
                    </p>
                  </Grid.Col>
                  <Grid.Col md={2}>
                    <p
                      style={{
                        fontSize: "10px",
                        margin: 0,
                        fontWeight: "bold",
                        color: "#B8B8B8",
                      }}
                    >
                      FULL NAME
                    </p>
                    {selectedUserID === user.id ? (
                      <Form.Input
                        value={editUser.full_name}
                        onChange={(e) =>
                          setEditUser({
                            ...editUser,
                            full_name: e.target.value,
                          })
                        }
                      />
                    ) : (
                      user.full_name
                    )}
                  </Grid.Col>
                  <Grid.Col md={2}>
                    <p
                      style={{
                        fontSize: "10px",
                        margin: 0,
                        fontWeight: "bold",
                        color: "#B8B8B8",
                      }}
                    >
                      DEPARTMENT
                    </p>
                    {selectedUserID === user.id ? (
                      <Form.Select
                        value={editUser.department}
                        onChange={(e) =>
                          setEditUser({
                            ...editUser,
                            department: e.target.value,
                          })
                        }
                      >
                        <option value="">SELECT ONE</option>
                        {departmentList.map((dept, index) => (
                          <option value={dept.value}>{dept.value}</option>
                        ))}
                      </Form.Select>
                    ) : (
                      user.department
                    )}
                  </Grid.Col>
                  <Grid.Col md={2}>
                    <p
                      style={{
                        fontSize: "10px",
                        margin: 0,
                        fontWeight: "bold",
                        color: "#B8B8B8",
                      }}
                    >
                      ROLE / POSITION
                    </p>
                    {selectedUserID === user.id ? (
                      <Form.Select
                        value={editUser.role}
                        onChange={(e) =>
                          setEditUser({ ...editUser, role: e.target.value })
                        }
                      >
                        <option value="">SELECT ONE</option>
                        {roleList.map((role, index) => (
                          <option value={role.value}>{role.value}</option>
                        ))}
                      </Form.Select>
                    ) : (
                      user.role
                    )}
                  </Grid.Col>
                  <Grid.Col md={2}>
                    <p
                      style={{
                        fontSize: "10px",
                        margin: 0,
                        fontWeight: "bold",
                        color: "#B8B8B8",
                      }}
                    >
                      GMS ACCESS LEVEL
                    </p>
                    {selectedUserID === user.id ? (
                      <Form.Select
                        value={editUser.access_level}
                        onChange={(e) =>
                          setEditUser({
                            ...editUser,
                            access_level: e.target.value,
                          })
                        }
                      >
                        <option value="">SELECT ONE</option>
                        {accessLevelList.map((access, index) => (
                          <option value={access.value}>{access.value}</option>
                        ))}
                      </Form.Select>
                    ) : (
                      user.access_level
                    )}
                  </Grid.Col>
                  <Grid.Col md={2}>
                    {selectedUserID === user.id ? (
                      <>
                        <Button
                          icon="save"
                          color="cyan"
                          onClick={() =>
                            updateUsers(editUser, setSelectedUserID)
                          }
                        >
                          SAVE
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => setSelectedUserID("")}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        icon="edit"
                        color="cyan"
                        onClick={() =>
                          setSelectedUserID(
                            selectedUserID === user.id ? "" : user.id
                          )
                        }
                      >
                        Edit
                      </Button>
                    )}
                  </Grid.Col>
                </Grid.Row>

                {selectedUserID === user.id && editApps ? (
                  <>
                    <br />
                    <Grid.Row>
                      <Card>
                        <Card.Header>GMS APPS ACCESS</Card.Header>
                        <Card.Body>
                          <Grid.Row>
                            {editApps.map((a, index) => (
                              <Grid.Col md={2} key={index}>
                                <Card>
                                  <Card.Body>
                                    <Form.Group>
                                      <Form.Label>
                                        {a.app.replace(/-/g, " ").toUpperCase()}
                                      </Form.Label>
                                      <Form.Select
                                        value={a.access}
                                        onChange={(e) =>
                                          setEditUser({
                                            ...editUser,
                                            apps_list: editApps.map((edit) =>
                                              edit.id == a.id
                                                ? {
                                                    ...edit,
                                                    access: e.target.value,
                                                  }
                                                : edit
                                            ),
                                          })
                                        }
                                      >
                                        <option value="">SELECT ONE</option>
                                        <option value="none">
                                          None / No Access
                                        </option>
                                        <option value="admin">Admin</option>
                                        <option value="edit">Edit</option>
                                        <option value="view">View Only</option>
                                        <option value="report">
                                          Report Only
                                        </option>
                                      </Form.Select>
                                      <Form.Label>Sub Level Access</Form.Label>
                                      <Form.Input
                                        value={a.subApps ? a.subApps : ""}
                                        placeholder="comma separated"
                                        onChange={(e) =>
                                          setEditUser({
                                            ...editUser,
                                            apps_list: editApps.map((edit) =>
                                              edit.id == a.id
                                                ? {
                                                    ...edit,
                                                    subApps: e.target.value,
                                                  }
                                                : edit
                                            ),
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </Card.Body>
                                </Card>
                              </Grid.Col>
                            ))}
                          </Grid.Row>
                        </Card.Body>
                      </Card>
                    </Grid.Row>
                  </>
                ) : (
                  ""
                )}
              </Card.Body>
            </React.Fragment>
          ))}
        {newList.length == 0 ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "40px",
              color: "#b9b9b9",
            }}
          >
            <Icon prefix="fa" name="folder-open" />
            <p
              style={{
                fontSize: "20px",
                margin: 0,
                fontWeight: "bold",

                textTransform: "uppercase",
              }}
            >
              NO DATA AVAILABLE
            </p>
          </div>
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={15}
            // changePage={changePage}
            totalItemsCount={newList.length}
            pageRangeDisplayed={pageRangeDisplayed}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default UserList;
