import React, { useEffect, useState } from "react";
import { Button, Card, Table, Icon } from "tabler-react";
import dateFormat from "dateformat";

import useOtherAccountsCalls from "../../../hooks/api/AMS_sales/useOtherAccountsCalls";
import useCustomFunctions from "../../../hooks/services/useCustomFunctions";
import Pagination from "../../../components/Pagination";
import CallFilter from "../components/CallFilter";

const Calls = () => {
  const { callLogs } = useOtherAccountsCalls();
  const { getInBetweenDates } = useCustomFunctions();

  const [activePage, setActivePage] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  //filters
  const [filtered, setFiltered] = useState([]); // new filtered data
  const [categoryList, setCategoryList] = useState([]);
  const [filter1, setFilter1] = useState(""); // client_id
  const [filter2, setFilter2] = useState(""); // STATUS
  const [filter3, setFilter3] = useState(""); // CLIENT NAME
  const [filter4a, setFilter4a] = useState(new Date()); // Start Date
  const [filter4b, setFilter4b] = useState(new Date()); // End Date
  const [filter5, setFilter5] = useState(""); // Reciever
  const [filter6, setFilter6] = useState(""); // Accounts
  const [filter7, setFilter7] = useState(""); // Accounts
  const [filter8, setFilter8] = useState(""); // Accounts

  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    setSelectedDates(
      getInBetweenDates(new Date(filter4a), new Date(filter4b), "")
    );
  }, [filter4a, filter4b]);

  console.log(callLogs);
  

  useEffect(() => {
    setFiltered(
      callLogs
        // .filter((call) => call.active == 0)
        .filter((call) =>
          filter1 !== "" ? call.client_id.includes(filter1) : call
        )
        .filter((call) =>
          filter2 !== "" ? call.call_status.includes(filter2) : call
        )
        .filter((call) =>
          filter3 !== ""
            ? call.account_holder.toLowerCase().includes(filter3.toLowerCase())
            : call
        )
        .filter((call) =>
          filter4a && filter4b
            ? selectedDates.includes(
                dateFormat(call.call_date, "paddedShortDate")
              )
            : call
        )
        .filter((call) =>
          filter5 !== "" ? call.received_by.includes(filter5) : call
        )
        .filter((call) =>
          filter6 !== "" ? call.particulars.includes(filter6) : call
        )
        .filter((call) =>
          filter7 !== "" ? call.remarks.includes(filter7) : call
        )
        .filter((call) =>
          filter8 !== ""
            ? call.nature_of_business
                .toLowerCase()
                .includes(filter8.toLowerCase())
            : call
        )
    );
  }, [
    callLogs,
    selectedDates,
    filter1,
    filter2,
    filter3,
    filter4a,
    filter4b,
    filter5,
    filter6,
    filter7,
    filter8,
  ]);

  useEffect(() => {
    setCategoryList(
      filtered
        .map((list) => list.nature_of_business)
        .filter(
          (v, i) =>
            filtered.map((list) => list.nature_of_business).indexOf(v) == i
        )
        .filter((list) => list !== "")
    );
  }, [filtered]);

  return (
    <>
      <Card>
        <CallFilter
          data={{
            filtered,
            filter1,
            setFilter1,
            filter2,
            setFilter2,
            filter3,
            setFilter3,
            filter4a,
            setFilter4a,
            filter4b,
            setFilter4b,
            filter5,
            setFilter5,
            filter6,
            setFilter6,
            filter7,
            setFilter7,
            categoryList,
            filter8,
            setFilter8,
          }}
        />
        {filtered.length == 0 ? (
          <Card.Body>
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          </Card.Body>
        ) : (
          <Card.Body>
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  {/* <Table.ColHeader>Client ID</Table.ColHeader> */}
                  <Table.ColHeader>Client Name</Table.ColHeader>
                  <Table.ColHeader>Call Date</Table.ColHeader>
                  <Table.ColHeader>Received By</Table.ColHeader>
                  <Table.ColHeader>Notes</Table.ColHeader>
                  <Table.ColHeader>Remarks</Table.ColHeader>
                  <Table.ColHeader>Call Status</Table.ColHeader>
                  <Table.ColHeader>Actions</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filtered
                  .slice(
                    activePage == 1 ? 0 : (activePage - 1) * pageLimit,
                    activePage * pageLimit
                  )
                  .map((call, index) => (
                    <Table.Row>
                      {/* <Table.Col>{call.client_id.replace("*", "")}</Table.Col> */}
                      <Table.Col>{call.account_holder}</Table.Col>
                      <Table.Col>
                        {dateFormat(call.call_date, "mmm d, yyyy h:MM TT")}
                      </Table.Col>
                      <Table.Col>{call.received_by}</Table.Col>
                      <Table.Col>{call.notes.slice(0, 10)}...</Table.Col>
                      <Table.Col>{call.remarks}</Table.Col>
                      <Table.Col>{call.call_status}</Table.Col>
                      <Table.Col>
                        <a href={`/AMS_sales/Account?id=${call.account_id}`}>
                          <Button color="cyan" icon="home">
                            Visit
                          </Button>
                        </a>
                      </Table.Col>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Card.Body>
        )}

        {filtered.length <= pageLimit ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={pageLimit}
            totalItemsCount={filtered.length}
            pageRangeDisplayed={pageRangeDisplayed}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default Calls;
