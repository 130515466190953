import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MyBoards from "./MyBoards";

const Index = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/GPM/MyBoards" component={MyBoards} />
        </Switch>
      </Router>
    </>
  );
};

export default Index;
