import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExport711Stores() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadStores(stores) {
    console.log(stores);
    var headers = {
      store_number: "store_number",
      store_name: "store_name",
      store_manager: "store_manager",
      schedule: "schedule",
      location: "location",
      status: "status",
      area_manager: "area_manager",
      full_address: "full_address",
      contact_number: "contact_number",
      email_address: "email_address",
      telemarketer: "telemarketer",
      account_executive: "account_executive",
      courier_status: "courier_status",
      area: "area",
      district: "district",
      cluster: "cluster",
      zone: "zone",
      monthly_target: "monthly_target",
      date_open: "date_open",
      network_preference: "network_preference",
      active: "active",
    };

    var itemsNotFormatted = stores.map((store) => ({
      ...store,
      networkPreference:
        store.networkPreference !== "" || store.networkPreference.length !== 0
          ? JSON.parse(store.networkPreference).join("|")
          : "",
    }));

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      console.log(item);
      itemsFormatted.push({
        store_number: item.store_number.replace("*", ""),
        store_name: item.store_name.replace(/(\r\n|\n|\r|,)/gm, ""),
        store_manager: item.store_manager.replace(/(\r\n|\n|\r|,)/gm, ""),
        schedule: item.schedule,
        location: item.location.replace(/(\r\n|\n|\r|,)/gm, ""),
        status: item.status,
        area_manager: item.area_manager.replace(/(\r\n|\n|\r|,)/gm, ""),
        full_address: item.full_address.replace(/(\r\n|\n|\r|,)/gm, ""),
        contact_number: item.contact_number.replace(/(\r\n|\n|\r|,)/gm, ""),
        email_address: item.email_address
          ? item.email_address
          : `st${item.store_number.replace("*", "")}@store.philseven.com`,
        telemarketer: item.telemarketer,
        account_executive: item.account_executive,
        courier_status: item.courier_status,
        area: item.area,
        district: item.district,
        cluster: item.cluster,
        zone: item.zone,
        monthly_target: item.monthly_target,
        date_open: dateFormat(new Date(item.date_open), "paddedShortDate"),
        network_preference: item.networkPreference,
        active: item.active,
      });
    });

    var fileTitle = `711 Stores_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return { downloadStores };
}

export default useExport711Stores;
