import React, { useEffect, useState } from "react";
import { Button, Card, Table, Icon, Loader, Grid, Form } from "tabler-react";
import dateFormat from "dateformat";
import Tooltip from "react-simple-tooltip";
import Pagination from "../../../../../components/Pagination";

//Hooks
import useCustomFunctions from "../../../../../hooks/services/useCustomFunctions";
import useExportCallLogs from "../../../../../hooks/api/AMS/extractions/useExportCallLogs";

const CallHistory = (props) => {
  const { data } = props;
  const {
    user,
    store,
    calls,
    accessLevel,
    setReminderSuccess,
    setReminderRemove,
    setSelectedCallLog,
    setViewCall,
    setEditCall,
    setAddCall,
    reminder,
    addReminder,
    removeReminder,
    getReminder,
    storeID,
    deleteCallLogs,
    getStoreCallLogs,
  } = data;

  console.log(calls);
  const { formatNumberComma } = useCustomFunctions();
  const { downloadCallLogs } = useExportCallLogs();

  const [activePage, setActivePage] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);

  const [deleteBTN, setDeleteBTN] = useState(false);
  const [loading, setLoading] = useState(true);

  //filters
  const [filtered, setFiltered] = useState([]);
  const [fStatus, setFStatus] = useState("");

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [calls]);

  useEffect(() => {
    // console.log(calls);
    setFiltered(
      calls.filter((call) =>
        fStatus !== "" ? call.call_status === fStatus : call
      )
    );
  }, [calls, fStatus]);

  const [currentSKU, setCurrentSKU] = useState([]);
  const [openSKU, setOpenSKU] = useState(false);

  const [skuFilter, setSKUfilter] = useState("");

  useEffect(() => {
    setCurrentSKU(
      calls.filter(
        (call) => call.fastSKU || call.slowSKU || call.notCarryingSKU
      )
        ? calls
            .filter(
              (call) => call.fastSKU || call.slowSKU || call.notCarryingSKU
            )
            .map((call) => ({
              ...call,
              fastSKU: call.fastSKU
                ? call.fastSKU.split("|").map((product) => ({
                    brand: product.split("_")[0],
                    sku: product.split("_")[1],
                    value: product,
                  }))
                : [],
              slowSKU: call.slowSKU
                ? call.slowSKU.split("|").map((product) => ({
                    brand: product.split("_")[0],
                    sku: product.split("_")[1],
                    value: product,
                  }))
                : [],
              notCarryingSKU: call.notCarryingSKU
                ? call.notCarryingSKU.split("|").map((product) => ({
                    brand: product.split("_")[0],
                    sku: product.split("_")[1],
                    value: product,
                  }))
                : [],
            }))[
            calls.filter(
              (call) => call.fastSKU || call.slowSKU || call.notCarryingSKU
            ).length - 1
          ]
        : []
    );
  }, [calls]);

  console.log(currentSKU);

  return (
    <>
      {openSKU && currentSKU ? (
        <div className="modal-achievement">
          <Card className="modal-achievement-content-container">
            <Card.Header>
              <p>{dateFormat(currentSKU.timestamp, "fullDate")}</p>
              <Card.Options>
                <Button color="secondary" onClick={() => setOpenSKU(false)}>
                  Close
                </Button>
              </Card.Options>
            </Card.Header>
            <Card.Body>
              <Form.Select
                value={skuFilter}
                onChange={(e) => setSKUfilter(e.target.value)}
              >
                <option value="">SELECT ALL</option>

                {currentSKU.fastSKU
                  .concat(currentSKU.slowSKU)
                  .concat(currentSKU.notCarryingSKU)
                  .map((t) => t.brand)
                  .filter(
                    (v, i) =>
                      currentSKU.fastSKU
                        .concat(currentSKU.slowSKU)
                        .concat(currentSKU.notCarryingSKU)
                        .map((t) => t.brand)
                        .indexOf(v) == i
                  )
                  .map((brand) => (
                    <option value={brand}>{brand}</option>
                  ))}
              </Form.Select>
            </Card.Body>
            <Card.Body>
              {currentSKU.fastSKU.filter((product) =>
                skuFilter ? skuFilter === product.brand : product
              ).length !== 0 ? (
                <>
                  <h5>Fast Moving SKU's</h5>
                  {currentSKU.fastSKU
                    .filter((product) =>
                      skuFilter ? skuFilter === product.brand : product
                    )
                    .map((product) => (
                      <Form.Checkbox
                        label={product.sku}
                        value={product.value}
                        checked
                      />
                    ))}
                </>
              ) : (
                ""
              )}

              <>
                {currentSKU.slowSKU.filter((product) =>
                  skuFilter ? skuFilter === product.brand : product
                ).length !== 0 ? (
                  <>
                    <hr style={{ padding: 5, margin: 0, marginTop: 10 }} />
                    <h5>Slow Moving SKU's</h5>
                    {currentSKU.slowSKU
                      .filter((product) =>
                        skuFilter ? skuFilter === product.brand : product
                      )
                      .map((product) => (
                        <Form.Checkbox
                          label={product.sku}
                          value={product.value}
                          checked
                        />
                      ))}
                  </>
                ) : (
                  ""
                )}
              </>
              <>
                {currentSKU.notCarryingSKU.filter((product) =>
                  skuFilter ? skuFilter === product.brand : product
                ).length !== 0 ? (
                  <>
                    <hr style={{ padding: 5, margin: 0, marginTop: 10 }} />
                    <h5>Not Carrying SKU's</h5>
                    {currentSKU.notCarryingSKU
                      .filter((product) =>
                        skuFilter ? skuFilter === product.brand : product
                      )
                      .map((product) => (
                        <Form.Checkbox
                          label={product.sku}
                          value={product.value}
                          checked
                        />
                      ))}
                  </>
                ) : (
                  ""
                )}
              </>
            </Card.Body>
          </Card>
        </div>
      ) : (
        ""
      )}

      <Card>
        <Card.Header>
          <Card.Options>
            <Button
              onClick={() => setAddCall(true)}
              icon="plus"
              color="cyan"
              disabled={accessLevel === "view" || accessLevel === "report"}
            >
              Add New Call Logs
            </Button>
            {reminder.length != 0 ? (
              <Button
                onClick={() =>
                  removeReminder(
                    reminder.id,
                    () =>
                      setReminderRemove(true) ||
                      getReminder(storeID) ||
                      setTimeout(() => {
                        setReminderRemove(false);
                      }, 2000)
                  )
                }
                icon="bell"
                color="danger"
                disabled={accessLevel === "view" || accessLevel === "report"}
              >
                REMOVE REMINER
              </Button>
            ) : (
              <Button
                onClick={() =>
                  addReminder(
                    user.username,
                    store.store_number,
                    () =>
                      setReminderSuccess(true) ||
                      getReminder(storeID) ||
                      setTimeout(() => {
                        setReminderSuccess(false);
                      }, 2000)
                  )
                }
                icon="bell"
                color="teal"
                disabled={accessLevel === "view" || accessLevel === "report"}
              >
                REMIND ME!
              </Button>
            )}
            {currentSKU ? (
              <Button color="warning" onClick={() => setOpenSKU(true)}>
                View Updated SKU's
              </Button>
            ) : (
              ""
            )}
            {accessLevel !== "view" ? (
              <Button
                icon="download"
                color="azure"
                onClick={() => downloadCallLogs(filtered)}
              >
                Export
              </Button>
            ) : (
              ""
            )}
            {accessLevel === "admin" ||
            calls.filter((call) => call.call_status === "Pending").length !=
              0 ? (
              <Button
                onClick={() => setDeleteBTN(!deleteBTN)}
                color="secondary"
                disabled={accessLevel === "view" || accessLevel === "report"}
              >
                {deleteBTN ? "Hide " : "Show "}Delete Button
              </Button>
            ) : (
              ""
            )}
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={2}>
              <p
                style={{
                  fontSize: "12px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#b9b9b9",
                  textTransform: "uppercase",
                }}
              >
                Status
              </p>
              <Form.Select
                value={fStatus}
                onChange={(e) => setFStatus(e.target.value)}
              >
                <option value="">ALL</option>
                <option value="Completed">Completed</option>
                <option value="Pending">Pending</option>
              </Form.Select>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        {!loading ? (
          <Card.Body>
            {calls.length !== 0 ? (
              <Table
                responsive
                highlightRowOnHover
                hasOutline
                verticalAlign="center"
                cards
                className="text-nowrap"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Order Date</Table.ColHeader>
                    <Table.ColHeader>Received By</Table.ColHeader>
                    <Table.ColHeader>Amount</Table.ColHeader>
                    <Table.ColHeader>Remarks</Table.ColHeader>
                    <Table.ColHeader>Allocation</Table.ColHeader>
                    <Table.ColHeader>Notes</Table.ColHeader>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader>Actions</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {filtered
                    .sort(function (a, b) {
                      return b.id - a.id;
                    })
                    .slice(
                      activePage == 1 ? 0 : (activePage - 1) * pageLimit,
                      activePage * pageLimit
                    )
                    .map((call, index) => (
                      <Table.Row>
                        <Table.Col>
                          {dateFormat(
                            new Date(call.date_order),
                            "mmmm d, yyyy ( h:MM TT )"
                          )}
                        </Table.Col>
                        <Table.Col>{call.received_by}</Table.Col>
                        <Table.Col>{formatNumberComma(call.amount)}</Table.Col>
                        <Table.Col>{call.remarks}</Table.Col>
                        <Table.Col>{call.allocation}</Table.Col>
                        <Table.Col>{`${call.notes.slice(0, 15)}...`}</Table.Col>
                        <Table.Col>
                          <p
                            style={{
                              color:
                                call.call_status === "Completed"
                                  ? "#17a2b8"
                                  : "#2bcbba",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                              margin: 0,
                            }}
                            className="call_status_btn"
                          >
                            {call.call_status}
                          </p>
                        </Table.Col>
                        <Table.Col>
                          <Tooltip
                            content="View Call Log"
                            radius={5}
                            padding={5}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <Button
                              color="teal"
                              icon="eye"
                              onClick={() =>
                                setSelectedCallLog(call.id) ||
                                setEditCall(false) ||
                                setViewCall(true)
                              }
                            ></Button>
                          </Tooltip>
                          {accessLevel === "admin" ||
                          call.call_status === "Pending" ? (
                            <>
                              <Tooltip
                                content="Edit Call Log"
                                radius={5}
                                padding={5}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <Button
                                  color="azure"
                                  icon="edit"
                                  onClick={() =>
                                    setSelectedCallLog(call.id) ||
                                    setViewCall(false) ||
                                    setEditCall(true)
                                  }
                                  disabled={
                                    accessLevel === "view" ||
                                    accessLevel === "report"
                                  }
                                ></Button>
                              </Tooltip>
                            </>
                          ) : (
                            ""
                          )}
                          {(accessLevel === "admin" && deleteBTN) ||
                          (call.call_status === "Pending" && deleteBTN) ? (
                            <>
                              <Tooltip
                                content="Delete Call Log"
                                radius={5}
                                padding={5}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <Button
                                  color="danger"
                                  icon="trash"
                                  onClick={() =>
                                    deleteCallLogs(call.id, () =>
                                      getStoreCallLogs(storeID)
                                    )
                                  }
                                ></Button>
                              </Tooltip>
                            </>
                          ) : (
                            ""
                          )}
                        </Table.Col>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            ) : (
              <Card.Body>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "40px",
                    color: "#b9b9b9",
                  }}
                >
                  <Icon prefix="fa" name="folder-open" />
                  <p
                    style={{
                      fontSize: "20px",
                      margin: 0,
                      fontWeight: "bold",

                      textTransform: "uppercase",
                    }}
                  >
                    NO DATA AVAILABLE
                  </p>
                </div>
              </Card.Body>
            )}
          </Card.Body>
        ) : (
          <div style={{ padding: "5px" }}>
            <Loader className="loader" />
          </div>
        )}

        {filtered.length == 0 || loading || store.length == 0 ? (
          ""
        ) : (
          <Card.Body>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={pageLimit}
              // changePage={changePage}
              totalItemsCount={filtered.length}
              pageRangeDisplayed={pageRangeDisplayed}
              setActivePage={setActivePage}
              setPageRangeDisplayed={setPageRangeDisplayed}
            />
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default CallHistory;
