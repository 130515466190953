import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Loader,
  Avatar,
  Grid,
  Text,
  Icon,
} from "tabler-react";
import Gold from "../../../../../assets/icon/gold.png";
import Silver from "../../../../../assets/icon/silver.png";
import Bronze from "../../../../../assets/icon/bronze.png";

const DashboardOrderList = (props) => {
  const { data } = props;
  const {
    accessLevel,
    loading,
    telemarketers,
    rangeSelection,
    formatNumberComma,
    exportOrderContribution,
  } = data;

  // let colorBadge = ["yellow", "grey", "orange", "red"];
  let badges = [Gold, Silver, Bronze];

  return (
    <>
      <Card>
        <Card.Status color="cyan" side />
        <Card.Header>
          <p>{rangeSelection} - Order Contribution Completed for 711</p>

          <Card.Options>
            {loading ? (
              <div style={{ padding: "5px" }}>
                <Loader className="loader" />
              </div>
            ) : (
              <>
                {/* <FormTextInput
                  label="Search"
                  placeholder="Search . . ."
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                /> */}
              </>
            )}
          </Card.Options>
          <Card.Options>
            {loading ? (
              <div style={{ padding: "5px" }}>
                <Loader className="loader" />
              </div>
            ) : (
              <>
                <Button
                  icon="download"
                  color="azure"
                  onClick={() =>
                    exportOrderContribution(
                      rangeSelection,
                      telemarketers.filter(
                        (tele) =>
                          tele.order_amount != 0 ||
                          tele.order_amount_by_assigned_store != 0
                      )
                    )
                  }
                  disabled={accessLevel === "view"}
                >
                  Export {rangeSelection} Report
                </Button>
              </>
            )}
          </Card.Options>
        </Card.Header>
        {telemarketers.filter(
          (tele) =>
            tele.order_amount != 0 || tele.order_amount_by_assigned_store != 0
        ).length == 0 ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "40px",
              color: "#b9b9b9",
            }}
          >
            <Icon prefix="fa" name="folder-open" />
            <p
              style={{
                fontSize: "20px",
                margin: 0,
                fontWeight: "bold",

                textTransform: "uppercase",
              }}
            >
              NO DATA AVAILABLE
            </p>
          </div>
        ) : (
          <>
            {loading ? (
              <div style={{ padding: "5px" }}>
                <Loader className="loader" />
              </div>
            ) : (
              <Card.Body>
                <Table
                  responsive
                  highlightRowOnHover
                  hasOutline
                  verticalAlign="center"
                  cards
                  className="text-nowrap"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>Telemarketer</Table.ColHeader>
                      <Table.ColHeader>
                        Ordered Received - Not Assigned
                      </Table.ColHeader>
                      <Table.ColHeader>Amount</Table.ColHeader>
                      <Table.ColHeader>
                        Ordered Received - Assigned
                      </Table.ColHeader>
                      <Table.ColHeader>Amount</Table.ColHeader>
                      <Table.ColHeader>Total Ordered Assigned</Table.ColHeader>
                      <Table.ColHeader>Amount</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {telemarketers
                      .filter(
                        (tele) =>
                          tele.order_amount != 0 ||
                          tele.order_amount_by_assigned_store != 0
                      )
                      .sort(
                        (a, b) =>
                          b.order_amount_by_assigned_store -
                          a.order_amount_by_assigned_store
                      )
                      .map((tele, index) => (
                        <Table.Row key={index}>
                          <Table.Col>
                            <Grid.Row>
                              <Grid.Col md={3}>
                                <Avatar
                                  size="md"
                                  imageURL={
                                    index < 2 ? badges[index] : badges[2]
                                  }
                                >
                                  <span style={{ color: "white" }}>
                                    {index + 1}
                                  </span>
                                </Avatar>
                              </Grid.Col>
                              <Grid.Col md={9}>
                                <Text size="md">{tele.full_name}</Text>
                                <Text size="sm" muted>
                                  {tele.role}
                                </Text>
                              </Grid.Col>
                            </Grid.Row>
                          </Table.Col>
                          <Table.Col>
                            <p
                              style={{
                                color: "#17a2b8",
                                margin: 0,
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {tele.ordered_calls.length}
                            </p>
                          </Table.Col>
                          <Table.Col>
                            <p
                              style={{
                                color: "#17a2b8",
                                margin: 0,
                                fontWeight: "bold",
                              }}
                            >
                              Php {formatNumberComma(tele.order_amount)}
                            </p>
                          </Table.Col>
                          <Table.Col>
                            <p
                              style={{
                                color: "#2bcbba",
                                margin: 0,
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {tele.ordered_calls_assigned_only.length}
                            </p>
                          </Table.Col>
                          <Table.Col>
                            <p
                              style={{
                                color: "#2bcbba",
                                margin: 0,
                                fontWeight: "bold",
                              }}
                            >
                              Php{" "}
                              {formatNumberComma(
                                tele.order_amount_assigned_only
                              )}
                            </p>
                          </Table.Col>
                          <Table.Col>
                            <p
                              style={{
                                color: "#45aaf2",
                                margin: 0,
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {tele.ordered_calls_by_assigned_store.length}
                            </p>
                          </Table.Col>
                          <Table.Col>
                            <p
                              style={{
                                color: "#45aaf2",
                                margin: 0,
                                fontWeight: "bold",
                              }}
                            >
                              Php{" "}
                              {formatNumberComma(
                                tele.order_amount_by_assigned_store
                              )}
                            </p>
                          </Table.Col>
                        </Table.Row>
                      ))}
                    <Table.Row>
                      <Table.Col>
                        <p
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "800",
                          }}
                        >
                          SUBTOTAL
                        </p>
                      </Table.Col>
                      <Table.Col>
                        <p
                          style={{
                            color: "#17a2b8",
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "800",
                            textAlign: "center",
                          }}
                        >
                          {formatNumberComma(
                            telemarketers
                              .filter(
                                (tele) =>
                                  tele.order_amount != 0 ||
                                  tele.order_amount_by_assigned_store != 0
                              )
                              .reduce((a, b) => +a + +b.ordered_calls.length, 0)
                          )}
                        </p>
                      </Table.Col>
                      <Table.Col>
                        <p
                          style={{
                            color: "#17a2b8",
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "800",
                          }}
                        >
                          Php{" "}
                          {formatNumberComma(
                            parseFloat(
                              telemarketers
                                .filter(
                                  (tele) =>
                                    tele.order_amount != 0 ||
                                    tele.order_amount_by_assigned_store != 0
                                )
                                .reduce((a, b) => a + b.order_amount, 0)
                            ).toFixed(2)
                          )}
                        </p>
                      </Table.Col>
                      <Table.Col>
                        <p
                          style={{
                            color: "#2bcbba",
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "800",
                            textAlign: "center",
                          }}
                        >
                          {formatNumberComma(
                            telemarketers
                              .filter(
                                (tele) =>
                                  tele.order_amount != 0 ||
                                  tele.order_amount_by_assigned_store != 0
                              )
                              .reduce(
                                (a, b) =>
                                  +a + +b.ordered_calls_assigned_only.length,
                                0
                              )
                          )}
                        </p>
                      </Table.Col>
                      <Table.Col>
                        <p
                          style={{
                            color: "#2bcbba",
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "800",
                          }}
                        >
                          Php{" "}
                          {formatNumberComma(
                            parseFloat(
                              telemarketers
                                .filter(
                                  (tele) =>
                                    tele.order_amount != 0 ||
                                    tele.order_amount_by_assigned_store != 0
                                )
                                .reduce(
                                  (a, b) => +a + +b.order_amount_assigned_only,
                                  0
                                )
                            ).toFixed(2)
                          )}
                        </p>
                      </Table.Col>
                      <Table.Col>
                        <p
                          style={{
                            color: "#45aaf2",
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "800",
                            textAlign: "center",
                          }}
                        >
                          {formatNumberComma(
                            telemarketers
                              .filter(
                                (tele) =>
                                  tele.order_amount != 0 ||
                                  tele.order_amount_by_assigned_store != 0
                              )
                              .reduce(
                                (a, b) =>
                                  +a +
                                  +b.ordered_calls_by_assigned_store.length,
                                0
                              )
                          )}
                        </p>
                      </Table.Col>
                      <Table.Col>
                        <p
                          style={{
                            color: "#45aaf2",
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "800",
                          }}
                        >
                          Php{" "}
                          {formatNumberComma(
                            parseFloat(
                              telemarketers
                                .filter(
                                  (tele) =>
                                    tele.order_amount != 0 ||
                                    tele.order_amount_by_assigned_store != 0
                                )
                                .reduce(
                                  (a, b) =>
                                    +a + +b.order_amount_by_assigned_store,
                                  0
                                )
                            ).toFixed(2)
                          )}
                        </p>
                      </Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Body>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default DashboardOrderList;
