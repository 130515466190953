import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractPaymentRelease() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadPayments(order, month, year) {
    var headers = {
      order_id: "order_id",
      dr_number: "dr_number",
      SI_number: "SI_number",
      original_price: "original_price",
      coin_cashback: "coin_cashback",
      discount_rebate: "discount_rebate",
      seller_promotion: "seller_promotion",
      seller_voucher: "seller_voucher",
      commissionF_fee: "commission_fee",
      transaction_fee: "transaction_fee",
      paid_shipping_fee: "paid_shipping_fee",
      release_amount: "release_amount",
      year: "year",
      month: "month",
    };

    var itemsNotFormatted = order;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        order_id: item.order_id,
        dr_number: item.dr_number,
        SI_number: item.SI_number,
        original_price: item.original_price,
        coin_cashback: item.coin_cashback,
        discount_rebate: item.discount_rebate,
        seller_promotion: item.seller_promotion,
        seller_voucher: item.seller_voucher,
        commissionF_fee: item.commission_fee,
        transaction_fee: item.transaction_fee,
        paid_shipping_fee: item.paid_shipping_fee,
        release_amount: item.release_amount,
        year: year,
        month: month,
      });
    });

    var fileTitle = `Shopee_Payments_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return { downloadPayments };
}

export default useExtractPaymentRelease;
