import { useState, useEffect } from "react";
import config from "../../../config";
import axios from "axios";
import dateFormat from "dateformat";

//hooks
import useUsers from "../Users/useUsers";

function useShopeePending() {
  const { loginSession } = useUsers();
  const [pendingCount, setPendingCount] = useState(0);
  const [pendingOrders, setPendingOrder] = useState([]);

  function getPendingOrders() {
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/tiktok_gomo/get_pending_orders`)
        .then(function (response) {
          // handle success
          let data = response.data;
          setPendingCount(data.length);
          setPendingOrder(data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function addPendingOrders(orders, actions) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/tiktok_gomo/insert_pending_shopee_orders`,
          {
            orders,
            temp_dr_number: `*${dateFormat(new Date(), "mmddyyyy")}`,
            dr_month: dateFormat(new Date(), "mmmm"),
            dr_year: dateFormat(new Date(), "yyyy"),
            user: loginSession.username,
          }
        )
        .then(function (response) {
          // handle success
          actions();
          getPendingOrders()
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function updatePendingOrders(dr_number, temp_dr_number, actions) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/tiktok_gomo/update_pending_orders`,
          {
            dr_number: `*${dr_number}`,
            temp_dr_number: temp_dr_number,
            user: loginSession.username,
          }
        )
        .then(function (response) {
          // handle success
          actions();
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function deletePendingOrder(id, temp_dr_number, actions) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/tiktok_gomo/delete_pending_order`,
          {
            id: id,
            temp_dr_number: temp_dr_number,
            user: loginSession.username,
          }
        )
        .then(function (response) {
          // handle success
          actions();
          getPendingOrders()
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  useEffect(() => {
    getPendingOrders();
  }, []);

  return { pendingCount, pendingOrders, addPendingOrders, updatePendingOrders, deletePendingOrder };
}

export default useShopeePending;
