import { useState, useEffect } from "react";
import config from "./../../../config";
import axios from "axios";
import useUsers from "../Users/useUsers";

function useReminders() {
  const { loginSession } = useUsers();

  const [reminders, setReminders] = useState([]);
  const [reminder, setReminder] = useState([]);

  function addReminder(reminder, store_number, actions) {
    axios
      .post(`${config.host}:${config.port}/api/v2/711/add_reminders_logs`, {
        store_number: store_number.replace("*", ""),
        reminder: reminder,
      })
      .then(function (response) {
        // console.log(response);
        actions();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getReminders() {
    if (loginSession) {
      axios
        .get(`${config.host}:${config.port}/api/v2/711/reminders_logs`)
        .then(function (response) {
          let data = response.data;
          setReminders(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getReminder(store_number) {
    if (loginSession) {
      axios
        .post(`${config.host}:${config.port}/api/v2/711/store_reminders_logs`, {
          store_number: store_number,
        })
        .then(function (response) {
          let data = response.data;
          setReminder(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function removeReminder(id, actions) {
    axios
      .post(`${config.host}:${config.port}/api/v2/711/delete_reminders_logs`, {
        id: id,
      })
      .then(function (response) {
        // console.log(response);
        actions();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getReminders();
  }, []);

  return { reminders, reminder, addReminder, getReminder, removeReminder };
}

export default useReminders;
