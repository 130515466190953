import React from "react";
import { Button, Card, Table, Icon, Grid, Form } from "tabler-react";

const Filter = (props) => {
  const {
    filter1,
    setFilter1,
    filter2,
    setFilter2,
    filter3,
    setFilter3,
    categoryList,
    filter4,
    setFilter4,
  } = props.data;
  return (
    <>
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={2}>
            <Form.Group>
              <Form.Label>Client ID</Form.Label>
              <Form.Input
                value={filter1}
                onChange={(e) => setFilter1(e.target.value)}
                placeholder="Search by Client ID"
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Group>
              <Form.Label>Client Name</Form.Label>
              <Form.Input
                value={filter3}
                onChange={(e) => setFilter3(e.target.value)}
                placeholder="Search by Client Name"
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Input
                value={filter4}
                onChange={(e) => setFilter4(e.target.value)}
                placeholder="Search by Category"
              />
              {filter4 !== "" && !categoryList.find((l) => l === filter4) ? (
                <div
                  className="dropdown-menu dropdown-menu-left show"
                  data-placement="bottom"
                  style={{
                    position: "absolute",
                    inset: "0px auto auto 0px",
                    transform: "translate(5px, 63px)",
                  }}
                >
                  {categoryList.filter((l) =>
                    l.toLowerCase().includes(filter4.toLowerCase())
                  ).map((l) => (
                    <Button
                      className="dropdown-item"
                      onClick={() =>
                        setFilter4(l)
                      }
                    >
                      {l}
                    </Button>
                  ))}
                </div>
              ) : (
                ""
              )}
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Select
                value={filter2}
                onChange={(e) => setFilter2(e.target.value)}
              >
                <option value="">SELECT ONE</option>
                <option value="0">Active</option>
                <option value="1">Inactive</option>
              </Form.Select>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </>
  );
};

export default Filter;
