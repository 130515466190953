import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid } from "tabler-react";
import useUsers from "./../../hooks/api/Users/useUsers";
import useQueryURL from "../../hooks/services/useQueryURL";

//COMPONENTS
import LoginForm from "./components/LoginForm";

//Images
import One from "./../../assets/images/landing_page/1.svg";
import Two from "./../../assets/images/landing_page/2.svg";
import Three from "./../../assets/images/landing_page/3.svg";
import Four from "./../../assets/images/landing_page/4.svg";
import Five from "./../../assets/images/landing_page/5.svg";
import Six from "./../../assets/images/landing_page/6.svg";
import Seven from "./../../assets/images/landing_page/7.svg";

const Index = () => {
  const { login, user, loginSession } = useUsers();
  const { getQuery } = useQueryURL();

  const [data, setData] = useState({
    username: "",
    password: "",
  });

  let ERROR_CODE = getQuery("error_code");

  useEffect(() => {
    if (loginSession.length != 0) {
      window.location.replace("/");
    }
  });

  const images = [One, Two, Three, Four, Five, Six, Seven];

  const [slides, setSlide] = useState(
    Math.floor(Math.random() * (6 - 0 + 1)) + 0
  );

  useEffect(() => {
    setInterval(() => {
      setSlide(Math.floor(Math.random() * (6 - 0 + 1)) + 0);
    }, 7000);
  }, []);

  return (
    <>
      <Helmet>
        <title>GMS Dashboard - Login</title>
      </Helmet>
      <div style={{ margin: 0, background: "#17a2b8", height: "100vh" }}>
        <Grid.Row>
          <Grid.Col md={6}>
            <div
              style={{ margin: "20px auto", width: "90%", paddingTop: "15%" }}
            >
              <img src={images[slides]} className="landing_page_image" style={{width: "100%"}} />
            </div>
          </Grid.Col>
          <Grid.Col md={6}>
            <LoginForm loginDetails={{ data, setData, login, ERROR_CODE }} />
          </Grid.Col>
        </Grid.Row>
      </div>
    </>
  );
};

export default Index;
