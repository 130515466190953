import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractReportLazada() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadOrders(order, fileName) {
    var headers = {
      order_date: "ORDER DATE",
      shipout_date: "SHIPOUT DATE",
      order_id: "ORDER ID",
      order_item_id: "ORDER ITEM ID",
      tracking_number: "TRACKING NUMBER",
      shipping_option: "SHIPPING OPTION",
      product_name: "	PRODUCT NAME",
      quantity: "QUANTITY",
      gate_price: "ORIGINAL PRICE",
      laz_price: "DEAL PRICE",
      buyer_paid_shipping_fee: "BUYER PAID SHIPPING FEE",
      variant_name: "GATE VARIANT NAME",
      gross_price: "GROSS",
      aquisition: "AQUISITION",
      item_code: "ITEM CODE",
      voucher_discount: "VOUCHER DISCOUNT"
    };

    var itemsNotFormatted = order;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        order_date: dateFormat(item.order_date, "paddedShortDate"),
        shipout_date: dateFormat(item.shipout_date, "paddedShortDate"),
        order_id: item.order_id,
        order_item_id: item.order_item_id,
        tracking_number: item.tracking_number,
        shipping_option: item.shipping_option,
        product_name: item.product_name.replace(/,/g, '-'),
        quantity: item.quantity,
        gate_price: item.gate_price,
        laz_price: item.laz_price,
        buyer_paid_shipping_fee: item.buyer_paid_shipping_fee,
        variant_name: item.variant_name,
        gross_price: item.gross_price,
        aquisition: item.aquisition,
        item_code: item.item_code,
        voucher_discount: item.voucher_discount
      });
    });

    // var fileTitle = fileName; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileName); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  function downloadSummary(summary, fileName) {
    var headers = {
      variant_name: "GATE VARIANT NAME",
      item_code: "ITEM CODE",
      quantoty: "QUANTITY"
    };

    var itemsNotFormatted = summary;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        variant_name: item.variant_name,
        item_code: item.item_code,
        quantity: item.quantity
      });
    });

    // var fileTitle = `${dateFormat(new Date())}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, `ORDER SUMMARY - ${fileName}`); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return {
    downloadOrders,
    downloadSummary
  };
}

export default useExtractReportLazada;
