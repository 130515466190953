import React, { useEffect, useState } from "react";
import { Button, Card, Form, Grid, Table, Icon } from "tabler-react";

//hooks
import useShopeePending from "../../../../hooks/api/ShopeeShop/useShopeePending";
import useUsers from "../../../../hooks/api/Users/useUsers";
import Orders from "../../../LazadaShop/pages/Orders/Orders";
import PrintDR from "./PrintDR";

const PendingDashboard = () => {
  const {
    pendingCount,
    pendingOrders,
    updatePendingOrders,
    deletePendingOrder,
  } = useShopeePending();
  const { user } = useUsers();

  const [drNumber, setDRNumber] = useState("");
  const [tempDrNumber, setTempDRNumber] = useState("");
  const [orderID, setOrderID] = useState("");

  const [viewPrintDR, setViewPrintDR] = useState(false);

  useEffect(() => {
    if (pendingCount != 0) {
      setTempDRNumber(pendingOrders.map((p) => p.dr_number)[0]);
    }
  }, [pendingOrders]);

  return (
    <>
      {viewPrintDR ? (
        <PrintDR
          data={pendingOrders.map((order) => ({
            ...order,
            assign_courier: order.courier.includes("Shopee")
              ? "shopee-express"
              : order.courier.includes("J&T Express")
              ? "jnt-express"
              : "shopee-express",
          }))}
          user={user}
          setViewPrintDR={setViewPrintDR}
        />
      ) : (
        ""
      )}

      <Card>
        <Card.Header>
          Pending Orders
          <Card.Options>
            <Button color="primary" onClick={() => setViewPrintDR(true)}>
              <Icon prefix="fa" name="print" />
              View & Print AR
            </Button>
          </Card.Options>
        </Card.Header>

        {pendingCount == 0 ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "40px",
              color: "#b9b9b9",
            }}
          >
            <Icon prefix="fa" name="folder-open" />
            <p
              style={{
                fontSize: "20px",
                margin: 0,
                fontWeight: "bold",

                textTransform: "uppercase",
              }}
            >
              NO DATA AVAILABLE
            </p>
          </div>
        ) : (
          <>
            <Card.Body>
              <Grid.Row>
                <Grid.Col md={2}>
                  <Form.Input
                    value={drNumber}
                    onChange={(e) => setDRNumber(e.target.value)}
                    placeholder="DR Number"
                    disabled={
                      !["IT Specialist", "Telemarketer OA"].includes(user.role)
                    }
                  />
                </Grid.Col>

                {drNumber !== "" ? (
                  <Grid.Col md={2}>
                    <Button
                      color="cyan"
                      onClick={() =>
                        updatePendingOrders(
                          drNumber,
                          tempDrNumber,
                          () =>
                            alert("Pending Order Updated Successfully!") ||
                            window.location.reload()
                        )
                      }
                    >
                      Approve DR
                    </Button>
                  </Grid.Col>
                ) : (
                  ""
                )}

                <Grid.Col md={2}>
                  <Form.Input
                    value={orderID}
                    onChange={(e) => setOrderID(e.target.value)}
                    placeholder="Order ID"
                  />
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
            <Card.Body>
              <Table
                responsive
                highlightRowOnHover
                hasOutline
                verticalAlign="center"
                cards
                className="text-nowrap"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>No.</Table.ColHeader>
                    <Table.ColHeader>Order Date</Table.ColHeader>
                    <Table.ColHeader>Shipout Date</Table.ColHeader>
                    <Table.ColHeader>Courier</Table.ColHeader>
                    <Table.ColHeader>Order ID</Table.ColHeader>
                    <Table.ColHeader>Tracking #</Table.ColHeader>
                    <Table.ColHeader>Product Name</Table.ColHeader>
                    <Table.ColHeader>Gate Price</Table.ColHeader>
                    <Table.ColHeader>Shopee Price</Table.ColHeader>
                    <Table.ColHeader>Quantity</Table.ColHeader>
                    <Table.ColHeader>Actions</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {pendingOrders
                    .filter((p) =>
                      orderID !== "" ? p.order_id.includes(orderID) : p
                    )
                    .map((p, index) => (
                      <Table.Row key={index}>
                        <Table.Col>{index + 1}</Table.Col>
                        <Table.Col>{p.order_date}</Table.Col>
                        <Table.Col>{p.shipout_date}</Table.Col>
                        <Table.Col>{p.courier}</Table.Col>
                        <Table.Col>{p.order_id}</Table.Col>
                        <Table.Col>{p.tracking_number}</Table.Col>
                        <Table.Col>{p.product_name}</Table.Col>
                        <Table.Col>{p.gate_price}</Table.Col>
                        <Table.Col>{p.shopee_price}</Table.Col>
                        <Table.Col>{p.quantity}</Table.Col>
                        <Table.Col>
                          <Button
                            color="danger"
                            icon="trash"
                            onClick={() =>
                              deletePendingOrder(p.id, p.dr_number, () =>
                                alert(
                                  `${p.order_id} has been deleted from database!`
                                )
                              )
                            }
                            disabled={
                              !["IT Specialist", "Telemarketer OA"].includes(
                                user.role
                              )
                            }
                          >
                            Remove
                          </Button>
                        </Table.Col>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </Card.Body>
          </>
        )}
      </Card>
    </>
  );
};

export default PendingDashboard;
