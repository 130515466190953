import React from "react";
import { Button, Card, Form, Grid } from "tabler-react";
import CSVReader from "react-csv-reader";
import DatePicker from "react-datepicker";
import useHRReportTool from "../../hooks/api/HR/useHRReportTool";

const Calendar = (props) => {
  const { saveReportLocal } = useHRReportTool();
  const {
    getInBetweenDates,
    report,
    generateReport,
    startDate,
    endDate,
    holidays,
    timeKeeping,
    setStartDate,
    setEndDate,
    setHolidays,
    setTimeKeeping,
    filter1,
    setFilter1,
  } = props.data;

  return (
    <>
      <Card>
        <Card.Body>
          <Button
            color="cyan"
            icon="calendar"
            onClick={() => window.location.replace("/HR")}
          >
            View Calendar
          </Button>
          {report.length != 0 ? (
            <Button
              color="cyan"
              icon="save"
              onClick={() => saveReportLocal(report)}
            >
              Save this generated report
            </Button>
          ) : (
            ""
          )}
        </Card.Body>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={2}>
              <Form.Label>Select Date</Form.Label>
              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                className="form-control"
                selectsRange
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Label>Upload the DAT file here</Form.Label>
              <CSVReader
                onFileLoaded={(data, fileInfo) =>
                  setTimeKeeping(data.slice(1, data.length - 1))
                }
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Label>Search by Employee ID</Form.Label>
              <Form.Input
                value={filter1}
                onChange={(e) => setFilter1(e.target.value)}
                placeholder="employee id (eg. G0173)"
              ></Form.Input>
            </Grid.Col>

            <Grid.Col md={3}></Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Calendar;
