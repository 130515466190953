import React, { useState, useEffect } from "react";
import { Button, Card, Table, Icon, Grid, Form } from "tabler-react";
import Pagination from "../../../components/Pagination";

import useOtherAccounts from "../../../hooks/api/AMS_sales/useOtherAccounts";
import useUsers from "../../../hooks/api/Users/useUsers";
import Filter from "../components/Filter";
import ProfileForm from "../components/ProfileForm";

const AccountsList = () => {
  const { otherAccounts, getOtherAccountByID, addAccount } = useOtherAccounts();
  const [activePage, setActivePage] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  //filters
  const [filtered, setFiltered] = useState([]); // new filtered data
  const [categoryList, setCategoryList] = useState([]);
  const [filter1, setFilter1] = useState(""); // client_id
  const [filter2, setFilter2] = useState(0); // STATUS
  const [filter3, setFilter3] = useState(""); // CLIENT NAME
  const [filter4, setFilter4] = useState(""); // category

  useEffect(() => {
    setFiltered(
      otherAccounts
        .filter((account) => account.client_id.includes(filter1))
        .filter((account) => account.active == filter2)
        .filter((account) =>
          account.account_holder.toLowerCase().includes(filter3.toLowerCase())
        ).filter((account) =>
        account.nature_of_business.toLowerCase().includes(filter4.toLowerCase())
      )
    );
  }, [otherAccounts, filter1, filter2, filter3, filter4]);

  useEffect(() => {
    setCategoryList(
      filtered
        .map((list) => list.nature_of_business)
        .filter(
          (v, i) =>
            filtered.map((list) => list.nature_of_business).indexOf(v) == i
        ).filter(list => list !=="")
    );
  }, [filtered]);

  console.log(categoryList);

  const { user } = useUsers();

  const [accessLevel, setAccessLevel] = useState("none");

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "ams-sales-accounts")
            .access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  const [openAddForm, setOpenAddForm] = useState(false);

  // console.log(filtered);

  return (
    <>
      {openAddForm ? (
        <ProfileForm
          data={{
            otherAccount: [],
            updateAccount: addAccount,
            getOtherAccountByID,
            accessLevel,
            formType: "new",
            openAddForm,
            setOpenAddForm,
          }}
        />
      ) : (
        ""
      )}

      <Card>
        <Card.Header>
          Other Accounts List{" "}
          {["admin", "edit"].includes(accessLevel) ? (
            <Card.Options>
              <Button
                color="cyan"
                icon="plus"
                onClick={() => setOpenAddForm(true)}
              >
                ADD NEW CLIENT
              </Button>
            </Card.Options>
          ) : (
            ""
          )}
        </Card.Header>
        <Filter
          data={{
            
            filter1,
            setFilter1,
            filter2,
            setFilter2,
            filter3,
            setFilter3,
            categoryList,
            filter4,
            setFilter4,
          }}
        />
        {filtered.length == 0 ? (
          <Card.Body>
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          </Card.Body>
        ) : (
          <Card.Body>
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Client ID</Table.ColHeader>
                  <Table.ColHeader>Client Name</Table.ColHeader>
                  <Table.ColHeader>Contact Person</Table.ColHeader>
                  <Table.ColHeader>Mobile Number</Table.ColHeader>
                  <Table.ColHeader>Acount</Table.ColHeader>
                  <Table.ColHeader>AE Name</Table.ColHeader>
                  <Table.ColHeader>Status</Table.ColHeader>
                  <Table.ColHeader>Actions</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filtered
                  .slice(
                    activePage == 1 ? 0 : (activePage - 1) * pageLimit,
                    activePage * pageLimit
                  )
                  .map((account, index) => (
                    <Table.Row key={index}>
                      <Table.Col>
                        {account.client_id?.replace("*", "")}
                      </Table.Col>
                      <Table.Col>{account.account_holder}</Table.Col>
                      <Table.Col>{account.contact_person}</Table.Col>
                      <Table.Col>{account.mobile_number}</Table.Col>
                      <Table.Col>{account.paticulars}</Table.Col>
                      <Table.Col>{account.ae_name}</Table.Col>
                      <Table.Col>
                        {account.active == 0 ? "Active" : "Inactive"}
                      </Table.Col>
                      <Table.Col>
                        <Button
                          color="cyan"
                          icon="home"
                          onClick={() =>
                            window.location.replace(
                              `/AMS_sales/Account?id=${account.id}`
                            )
                          }
                        >
                          View Profile
                        </Button>
                        {/* <Button color="teal" icon="edit"></Button> */}
                      </Table.Col>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Card.Body>
        )}
        {filtered.length <= pageLimit ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={pageLimit}
            totalItemsCount={filtered.length}
            pageRangeDisplayed={pageRangeDisplayed}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default AccountsList;
