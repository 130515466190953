import React from "react";
import TagList from "./TagList";

//hooks
import useTagList from "../../../../hooks/api/useTagList";
import { Card } from "tabler-react";
import { Helmet } from "react-helmet";

const Index = () => {
  const { tagList } = useTagList();

  return (
    <>
    <Helmet>
        <title>GMS - Shopee Shop | Help</title>
    </Helmet>
      <Card>
        <Card.Header>Status and Remarks Tagging</Card.Header>
        <Card.Body>
          <TagList
            data={{
              list: tagList.filter((tag) => tag.platforms.includes("shopee")),
            }}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Index;
