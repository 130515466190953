import React, { useEffect, useState } from "react";
import { Card, Loader, Table, Icon, Button } from "tabler-react";
import Pagination from "../../../../components/Pagination";
import Filter from "./Filter";

//hooks
import useLazadaAPI from "../../../../hooks/api/LazadaShop/useLazadaAPI";
import useQueryURL from "../../../../hooks/services/useQueryURL";
import useUsers from "../../../../hooks/api/Users/useUsers";

const PaymentRelease = () => {
  const { user} = useUsers()
  const { getQuery } = useQueryURL();

  const [month, setMonth] = useState(
    getQuery("month") ? getQuery("month") : "January"
  );

  const { paymentRelease } = useLazadaAPI(month, "");

  const [year, setYear] = useState(
    getQuery("year") ? getQuery("year") : "2021"
  );

  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");
  const [search4, setSearch4] = useState("");
  const [search5, setSearch5] = useState("");

  const [newPaymentRelease, setNewPaymentRelease] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (paymentRelease.length !== 0) {
      setTimeout(() => {
        setLoading(false);
        setNewPaymentRelease(
          paymentRelease
            .filter((o) =>
              search !== ""
                ? o.dr_number.replace("*", "").includes(search) ||
                  o.SI_number.replace("*", "").includes(search)
                : o
            )
            .filter((o) =>
              search2 !== ""
                ? o.order_number.replace("*", "").includes(search2) ||
                  o.order_item_number.replace("*", "").includes(search2)
                : o
            )
            .filter((o) => (search3 !== "" ? o.fee_name.includes(search3) : o))
        );
      }, 1000);
    } else {
      setTimeout(() => {
        setLoading(false);
        setNewPaymentRelease([]);
      }, 1000);
    }
  }, [paymentRelease, search, search2, search3]);

  return (
    <>
      <Card>
        <Card.Header>
          Payment Release
          {user &&
          user.apps_list &&
          user.apps_list.find(
            (app) =>
              app.app === "lazada-shop" &&
              ["edit", "admin"].includes(app.access)
          ) ? (
            <Card.Options>
              <a href="/LazadaShop/UploadPaymentRelease">
                <Button color="cyan">
                  <Icon prefix="fa" name="upload" /> Upload Payment Release
                </Button>
              </a>
            </Card.Options>
          ) : (
            ""
          )}
        </Card.Header>
        <Filter
          data={{
            paymentRelease,
            newPaymentRelease,
            src: "PaymentRelease",
            month,
            year,
            search,
            setSearch,
            search2,
            setSearch2,
            search3,
            setSearch3,
            search4,
            setSearch4,
            search5,
            setSearch5,
          }}
        />
        <Card.Body>
          {loading ? (
            <Loader />
          ) : (
            <>
              {newPaymentRelease.length != 0 ? (
                <Table
                  responsive
                  highlightRowOnHover
                  hasOutline
                  verticalAlign="center"
                  cards
                  className="text-nowrap"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>DR Number</Table.ColHeader>
                      <Table.ColHeader>SI/CI Number</Table.ColHeader>
                      <Table.ColHeader>Order No.</Table.ColHeader>
                      <Table.ColHeader>Order Item No.</Table.ColHeader>
                      <Table.ColHeader>Order Status</Table.ColHeader>
                      <Table.ColHeader>Fee Name</Table.ColHeader>
                      <Table.ColHeader>Details</Table.ColHeader>
                      <Table.ColHeader>Amount</Table.ColHeader>
                      <Table.ColHeader>Paid Status</Table.ColHeader>
                      <Table.ColHeader>Actions</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {newPaymentRelease
                      .sort(function (a, b) {
                        return b.id - a.id;
                      })
                      .slice(
                        activePage == 1 ? 0 : (activePage - 1) * 30,
                        activePage * 30
                      )
                      .map((order, index) => (
                        <Table.Row>
                          <Table.Col>
                            {order.dr_number.replace("*", "")}
                          </Table.Col>
                          <Table.Col>
                            {order.SI_number.replace("*", "")}
                          </Table.Col>
                          <Table.Col>
                            {order.order_number.replace("*", "")}
                          </Table.Col>
                          <Table.Col>
                            {order.order_item_number.replace("*", "")}
                          </Table.Col>
                          <Table.Col>{order.order_status}</Table.Col>
                          <Table.Col>{order.fee_name}</Table.Col>
                          <Table.Col>{order.product_name}</Table.Col>
                          <Table.Col>{order.amount}</Table.Col>
                          <Table.Col>
                            {order.paid_status.toUpperCase()}
                          </Table.Col>
                          <Table.Col>
                            <a
                              href={`/LazadaShop/Orders?dr_number=${order.dr_number.replace(
                                "*",
                                ""
                              )}&month=${month}&year=${year}&order_id=${order.order_number.replace(
                                "*",
                                ""
                              )}`}
                              target="_blank"
                            >
                              <Button color="cyan">View</Button>
                            </a>
                          </Table.Col>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              ) : (
                ""
              )}
            </>
          )}
          {newPaymentRelease.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          ) : (
            ""
          )}
        </Card.Body>
        {newPaymentRelease.length <= 30 ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={30}
            totalItemsCount={newPaymentRelease.length}
            pageRangeDisplayed={pageRangeDisplayed}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default PaymentRelease;
