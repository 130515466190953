import { useEffect, useState } from "react";
import dateFormat from "dateformat";
import useOtherAccounts from "./useOtherAccounts";
import useOtherAccountsCalls from "./useOtherAccountsCalls";
import useUsers from "../Users/useUsers";

function useOADashboard() {
  const { loginSession } = useUsers();
  const { telemarketerOA } = useOtherAccounts();
  const { allCallLogs, getOtherAccountsAllCalls } = useOtherAccountsCalls();

  const [dashboard, setDashboard] = useState([]);

  function getDashboard(allCallLogs, telemarketerOA) {
    if (loginSession.length != 0) {
      let data = telemarketerOA
        .map((tele) => ({
          full_name: tele.full_name,
          username: tele.username,
          pending_calls: allCallLogs.filter(
            (call) =>
              call.call_status === "Pending" &&
              call.received_by === tele.full_name
          ),
          initials: tele.full_name.match(/\b(\w)/g).slice(0, 2),
          role: tele.role,
        }))
        .map((tele) => ({
          ...tele,
          dates: tele.pending_calls
            .map((p) => p.call_date)
            .sort((a, b) => new Date(a) - new Date(b))
            .map((p) => dateFormat(p, "paddedShortDate")),
        }))
        .map((tele) => ({
          ...tele,
          start_date: tele.dates.length != 0 ? tele.dates[0] : new Date(),
          end_date:
            tele.dates.length > 1
              ? tele.dates[tele.dates.length - 1]
              : tele.dates[0],
        }));

      setDashboard(data);
    }
  }

  useEffect(() => {
    getOtherAccountsAllCalls();
  }, []);

  useEffect(() => {
    getDashboard(allCallLogs, telemarketerOA);
  }, [telemarketerOA, allCallLogs]);

  return { dashboard };
}

export default useOADashboard;
