import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Grid } from "tabler-react";
import { Helmet } from "react-helmet";
import Dashboard from "./pages/Dashboard/Dashboard";
import SIDashboard from "./pages/Dashboard/SIDashboard";
import RTSDashboard from "./pages/Dashboard/RTSDashboard"
import PendingDashboard from "./pages/Dashboard/PendingDashboard";
import Orders from "./pages/Orders/Orders";
import OrderItemList from "./pages/Orders/OrderItemList";
import PaymentRelease from "./pages/PaymentRelease/PaymentRelease";
import SummaryReport from "./pages/Reports/SummaryReport";
import Menu from "../TiktokGOMOShop/menu/MainMenuSide";
import Loading from "../../components/Loading/Loading";
import Help from "./pages/Help"
import UploadPaymentRealease from "./pages/PaymentRelease/UploadPaymentRealease";

//hooks
import useUsers from "../../hooks/api/Users/useUsers";

const Index = () => {
  const { user, loginSession } = useUsers();
  useEffect(() => {
    if (loginSession.length == 0) {
      window.location.replace("/");
    }
  }, []);

  const [accessLevel, setAccessLevel] = useState("none");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "lazada-shop").access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Helmet>
        <title>GMS - Tiktok Shop</title>
      </Helmet>
      <div style={{ margin: "5px" }}>
        {loading ? (
          <div style={{ paddingTop: "10%" }}>
            <Loading />
          </div>
        ) : (
          <Grid.Row>
            <Grid.Col md={1} sm={12}>
              <Menu user={user} />
            </Grid.Col>
            <Grid.Col md={11} sm={12}>
              <Router>
                <Switch>
                  <Route exact path="/" component={SummaryReport} />
                  <Route exact path="/TiktokGOMOShop/" component={SummaryReport} />
                  <Route
                    exact
                    path="/TiktokGOMOShop/Report/SummaryReport"
                    component={SummaryReport}
                  />
                  <Route
                    exact
                    path="/TiktokGOMOShop/Dashboard"
                    component={Dashboard}
                  />
                  <Route
                    exact
                    path="/TiktokGOMOShop/PendingDashboard"
                    component={PendingDashboard}
                  />
                  <Route
                    exact
                    path="/TiktokGOMOShop/SIDashboard"
                    component={SIDashboard}
                  />
                  <Route
                    exact
                    path="/TiktokGOMOShop/RTSDashboard"
                    component={RTSDashboard}
                  />
                  <Route path="/TiktokGOMOShop/Orders" component={Orders} />
                  <Route
                    path="/TiktokGOMOShop/OrderItemList"
                    component={OrderItemList}
                  />
                  <Route
                    path="/TiktokGOMOShop/PaymentRelease"
                    component={PaymentRelease}
                  />
                  <Route
                    path="/TiktokGOMOShop/UploadPaymentRelease"
                    component={UploadPaymentRealease}
                  />
                  <Route
                    path="/TiktokGOMOShop/Help"
                    component={Help}
                  />
                </Switch>
              </Router>
            </Grid.Col>
          </Grid.Row>
        )}
      </div>
    </>
  );
};

export default Index;
