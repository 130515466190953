import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Button,
  Icon,
  Progress,
  Text,
  Loader,
  Avatar,
  Grid,
} from "tabler-react";
import Tooltip from "react-simple-tooltip";
import OnlineOrders from "../../components/OnlineOrders";

import useOnlineOrders from "../../../../../hooks/api/Online_orders/useOnlineOrders";
import useUsers from "../../../../../hooks/api/Users/useUsers";

const DashboardTelemarketerList = (props) => {
  const { user } = useUsers();
  console.log(user);
  const { approvedWebOrders, webOrders } = useOnlineOrders();
  const { data } = props;
  const {
    backURL,
    telemarketers,
    rangeSelection,
    downloadDashboardSummaryReport,
    accessLevel,
    curWeek,
  } = data;

  // console.log(curWeek);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (telemarketers.length != 0) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [telemarketers]);

  const [openForm, setOpenForm] = useState("");

  return (
    <>
      <Card>
        <Card.Status color="cyan" side />
        <Card.Header>
          {rangeSelection} - Completed, Pending calls, Uncalled Stores per Tele
          for 711
          <Card.Options>
            {!loading ? (
              <Button
                icon="download"
                color="azure"
                onClick={() =>
                  downloadDashboardSummaryReport(
                    rangeSelection,
                    telemarketers.filter(
                      (telemarketer) => telemarketer.all_stores.length != 0
                    )
                  )
                }
                disabled={accessLevel === "view"}
              >
                Export {rangeSelection} Report
              </Button>
            ) : (
              <Loader className="loader" />
            )}
          </Card.Options>
        </Card.Header>
        {!loading ? (
          <Card.Body>
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Telemarketer</Table.ColHeader>
                  <Table.ColHeader>Assigned Stores</Table.ColHeader>
                  <Table.ColHeader>Completed Stores</Table.ColHeader>
                  <Table.ColHeader>Pending Stores</Table.ColHeader>
                  <Table.ColHeader>Uncalled Stores</Table.ColHeader>
                  <Table.ColHeader>Uncalled Last Week</Table.ColHeader>
                  {accessLevel === "report" ? (
                    ""
                  ) : (
                    <Table.ColHeader>Actions</Table.ColHeader>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {telemarketers
                  .filter(
                    (tele) =>
                      tele.full_name !== "CLOSED" &&
                      tele.full_name !== "Admin Temp" &&
                      tele.full_name !== "Previous Staff"
                  )
                  .filter((tele) => tele.stores.length != 0)
                  .sort(
                    (a, b) =>
                      b.completed_count.length - a.completed_count.length
                  )
                  .map((tele) => ({
                    ...tele,
                    initials: tele.full_name.match(/\b(\w)/g).slice(0, 2),
                  }))
                  .map((tele, index) => (
                    <Table.Row key={index}>
                      <Table.Col>
                        <Grid.Row>
                          <Grid.Col md={3}>
                            <Avatar size="md" color="cyan">
                              {tele.initials}
                            </Avatar>
                          </Grid.Col>
                          <Grid.Col md={9}>
                            <Text size="md">{tele.full_name}</Text>
                            <Text size="sm" muted>
                              {tele.role}
                            </Text>
                          </Grid.Col>
                        </Grid.Row>
                      </Table.Col>
                      <Table.Col>
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>{tele.stores.length}</strong>
                          </div>
                          <div className="float-right">
                            <Text.Small muted>Stores</Text.Small>
                          </div>
                        </div>
                        <Progress size="xs">
                          <Progress.Bar color="cyan" width={100} />
                        </Progress>
                      </Table.Col>
                      <Table.Col alignContent="center">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>{tele.completed_count.length}</strong>
                          </div>
                          <div className="float-right">
                            <Text.Small muted>
                              {parseFloat(
                                (tele.completed_count.length /
                                  tele.stores.length) *
                                  100
                              ).toFixed(2)}
                              % Completed Stores
                            </Text.Small>
                          </div>
                        </div>
                        <Progress size="xs">
                          <Progress.Bar
                            color="cyan"
                            width={
                              (tele.completed_count.length /
                                tele.stores.length) *
                              100
                            }
                          />
                        </Progress>
                      </Table.Col>
                      <Table.Col alignContent="center">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>{tele.pending_count.length}</strong>
                          </div>
                        </div>
                        <Progress size="xs">
                          <Progress.Bar
                            color="cyan"
                            width={
                              (tele.pending_count.length / tele.stores.length) *
                              100
                            }
                          />
                        </Progress>
                      </Table.Col>
                      <Table.Col alignContent="center">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>{tele.uncalled_stores_count.length}</strong>
                          </div>
                          <div className="float-right">
                            <Text.Small muted>
                              {parseFloat(
                                (tele.uncalled_stores_count.length /
                                  tele.stores.length) *
                                  100
                              ).toFixed(2)}
                              % Uncalled Stores
                            </Text.Small>
                          </div>
                        </div>
                        <Progress size="xs">
                          <Progress.Bar
                            color="cyan"
                            width={
                              (tele.uncalled_stores_count.length /
                                tele.stores.length) *
                              100
                            }
                          />
                        </Progress>
                      </Table.Col>
                      <Table.Col alignContent="center">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>
                              {tele.uncalled_called_stores_last_week.length}
                            </strong>
                          </div>
                          <div className="float-right">
                            <Text.Small muted>
                              {parseFloat(
                                (tele.uncalled_called_stores_last_week.length /
                                  tele.stores.length) *
                                  100
                              ).toFixed(2)}
                              % Uncalled Stores Last Week
                            </Text.Small>
                          </div>
                        </div>
                        <Progress size="xs">
                          <Progress.Bar
                            color="orange"
                            width={
                              (tele.uncalled_called_stores_last_week.length /
                                tele.stores.length) *
                              100
                            }
                          />
                        </Progress>
                      </Table.Col>
                      <Table.Col alignContent="left">
                        <>
                          {tele.uncalled_called_stores_last_week.length !== 0 &&
                          (accessLevel === "view" ||
                            accessLevel !== "report") ? (
                            <Tooltip
                              content="Uncalled Stores Last Week"
                              radius={5}
                              padding={5}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <Button
                                color="orange"
                                onClick={() =>
                                  window.location.replace(
                                    `/AMS/CallLogs711History/UncalledLastWeek?tele=${tele.full_name}&range=${rangeSelection}&backURL=${backURL}
                            `
                                  )
                                }
                                disabled={
                                  accessLevel === "view" ||
                                  accessLevel === "report"
                                }
                              >
                                <Icon prefix="fa" name="exclamation-circle" />
                              </Button>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {tele.completed_count.length !== 0 &&
                          (accessLevel === "view" ||
                            accessLevel !== "report") ? (
                            <Tooltip
                              content="Completed Stores"
                              radius={5}
                              padding={5}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <Button
                                icon="home"
                                color="cyan"
                                onClick={() =>
                                  window.location.replace(
                                    `/AMS/CallLogs711History/Completed?tele=${
                                      tele.full_name
                                    }&range=${rangeSelection}&backURL=${backURL}${
                                      rangeSelection === "Weekly"
                                        ? `&dates=${curWeek[1]}|${curWeek[6]}`
                                        : ""
                                    }`
                                  )
                                }
                                disabled={accessLevel === "view"}
                              ></Button>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {tele.pending_count.length !== 0 &&
                          (accessLevel === "view" ||
                            accessLevel !== "report") ? (
                            <Tooltip
                              content="Pending Stores"
                              radius={5}
                              padding={5}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <Button
                                color="teal"
                                onClick={() =>
                                  window.location.replace(
                                    `/AMS/CallLogs711History/Pending?tele=${
                                      tele.full_name
                                    }&range=${rangeSelection}&backURL=${backURL}${
                                      rangeSelection === "Weekly"
                                        ? `&dates=${curWeek[1]}|${curWeek[6]}`
                                        : ""
                                    }`
                                  )
                                }
                                disabled={
                                  accessLevel === "view" ||
                                  accessLevel === "report"
                                }
                              >
                                <Icon prefix="fa" name="mail-reply" />
                              </Button>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {tele.uncalled_stores_count.length !== 0 &&
                          (accessLevel === "view" ||
                            accessLevel !== "report") ? (
                            <Tooltip
                              content="Uncalled Stores"
                              radius={5}
                              padding={5}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <Button
                                color="azure"
                                onClick={() =>
                                  window.location.replace(
                                    `/AMS/CallLogs711History/Uncalled?tele=${
                                      tele.full_name
                                    }&range=${rangeSelection}&backURL=${backURL}${
                                      rangeSelection === "Weekly"
                                        ? `&dates=${curWeek[1]}|${curWeek[6]}`
                                        : ""
                                    }
                            `
                                  )
                                }
                                disabled={
                                  accessLevel === "view" ||
                                  accessLevel === "report"
                                }
                              >
                                <Icon prefix="fa" name="phone" />
                              </Button>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {webOrders.filter(
                            (order) => order.telemarketer === tele.full_name
                          ).length != 0 ? (
                            <>
                              {openForm === tele.full_name ? (
                                <OnlineOrders
                                  data={{
                                    backURL,
                                    setOpenForm,
                                    approvedWebOrders,
                                    onlineOrders: webOrders.filter(
                                      (order) => order.telemarketer === tele.full_name
                                    ),
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              <Tooltip
                                content="Online Orders"
                                radius={5}
                                padding={5}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <Button
                                  color="lime"
                                  onClick={() => setOpenForm(tele.full_name)}
                                >
                                  <Icon prefix="fa" name="globe" />
                                </Button>
                                <span className="badge">
                                  {
                                    webOrders.filter(
                                      (order) =>
                                        order.telemarketer === tele.full_name
                                    ).length
                                  }
                                </span>
                              </Tooltip>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      </Table.Col>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Card.Body>
        ) : (
          <Card.Body>
            <div style={{ padding: "5px" }}>
              <Loader className="loader" />
            </div>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default DashboardTelemarketerList;
