import React, { useRef } from "react";
import { Button, Card, Table, Icon, Header, Form, Grid } from "tabler-react";
import ReactToPrint from "react-to-print";
import dateFormat from "dateformat";

const Summary = (props) => {
  const {
    viewReport,
    setViewReport,
    summaryReport,
    DR_number,
    DR_month,
    DR_year,
    user,
  } = props.data;

  const componentRef = useRef();

  return (
    <>
      <div className="modal-summary">
        <Card ref={componentRef} className="modal-summary-content-container">
          <Card.Header>
            Order Summary Report
            <Card.Options>
              <ReactToPrint
                trigger={() => (
                  <Button color="cyan">
                    <Icon prefix="fa" name="print" />
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
              <Button color="secondary" onClick={() => setViewReport(false)}>
                <Icon prefix="fa" name="close" />
                Close
              </Button>
            </Card.Options>
          </Card.Header>
          <Card.Body   className="modal-summary-content">
            <p
              style={{
                margin: "0px auto",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              Printing Date: {dateFormat(new Date(), "ddd mmm dd yyyy h:MM TT")}
            </p>
            <Grid.Row>
              <Grid.Col md={4}>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  DR NO. {DR_number}
                </p>
              </Grid.Col>
              <Grid.Col md={4}></Grid.Col>
              <Grid.Col md={4}>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {DR_month} {DR_year}
                </p>
              </Grid.Col>
            </Grid.Row>

            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-wrap summary-table"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Item Code</Table.ColHeader>
                  <Table.ColHeader>Variant Name</Table.ColHeader>
                  <Table.ColHeader>Quantity</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {summaryReport
                  .filter((s) => !s.name.includes("FOR RETURN - "))
                  .filter((s) => !s.name.includes("LOST INVENTORY - "))
                  .filter((s) => !s.name.includes("CHARGE TO EMPLOYEE - "))
                  .map((variant, index) => (
                    <Table.Row>
                      <Table.Col>{variant.code}</Table.Col>
                      <Table.Col>{variant.name}</Table.Col>
                      <Table.Col>{variant.qty}</Table.Col>
                    </Table.Row>
                  ))}

                {summaryReport.filter((s) => s.name.includes("FOR RETURN - "))
                  .length != 0 ? (
                  <>
                    <Table.Row>
                      <Table.Col>
                        <b>FOR RETURNS</b>
                      </Table.Col>
                      <Table.Col></Table.Col>
                      <Table.Col></Table.Col>
                    </Table.Row>
                    {summaryReport
                      .filter((s) => s.name.includes("FOR RETURN - "))
                      .map((variant, index) => (
                        <Table.Row>
                          <Table.Col>{variant.code}</Table.Col>
                          <Table.Col>{variant.name}</Table.Col>
                          <Table.Col>{variant.qty}</Table.Col>
                        </Table.Row>
                      ))}
                  </>
                ) : (
                  ""
                )}

                {summaryReport.filter((s) =>
                  s.name.includes("LOST INVENTORY - ")
                ).length != 0 ? (
                  <>
                    <Table.Row>
                      <Table.Col>
                        <b>LOST INVENTORY</b>
                      </Table.Col>
                      <Table.Col></Table.Col>
                      <Table.Col></Table.Col>
                    </Table.Row>
                    {summaryReport
                      .filter((s) => s.name.includes("LOST INVENTORY - "))
                      .map((variant, index) => (
                        <Table.Row>
                          <Table.Col>{variant.code}</Table.Col>
                          <Table.Col>{variant.name}</Table.Col>
                          <Table.Col>{variant.qty}</Table.Col>
                        </Table.Row>
                      ))}
                  </>
                ) : (
                  ""
                )}

                {summaryReport.filter((s) =>
                  s.name.includes("CHARGE TO EMPLOYEE - ")
                ).length != 0 ? (
                  <>
                    <Table.Row>
                      <Table.Col>
                        <b>CHARGE TO EMPLOYEE</b>
                      </Table.Col>
                      <Table.Col></Table.Col>
                      <Table.Col></Table.Col>
                    </Table.Row>
                    {summaryReport
                      .filter((s) => s.name.includes("CHARGE TO EMPLOYEE - "))
                      .map((variant, index) => (
                        <Table.Row>
                          <Table.Col>{variant.code}</Table.Col>
                          <Table.Col>{variant.name}</Table.Col>
                          <Table.Col>{variant.qty}</Table.Col>
                        </Table.Row>
                      ))}
                  </>
                ) : (
                  ""
                )}
              </Table.Body>
            </Table>
            <p style={{ margin: "0px auto", textAlign: "center",fontSize: "15px", }}>
              -----------------------------------------------NOTHING
              FOLLOWS-------------------------------------------
            </p>
            <p
              style={{
                margin: "0px auto",
                textAlign: "center",
                fontSize: "15px",
              }}
            >
              Printing Date: {dateFormat(new Date(), "ddd mmm dd yyyy h:MM TT")}
            </p>
            <br />
            <br />
            <br />
            <div style={{ marginLeft: "50px" }}>
              <p
                style={{
                  margin: "0px auto",
                  fontSize: "15px",
                  margin: 0,
                }}
              >
                Prepared By:
              </p>
              <br />
              <br />
              <p
                style={{
                  margin: "0px auto",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                ------------------------------ <br />
                {user.full_name.toUpperCase()}
              </p>
              <p
                style={{
                  margin: "0px auto",
                  fontSize: "15px",
                }}
              >
                {user.role.toUpperCase()}
              </p>
              <br />
              <br />
              <p style={{ margin: "0px auto", textAlign: "center",fontSize: "15px", }}>
                -------------------------------------------------------------------------------------------------------------------
              </p>
              <p
                style={{
                  margin: "0px auto",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                Printing Date:{" "}
                {dateFormat(new Date(), "ddd mmm dd yyyy h:MM TT")}
              </p>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default Summary;
