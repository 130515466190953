import React, { useState, useEffect } from "react";
import { Button, Card, Grid, Loader } from "tabler-react";
import UploadForm from "../components/uploadForm";
import TableResults from "./components/tableResult";
import Progress from "../components/Progress";
import useLazadaTool from "../../../hooks/api/GBT/useLazadaTool";
import { Helmet } from "react-helmet";

const Index = () => {
  const [extractedReport, setExtractedReport] = useState([]);
  const [fileName, setFileName] = useState("GATE LAZADA MALL ORDER");
  const [customLabel, setCustomLabel] = useState(new Date());
  const [orderIDs, setOrderIDs] = useState("");

  const { orders, ordersCount, orderSummary } = useLazadaTool(
    extractedReport,
    orderIDs,
    customLabel
  );

  const [loading, setLoading] = useState(true);

  return (
    <>
    <Helmet> <title>GMS Basic Tool - Lazada Report</title></Helmet>
    <div>
      <Card>
        <Card.Header>
          <b>LAZADA REPORT:</b> UPLOAD THE REQUIRED DATA
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={4} sm={12}>
              <UploadForm
                onUpload={setExtractedReport}
                label="Extracted File as of Today"
                type="upload"
                value={extractedReport}
                fileName={fileName}
                customLabel={customLabel}
                setFileName={setFileName}
                setCustomLabel={setCustomLabel}
                shippingOptions={["lex"]}
              />
            </Grid.Col>
            <Grid.Col md={4} sm={12}>
              <UploadForm
                label="List of Processed Order IDs/ Excluded Orders"
                type="text"
                orderIDs={orderIDs}
                setOrderIDs={setOrderIDs}
              />
            </Grid.Col>
            <Grid.Col md={4} sm={12}>
              <Grid.Row>
                {orders.length != 0 ? (
                  <>
                    {orders.filter(
                      (order) => order.variant_name === "No Variant Name"
                    ).length !== 0 ? (
                      <Grid.Col md={6}>
                        <Progress
                          header="Total Items w/ Errors"
                          content={
                            orders
                              ? orders.filter(
                                  (order) =>
                                    order.variant_name === "No Variant Name"
                                ).length
                              : 0
                          }
                          progressWidth={
                            orders
                              ? (orders.filter(
                                  (order) =>
                                    order.variant_name === "No Variant Name"
                                ).length /
                                  ordersCount) *
                                100
                              : 0
                          }
                          progressColor="danger"
                          color="#cd201f"
                        />
                      </Grid.Col>
                    ) : (
                      ""
                    )}

                    <Grid.Col md={6}>
                      <Progress
                        header="Total Order Count"
                        content={ordersCount}
                        progressWidth={ordersCount}
                        progressColor="cyan"
                      />
                    </Grid.Col>
                  </>
                ) : (
                  ""
                )}
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>

      <div>
        {orders.length !== 0 ? (
          <>
            <TableResults
              orders={orders}
              orderSummary={orderSummary}
              ordersCount={ordersCount}
              fileName={fileName}
              customLabel={customLabel}
            />
          </>
        ) : (
          ""
        )}
      </div>
    </div>
    </>
    
  );
};

export default Index;
