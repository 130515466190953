import React, {useEffect} from "react";
import { Button, Card, Table, Icon, Grid, Form } from "tabler-react";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import useExtractReports from "../../../hooks/api/AMS_other/extractions/useExtractReports";
import useQueryURL from "../../../hooks/services/useQueryURL";

const CallFilter = (props) => {
  const { getQuery, changeQueryParam } = useQueryURL();
  
  const { downloadCallLogs } = useExtractReports();
  const {
    filtered,
    filter1,
    setFilter1,
    filter2,
    setFilter2,
    filter3,
    setFilter3,
    filter4a,
    setFilter4a,
    filter4b,
    setFilter4b,
    filter5,
    setFilter5,
  } = props.data;

  const dates = getQuery("dates") ? getQuery("dates").split("|") : [new Date(), new Date()]
  const call_status = getQuery("call_status") || ""

  useEffect(() => {
    setFilter4a(new Date(dates[0]))
    setFilter4b(new Date(dates[1]))
    setFilter2(call_status)
  }, [])
  

  return (
    <Card.Body>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Client ID</Form.Label>
            <Form.Input
              value={filter1}
              onChange={(e) => setFilter1(e.target.value)}
              placeholder="Search by Client ID"
            />
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Client Name</Form.Label>
            <Form.Input
              value={filter3}
              onChange={(e) => setFilter3(e.target.value)}
              placeholder="Search by Client Name"
            />
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Select
              value={filter2}
              onChange={(e) => setFilter2(e.target.value)}
            >
              <option value="">SELECT ALL</option>
              <option value="Completed">Completed</option>
              <option value="Pending">Pending</option>
            </Form.Select>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Call Date</Form.Label>
            {/* <DatePicker
              selected={filter4a}
              onChange={(date) => {
                setFilter4a(date);
              }}
              //   onChange={(dates) => {
              //     const [start, end] = dates;
              //     setFilter4a(start);
              //     setFilter4b(end);
              //   }}
              //   startDate={filter4a}
              //   endDate={filter4b}
              minDate={new Date(`01/01/2022`)}
              maxDate={new Date()}
              className="form-control"
              //   selectsRange
              monthsShown={1}
            /> */}
            <DatePicker
              selected={filter4a}
              onChange={(dates) => {
                const [start, end] = dates;
                setFilter4a(start);
                setFilter4b(end);
                if (end !== null && start !== null) {
                  
                  filter4a || filter4a === "" || filter4b || filter4b === ""
                    ? window.location.replace(
                        changeQueryParam(
                          window.location.href,
                          "dates",
                          `${dateFormat(start, "paddedShortDate")}|${dateFormat(
                            end,
                            "paddedShortDate"
                          )}`
                        )
                      )
                    : window.location.replace(
                        window.location.href +
                          `&dates=${dateFormat(
                            start,
                            "paddedShortDate"
                          )}|${dateFormat(end, "paddedShortDate")}`
                      );
                }
              }}
              startDate={filter4a}
              endDate={filter4b}
              className="form-control"
              selectsRange
              monthsShown={1}
            />
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Received By</Form.Label>
            <Form.Select
              value={filter5}
              onChange={(e) => setFilter5(e.target.value)}
            >
              <option value="">SELECT ALL</option>
              <option value="Patrick Sayo">Patrick Sayo</option>
              <option value="Clifford Mahilum">Clifford Mahilum</option>
            </Form.Select>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        {filtered.length != 0 ? (
          <Grid.Col md={2}>
            <Button
              color="cyan"
              icon="download"
              onClick={() => downloadCallLogs(filtered)}
            >
              Extract
            </Button>
          </Grid.Col>
        ) : (
          ""
        )}
      </Grid.Row>
    </Card.Body>
  );
};

export default CallFilter;
