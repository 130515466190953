import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Alert, Card, Grid, Icon, SiteFooter } from "tabler-react";
import Login from "./pages/Login";
import Logout from "./pages/Login/Logout";
import Home from "./pages/Home";
import Profile from "./pages/Home/Profile";

//USERs
import Users from "./pages/Users";

//HR
import HR from "./pages/HR";

//AMS
import AMS from "./pages/AMS";

//GVD
import GVD from "./pages/GVD";

//GVT
import GBT from "./pages/GBT";

//LazadaShop
import LazadaShop from "./pages/LazadaShop";

//ShopeeShop
import ShopeeShop from "./pages/ShopeeShop";

//TiktokShop
import TiktokShop from "./pages/TiktokShop"
import TiktokGOMOShop from "./pages/TiktokGOMOShop"

//hooks
import useUsers from "./hooks/api/Users/useUsers";

import GPM from "./pages/GPM";

import Notification from "./pages/Notification";

import AMS_other from "./pages/AMS_other";
import AMS_sales from "./pages/AMS_sales"

const App = () => {

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Profile" component={Profile} />
          <Route path="/Users" component={Users} />
          <Route path="/Login" component={Login} />
          <Route path="/Logout" component={Logout} />

          {/*HR Routes*/}
          <Route path="/HR" component={HR} />

          {/*AMS Routes*/}
          <Route path="/AMS" component={AMS} />
          <Route path="/AMS_other" component={AMS_other} />
          <Route path="/AMS_sales" component={AMS_sales} />

          {/*GVD Routes*/}
          <Route path="/GVD" component={GVD} />

          {/*GBT Routes*/}
          <Route path="/GBT" component={GBT} />

          {/*LazadaShop Routes*/}
          <Route path="/LazadaShop" component={LazadaShop} />

          {/*ShopeeShop Routes*/}
          <Route path="/ShopeeShop" component={ShopeeShop} />

          {/*TiktokShop Routes*/}
          <Route path="/TiktokShop" component={TiktokShop} />
          <Route path="/TiktokGOMOShop" component={TiktokGOMOShop} />

          {/*GPM Routes*/}
          <Route path="/GPM" component={GPM} />

          <Route path="/Notifications" component={Notification} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
