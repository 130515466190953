import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Tab,
  Table,
  TabbedCard,
  Tabs,
  Icon,
  Grid,
} from "tabler-react";
import dateFormat from "dateformat";
import useExtractSerials from "../../../../hooks/api/GBT/extractions/useExtractSerials";
import "../index.css";

const TableResults = (props) => {
  const { fileName, customLabel, extractedReport, orders, orderSummary } =
    props;

  const { downloadOrders, downloadSummary } = useExtractSerials();

  const [filter, setFilter] = useState(true);
  const [filter1, setFilter1] = useState(true);

  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      <Card>
        <Card.Status color="cyan" side />
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={8}>
              <Button
                color={currentTab == 0 ? "cyan" : "secondary"}
                onClick={() => setCurrentTab(0)}
              >
                Serial Summary
              </Button>
              <Button
                color={currentTab == 1 ? "cyan" : "secondary"}
                onClick={() => setCurrentTab(1)}
              >
                Serial List
              </Button>
            </Grid.Col>
            <Grid.Col md={4} className="extraction-btn">
              {currentTab == 0 ? (
                <>
                  {orderSummary.filter(
                    (order) =>
                      order.match === false || order.match === "NO RECORDS"
                  ).length === 0 ? (
                    <>
                      {orders.length !== 0 ? (
                        <>
                          <Button
                            onClick={() =>
                              downloadSummary(
                                orderSummary,
                                `${fileName} - ${dateFormat(
                                  new Date(),
                                  "longDate"
                                )}`
                              )
                            }
                            color="cyan"
                          >
                            <Icon prefix="fa" name="download" /> Extract Serial
                            Summary
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <Button onClick={() => setFilter(true)} color="azure">
                        <Icon prefix="fa" name="eye" /> View All
                      </Button>
                      <Button
                        onClick={() => setFilter("NO RECORDS")}
                        color="orange"
                      >
                        <Icon prefix="fa" name="eye" /> NO RECORDS
                      </Button>
                      <Button onClick={() => setFilter(false)} color="danger">
                        <Icon prefix="fa" name="eye" /> View Errors
                      </Button>
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              {currentTab == 1 ? (
                <>
                  {orders.filter((order) =>
                    order.serials.includes("No Serial Available")
                  ).length === 0 ? (
                    <>
                      <Button
                        onClick={() =>
                          downloadOrders(
                            orders,
                            `${fileName} - ${dateFormat(
                              new Date(),
                              "longDate"
                            )}`
                          )
                        }
                        color="cyan"
                      >
                        <Icon prefix="fa" name="download" /> Extract Generated
                        Serials
                      </Button>
                    </>
                  ) : (
                    <>
                      {!filter1 ? (
                        <Button onClick={() => setFilter1(true)} color="azure">
                          <Icon prefix="fa" name="eye" /> View All
                        </Button>
                      ) : (
                        <Button
                          onClick={() => setFilter1(false)}
                          color="danger"
                        >
                          <Icon prefix="fa" name="eye" /> View Errors
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        {currentTab == 0 ? (
          <>
            <Card.Body>
              <div className="table-result-container">
                <Table
                  responsive
                  highlightRowOnHover
                  hasOutline
                  verticalAlign="center"
                  cards
                  className="text-nowrap"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>Gate Variant Name</Table.ColHeader>
                      <Table.ColHeader>
                        Total # of Assigned Serials
                      </Table.ColHeader>
                      <Table.ColHeader>Total # of Orders</Table.ColHeader>
                      <Table.ColHeader>Status</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {orderSummary
                      .filter((order) =>
                        filter === true ? order : order.match === filter
                      )
                      .map((order, index) => (
                        <>
                          <Table.Row key={index}>
                            <Table.Col>{order.variant_name}</Table.Col>
                            <Table.Col>{order.totalSerials}</Table.Col>
                            <Table.Col>{order.totalOrders}</Table.Col>
                            <Table.Col>
                              {order.match === true ? (
                                <span
                                  style={{
                                    color: "#17A2B8",
                                    padding: "8px",
                                    borderRadius: "5px",
                                    textTransform: "uppercase",
                                    margin: "5px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`SUFFICIENT`}
                                </span>
                              ) : (
                                <>
                                  {order.totalSerials > order.totalOrders &&
                                  order.totalSerials !== "NO RECORDS" ? (
                                    <span
                                      style={{
                                        color: "#FFBF00",
                                        padding: "8px",
                                        borderRadius: "5px",
                                        textTransform: "uppercase",
                                        margin: "5px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {`OVER SERIALS ${
                                        order.totalSerials - order.totalOrders
                                      }`}
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {order.totalSerials < order.totalOrders &&
                                  order.totalSerials !== "NO RECORDS" ? (
                                    <span
                                      style={{
                                        color: "#DB2656",
                                        padding: "8px",
                                        borderRadius: "5px",
                                        textTransform: "uppercase",
                                        margin: "5px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {`INSUFFICIENT ${
                                        order.totalSerials - order.totalOrders
                                      }`}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}

                              {order.match === "NO RECORDS" ? (
                                <span
                                  style={{
                                    color: "#FFBF00",
                                    padding: "8px",
                                    borderRadius: "5px",
                                    textTransform: "uppercase",
                                    margin: "5px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`NO RECORDS `}
                                </span>
                              ) : (
                                ""
                              )}
                            </Table.Col>
                          </Table.Row>
                        </>
                      ))}
                  </Table.Body>
                </Table>
              </div>
              <div
                style={{ margin: "0 auto", textAlign: "center", padding: 10 }}
              >
                {orderSummary.filter((order) =>
                  filter === true ? order : order.match === filter
                ).length === 0 ? (
                  <>
                    <Icon prefix="fa" name="folder-open" /> NO DATA AVAILABLE
                  </>
                ) : (
                  ""
                )}
              </div>
            </Card.Body>
          </>
        ) : (
          ""
        )}

        {currentTab == 1 ? (
          <>
            <Card.Body>
              <div className="table-result-container">
                <Table
                  responsive
                  highlightRowOnHover
                  hasOutline
                  verticalAlign="center"
                  cards
                  className="text-nowrap"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>Order ID</Table.ColHeader>
                      <Table.ColHeader>Tracking Number</Table.ColHeader>
                      <Table.ColHeader>Variant Name</Table.ColHeader>
                      <Table.ColHeader>QTY</Table.ColHeader>
                      <Table.ColHeader>Serials</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {orders
                      .filter((order) =>
                        filter1
                          ? order
                          : order.serials.includes("No Serial Available")
                      )
                      .map((order, index) => (
                        <>
                          <Table.Row key={index}>
                            <Table.Col>{order.order_id}</Table.Col>
                            <Table.Col>{order.tracking_number}</Table.Col>
                            <Table.Col>{order.variant_name}</Table.Col>
                            <Table.Col>{order.quantity}</Table.Col>
                            <Table.Col>{order.serials}</Table.Col>
                          </Table.Row>
                        </>
                      ))}
                  </Table.Body>
                </Table>
              </div>
            </Card.Body>
          </>
        ) : (
          ""
        )}
      </Card>
    </>
  );
};

export default TableResults;
