import React from "react";
import { Button, Card, Form, Grid } from "tabler-react";

const Filter = (props) => {
  const { data } = props;
  const {
    src,
    month,
    year,
    search,
    setSearch,
    search2,
    setSearch2,
    search3,
    setSearch3,
    search4,
    setSearch4,
    search5,
    setSearch5,
    downloadSISummary,
    newDrList,
  } = data;

  return (
    <>
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={2}>
            <Form.Label>Month</Form.Label>
            <Form.Select
              value={month}
              onChange={(e) =>
                window.location.replace(
                  `/TiktokShop/${src}?month=${e.target.value}&year=${year}`
                )
              }
            >
              <option value="">SELECT </option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </Form.Select>
          </Grid.Col>
          <Grid.Col md={2}>
            <Form.Label>Year</Form.Label>
            <Form.Select
              value={year}
              onChange={(e) =>
                window.location.replace(
                  `/TiktokShop/${src}?month=${month}&year=${e.target.value}`
                )
              }
            >
              <option value="">SELECT YEAR</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
            </Form.Select>
          </Grid.Col>
          {src === "RTSDashboard" || src === "SIDashboard" ? (
            ""
          ) : (
            <Grid.Col md={2}>
              <Form.Label>Status</Form.Label>
              <Form.Select
                value={search2}
                onChange={(e) => setSearch2(e.target.value)}
              >
                <option value="">All</option>
                <option value="Ready">Ready</option>
                <option value="Not Ready">Not Ready</option>
              </Form.Select>
            </Grid.Col>
          )}

          <Grid.Col md={2}>
            <Form.Label>
              {src === "RTSDashboard" ? "DR Number" : "DR / SI Number"}
            </Form.Label>
            <Form.Input
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid.Col>

          {src === "RTSDashboard" ? (
            <>
              <Grid.Col md={2}>
                <Form.Label>Order ID/ Item Number</Form.Label>
                <Form.Input
                  placeholder="Search..."
                  value={search3}
                  onChange={(e) => setSearch3(e.target.value)}
                />
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Label>Status/Remarks</Form.Label>
                <Form.Select
                  value={search4}
                  onChange={(e) => setSearch4(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Returned">Returned</option>
                  <option value="Endorsed to MKTG">Endorsed to MKTG</option>
                  <option value="Payment Waiting">Payment Waiting</option>
                </Form.Select>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Label>DR Status</Form.Label>
                <Form.Select
                  value={search5}
                  onChange={(e) => setSearch5(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="Completed">Completed</option>
                  <option value="Pending DR">Pending DR</option>
                </Form.Select>
              </Grid.Col>
            </>
          ) : (
            ""
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={2}>
            <Form.Label></Form.Label>
            <Button
              color="cyan"
              icon="download"
              onClick={() => downloadSISummary(newDrList)}
            >
              Export
            </Button>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </>
  );
};

export default Filter;
