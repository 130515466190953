import React, { useState, useEffect } from "react";
import { Button, Card, Grid, Loader } from "tabler-react";
import UploadForm from "../components/uploadForm";
import TableResults from "./components/tableResult";
import Progress from "../components/Progress";

import useShopeeTool from "../../../hooks/api/GBT/useShopeeTool";
import { Helmet } from "react-helmet";

const Index = () => {
  //shipping Settings
  const [spx, setSPX] = useState(new Date());
  const [jnt, setJNT] = useState(new Date());

  const [loading, setLoading] = useState(true);
  const [extractedReport, setExtractedReport] = useState([]);
  const [fileName, setFileName] = useState("GATE SHOPEE MALL ORDER");
  const [customLabel, setCustomLabel] = useState(new Date());
  const [orderIDs, setOrderIDs] = useState("");

  const { orders, ordersCount, orderSummary } = useShopeeTool(
    extractedReport,
    orderIDs,
    spx,
    jnt
  );

  useEffect(() => {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
    }, 2000);
  }, [orders]);

  console.log(orders);

  return (
    <>
      <Helmet>
        <title>GMS Basic Tool - Shopee Report</title>
      </Helmet>
      <div>
        <Card>
          <Card.Header>
            <b>SHOPEE REPORT</b>: UPLOAD THE REQUIRED DATA
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={4} sm={12}>
                <UploadForm
                  onUpload={setExtractedReport}
                  label="Extracted File as of Today"
                  type="upload"
                  value={extractedReport}
                  fileName={fileName}
                  customLabel={customLabel}
                  setFileName={setFileName}
                  setCustomLabel={setCustomLabel}
                  shippingOptions={["jnt", "spx"]}
                  jnt={jnt}
                  setJNT={setJNT}
                  spx={spx}
                  setSPX={setSPX}
                />
              </Grid.Col>
              <Grid.Col md={4} sm={12}>
                <UploadForm
                  label="List of Processed Order IDs/ Excluded Orders"
                  type="text"
                  orderIDs={orderIDs}
                  setOrderIDs={setOrderIDs}
                />
              </Grid.Col>
              <Grid.Col md={4} sm={12}>
                <Grid.Row>
                  {orders.length != 0 ? (
                    <>
                      {orders.filter(
                        (order) => order.variant_name === "No Variant Name"
                      ).length !== 0 ? (
                        <Grid.Col md={6}>
                          <Progress
                            header="Total Items w/ Errors"
                            content={
                              orders
                                ? orders.filter(
                                    (order) =>
                                      order.variant_name === "No Variant Name"
                                  ).length
                                : 0
                            }
                            progressWidth={
                              orders
                                ? (orders.filter(
                                    (order) =>
                                      order.variant_name === "No Variant Name"
                                  ).length /
                                    ordersCount) *
                                  100
                                : 0
                            }
                            progressColor="danger"
                            color="#cd201f"
                          />
                        </Grid.Col>
                      ) : (
                        ""
                      )}

                      <Grid.Col md={6}>
                        <Progress
                          header="Total Order Count"
                          content={ordersCount}
                          progressWidth={ordersCount}
                          progressColor="cyan"
                        />
                      </Grid.Col>

                      <Grid.Col md={6}>
                        <Progress
                          header="J&T Express"
                          content={
                            orders
                              .filter((order) =>
                                order.shipping_option.includes("J&T")
                              )
                              .map((order) => order.order_id)
                              .filter(
                                (v, i) =>
                                  orders
                                    .filter((order) =>
                                      order.shipping_option.includes("J&T")
                                    )
                                    .map((order) => order.order_id)
                                    .indexOf(v) == i
                              ).length
                          }
                          progressWidth={
                            (orders
                              .filter((order) =>
                                order.shipping_option.includes("J&T")
                              )
                              .map((order) => order.order_id)
                              .filter(
                                (v, i) =>
                                  orders
                                    .filter((order) =>
                                      order.shipping_option.includes("J&T")
                                    )
                                    .map((order) => order.order_id)
                                    .indexOf(v) == i
                              ).length /
                              ordersCount) *
                            100
                          }
                          progressColor="cyan"
                        />
                      </Grid.Col>

                      <Grid.Col md={6}>
                        <Progress
                          header="Shopee Express"
                          content={
                            orders
                              .filter(
                                (order) =>
                                  !order.shipping_option.includes("J&T") && !order.shipping_option.includes("Ninja Van")
                              )
                              .map((order) => order.order_id)
                              .filter(
                                (v, i) =>
                                  orders
                                    .filter(
                                      (order) =>
                                        !order.shipping_option.includes("J&T") && !order.shipping_option.includes("Ninja Van")
                                    )
                                    .map((order) => order.order_id)
                                    .indexOf(v) == i
                              ).length
                          }
                          progressWidth={
                            (orders
                              .filter(
                                (order) =>
                                  !order.shipping_option.includes("J&T") && !order.shipping_option.includes("Ninja Van")
                              )
                              .map((order) => order.order_id)
                              .filter(
                                (v, i) =>
                                  orders
                                    .filter(
                                      (order) =>
                                        !order.shipping_option.includes("J&T") && !order.shipping_option.includes("Ninja Van")
                                    )
                                    .map((order) => order.order_id)
                                    .indexOf(v) == i
                              ).length /
                              ordersCount) *
                            100
                          }
                          progressColor="cyan"
                        />
                      </Grid.Col>

                      <Grid.Col md={6}>
                        <Progress
                          header="Ninja Van"
                          content={
                            orders
                              .filter(
                                (order) =>
                                  order.shipping_option.includes("Ninja Van")
                              )
                              .map((order) => order.order_id)
                              .filter(
                                (v, i) =>
                                  orders
                                    .filter(
                                      (order) =>
                                        order.shipping_option.includes("Ninja Van")
                                    )
                                    .map((order) => order.order_id)
                                    .indexOf(v) == i
                              ).length
                          }
                          progressWidth={
                            (orders
                              .filter(
                                (order) =>
                                  order.shipping_option.includes("Ninja Van")
                              )
                              .map((order) => order.order_id)
                              .filter(
                                (v, i) =>
                                  orders
                                    .filter(
                                      (order) =>
                                        order.shipping_option.includes("Ninja Van")
                                    )
                                    .map((order) => order.order_id)
                                    .indexOf(v) == i
                              ).length /
                              ordersCount) *
                            100
                          }
                          progressColor="cyan"
                        />
                      </Grid.Col>
                    </>
                  ) : (
                    ""
                  )}
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        </Card>

        <div>
          {orders.length !== 0 ? (
            <>
              <TableResults
                orders={orders}
                orderSummary={orderSummary}
                ordersCount={ordersCount}
                fileName={fileName}
                customLabel={customLabel}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
