import { useState, useEffect } from "react";
import config from "../../../config";
import useDirectoryAPI from "../GVD/useDirectoryAPI";
import axios from "axios";
import dateFormat from "dateformat";
import useQueryURL from "../../services/useQueryURL";
import useCustomFunctions from "./../../services/useCustomFunctions";
import useUsers from "../Users/useUsers";

function useLazadaAPI(month, dr_number) {
  const { loginSession } = useUsers();

  const { getQuery } = useQueryURL();
  const { getDateDifference } = useCustomFunctions();

  let year = getQuery("year") ? getQuery("year") : "2021";
  let order_id = getQuery("order_id") ? getQuery("order_id") : "";

  const { directory } = useDirectoryAPI();
  const [orders, setOrders] = useState([]);
  const [reports, setReports] = useState([]);
  const [paymentRelease, setPaymentRelease] = useState([]);
  const [drList, setDrList] = useState([]);
  const [sIList, setSIList] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [RTSList, setRTSList] = useState([]);

  function getOrders() {
    if (paymentRelease) {
      if (order_id !== "") {
        axios
          .post(
            `${config.host}:${config.port}/api/v2/lazada/get_all_orders_by_id`,
            {
              order_id: order_id,
              month: month,
              year: year,
            }
          )
          .then(function (response) {
            // handle success
            setOrders(generateOrders(response.data));
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      } else if (dr_number !== "") {
        axios
          .post(
            `${config.host}:${config.port}/api/v2/lazada/get_orders_by_dr`,
            {
              dr_number: dr_number,
              month: month,
              year: year,
            }
          )
          .then(function (response) {
            // handle success
            setOrders(generateOrders(response.data));
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      } else if (month.includes("-report")) {
        axios
          .post(`${config.host}:${config.port}/api/v2/lazada/get_all_orders`, {
            month: month.replace("-report", ""),
            year: year,
          })
          .then(function (response) {
            // handle success
            setReports(response.data);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      } else {
        axios
          .post(`${config.host}:${config.port}/api/v2/lazada/get_orders`, {
            month: month ? month : "August",
            year: year,
          })
          .then(function (response) {
            // handle success
            setOrders(generateOrders(response.data));
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      }
    }
  }

  function assignedGatePrice(status, remarks, gate_price) {
    let gatePrice;
    if (status === "Returned" && remarks === "Inventory") {
      gatePrice = 0;
    } else if (status === "Cancelled" && remarks === "Inventory") {
      gatePrice = 0;
    } else if (status === "Returned" && remarks === "Lost Inventory") {
      gatePrice = gate_price;
    } else if (status === "Returned" && remarks === "Charge To Employee") {
      gatePrice = gate_price;
    } else {
      gatePrice = gate_price;
    }

    return gatePrice;
  }

  function getReleaseAmount(order_item_number, order_id) {
    let payments =
      paymentRelease.filter((p) => p.order_item_number === order_item_number) ||
      [];

    let payment_release;
    let commission_fee;
    let payment_fee;
    let order_status;
    let paid_status;
    let payment_match;
    let undercharge_commission_fee;
    let undercharge_payment_fee;
    let promotional_vouchers;

    let payment_item_credit;
    let payment_release_reversal;
    let lost_claim;
    let commission_fee_reversal;
    let damage_claim;
    let over_pay;

    const getPaymentValue = (fee_name) => {
      return (
        paymentRelease
          .filter(
            (p) =>
              p.order_item_number === order_item_number &&
              p.fee_name === fee_name &&
              p.paid_status === "paid"
          )
          ?.reduce((a, b) => parseInt(a) + parseInt(b.amount), 0) || 0
      );
    };

    const getOrderStatus = (status) => {
      if (status === "Delivered") {
        if (lost_claim !== 0) {
          order_status = "Lost - PR";
        } else if (payment_release_reversal) {
          order_status = "Returned - PRV";
        } else {
          order_status = "Delivered";
        }
      } else if (status === "Returned") {
        if (lost_claim !== 0) {
          order_status = "Lost - PR";
        } else if (payment_release_reversal) {
          order_status = "Returned - PRV";
        } else {
          order_status = "Returned - PR";
        }
      } else if (status === "lost by 3pl") {
        order_status = "Lost - PR";
      } else {
        order_status = "--";
      }

      return order_status;
    };

    payment_item_credit = getPaymentValue("Item Price Credit");

    payment_release_reversal = getPaymentValue("Reversal Item Price");

    lost_claim = getPaymentValue("Lost Claim");
    commission_fee_reversal = getPaymentValue("Reversal Commission");

    damage_claim = getPaymentValue("Damaged Claim(Lazada)");

    commission_fee = getPaymentValue("Commission");

    undercharge_commission_fee = getPaymentValue(
      "Commission fee - correction for undercharge"
    );

    undercharge_payment_fee = getPaymentValue(
      "Payment fee - correction for undercharge"
    );

    payment_fee = getPaymentValue("Payment Fee");

    over_pay = getPaymentValue("Over Pay");

    promotional_vouchers = getPaymentValue("Promotional Charges Vouchers");

    payment_release =
      payment_item_credit +
      lost_claim +
      damage_claim +
      (commission_fee_reversal -
        undercharge_commission_fee -
        undercharge_payment_fee -
        payment_release_reversal);

    paid_status = payments?.[payments.length - 1]?.paid_status || "unpaid";

    payment_match =
      payments.length != 0
        ? payments[payments.length - 1].order_item_number ===
            order_item_number &&
          payments[payments.length - 1].order_number === order_id
          ? "Matched"
          : "Not Matched"
        : "--";

    order_status =
      payments.length != 0
        ? getOrderStatus(payments[payments.length - 1].order_status)
        : "--";

    return {
      over_pay,
      undercharge_commission_fee,
      undercharge_payment_fee,
      payment_release,
      commission_fee,
      payment_fee,
      order_status,
      paid_status,
      payment_match,
    };
  }

  function generateOrders(data) {
    let reOrders = data.map((order) => {
      let releaseAmount = getReleaseAmount(
        order.order_item_number,
        order.order_id
      );

      let directoryValue = directory.find(
        (d) =>
          d.shop_name === order.product_name &&
          d.laz_price === order.lazada_price
      );

      let isThisPaidRTS =
        ["Returned", "Cancelled"].includes(order.order_status) &&
        ["Lost Inventory", "Charge To Employee", "Paid"].includes(
          order.remarks
        );

      let isThisPaid = releaseAmount.paid_status === "paid";

      let newData = {
        ...order,
        globe_discount: isThisPaid ? order.globe_discount : 0,
        smart_discount: isThisPaid ? order.smart_discount : 0,
        isThisPaidRTS: isThisPaidRTS,
        isThisPaid: isThisPaid,
        gate_price: assignedGatePrice(
          order.order_status,
          order.remarks,
          order.gate_price
        ),
        status: releaseAmount.paid_status,
        payment_waiting: isThisPaid
          ? "--"
          : ["Returned", "Cancelled", "Refunded"].includes(order.order_status)
          ? "--"
          : 15 -
              Math.ceil(
                getDateDifference(new Date(order.order_date), new Date())
              ) <=
            0
          ? "Request for Payment"
          : `${
              15 -
              Math.ceil(
                getDateDifference(new Date(order.order_date), new Date())
              )
            } DAYS LEFT`,
        overPay: releaseAmount.over_pay,
        paymentRelease: isThisPaidRTS
          ? order.gate_price
          : releaseAmount.payment_release,
        commissionFee: isThisPaidRTS
          ? 0
          : releaseAmount.order_status === "Lost - PR"
          ? releaseAmount.undercharge_commission_fee
          : releaseAmount.commission_fee,
        paymentFee: isThisPaidRTS
          ? 0
          : releaseAmount.order_status === "Lost - PR"
          ? releaseAmount.undercharge_payment_fee
          : releaseAmount.payment_fee,
        payment_match: releaseAmount.payment_match,
        deliveryStatus:
          releaseAmount.order_status !== "--"
            ? releaseAmount.order_status
            : "--",
        gate_status: order.order_status,
        item_code: directoryValue ? directoryValue.item_code : "--",
        variant_name: directoryValue ? directoryValue.variant_name : "--",
        supplier: directoryValue ? directoryValue.telco : "--",
        amount_release: isThisPaidRTS
          ? order.gate_price
          : parseInt(releaseAmount.payment_release) -
            parseInt(releaseAmount.commission_fee) -
            parseInt(releaseAmount.payment_fee),
      };

      return newData;
    });

    // console.log(reOrders);
    // console.log(reOrders.filter((d) => d.order_id === "*482843347949037"));

    return reOrders;
  }

  function generateDR(data) {
    let newDRlist = data
      .filter((o) => o.SI_number === "--")
      .map((d) => d.dr_number);

    let filtered = newDRlist.filter((v, i) => newDRlist.indexOf(v) == i);

    let list = filtered.map((dr, index) => {
      let newData = data.filter((d) => d.dr_number === filtered[index]);

      return {
        id: index,
        dr_number: dr,
        SI_number: newData[0].SI_number,
        SI_Date: newData[0].SI_Date,
        DR_year: newData[0].DR_year,
        DR_month: newData[0].DR_month,
        gate_price: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.gate_price),
          0
        ),
        globe_discount: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.globe_discount),
          0
        ),
        smart_discount: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.smart_discount),
          0
        ),
        paymentRelease: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.paymentRelease),
          0
        ),
        commissionFee: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.commissionFee),
          0
        ),
        paymentFee: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.paymentFee),
          0
        ),
        balance:
          newData.reduce((a, b) => parseInt(a) + parseInt(b.gate_price), 0) -
          (newData.reduce(
            (a, b) => parseInt(a) + parseInt(b.paymentRelease),
            0
          ) +
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.globe_discount),
              0
            ) +
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.smart_discount),
              0
            ) -
            newData.reduce((a, b) => parseInt(a) + parseInt(b.overPay), 0)),
        amount_release: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.amount_release),
          0
        ),
        overPay: newData.reduce((a, b) => parseInt(a) + parseInt(b.overPay), 0),
      };
    });

    return list;
  }

  function generateSI(data) {
    let SIlist = data
      .filter((o) => o.SI_number !== "--")
      .map((d) => d.dr_number);
    let filtered = SIlist.filter((v, i) => SIlist.indexOf(v) == i);

    let list = filtered.map((dr, index) => {
      let newData = data.filter((d) => d.dr_number === filtered[index]);
      return {
        id: index,
        dr_number: dr,
        SI_number: newData[0].SI_number,
        SI_Date: newData[0].SI_Date,
        DR_year: newData[0].DR_year,
        DR_month: newData[0].DR_month,
        gate_price: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.gate_price),
          0
        ),
        globe_discount: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.globe_discount),
          0
        ),
        smart_discount: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.smart_discount),
          0
        ),
        paymentRelease: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.paymentRelease),
          0
        ),
        commissionFee: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.commissionFee),
          0
        ),
        paymentFee: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.paymentFee),
          0
        ),
        balance:
          newData.reduce((a, b) => parseInt(a) + parseInt(b.gate_price), 0) -
          (newData.reduce(
            (a, b) => parseInt(a) + parseInt(b.paymentRelease),
            0
          ) +
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.globe_discount),
              0
            ) +
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.smart_discount),
              0
            )),
        amount_release: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.amount_release),
          0
        ),
      };
    });

    return list;
  }

  function generateSummaryReport(data) {
    let list = [];
    for (let index = 0; index < data.length; index++) {
      if (
        data[index].order_status === "Returned" &&
        data[index].remarks === "Inventory"
      ) {
        list.push(`FOR RETURN - ${data[index].variant_name}`);
      } else if (
        data[index].order_status === "Cancelled" &&
        data[index].remarks === "Inventory"
      ) {
        list.push(`FOR RETURN - ${data[index].variant_name}`);
      } else if (
        data[index].order_status === "Cancelled" &&
        data[index].remarks === "Waiting"
      ) {
        list.push(`FOR RETURN - ${data[index].variant_name}`);
      } else if (
        data[index].order_status === "Returned" &&
        data[index].remarks === "Waiting"
      ) {
        list.push(`FOR RETURN - ${data[index].variant_name}`);
      } else if (
        data[index].order_status === "Returned" &&
        data[index].remarks === "Lost Inventory"
      ) {
        list.push(`LOST INVENTORY - ${data[index].variant_name}`);
      } else if (
        data[index].order_status === "Returned" &&
        data[index].remarks === "Charge To Employee"
      ) {
        list.push(`CHARGE TO EMPLOYEE - ${data[index].variant_name}`);
      } else {
        list.push(data[index].variant_name);
      }
    }

    let variant_list = list.filter((v, i) => list.indexOf(v) == i);

    let newList = [];
    for (let index = 0; index < variant_list.length; index++) {
      newList.push({
        name: variant_list[index],
        qty: list.filter((l) => l === variant_list[index]).length,
        code: directory.find(
          (d) =>
            d.variant_name ===
            variant_list[index]
              .replace("FOR RETURN - ", "")
              .replace("LOST INVENTORY - ", "")
              .replace("CHARGE TO EMPLOYEE - ", "")
        )
          ? directory.find(
              (d) =>
                d.variant_name ===
                variant_list[index]
                  .replace("FOR RETURN - ", "")
                  .replace("LOST INVENTORY - ", "")
                  .replace("CHARGE TO EMPLOYEE - ", "")
            ).item_code
          : "--",
      });
    }

    return newList;
  }

 

  function updateItemStatus(status, remarks, order_item_number) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/lazada/update_item_status`,
          {
            order_status: status,
            remarks: remarks,
            order_item_number: order_item_number,
            last_edit: loginSession.username,
          }
        )
        .then(function (response) {
          getOrders();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function assignedSINumber(dr_number, si_number, si_date) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/lazada/assigned_si_number`,
          {
            dr_number: `*${dr_number}`,
            SI_number: si_number,
            SI_Date: dateFormat(si_date),
            last_edit: loginSession.username,
          }
        )
        .then(function (response) {
          getOrders();
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getPaymentRelease() {
    if (order_id !== "") {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/lazada/get_summarized_release_by_id`,
          {
            order_id: order_id,
            month: month ? month : "August",
            year: year,
          }
        )
        .then(function (response) {
          // handle success
          let data = response.data;
          setPaymentRelease(data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    } else if (dr_number !== "") {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/lazada/get_summarized_release_by_dr`,
          {
            dr_number: dr_number,
            month: month ? month : "August",
            year: year,
          }
        )
        .then(function (response) {
          // handle success
          let data = response.data;
          setPaymentRelease(data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    } else if ((month !== "") & (year !== "")) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/lazada/get_summarized_release`,
          {
            month: month ? month : "August",
            year: year,
          }
        )
        .then(function (response) {
          // handle success
          let data = response.data;
          setPaymentRelease(data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    } else {
      axios
        .get(
          `${config.host}:${config.port}/api/v2/lazada/get_lazada_payment_release`
        )
        .then(function (response) {
          // handle success
          let data = response.data;
          setPaymentRelease(data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getRTSList() {
    if ((month !== "") & (year !== "")) {
      axios
        .post(`${config.host}:${config.port}/api/v2/lazada/get_rts`, {
          month: month ? month : "August",
          year: year,
        })
        .then(function (response) {
          // handle success
          let data = response.data;
          setRTSList(data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  useEffect(() => {
    getPaymentRelease();
    getRTSList();
  }, [year, month]);

  useEffect(() => {
    getOrders();
  }, [paymentRelease, month, year]);

  useEffect(() => {
    setDrList(generateDR(orders));
    setSIList(generateSI(orders));
  }, [orders, paymentRelease, month, year]);

  return {
    orders,
    getOrders,
    reports,
    paymentRelease,
    drList,
    sIList,
    updateStatus,
    setUpdateStatus,
    updateItemStatus,
    assignedSINumber,
    generateSummaryReport,
    RTSList,
  };
}

export default useLazadaAPI;
