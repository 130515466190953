import { useEffect, useState, useMemo } from "react";
import useUsers from "../Users/useUsers";
import use711Stores from "./use711Stores";
import useQueryURL from "./../../services/useQueryURL";
import dateFormat from "dateformat";
import config, { CURRENT_YEAR } from "../../../config";
import axios from "axios";

function use711Calls() {
  const { user, loginSession } = useUsers();
  const { stores } = use711Stores();
  const { getQuery } = useQueryURL();

  const [calls, setCalls] = useState([]);
  const [allCallLogs, setAllCallLogs] = useState([]);
  const [year, setYear] = useState(CURRENT_YEAR);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    setYear(getQuery("year") ? getQuery("year") : CURRENT_YEAR);
    setStartDate(
      getQuery("dates") ? new Date(getQuery("dates").split("|")[0]) : new Date()
    );
    setEndDate(
      getQuery("dates") ? new Date(getQuery("dates").split("|")[1]) : new Date()
    );
  }, []);

  function getStoreCallLogs(store_number) {
    if (loginSession) {
      axios
        .post(`${config.host}:${config.port}/api/v2/711/store_call_logs`, {
          store_number: store_number,
          user: loginSession.username,
        })
        .then(function (response) {
          let data = response.data;
          setCalls(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getAllCallLogs(data) {
    // const { startDate, endDate } = data;
    if (loginSession) {
      axios
        .post(`${config.host}:${config.port}/api/v2/711/all_call_logs`, {
          year: parseInt(year),
          // startDate: new Date(startDate),
          startDate: dateFormat(data.startDate, "yyyy-mm-dd'T'HH:MM:sso"),
          endDate: dateFormat(data.endDate, "yyyy-mm-dd'T'HH:MM:sso"),
          month: parseInt(dateFormat(data.startDate, "m")),
          user: loginSession.username,
        })
        .then(function (response) {
          let data = response.data;
          setAllCallLogs(data);
          console.log(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function addCallLogs(data, actions) {
    const {
      date_order,
      store_id,
      amount,
      remarks,
      notes,
      allocation,
      // allocation_remarks,
      // received_by,
      call_status,
      fastSKU,
      slowSKU,
      notCarryingSKU,
      cliqqStatus,
      callSource,
      store,
    } = data;

    axios
      .post(`${config.host}:${config.port}/api/v2/711/add_call_logs`, {
        store_id: store_id,
        store_number: parseInt(store.store_number.replace("*", "")),
        store_name: store.store_name,
        telemarketer: store.telemarketer,
        account_executive: store.account_executive,
        schedule: store.schedule,
        date_order: date_order,
        amount: amount ?? 0,
        remarks: remarks ?? "",
        notes: notes ?? "",
        allocation: allocation ?? "",
        allocation_remarks: "",
        received_by: /*received_by*/ user.full_name,
        call_status: call_status ?? "Pending",
        fastSKU: fastSKU,
        slowSKU: slowSKU,
        notCarryingSKU: notCarryingSKU,
        cliqqStatus: cliqqStatus,
        callSource: callSource,
        last_edit: loginSession.username,
      })
      .then(function (response) {
        // console.log(response);
        actions();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function updateCallLogs(
    selectedCallLog,
    amount,
    remarks,
    notes,
    allocation,
    // allocation_remarks,
    // received_by,
    call_status,
    fastSKU,
    slowSKU,
    notCarryingSKU,
    cliqqStatus,
    date_order,
    callSource,
    actions
  ) {
    axios
      .post(`${config.host}:${config.port}/api/v2/711/update_call_logs`, {
        id: selectedCallLog,
        amount: amount,
        remarks: remarks,
        notes: notes,
        allocation: allocation,
        call_status: call_status,
        date_order: date_order,
        fastSKU: fastSKU,
        slowSKU: slowSKU,
        notCarryingSKU: notCarryingSKU,
        cliqqStatus: cliqqStatus,
        callSource: callSource,
        last_edit: loginSession.username,
      })
      .then(function (response) {
        // console.log(response);
        actions();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function deleteCallLogs(id, actions) {
    axios
      .post(`${config.host}:${config.port}/api/v2/711/delete_call_logs`, {
        id: id,
        last_edit: loginSession.username,
      })
      .then(function (response) {
        // console.log(response);
        actions();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // const allCallLogsMemo = useMemo(() => getAllCallLogs())
  // useEffect(() => {
  //   getAllCallLogs();
  // }, [stores, year, startDate, endDate]);

  return {
    calls,
    getStoreCallLogs,
    addCallLogs,
    updateCallLogs,
    deleteCallLogs,
    allCallLogs,
    getAllCallLogs,
  };
}

export default use711Calls;
