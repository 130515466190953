import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Calendar from "./Calendar";
import MenuHeader from "../../components/MenuHeader";
import Results from "./Results";

//hooks
import useHRTool from "../../hooks/api/HR/useHRTool";
import useCustomFunctions from "../../hooks/services/useCustomFunctions";
import useUsers from "../../hooks/api/Users/useUsers";
import { Loader } from "tabler-react";

const Index = () => {
  const { user } = useUsers();

  const [accessLevel, setAccessLevel] = useState("none");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "hr-tool").access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const { getInBetweenDates } = useCustomFunctions();
  const {
    employees,
    report,
    generateReport,
    getApprovedLeaves,
    approvedLeaves,
    getApprovedOB,
    approvedOB,
    approvedOT,
    approvedCTO,
    approvedROT,
    getApprovedCTO,
    getApprovedOT,
    getApprovedROT,
  } = useHRTool();


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [holidays, setHolidays] = useState([]);
  const [timeKeeping, setTimeKeeping] = useState([]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      generateReport(timeKeeping, getInBetweenDates(startDate, endDate, "dtr"));
      getApprovedLeaves(startDate, endDate);
      getApprovedOB(startDate, endDate);
      getApprovedOT(startDate, endDate);
      getApprovedCTO(startDate, endDate);
      getApprovedROT(startDate, endDate);
    }
  }, [timeKeeping, startDate, endDate]);

  const [filter1, setFilter1] = useState("");

  const [edit, setEdit] = useState(false);
  return (
    <>
      <Helmet>
        <title>GMS - HR & Timekeeping Tool</title>
      </Helmet>
      {/* <MenuHeader data={{ user }} /> */}
      <div style={{ margin: "5px" }}>
        <Calendar
          data={{
            startDate,
            endDate,
            holidays,
            timeKeeping,
            setStartDate,
            setEndDate,
            setHolidays,
            setTimeKeeping,
            getInBetweenDates,
            report,
            generateReport,
            filter1,
            setFilter1,
          }}
        />
        <p style={{ pageBreakAfter: "always" }}></p>

        {report.length != 0 ? (
          <Results
            data={{ filter1, employees, report, startDate, endDate, user }}
          />
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default Index;
