import React from "react";
import { Card } from "tabler-react";

const EditTaskModal = (props) => {
  const { items, setItems, selectedItemID } = props.data;

  return (
    <>
      <div className="modal-summary">
        <Card className="modal-summary-content-container">
          {items
            .filter((item) => item.id === selectedItemID)
            .map((item, index) => (
              <>
                <Card.Header>{item.name}</Card.Header>
                <Card.Body>
                    
                </Card.Body>
              </>
            ))}
        </Card>
      </div>
    </>
  );
};

export default EditTaskModal;
