import { useState, useEffect } from "react";
import axios from "axios";
import config from "./../../../config";

import use711Stores from "../../api/AMS/use711Stores";
import useUsers from "../Users/useUsers";

function useOnlineOrders() {
  const { user } = useUsers();
  const { stores } = use711Stores();
  const [products711, setProducts711] = useState([]);
  const [onlineOrders, setOnlineOrders] = useState([]);
  const [webOrders, setWebOrders] = useState([]);

  function getProducts711() {
    axios
      .get(`${config.host}:${config.port}/api/v2/main_directory/products711`)
      .then(function (response) {
        let data = response.data;
        setProducts711(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getOnlineORders() {
    axios
      .get(`${config.host}:${config.port}/api/v2/online_orders/web_orders`)
      .then(function (response) {
        let data = response.data;
        console.log(data);
        if (data.length != 0) {
          setOnlineOrders(
            data.map((d) => ({ ...d, orders: JSON.parse(d.orders) }))
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getWebORders() {
    axios
      .get(`${config.host}:${config.port}/api/v2/online_orders/web_orders`)
      .then(function (response) {
        console.log(response);
        let data = response.data;
        if (data.length != 0 && stores.length != 0) {
          setWebOrders(
            data
              .map((d) => ({ ...d, orders: JSON.parse(d.orders) }))
              .map((d) => ({
                ...d,
                telemarketer: stores.find(
                  (s) => s.store_number === d.store_number
                ).telemarketer,
                store_name: stores.find(
                  (s) => s.store_number === d.store_number
                ).store_name,
                store: stores.find((s) => s.store_number === d.store_number),
              }))
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function approvedWebOrders(order_id, status) {
    axios
      .post(
        `${config.host}:${config.port}/api/v2/online_orders/approve_web_orders`,
        {
          order_id: order_id,
          status: status,
          user: user.full_name,
        }
      )
      .then(function (response) {
        // window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  console.log(webOrders);

  useEffect(() => {
    getProducts711();
    // getOnlineORders();
    getWebORders();
  }, [stores]);

  return {
    products711,
    onlineOrders,
    webOrders,
    approvedWebOrders,
  };
}

export default useOnlineOrders;
