import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExportCallLogs() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadCallLogs(calls) {
    var headers = {
      store_number: "store_number",
      store_name: "store_name",
      location: "loation",
      telemarketer: "telemarketer",
      account_executive: "account_executive",
      schedule: "schedule",
      date_order: "date_order",
      amount: "amount",
      remarks: "remarks",
      notes: "notes",
      courier_status: "courier_status",
      allocation: "allocation",
      allocation_remarks: "allocation_remarks",
      received_by: "received_by",
      fast_sku: "fast_sku",
      slow_sku: "slow_sku",
      
      not_carrying_sku: "not_carrying_sku",
      cliqq_status: "cliqq_status",
      cliqq_notes: "cliqq_notes",
      call_status: "call_status",
      call_source: "source"
    };

    var itemsNotFormatted = calls;
    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        store_number: `${item.store_number}`,
        store_name: item.store_name.replace(/(\r\n|\n|\r|,)/gm, ""),
        location: item.location,
        telemarketer: item.telemarketer,
        account_executive: item.account_executive,
        schedule: item.schedule,
        date_order: dateFormat(
          new Date(item.date_order),
          "mm/dd/yyyy HH:MM:ss"
        ),
        amount: item.amount.replace(/(\r\n|\n|\r|,)/gm, ""),
        remarks: item.remarks.replace(/(\r\n|\n|\r|,)/gm, ""),
        notes: item.notes.replace(/(\r\n|\n|\r|,)/g, ""),
        courier_status: item.courier_status,
        allocation: item.allocation,
        allocation_remarks: item.allocation_remarks,
        received_by: item.received_by,
        fast_sku: item.fastSKU ? item.fastSKU.replace(
          /GLOBE_|SMART_|CIGNAL_|DITO_|EPINS_/gm,
          ""
        ) : "",
        slow_sku: item.slowSKU ? item.slowSKU.replace(
          /GLOBE_|SMART_|CIGNAL_|DITO_|EPINS_/gm,
          ""
        ) : "",
        not_carrying_sku: item.notCarryingSKU ? item.notCarryingSKU.replace(
          /GLOBE_|SMART_|CIGNAL_|DITO_|EPINS_/gm,
          ""
        ) : "",
        cliqq_status: item.cliqqStatus || "",
        cliqq_notes:
          item.notes.replace(/(\r\n|\n|\r|,)/gm, "").split("##")[1] || "",
        call_status: item.call_status,
        call_source: item.callSource
      });
    });

    var fileTitle = `711_Stores_Call_Logs_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return { downloadCallLogs };
}

export default useExportCallLogs;
