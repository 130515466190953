import React, { useState, useEffect } from "react";
import { Card, Loader, Form, Grid, Button, Icon } from "tabler-react";
import dateFormat from "dateformat";
import usePreferences from "../../../../../hooks/api/AMS/usePreferences";
import { PRODUCTS } from "../../../../../hooks/api/AMS/product_list";

const StoreCallLogAddForm = (props) => {
  const { data } = props;
  const {
    accessLevel,
    calls,
    store,
    callLog,
    addCallLogs,
    updateCallLogs,
    selectedCallLog,
    setSelectedCallLog,
    viewCall,
    setViewCall,
    editCall,
    setEditCall,
    addCall,
    setAddCall,
    storeID,
    getStoreCallLogs,
    setCallLogSuccess,
  } = data;

  const { preferences } = usePreferences();

  const [amount, setAmount] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [notes, setNotes] = useState("");
  const [allocation, setAllocation] = useState("");
  const [call_status, setCallStatus] = useState("");
  const [orderDate, setOrderDate] = useState(new Date());

  //NEw
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedProductSlow, setSelectedProductSlow] = useState("");
  const [fastSKU, setFastSKU] = useState([]);
  const [slowSKU, setSlowSKU] = useState([]);

  // const [loading, setLoading] = useState(true);

  const [remarkPref, setRemarksPref] = useState([]);
  const [allocationPref, setAllocationPref] = useState([]);

  //Additional
  const [cliqqStatus, setCliqqStatus] = useState("");
  const [notCarryingSKU, setNotCarryingSKU] = useState("");

  const [callSource, setCallSource] = useState("");

  useEffect(() => {
    if (callLog.length != 0) {
      setAmount(callLog ? callLog.amount : 0);
      setRemarks(callLog ? callLog.remarks : "");
      setNotes(callLog ? callLog.notes : "");
      setAllocation(callLog ? callLog.allocation : "");
      setCallStatus(callLog ? callLog.call_status : "");
      setOrderDate(callLog ? new Date(callLog.date_order) : new Date());
      setFastSKU(
        callLog ? (callLog.fastSKU ? callLog.fastSKU.split("|") : []) : []
      );
      setSlowSKU(
        callLog ? (callLog.slowSKU ? callLog.slowSKU.split("|") : []) : []
      );
      setNotCarryingSKU(
        callLog
          ? callLog.notCarryingSKU
            ? callLog.notCarryingSKU.split("|")
            : []
          : []
      );
      setCliqqStatus(callLog ? callLog.cliqqStatus : "");
      setCallSource(callLog ? callLog.callSource : "");
    }

    console.log(callLog);
  }, [callLog]);

  useEffect(() => {
    setRemarksPref(
      preferences
        ? preferences.filter(
            (ref) => ref.type === "remarks" && ref.store_type == 0
          )
        : []
    );
    setAllocationPref(
      preferences
        ? preferences.filter(
            (ref) => ref.type === "allocation" && ref.store_type == 0
          )
        : []
    );
  }, [preferences]);

  const [currentSKU, setCurrentSKU] = useState([]);
  const [openSKU, setOpenSKU] = useState(false);

  useEffect(() => {
    setCurrentSKU(
      calls.filter(
        (call) => call.fastSKU || call.slowSKU || call.notCarryingSKU
      )
        ? calls
            .filter(
              (call) => call.fastSKU || call.slowSKU || call.notCarryingSKU
            )
            .map((call) => ({
              ...call,
              fastSKU: call.fastSKU
                ? call.fastSKU.split("|").map((product) => ({
                    brand: product.split("_")[0],
                    sku: product.split("_")[1],
                    value: product,
                  }))
                : [],
              slowSKU: call.slowSKU
                ? call.slowSKU.split("|").map((product) => ({
                    brand: product.split("_")[0],
                    sku: product.split("_")[1],
                    value: product,
                  }))
                : [],
              notCarryingSKU: call.notCarryingSKU
                ? call.notCarryingSKU.split("|").map((product) => ({
                    brand: product.split("_")[0],
                    sku: product.split("_")[1],
                    value: product,
                  }))
                : [],
            }))[
            calls.filter(
              (call) => call.fastSKU || call.slowSKU || call.notCarryingSKU
            ).length - 1
          ]
        : []
    );
  }, [calls]);

  const [productList, setProductList] = useState([]);

  useEffect(() => {
    setProductList(
      PRODUCTS.map((product) => ({
        brand: product.split("_")[0],
        sku: product.split("_")[1],
        value: product,
      }))
    );
  }, [PRODUCTS]);

  const [skuFilter, setSKUfilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const [selectedSKUs, setSeletedSKUs] = useState([]);

  const [openNotCModal, setOpenNotCModal] = useState(false);

  return (
    <>
      {openModal ? (
        <div className="modal-achievement">
          <Card className="modal-achievement-content-container">
            <Card.Header>
              {modalType === "fast"
                ? "PLEASE SELECT FAST SKU's"
                : "PLEASE SELECT SLOW SKU's"}
              <Card.Options>
                {currentSKU ? (
                  <Button
                    color="teal"
                    onClick={() =>
                      modalType === "fast"
                        ? setSeletedSKUs(
                            currentSKU.fastSKU
                              .filter(
                                (product) =>
                                  !currentSKU.notCarryingSKU
                                    .concat(currentSKU.slowSKU)
                                    .map((p) => p.value)
                                    .concat(
                                      notCarryingSKU
                                        .concat(slowSKU)
                                        .map((p) => p)
                                    )
                                    .includes(product.value)
                              )
                              .map((product) => product.value)
                          )
                        : setSeletedSKUs(
                            currentSKU.slowSKU
                              .filter(
                                (product) =>
                                  !currentSKU.notCarryingSKU
                                    .concat(currentSKU.fastSKU)
                                    .map((p) => p.value)
                                    .concat(
                                      fastSKU
                                        .concat(notCarryingSKU)
                                        .map((p) => p)
                                    )
                                    .includes(product.value)
                              )
                              .map((product) => product.value)
                          )
                    }
                    disabled={
                      (modalType === "fast"
                        ? currentSKU.fastSKU
                            .filter(
                              (product) =>
                                !currentSKU.notCarryingSKU
                                  .concat(currentSKU.slowSKU)
                                  .map((p) => p.value)
                                  .concat(
                                    slowSKU.concat(notCarryingSKU).map((p) => p)
                                  )
                                  .includes(product.value)
                            )
                            .map((product) => product.value).length === 0
                        : currentSKU.slowSKU
                            .filter(
                              (product) =>
                                !currentSKU.notCarryingSKU
                                  .concat(currentSKU.fastSKU)
                                  .map((p) => p.value)
                                  .concat(
                                    fastSKU.concat(notCarryingSKU).map((p) => p)
                                  )
                                  .includes(product.value)
                            )
                            .map((product) => product.value).length === 0) ||
                      viewCall
                    }
                  >
                    Copy Previous SKU's
                  </Button>
                ) : (
                  ""
                )}

                {currentSKU ? (
                  <Button color="warning" onClick={() => setOpenSKU(true)}>
                    View Updated SKU's
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  color="secondary"
                  onClick={() => setOpenModal(false) || setSeletedSKUs([])}
                >
                  Close
                </Button>
              </Card.Options>
            </Card.Header>
            <Card.Body>
              <Grid.Row>
                {viewCall ? (
                  ""
                ) : (
                  <>
                    <Grid.Col md={6}>
                      <Form.Select
                        value={skuFilter}
                        onChange={(e) => setSKUfilter(e.target.value)}
                      >
                        <option value="">SELECT ALL</option>

                        {productList
                          .filter((product) =>
                            modalType === "fast"
                              ? !slowSKU
                                  .concat(notCarryingSKU)
                                  .includes(product.value)
                              : !fastSKU
                                  .concat(notCarryingSKU)
                                  .includes(product.value)
                          )
                          .map((t) => t.brand)
                          .filter(
                            (v, i) =>
                              productList
                                .filter((product) =>
                                  modalType === "fast"
                                    ? !slowSKU
                                        .concat(notCarryingSKU)
                                        .includes(product.value)
                                    : !fastSKU
                                        .concat(notCarryingSKU)
                                        .includes(product.value)
                                )
                                .map((t) => t.brand)
                                .indexOf(v) == i
                          )
                          .map((brand) => (
                            <option value={brand}>{brand}</option>
                          ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col md={6}>
                      <Form.Input
                        value={searchFilter}
                        onChange={(e) => setSearchFilter(e.target.value)}
                        placeholder="search for sku"
                      />
                    </Grid.Col>
                    <Grid.Col md={12}>
                      <Form.Checkbox
                        label={
                          productList
                            .filter((product) =>
                              skuFilter ? product.brand === skuFilter : product
                            )
                            .filter((product) =>
                              modalType === "fast"
                                ? !slowSKU
                                    .concat(notCarryingSKU)
                                    .includes(product.value)
                                : !fastSKU
                                    .concat(notCarryingSKU)
                                    .includes(product.value)
                            )
                            .map((product) => product.value).length ===
                          selectedSKUs.length
                            ? "UNSELECT ALL"
                            : "SELECT ALL"
                        }
                        checked={
                          productList
                            .filter((product) =>
                              skuFilter ? product.brand === skuFilter : product
                            )
                            .filter((product) =>
                              modalType === "fast"
                                ? !slowSKU
                                    .concat(notCarryingSKU)
                                    .includes(product.value)
                                : !fastSKU
                                    .concat(notCarryingSKU)
                                    .includes(product.value)
                            )
                            .map((product) => product.value).length ===
                          selectedSKUs.length
                        }
                        onChange={() =>
                          setSeletedSKUs(
                            productList
                              .filter((product) =>
                                skuFilter
                                  ? product.brand === skuFilter
                                  : product
                              )
                              .filter((product) =>
                                modalType === "fast"
                                  ? !slowSKU
                                      .concat(notCarryingSKU)
                                      .includes(product.value)
                                  : !fastSKU
                                      .concat(notCarryingSKU)
                                      .includes(product.value)
                              )
                              .map((product) => product.value).length ===
                              selectedSKUs.length
                              ? []
                              : productList
                                  .filter((product) =>
                                    skuFilter
                                      ? product.brand === skuFilter
                                      : product
                                  )
                                  .filter((product) =>
                                    modalType === "fast"
                                      ? !slowSKU
                                          .concat(notCarryingSKU)
                                          .includes(product.value)
                                      : !fastSKU
                                          .concat(notCarryingSKU)
                                          .includes(product.value)
                                  )
                                  .map((product) => product.value)
                          )
                        }
                        disabled={searchFilter !== ""}
                      />
                    </Grid.Col>
                  </>
                )}
              </Grid.Row>
            </Card.Body>
            <Card.Body>
              <div style={{ maxHeight: "60vh", overflow: "auto" }}>
                {productList
                  .filter((product) =>
                    skuFilter ? product.brand === skuFilter : product
                  )
                  .filter((product) =>
                    searchFilter !== ""
                      ? product.sku
                          .toLowerCase()
                          .includes(searchFilter.toLowerCase())
                      : product
                  )
                  .filter((product) =>
                    modalType === "fast"
                      ? !slowSKU.concat(notCarryingSKU).includes(product.value)
                      : !fastSKU.concat(notCarryingSKU).includes(product.value)
                  )
                  .map((product) => (
                    <Form.Checkbox
                      label={product.sku}
                      value={product.value}
                      onChange={() =>
                        setSeletedSKUs(
                          selectedSKUs.includes(product.value)
                            ? selectedSKUs.filter(
                                (sku) => sku !== product.value
                              )
                            : [...selectedSKUs, product.value]
                        )
                      }
                      checked={selectedSKUs.includes(product.value)}
                      disabled={viewCall}
                    />
                  ))}
              </div>
            </Card.Body>
            {viewCall ? (
              ""
            ) : (
              <Card.Body>
                <Button
                  color="cyan"
                  onClick={() =>
                    (modalType === "fast"
                      ? setFastSKU(selectedSKUs)
                      : setSlowSKU(selectedSKUs)) ||
                    setOpenModal(false) ||
                    setSeletedSKUs([]) ||
                    setSKUfilter("")
                  }
                >
                  SAVE
                </Button>
              </Card.Body>
            )}
          </Card>
        </div>
      ) : (
        ""
      )}

      {openNotCModal ? (
        <div className="modal-achievement">
          <Card className="modal-achievement-content-container">
            <Card.Header>
              NOT CARRYING SKU's
              <Card.Options>
                {currentSKU ? (
                  <Button
                    color="teal"
                    onClick={() =>
                      setSeletedSKUs(
                        currentSKU.notCarryingSKU
                          .filter(
                            (product) =>
                              !currentSKU.fastSKU
                                .concat(currentSKU.slowSKU)
                                .map((p) => p.value)
                                .concat(fastSKU.concat(slowSKU).map((p) => p))
                                .includes(product.value)
                          )
                          .map((product) => product.value)
                      )
                    }
                    disabled={
                      currentSKU.notCarryingSKU
                        .filter(
                          (product) =>
                            !currentSKU.fastSKU
                              .concat(currentSKU.slowSKU)
                              .map((p) => p.value)
                              .concat(fastSKU.concat(slowSKU).map((p) => p))
                              .includes(product.value)
                        )
                        .map((product) => product.value).length === 0 ||
                      viewCall
                    }
                  >
                    Copy Previous SKU's
                  </Button>
                ) : (
                  ""
                )}

                {currentSKU ? (
                  <Button color="warning" onClick={() => setOpenSKU(true)}>
                    View Updated SKU's
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  color="secondary"
                  onClick={() =>
                    setOpenNotCModal(false) ||
                    setSeletedSKUs([]) ||
                    setSKUfilter("")
                  }
                >
                  Close
                </Button>
              </Card.Options>
            </Card.Header>
            <Card.Body>
              {viewCall ? (
                ""
              ) : (
                <Grid.Row>
                  <Grid.Col md={6}>
                    <Form.Select
                      value={skuFilter}
                      onChange={(e) => setSKUfilter(e.target.value)}
                    >
                      <option value="">SELECT ALL</option>

                      {productList
                        .filter(
                          (product) =>
                            !fastSKU.concat(slowSKU).includes(product.value)
                        )
                        .map((t) => t.brand)
                        .filter(
                          (v, i) =>
                            productList
                              .filter(
                                (product) =>
                                  !fastSKU
                                    .concat(slowSKU)
                                    .includes(product.value)
                              )
                              .map((t) => t.brand)
                              .indexOf(v) == i
                        )
                        .map((brand) => (
                          <option value={brand}>{brand}</option>
                        ))}
                    </Form.Select>
                  </Grid.Col>
                  <Grid.Col md={6}>
                    <Form.Input
                      value={searchFilter}
                      onChange={(e) => setSearchFilter(e.target.value)}
                      placeholder="search for sku"
                    />
                  </Grid.Col>
                  <Grid.Col md={12}>
                    <Form.Checkbox
                      label={
                        productList
                          .filter((product) =>
                            skuFilter ? product.brand === skuFilter : product
                          )
                          .filter(
                            (product) =>
                              !fastSKU.concat(slowSKU).includes(product.value)
                          )
                          .map((product) => product.value).length ===
                        selectedSKUs.length
                          ? "UNSELECT ALL"
                          : "SELECT ALL"
                      }
                      checked={
                        productList
                          .filter((product) =>
                            skuFilter ? product.brand === skuFilter : product
                          )
                          .filter(
                            (product) =>
                              !fastSKU.concat(slowSKU).includes(product.value)
                          )
                          .map((product) => product.value).length ===
                        selectedSKUs.length
                      }
                      onChange={() =>
                        setSeletedSKUs(
                          productList
                            .filter((product) =>
                              skuFilter ? product.brand === skuFilter : product
                            )
                            .filter(
                              (product) =>
                                !fastSKU.concat(slowSKU).includes(product.value)
                            )
                            .map((product) => product.value).length ===
                            selectedSKUs.length
                            ? []
                            : productList
                                .filter((product) =>
                                  skuFilter
                                    ? product.brand === skuFilter
                                    : product
                                )
                                .filter(
                                  (product) =>
                                    !fastSKU
                                      .concat(slowSKU)
                                      .includes(product.value)
                                )
                                .map((product) => product.value)
                        )
                      }
                      disabled={searchFilter !== ""}
                    />
                  </Grid.Col>
                </Grid.Row>
              )}
            </Card.Body>
            <Card.Body>
              <div style={{ maxHeight: "60vh", overflow: "auto" }}>
                {productList
                  .filter((product) =>
                    skuFilter ? product.brand === skuFilter : product
                  )
                  .filter((product) =>
                    searchFilter !== ""
                      ? product.sku
                          .toLowerCase()
                          .includes(searchFilter.toLowerCase())
                      : product
                  )
                  .filter(
                    (product) =>
                      !fastSKU.concat(slowSKU).includes(product.value)
                  )
                  .map((product) => (
                    <Form.Checkbox
                      label={product.sku}
                      value={product.value}
                      onChange={() =>
                        setSeletedSKUs(
                          selectedSKUs.includes(product.value)
                            ? selectedSKUs.filter(
                                (sku) => sku !== product.value
                              )
                            : [...selectedSKUs, product.value]
                        )
                      }
                      checked={selectedSKUs.includes(product.value)}
                      disabled={viewCall}
                    />
                  ))}
              </div>
            </Card.Body>
            {viewCall ? (
              ""
            ) : (
              <Card.Body>
                <Button
                  color="cyan"
                  onClick={() =>
                    setNotCarryingSKU(selectedSKUs) ||
                    setOpenNotCModal(false) ||
                    setSeletedSKUs([]) ||
                    setSKUfilter("")
                  }
                >
                  SAVE
                </Button>
              </Card.Body>
            )}
          </Card>
        </div>
      ) : (
        ""
      )}

      {openSKU && currentSKU ? (
        <div className="modal-achievement">
          <Card className="modal-achievement-content-container">
            <Card.Header>
              <p>{dateFormat(currentSKU.timestamp, "fullDate")}</p>
              <Card.Options>
                <Button color="secondary" onClick={() => setOpenSKU(false)}>
                  Close
                </Button>
              </Card.Options>
            </Card.Header>

            <Card.Body>
              {modalType === "fast" ? (
                <>
                  <h5>Fast Moving SKU's</h5>
                  {currentSKU.fastSKU.map((product) => (
                    <Form.Checkbox
                      label={product.sku}
                      value={product.value}
                      checked
                    />
                  ))}
                </>
              ) : (
                ""
              )}

              {modalType === "slow" ? (
                <>
                  {currentSKU.slowSKU ? (
                    <>
                      <hr style={{ padding: 5, margin: 0, marginTop: 10 }} />
                      <h5>Slow Moving SKU's</h5>
                      {currentSKU.slowSKU.map((product) => (
                        <Form.Checkbox
                          label={product.sku}
                          value={product.value}
                          checked
                        />
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {modalType === "notCarrying" ? (
                <>
                  {currentSKU.notCarryingSKU ? (
                    <>
                      <hr style={{ padding: 5, margin: 0, marginTop: 10 }} />
                      <h5>Not Carrying SKU's</h5>
                      {currentSKU.notCarryingSKU.map((product) => (
                        <Form.Checkbox
                          label={product.sku}
                          value={product.value}
                          checked
                        />
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        </div>
      ) : (
        ""
      )}
      <>
        {viewCall || editCall || addCall ? (
          <Card>
            <Card.Header>
              {store.store_name} -{" "}
              {callLog.length != 0
                ? viewCall
                  ? "View Only"
                  : "Please Update Call Log"
                : "Add Call Log"}
              <Card.Options>
                <>
                  <Form.Group>
                    <Button
                      color={notCarryingSKU.length !== 0 ? "cyan" : "teal"}
                      onClick={() =>
                        setOpenNotCModal(true) ||
                        setModalType("notCarrying") ||
                        setSeletedSKUs(notCarryingSKU)
                      }
                    >
                      {viewCall ? (
                        "VIEW  NOT CARRYING SKU'S"
                      ) : (
                        <>
                          {notCarryingSKU.length !== 0
                            ? "EDIT NOT CARRYING SKU's"
                            : "ADD NOT CARRYING SKU's"}
                        </>
                      )}
                    </Button>
                  </Form.Group>
                </>

                <>
                  <Form.Group>
                    <Button
                      color={slowSKU.length !== 0 ? "cyan" : "teal"}
                      onClick={() =>
                        setOpenModal(true) ||
                        setModalType("slow") ||
                        setSeletedSKUs(slowSKU)
                      }
                    >
                      {viewCall ? (
                        "VIEW  SLOW MOVING SKU'S"
                      ) : (
                        <>
                          {slowSKU.length !== 0
                            ? "EDIT SLOW MOVING SKU's"
                            : "ADD NEW SLOW MOVING SKU's"}
                        </>
                      )}
                    </Button>
                  </Form.Group>
                </>

                <>
                  <Form.Group>
                    <Button
                      color={fastSKU.length !== 0 ? "cyan" : "teal"}
                      onClick={() =>
                        setOpenModal(true) ||
                        setModalType("fast") ||
                        setSeletedSKUs(fastSKU)
                      }
                    >
                      {viewCall ? (
                        "VIEW  FAST MOVING SKU'S"
                      ) : (
                        <>
                          {fastSKU.length !== 0
                            ? "EDIT FAST MOVING SKU's"
                            : "ADD NEW FAST MOVING SKU's"}
                        </>
                      )}
                    </Button>
                  </Form.Group>
                </>

                <Button
                  color="secondary"
                  onClick={() =>
                    setAmount(0) ||
                    setRemarks("") ||
                    setNotes("") ||
                    setAllocation("") ||
                    setCallStatus("") ||
                    setSelectedCallLog("") ||
                    setViewCall(false) ||
                    setEditCall(false) ||
                    setAddCall(false) ||
                    setFastSKU([]) ||
                    setSlowSKU([]) ||
                    setNotCarryingSKU([]) ||
                    setSelectedProductSlow("") ||
                    setSelectedProduct("") ||
                    setCliqqStatus("")
                  }
                >
                  <Icon prefix="fa" name="close" /> Close
                </Button>
              </Card.Options>
            </Card.Header>
            <Card.Body>
              <Grid.Row>
                <Grid.Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Amount</Form.Label>
                    <Form.Input
                      type="number"
                      placeholder="Please input the order amount"
                      min={0}
                      defaultValue={0}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      disabled={viewCall}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Remarks</Form.Label>
                    <Form.Select
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      disabled={viewCall}
                    >
                      <option value="">CHOOSE ONE</option>
                      {remarkPref.map((remark, index) => (
                        <>
                          {remark.active == 1 ? (
                            <option value={remark.value} key={index}>
                              {remark.value}
                            </option>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Notes</Form.Label>
                    <span style={{ fontSize: "12px" }}>
                      For cliqq Reason, please input your reason at the end of
                      your notes and place ## in the first line.
                    </span>
                    <Form.Textarea
                      type="text"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      disabled={viewCall}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col md={6} sm={12}>
                  <Grid.Row>
                    <Grid.Col md={6}>
                      <Form.Group>
                        <Form.Label>Allocation</Form.Label>

                        <Form.Select
                          value={allocation}
                          onChange={(e) => setAllocation(e.target.value)}
                          disabled={viewCall}
                        >
                          <option value="">CHOOSE ONE</option>
                          {allocationPref.map((allocation, index) => (
                            <>
                              {allocation.active == 1 ? (
                                <option value={allocation.value} key={index}>
                                  {allocation.value}
                                </option>
                              ) : (
                                ""
                              )}
                            </>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6}>
                      {" "}
                      <Form.Group>
                        <Form.Label>CliQQ Kios Status</Form.Label>

                        <Form.Select
                          value={cliqqStatus}
                          onChange={(e) => setCliqqStatus(e.target.value)}
                          disabled={viewCall}
                        >
                          <option value="">CHOOSE ONE</option>
                          <option value="up">UP (Working Properly)</option>
                          <option value="down">
                            DOWN (Not Working/ Disconnected)
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                    
                    <Grid.Col md={6}>
                      <Form.Group>
                        <Form.Label>Source</Form.Label>

                        <Form.Select
                          value={callSource}
                          onChange={(e) => setCallSource(e.target.value)}
                          disabled={viewCall}
                        >
                          <option value="">CHOOSE ONE</option>
                          <option value="Call">Call</option>
                          <option value="Email">Email</option>
                          <option value="SMS">SMS</option>
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>

                    <Grid.Col md={6}>
                      <Form.Group>
                        <Form.Label>Call Status</Form.Label>

                        <Form.Select
                          value={call_status}
                          onChange={(e) => setCallStatus(e.target.value)}
                          disabled={viewCall}
                        >
                          <option value="">CHOOSE ONE</option>
                          <option value="Completed">Completed</option>
                          <option value="Pending">Pending</option>
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>


                    <Grid.Col md={12}>
                      {viewCall ? (
                        <Form.Group>
                          <Form.Label>Order Date</Form.Label>

                          <Form.Input
                            type="text"
                            value={orderDate}
                            disabled={viewCall}
                          />
                        </Form.Group>
                      ) : (
                        <Form.Group>
                          {amount !== "" &&
                          notes !== "" &&
                          remarks !== "" &&
                          // notes !== "" &&
                          call_status !== "" &&
                          selectedCallLog === "" &&
                          addCall ? (
                            <>
                              <Form.Label>
                                Please check the information provided before
                                submitting it.
                              </Form.Label>
                              <Button
                                color="cyan"
                                onClick={() =>
                                  addCallLogs(
                                    {
                                      date_order: dateFormat(
                                        new Date(),
                                        "isoUtcDateTime"
                                      ),
                                      store_id: store.id,
                                      amount,
                                      remarks,
                                      notes,
                                      allocation,
                                      // received_by,
                                      call_status,
                                      fastSKU: fastSKU ? fastSKU.join("|") : "",
                                      slowSKU: slowSKU ? slowSKU.join("|") : "",
                                      notCarryingSKU: notCarryingSKU
                                        ? notCarryingSKU.join("|")
                                        : "",
                                      cliqqStatus,
                                      callSource,
                                      store,
                                    },
                                    () =>
                                      setAmount(0) ||
                                      setRemarks("") ||
                                      setNotes("") ||
                                      setAllocation("") ||
                                      setCallStatus("") ||
                                      setSelectedCallLog("") ||
                                      setViewCall(false) ||
                                      setEditCall(false) ||
                                      setAddCall(false) ||
                                      getStoreCallLogs(storeID) ||
                                      setCallLogSuccess(true) ||
                                      setTimeout(() => {
                                        setCallLogSuccess(false);
                                      }, 2000) ||
                                      setFastSKU([]) ||
                                      setSlowSKU([]) ||
                                      setSelectedProductSlow("") ||
                                      setSelectedProduct("") ||
                                      setCliqqStatus("") ||
                                      setCallSource("")
                                  )
                                }
                              >
                                <Icon prefix="fa" name="save" /> Save
                              </Button>
                            </>
                          ) : (
                            ""
                          )}

                          {amount !== "" &&
                          notes !== "" &&
                          remarks !== "" &&
                          // notes !== "" &&
                          call_status !== "" &&
                          selectedCallLog !== "" &&
                          editCall ? (
                            <>
                              <Form.Label>
                                Please check the information provided before
                                submitting it.
                              </Form.Label>
                              <Button
                                color="cyan"
                                onClick={() =>
                                  updateCallLogs(
                                    selectedCallLog,
                                    amount,
                                    remarks,
                                    notes,
                                    allocation,
                                    call_status,
                                    fastSKU.join("|"),
                                    slowSKU.join("|"),
                                    notCarryingSKU.join("|"),
                                    cliqqStatus,
                                    dateFormat(
                                      new Date(orderDate),
                                      "isoUtcDateTime"
                                    ),
                                    callSource,
                                    () =>
                                      setAmount(0) ||
                                      setRemarks("") ||
                                      setNotes("") ||
                                      setAllocation("") ||
                                      setCallStatus("") ||
                                      setSelectedCallLog("") ||
                                      setViewCall(false) ||
                                      setEditCall(false) ||
                                      setAddCall(false) ||
                                      getStoreCallLogs(storeID) ||
                                      setFastSKU([]) ||
                                      setSlowSKU([]) ||
                                      setSelectedProductSlow("") ||
                                      setSelectedProduct("") ||
                                      setCliqqStatus("") ||
                                      setCallSource("")
                                  )
                                }
                              >
                                <Icon prefix="fa" name="save" /> Update
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </Card>
        ) : (
          ""
        )}
      </>
    </>
  );
};

export default StoreCallLogAddForm;
