import React, { useState, useEffect } from "react";
// import dateFormat from "dateformat";
import { Grid } from "tabler-react";
import Loading from "../../../../../components/Loading/Loading";
import DashboardHeader from "./DashboardHeader";
import DashboardTelemarketerList from "./DashboardTelemarketerList";
import DashboardOrderList from "./DashboardOrderList";
import DashboardReminderList from "./DashboardReminderList";

//hooks
import use711Dashboard from "../../../../../hooks/api/AMS/use711Dashboard";
import useExtractDashboardData from "../../../../../hooks/api/AMS/extractions/useExtractDashboardData";
import useQueryURL from "../../../../../hooks/services/useQueryURL";
import useCreateBackURL from "../../../../../hooks/services/useCreateBackURL";
import useCustomFunctions from "../../../../../hooks/services/useCustomFunctions";
import useUsers from "../../../../../hooks/api/Users/useUsers";
import dateFormat from "dateformat";

const Dashboard = (props) => {
  const { user } = useUsers();
  const { getQuery } = useQueryURL();
  const { backURL } = useCreateBackURL();
  const { downloadDashboardSummaryReport, exportOrderContribution } =
    useExtractDashboardData();
  const { formatNumberComma } = useCustomFunctions();

  const today = dateFormat(new Date(), "dddd");

  const [rangeSelection, setRangeSelection] = useState(
    getQuery("range")
      ? getQuery("range")
      : "Today"
  );
  const { allCalls, stores, callSummary, telemarketers, curWeek } =
    use711Dashboard(rangeSelection);

  const [loading, setLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState("none");

  //side effects
  useEffect(() => {
    setLoading(true);
    if (stores.length != 0 || telemarketers.length != 0) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [stores, telemarketers]);

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "711-account-management")
            .access
        : "none"
    );
  }, [user]);

  return (
    <>
      {!loading ? (
        <>
          <DashboardHeader
            data={{
              callSummary,
              rangeSelection,
              setRangeSelection,
              accessLevel,
            }}
          />
          <Grid.Row>
            <Grid.Col md={12} className="col-header-dashboard">
              <DashboardTelemarketerList
                data={{
                  allCalls,
                  telemarketers,
                  rangeSelection,
                  setRangeSelection,
                  downloadDashboardSummaryReport,
                  backURL,
                  accessLevel,
                  curWeek,
                }}
              />
            </Grid.Col>
            <Grid.Col md={12} className="col-header-dashboard">
              <DashboardOrderList
                data={{
                  loading,
                  telemarketers,
                  rangeSelection,
                  formatNumberComma,
                  exportOrderContribution,
                  accessLevel,
                }}
              />
            </Grid.Col>
            {/* <Grid.Col md={4} className="col-header-dashboard">
              <DashboardReminderList backURL={backURL} />
            </Grid.Col> */}
          </Grid.Row>
        </>
      ) : (
        <div style={{ paddingTop: "15%" }}>
          <Loading />
        </div>
      )}
    </>
  );
};

export default Dashboard;
