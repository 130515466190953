import React, { useEffect, useState } from "react";
import { Card, Button, Icon } from "tabler-react";
import Tooltip from "react-simple-tooltip";
import ShopeeIconWhite from "../../../assets/icon/shopee-white.png";
import LazadaIconWhite from "../../../assets/icon/lazada-white.png";
import TiktokIcon from "../../../assets/icon/tiktok.svg";

import "./menu.css";

const MainMenuSide = (props) => {
  const { data } = props;
  const { user, subApp } = data;

  console.log(subApp);

  return (
    <div className="main-menu-container">
      <Card>
        <Card.Body>
          <Tooltip
            content="Home"
            radius={5}
            padding={5}
            placement="right"
            style={{ whiteSpace: "nowrap", width: "100%" }}
            zIndex={2}
          >
            <Button
              className="menu-button"
              color="cyan"
              onClick={() => window.location.replace("/")}
            >
              <Icon prefix="fa" name="home" />
            </Button>
          </Tooltip>
          <hr />

          {subApp.map((app) =>
            app === "shopee" || app === "all" ? (
              <Tooltip
                content="Shopee Report Generator"
                radius={5}
                padding={5}
                placement="right"
                style={{ whiteSpace: "nowrap", width: "100%" }}
                zIndex={2}
              >
                <Button
                  color="teal"
                  className="menu-button"
                  onClick={() =>
                    window.location.replace("/GBT/shopee-report-generator")
                  }
                >
                  <img src={ShopeeIconWhite} style={{ width: "55%" }} />
                </Button>
              </Tooltip>
            ) : (
              ""
            )
          )}

          {subApp.map((app) =>
            app === "lazada" || app === "all" ? (
              <Tooltip
                content="Lazada Report Generator"
                radius={5}
                padding={5}
                placement="right"
                style={{ whiteSpace: "nowrap", width: "100%" }}
                zIndex={2}
              >
                <Button
                  color="teal"
                  className="menu-button"
                  onClick={() =>
                    window.location.replace("/GBT/lazada-report-generator")
                  }
                >
                  <img src={LazadaIconWhite} style={{ width: "55%" }} />
                </Button>
              </Tooltip>
            ) : (
              ""
            )
          )}

          {subApp.map((app) =>
            app === "gate_store" || app === "all" ? (
              <Tooltip
                content="Online Store"
                radius={5}
                padding={5}
                placement="right"
                style={{ whiteSpace: "nowrap", width: "100%" }}
                zIndex={2}
              >
                <Button
                  color="teal"
                  className="menu-button"
                  onClick={() => window.location.replace("/GBT/online-store")}
                >
                  <Icon
                    prefix="fa"
                    name="shopping-bag"
                    className="menu-btn-icon"
                  />
                </Button>
              </Tooltip>
            ) : (
              ""
            )
          )}

          {subApp.map((app) =>
            app === "tiktok" || app === "all" ? (
              <Tooltip
                content="Tiktok Shop"
                radius={5}
                padding={5}
                placement="right"
                style={{ whiteSpace: "nowrap", width: "100%" }}
                zIndex={2}
              >
                <Button
                  color="teal"
                  className="menu-button"
                  onClick={() =>
                    window.location.replace("/GBT/tiktok-report-generator")
                  }
                >
                  <img
                    src={TiktokIcon}
                    style={{ width: "50%", color: "#fff" }}
                  />
                </Button>
              </Tooltip>
            ) : (
              ""
            )
          )}

          {subApp.map((app) =>
            app === "tiktok-gomo" || app === "all" ? (
              <Tooltip
                content="Tiktok GOMO Shop"
                radius={5}
                padding={5}
                placement="right"
                style={{ whiteSpace: "nowrap", width: "100%" }}
                zIndex={2}
              >
                <Button
                  color="teal"
                  className="menu-button"
                  onClick={() =>
                    window.location.replace("/GBT/tiktok-gomo-report-generator")
                  }
                >
                  GOMO
                </Button>
              </Tooltip>
            ) : (
              ""
            )
          )}

          {subApp.map((app) =>
            app === "serial" || app === "all" ? (
              <Tooltip
                content="Serial Generator"
                radius={5}
                padding={5}
                placement="right"
                style={{ whiteSpace: "nowrap", width: "100%" }}
                zIndex={2}
              >
                <Button
                  color="teal"
                  className="menu-button"
                  onClick={() =>
                    window.location.replace("/GBT/serial-generator")
                  }
                >
                  <Icon prefix="fa" name="barcode" className="menu-btn-icon" />
                </Button>
              </Tooltip>
            ) : (
              ""
            )
          )}

          {/* 

          {subApp.map((app) =>
            app === "waybill" || app === "all" ? (
              <a href="/waybill-generator" className="menu-button">
                <Button color="indigo">Waybill Generator</Button>
              </a>
            ) : (
              ""
            )
          )}
          {subApp.map((app) =>
            app === "checker" || app === "all" ? (
              <a href="/order-checker" className="menu-button">
                <Button color="purple">Order Checker</Button>
              </a>
            ) : (
              ""
            )
          )}
          {subApp.map((app) =>
            app === "directory" || app === "all" ? (
              <a href="/product-directory" className="menu-button">
                <Button color="azure">Gate Variant Directory</Button>
              </a>
            ) : (
              ""
            )
          )}
          {subApp.map((app) =>
            app === "shopee" || app === "all" ? (
              <a href="/shopee-report-generator" className="menu-button">
                <Button color="pink">Shopee Report Generator</Button>
              </a>
            ) : (
              ""
            )
          )}
          {subApp.map((app) =>
            app === "lazada" || app === "all" ? (
              <a href="/lazada-report-generator" className="menu-button">
                <Button color="red">Lazada Report Generator</Button>
              </a>
            ) : (
              ""
            )
          )}
          {subApp.map((app) =>
            app === "serial" || app === "all" ? (
              <a href="/serial-generator" className="menu-button">
                <Button color="orange">Serial Generator</Button>
              </a>
            ) : (
              ""
            )
          )}
          {subApp.map((app) =>
            app === "scanner" || app === "all" ? (
              <a href="/qr-scanner" className="menu-button">
                <Button color="primary">{`QR Scanner & Contact Tracing List`}</Button>
              </a>
            ) : (
              ""
            )
          )} */}
        </Card.Body>
      </Card>
    </div>
  );
};

export default MainMenuSide;
