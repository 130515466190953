import React, { useState, useEffect } from "react";
import { Card  } from "tabler-react";
import useUsers from "../../hooks/api/Users/useUsers";
import MenuHeader from "../../components/MenuHeader";
import AppList from "./AppList";

import useAppList from "../../hooks/api/Apps/useAppList";

const Index = () => {
  const {apps } = useAppList()
  const { loginSession, user } = useUsers();

  useEffect(() => {
    if (loginSession.length == 0) {
      window.location.replace("/login");
    }
  }, [loginSession]);

  return (
    <>
      <MenuHeader data={{ user }} />

      {user.length != 0 ? <AppList data={{ user, apps }} /> : ""}
    </>
  );
};

export default Index;
