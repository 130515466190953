import React, { useEffect } from "react";
import useLogs from "../../hooks/api/Logs/useLogs";
import useUsers from "../../hooks/api/Users/useUsers";

const Logout = () => {
  const { loginSession } = useUsers();
  const { login_logs } = useLogs();
  useEffect(() => {
    login_logs(loginSession, 1);
    localStorage.removeItem("session");
    window.location.replace("/login");
  }, []);
  return <div>Signing Off ....</div>;
};

export default Logout;
