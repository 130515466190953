import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { Button, Card, Form, Grid, Icon, Table } from "tabler-react";
import dateFormat from "dateformat";
import useShopPaymentReleaseAPI from "../../../../hooks/api/TiktokShop/useShopPaymentReleaseAPI";
import { CURRENT_YEAR } from "../../../../config";

const UploadPaymentRealease = () => {
  const { uploadPaymentRelease } = useShopPaymentReleaseAPI();
  const [uploadedPayments, setUploadedPayments] = useState([]);
  const [files, setFiles] = useState([]);
  const [finalPaymentList, setFinalPaymentList] = useState([]);

  const [success, setSuccess] = useState(false);

  const [month, setMonth] = useState(dateFormat(new Date(), "mmmm"));
  const [year, setYear] = useState(CURRENT_YEAR);

  console.log(uploadedPayments);
  console.log(finalPaymentList);

  const toObject = (data) => {
    let list = data
      // .filter((d) =>
      //   ["Commission", "Payment Fee", "Item Price Credit"].includes(d[2])
      // )
      .map((d) => ({ 
        refund_id: "--",
        refund_amount: 0,
        seller_voucher: 0,
        coin_cashback: 0,
        shipping_fee_rebate: 0,
        service_fee: 0,
        voucher_code: "",
        lost_compensation: 0,
        order_id: `*${d[0].replace(/^\s+|\s+$/gm, "")}`,
        username: d[1],
        order_date: d[2],
        payout_date: d[3],
        release_amount: Math.abs(d[5]),
        original_price: parseFloat(d[6]),
        sub_total_after_seller_discount: Math.abs(d[7]),
        sub_total_before_discount: Math.abs(d[8]),
        seller_promotion: Math.abs(d[9]),
        refund_sub_total_after_discount: Math.abs(d[10]),
        refund_sub_total_before_discount: Math.abs(d[11]),
        refund_seller_discount: Math.abs(d[12]),
        total_fees: Math.abs(d[13]),
        transaction_fee: Math.abs(d[14]),
        commission_fee: Math.abs(d[15]),
        seller_shipping_fee: Math.abs(d[16]),
        paid_shipping_fee: Math.abs(d[17]),
        shipping_fee_discount: Math.abs(d[18]),
        defined_shipping_fee: Math.abs(d[19]),
        refund_customer_shipping_fee: Math.abs(d[20]),
        actual_return_shipping_fee: Math.abs(d[21]),
        shipping_fee_subsidy: Math.abs(d[22]),
        affiliate_commission: Math.abs(d[23]),
        affiliate_partner_commission: Math.abs(d[24]),
        sfp_service_fee: Math.abs(d[25]),
        ajustment: Math.abs(d[26]),
        related_order: Math.abs(d[27]),
        customer_payment: Math.abs(d[29]),
        customer_refund: Math.abs(d[30]),
        seller_co_funded_voucher: Math.abs(d[31]),
        refund_of_seller_co_funded_voucher: Math.abs(d[32]),
        platform_discounts: Math.abs(d[33]),
        discount_rebate: Math.abs(d[34]),
        platform_co_funded_voucher: Math.abs(d[35]),
        refund_of_platform_co_funded_voucher: Math.abs(d[36]),
        seller_shipping_fee_discount: Math.abs(d[37]),
        estimated_package_weight: Math.abs(d[38]),
        actual_package_weight: Math.abs(d[39]),
        shipping_provider: "TIKTOK",
        month_release: month,
        year_release: year,
      }));

    setFinalPaymentList(list);
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={6}>
              <Form.Group>
                <CSVReader
                  disabled={files.reduce((a, b) => a + b.items, 0) >= 6000}
                  onFileLoaded={(data, fileInfo) => {
                    files.find((file) => file.name === fileInfo.name)
                      ? alert("duplicate file!")
                      : setFiles([
                          ...files,
                          { ...fileInfo, items: data.length - 1 },
                        ]) ||
                        setUploadedPayments([
                          ...uploadedPayments,
                          ...data
                            .slice(1, data.length - 1)
                            .map((d) => [
                              ...d,
                              fileInfo.name.replace(/.csv/g, ""),
                            ]),
                        ]);
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Select
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="">Select Month of Release</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Select
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="">Select Year of Release</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                </Form.Select>
              </Form.Group>

              {files.reduce((a, b) => a + b.items, 0) >= 6000 ? (
                <p style={{ color: "red" }}>
                  Maximum payment release size reach 6,000 items. Please upload
                  first the current selection.
                </p>
              ) : (
                ""
              )}
            </Grid.Col>
            <Grid.Col md={6}>
              <p>Uploaded Files</p>
              {files.length !== 0 ? (
                <>
                  {files.map((file) => (
                    <p>
                      ({file.items} items) {file.name}
                    </p>
                  ))}

                  {finalPaymentList.length !== 0 ? (
                    <>
                      {success ? (
                        <p>UPLOADED TO DATABASE!</p>
                      ) : (
                        <Button
                          color="cyan"
                          onClick={() =>
                            uploadPaymentRelease(
                              finalPaymentList,
                              () => alert("uploaded!") || setSuccess(true)
                            )
                          }
                        >
                          <Icon prefix="fa" name="upload" />
                          Upload Generated Payments
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      color="teal"
                      onClick={() => toObject(uploadedPayments)}
                    >
                      <Icon prefix="fa" name="print" />
                      Generate
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          {finalPaymentList.length !== 0 ? (
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Order ID</Table.ColHeader>
                  <Table.ColHeader>Order Date</Table.ColHeader>
                  <Table.ColHeader>Payout Date</Table.ColHeader>
                  <Table.ColHeader>Orginal Price</Table.ColHeader>
                  <Table.ColHeader>Seller Promotion</Table.ColHeader>
                  <Table.ColHeader>Release Amount</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finalPaymentList.map((payment) => (
                  <Table.Row>
                    <Table.Col>{payment.order_id}</Table.Col>
                    <Table.Col>{payment.order_date}</Table.Col>
                    <Table.Col>{payment.payout_date}</Table.Col>
                    <Table.Col>{payment.original_price}</Table.Col>
                    <Table.Col>{payment.seller_promotion}</Table.Col>
                    <Table.Col>{payment.release_amount}</Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default UploadPaymentRealease;
