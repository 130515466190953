import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Grid } from "tabler-react";
import MenuHeader from "../../components/MenuHeader";
import Loading from "../../components/Loading/Loading";
import ProgressBar from "./components/Progress";

//components
import UserList from "./components/UserList";
import useUsers from "../../hooks/api/Users/useUsers";
import usePreferences from "../../hooks/api/AMS/usePreferences";
import useLogs from "../../hooks/api/Logs/useLogs";


const Index = () => {
  const { loginLogs } = useLogs();

  // console.log(loginLogs);
  const { loginSession, user, users, updateUsers, addUsers } = useUsers();
  const { preferences } = usePreferences();

  const [selectedUserID, setSelectedUserID] = useState("");
  const [editUser, setEditUser] = useState([]);
  const [loading, setLoading] = useState(true);

  //Side Effects

  useEffect(() => {
    if (loginSession.length == 0) {
      window.location.replace("/login");
    }
  });

  useEffect(() => {
    if (selectedUserID !== "") {
      setEditUser(users.find((user) => user.id == selectedUserID));
    }
  }, [users, selectedUserID]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Helmet>
        <title>GMS - Users Management Tool</title>
      </Helmet>
      {loading ? (
        <div style={{ paddingTop: "10%" }}>
          <Loading />
        </div>
      ) : (
        <>
          <MenuHeader data={{ user }} />
          <div style={{ margin: "5px" }}>
            <Grid.Row>
              <Grid.Col md={2}>
                <ProgressBar
                  header="Online Users"
                  content={loginLogs.filter((log) => log.status == 0).length}
                  progressColor=""
                  progressWidth="0"
                  color="#17a2b8"
                />
                <ProgressBar
                  header="Offline Users"
                  content={loginLogs.filter((log) => log.status == 1).length}
                  progressColor=""
                  progressWidth="0"
                  color="#DB211D"
                />
              </Grid.Col>
              <Grid.Col md={10}>
                <UserList
                  data={{
                    loginLogs,
                    addUsers,
                    users,
                    selectedUserID,
                    setSelectedUserID,
                    editUser,
                    setEditUser,
                    departmentList: preferences.filter(
                      (pref) => pref.type === "department"
                    ),
                    roleList: preferences.filter(
                      (pref) => pref.type === "role"
                    ),
                    accessLevelList: preferences.filter(
                      (pref) => pref.type === "access_level"
                    ),
                    updateUsers,
                  }}
                />
              </Grid.Col>
            </Grid.Row>
          </div>
        </>
      )}
    </>
  );
};

export default Index;
