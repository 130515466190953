import React from "react";
import { Grid, PricingCard } from "tabler-react";
import "../../components/style/app_card.css";

const AppList = (props) => {
  const { user, apps } = props.data;

  return (
    <div style={{ padding: "10px" }}>
      {/* <Grid.Row>
        {apps.map((app, index) => (
          <>
            {user.apps_list.find(
              (user) => user.app === app.slug && user.access !== "none"
            ) ? (
              <Grid.Col md={3} sm={12} key={index}>
                <PricingCard>
                  <img
                    style={{ width: "80%", height: "100px" }}
                    src={app.image}
                  />
                  <PricingCard.AttributeList>
                    <PricingCard.AttributeItem>
                      <p style={{ fontSize: "12px" }}>{app.description}</p>
                    </PricingCard.AttributeItem>
                  </PricingCard.AttributeList>
                  <a href={app.redirect} style={{ textDecoration: "none" }}>
                    <PricingCard.Button>{app.button_label} </PricingCard.Button>
                  </a>
                </PricingCard>
              </Grid.Col>
            ) : (
              ""
            )}
          </>
        ))}
      </Grid.Row> */}

      <Grid.Row>
        {apps.map((app, index) => (
          <>
            {user.apps_list.find(
              (user) => user.app === app.slug && user.access !== "none"
            ) ? (
              <Grid.Col md={3} sm={12} key={index}>
                <a href={app.redirect} style={{textDecoration: "none"}}>
                  <div className={`app_card app_card-5`}>
                    <Grid.Row>
                      <Grid.Col md={12}>
                        <img
                          className="app_card_image"
                          src={app.image}
                        />
                      </Grid.Col>
                      <Grid.Col md={12}>
                        <h2 class="app_card__title">{app.description}</h2>
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                </a>
              </Grid.Col>
            ) : (
              ""
            )}
          </>
        ))}
      </Grid.Row>
    </div>
  );
};

export default AppList;
