import React, { useEffect, useState } from "react";
import { Button, Card, Form, Grid, Table, Icon } from "tabler-react";
import Pagination from "../../../components/Pagination";
import useCustomFunctions from "../../../hooks/services/useCustomFunctions";

const CallLogHistory = (props) => {
  const { formatNumberComma } = useCustomFunctions();
  const {
    callLogs,
    dateFormat,
    addNewCall,
    setAddNewCall,
    setSelectedCallLog,
    selectType,
    setSelectType,
    accessLevel,
  } = props.data;
  const [activePage, setActivePage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);
  return (
    <>
      <Card>
        <Card.Header>
          Call Logs
          <Card.Options>
            <Button
              color="cyan"
              icon="plus"
              onClick={() => setAddNewCall(true)}
            >
              New Call Log
            </Button>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {callLogs.length != 0 ? (
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Call Date</Table.ColHeader>
                  <Table.ColHeader>Amount</Table.ColHeader>
                  <Table.ColHeader>Received By</Table.ColHeader>
                  <Table.ColHeader>Note</Table.ColHeader>
                  <Table.ColHeader>Status</Table.ColHeader>
                  <Table.ColHeader>Actions</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {callLogs
                  .sort((a, b) => new Date(b.call_date) - new Date(a.call_date))
                  .slice(
                    activePage == 1 ? 0 : (activePage - 1) * pageLimit,
                    activePage * pageLimit
                  )
                  .map((call, index) => (
                    <Table.Row key={index}>
                      <Table.Col>
                        {dateFormat(
                          new Date(call.call_date),
                          "mmmm d, yyyy (h:MM TT)"
                        )}
                      </Table.Col>
                      <Table.Col>
                        Php {formatNumberComma(call.amount)}
                      </Table.Col>
                      <Table.Col>{call.received_by}</Table.Col>
                      <Table.Col>{call.notes}</Table.Col>
                      <Table.Col>
                        <p
                          style={{
                            color:
                              call.call_status === "Completed"
                                ? "#17a2b8"
                                : "#2bcbba",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            margin: 0,
                          }}
                          className="call_status_btn"
                        >
                          {call.call_status}
                        </p>
                      </Table.Col>
                      <Table.Col>
                        <Button
                          color="teal"
                          icon="eye"
                          onClick={() =>
                            setSelectedCallLog(call) || setSelectType("view")
                          }
                        ></Button>
                        {["admin", "edit"].includes(accessLevel) ? (
                          <Button
                            color="azure"
                            icon="edit"
                            onClick={() =>
                              setSelectedCallLog(call) || setSelectType("edit")
                            }
                          ></Button>
                        ) : (
                          ""
                        )}
                      </Table.Col>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          ) : (
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          )}
        </Card.Body>
        {callLogs.length == 0 ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={pageLimit}
            totalItemsCount={callLogs.length}
            pageRangeDisplayed={pageRangeDisplayed}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default CallLogHistory;
