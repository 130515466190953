import React, { useState } from "react";
import { Card, Button, Icon, Header } from "tabler-react";
import Tooltip from "react-simple-tooltip";
import Achievements from "../pages/components/Achievements";
import DashboardReminderList from "../pages/subPages/dashboard/DashboardReminderList";

import useCreateBackURL from "../../../hooks/services/useCreateBackURL";
import useReminders from "../../../hooks/api/AMS/useReminders";

import "./menu.css";

const MainMenuSide = (props) => {
  const { data } = props;
  const { reminders } = useReminders();
  const { backURL } = useCreateBackURL();
  const { accessLevel, user, rewards, markAsRead, getNotifications } = data;

  const [showAchievements, setShowAchievements] = useState(false);
  const [showReminders, setShowReminders] = useState(false);

  return (
    <>
      {showAchievements ? (
        <Achievements
          data={{ rewards, setShowAchievements, markAsRead, getNotifications }}
        />
      ) : (
        ""
      )}
      {showReminders ? (
        <DashboardReminderList
          data={{ backURL, showReminders, setShowReminders, reminders }}
        />
      ) : (
        ""
      )}

      <div className="main-menu-container">
        <Card>
          <Card.Header>
            <p className="menu-header">711 Account</p>
          </Card.Header>
          <Card.Body>
            <Tooltip
              content="Home"
              radius={5}
              padding={5}
              placement="right"
              style={{ whiteSpace: "nowrap", width: "100%" }}
              zIndex={2}
            >
              <Button
                className="menu-button"
                color="cyan"
                onClick={() => window.location.replace("/")}
              >
                <Icon prefix="fa" name="home" />
              </Button>
            </Tooltip>
            <hr />
            

            <Tooltip
              content="Dashboard"
              radius={5}
              padding={5}
              placement="right"
              style={{ whiteSpace: "nowrap", width: "100%" }}
            >
              <Button
                className="menu-button"
                color="teal"
                onClick={() => window.location.replace("/AMS")}
              >
                <Icon prefix="fa" name="dashboard" />
              </Button>
            </Tooltip>

            {["admin", "report", "edit"].includes(accessLevel) ? (
              <Tooltip
                content="Statistics"
                radius={5}
                padding={5}
                placement="right"
                style={{ whiteSpace: "nowrap", width: "100%" }}
              >
                <Button
                  className="menu-button"
                  color="teal"
                  onClick={() => window.location.replace("/AMS/Statistics/711")}
                >
                  <Icon prefix="fa" name="bar-chart" />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}

            <Tooltip
              content="711 Store Master List"
              radius={5}
              padding={5}
              placement="right"
              style={{ whiteSpace: "nowrap", width: "100%" }}
            >
              <Button
                className="menu-button"
                color="teal"
                onClick={() => window.location.replace("/AMS/MasterList/711")}
              >
                <Icon prefix="fa" name="home" />
              </Button>
            </Tooltip>
            <Tooltip
              content="711 Store Call History"
              radius={5}
              padding={5}
              placement="right"
              style={{ whiteSpace: "nowrap", width: "100%" }}
            >
              <Button
                className="menu-button"
                color="teal"
                onClick={() =>
                  window.location.replace("/AMS/CallLogs711History/AllCallLogs")
                }
              >
                <Icon prefix="fa" name="phone" />
              </Button>
            </Tooltip>

            {accessLevel === "admin" ? (
              <Tooltip
                content="Preferences"
                radius={5}
                padding={5}
                placement="right"
                style={{ whiteSpace: "nowrap", width: "100%" }}
              >
                <Button
                  className="menu-button"
                  color="teal"
                  onClick={() =>
                    window.location.replace("/AMS/Preferences/711")
                  }
                >
                  <Icon prefix="fa" name="gear" />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
            <Tooltip
              content="Achievements"
              radius={5}
              padding={5}
              placement="right"
              style={{ whiteSpace: "nowrap", width: "100%" }}
            >
              <Button
                className="menu-button"
                color="teal"
                onClick={() => setShowAchievements(true)}
              >
                <Icon prefix="fa" name="trophy" />
              </Button>
              {rewards.filter((r) => r.status == 0).length == 0 ? (
                ""
              ) : (
                <span className="badge">
                  {rewards.filter((r) => r.status == 0).length}
                </span>
              )}
            </Tooltip>

            <Tooltip
              content="Reminders"
              radius={5}
              padding={5}
              placement="right"
              style={{ whiteSpace: "nowrap", width: "100%" }}
            >
              <Button
                className="menu-button"
                color="teal"
                onClick={() => setShowReminders(true)}
              >
                <Icon prefix="fa" name="bell" />
              </Button>
              {reminders?.filter((r) => r.reminder === user.username).length ==
              0 ? (
                ""
              ) : (
                <span className="badge">
                  {
                    reminders?.filter((r) => r.reminder === user.username)
                      ?.length
                  }
                </span>
              )}
            </Tooltip>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default MainMenuSide;
