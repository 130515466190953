import React, { useState } from "react";
import {
  Button,
  Card,
  Grid,
  Notification,
  Avatar,
  Text,
  Icon,
  Form,
} from "tabler-react";
import dateFormat from "dateformat";
import use711Calls from "../../../../hooks/api/AMS/use711Calls";

const OnlineOrders = (props) => {
  const { addCallLogs } = use711Calls();
  const { backURL, setOpenForm, onlineOrders, approvedWebOrders } = props.data;

  const [orderSelectedID, setOrderSelectedID] = useState("");
  return (
    <>
      <div className="modal-online-orders">
        <Card className="modal-online-orders-content-container">
          <Card.Header>
            ONLINE ORDERS
            <Card.Options>
              <Button color="secondary" onClick={() => setOpenForm("")}>
                CLOSE
              </Button>
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={1}>
                <Form.Label>Store No.</Form.Label>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Label>Store Name</Form.Label>
              </Grid.Col>
              <Grid.Col md={2}>
                <Form.Label>No. of Items</Form.Label>
              </Grid.Col>
              {/* <Grid.Col md={2}>
                <Form.Label>Allocation</Form.Label>
              </Grid.Col> */}
              <Grid.Col md={2}>
                <Form.Label>Received By</Form.Label>
              </Grid.Col>
              <Grid.Col md={3}>
                <Form.Label>Actions</Form.Label>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
          {onlineOrders.map((order) => (
            <>
              <Card.Body>
                <Grid.Row>
                  <Grid.Col md={1}>
                    <Form.Label>
                      {order.store_number.replace("*", "")}
                    </Form.Label>
                  </Grid.Col>
                  <Grid.Col md={2}>
                    <Form.Label>{order.store_name}</Form.Label>
                  </Grid.Col>
                  <Grid.Col md={2}>
                    <Form.Label>
                      PHP{" "}
                      {order.orders
                        .map((o) => o.price * o.quantity)
                        .reduce((a, b) => a + b, 0)}
                    </Form.Label>
                  </Grid.Col>
                  {/* <Grid.Col md={2}>
                    <Form.Label>{order.allocation}</Form.Label>
                  </Grid.Col> */}
                  <Grid.Col md={2}>
                    <Form.Label>{order.received_by}</Form.Label>
                  </Grid.Col>
                  <Grid.Col md={3}>
                    <Button
                      color="cyan"
                      icon="home"
                      onClick={() =>
                        window.location.replace(
                          `/AMS/Account/711/Profile?id=${order.store_number.replace(
                            "*",
                            ""
                          )}&backURL=${window.btoa(backURL)}`
                        )
                      }
                    >
                      VISIT
                    </Button>
                    {order.id === orderSelectedID ? (
                      <Button
                        color="azure"
                        onClick={() => setOrderSelectedID("")}
                      >
                        <Icon prefix="fa" name="eye-slash" /> HIDE
                      </Button>
                    ) : (
                      <Button
                        color="azure"
                        icon="eye"
                        onClick={() => setOrderSelectedID(order.id)}
                      >
                        VIEW
                      </Button>
                    )}
                    <Button
                      color="teal"
                      icon="check"
                      onClick={() =>
                        addCallLogs(
                          {
                            date_order: dateFormat(
                              new Date(),
                              "isoUtcDateTime"
                            ),
                            store_id: order.store.id,
                            amount: order.orders
                              .map((o) => o.subTotal)
                              .reduce((a, b) => a + b, 0),
                            remarks: "Ordered",
                            notes: `------ CLIENTS ORDER ----- \n \n${order.orders
                              .map((o) => `${o.variant} = ${o.quantity}pcs = SERIALS: ${o.serials.join()}`)
                              .join("\n")}`,
                            allocation: order.allocation,
                            // allocation_remarks,
                            // received_by,
                            call_status: "Pending",
                            store: order.store,
                          },
                          () =>
                            approvedWebOrders(order.id, "Approved") ||
                            window.location.replace(
                              `/AMS/Account/711/Profile?id=${order.store_number.replace(
                                "*",
                                ""
                              )}&backURL=${window.btoa(backURL)}`
                            )
                        )
                      }
                    >
                      APPROVED
                    </Button>

                    <Button
                      color="secondary"
                      icon="cancel"
                      onClick={() => approvedWebOrders(order.id, "Declined")}
                    >
                      <Icon prefix="fa" name="close" /> DECLINE
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
              {order.id === orderSelectedID ? (
                <Card.Body>
                  <Grid.Row>
                    <Grid.Col md={12}>
                      <b>ALLOCATION:</b> {order.allocation}
                    </Grid.Col>
                    <br />
                    <br />
                    <Grid.Col md={2}>
                      <b>Product Name</b>
                    </Grid.Col>
                    <Grid.Col md={2}>
                      <b>Price</b>
                    </Grid.Col>
                    <Grid.Col md={2}>
                      <b>Quantity</b>
                    </Grid.Col>
                    <Grid.Col md={2}>
                      <b>SubTotal</b>
                    </Grid.Col>
                    <Grid.Col md={2}>
                      <b>Serials</b>
                    </Grid.Col>
                  </Grid.Row>
                  {order.orders.map((o) => (
                    <Grid.Row>
                      <Grid.Col md={2}>{o.variant}</Grid.Col>
                      <Grid.Col md={2}>PHP {o.price}</Grid.Col>
                      <Grid.Col md={2}>{o.quantity}</Grid.Col>
                      <Grid.Col md={2}>PHP {o.subTotal}</Grid.Col>
                      <Grid.Col md={2}>{o.serials.join(",")}</Grid.Col>
                    </Grid.Row>
                  ))}
                  <br />
                  <p>----------- NOTHING FOLLOWS -----------</p>
                </Card.Body>
              ) : (
                ""
              )}
            </>
          ))}
        </Card>
      </div>
    </>
  );
};

export default OnlineOrders;
