import React, { useState, useEffect } from "react";
import { Card, Icon, Table, Button } from "tabler-react";
import dateFormat from "dateformat";
import useReminders from "../../../../../hooks/api/AMS/useReminders";
import useUsers from "../../../../../hooks/api/Users/useUsers";

const DashboardReminderList = (props) => {
  const { backURL, showReminders, setShowReminders, reminders } = props?.data;
  const [loading, setLoading] = useState(true);
  const { user } = useUsers();
  // const { reminders } = useReminders();

  useEffect(() => {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
    }, 1000);
  }, [reminders]);

  return (
    <div className="modal-achievement">
      <Card className="modal-achievement-content-container">
        <Card.Status color="cyan" side />
        <Card.Header>
          My Reminder List{" "}
          <Card.Options>
            <Button color="secondary" onClick={() => setShowReminders(false)}>
              Close
            </Button>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {reminders?.filter((r) => r.reminder === user.username).length !==
          0 ? (
            <>
              <Table
                responsive
                highlightRowOnHover
                hasOutline
                verticalAlign="center"
                cards
                className="text-nowrap"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Store Number</Table.ColHeader>
                    <Table.ColHeader>Date Created</Table.ColHeader>
                    <Table.ColHeader>Action</Table.ColHeader>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {reminders
                    .filter((r) => r.reminder === user.username)
                    .sort(function (a, b) {
                      return b.id - a.id;
                    })
                    .map((reminder, index) => (
                      <Table.Row>
                        <Table.Col>{reminder.store_number}</Table.Col>
                        <Table.Col>
                          {dateFormat(reminder.timestamp, "fullDate")}
                        </Table.Col>
                        <Table.Col>
                          <a
                            href={`/AMS/Account/711/Profile?id=${reminder.store_number.replace(
                              "*",
                              ""
                            )}&backURL=${window.btoa(backURL)}`}
                          >
                            <Button color="cyan" icon="home">
                              Revisit Store
                            </Button>
                          </a>
                        </Table.Col>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </>
          ) : (
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default DashboardReminderList;
