import React, { useState } from "react";
import {
  Button,
  Card,
  Grid,
  Notification,
  Avatar,
  Text,
  Icon,
  Form,
} from "tabler-react";
import dateFormat from "dateformat";
import Gold from "../../../../assets/icon/gold.png";
import Silver from "../../../../assets/icon/silver.png";
import Bronze from "../../../../assets/icon/bronze.png";

const Achievements = (props) => {
  const { rewards, setShowAchievements, markAsRead, getNotifications } =
    props.data;
  let badges = [Gold, Silver, Bronze];

  const [messageID, setMessageID] = useState("");

  const createMarkUp = (message) => {
    return { __html: message };
  };
  console.log(rewards.filter((r) => r.id === messageID));

  return (
    <>
      <div className="modal-achievement">
        <Card className="modal-achievement-content-container">
          <Card.Header>
            Achievements
            <Card.Options>
              <Button
                color="secondary"
                onClick={() => setShowAchievements(false) || getNotifications()}
              >
                Close
              </Button>
            </Card.Options>
          </Card.Header>

          {rewards.filter((r) => r.status == 0).length != 0 ? (
            <>
              <div style={{ padding: "10px" }}>
                <Form.Label>Unread Messages</Form.Label>
              </div>
              {rewards
                .filter((r) => r.status == 0)
                .map((r, index) => (
                  <>
                    <Card.Body>
                      <Notification
                        message={
                          <>
                            <Grid.Row>
                              <Grid.Col md={1}>
                                <div style={{ padding: "5px" }}>
                                  <Avatar
                                    size="md"
                                    imageURL={r.rank <= 3 ? badges[r.rank - 1] : badges[2]}
                                    className="d-block"
                                  >
                                    <span style={{ color: "white" }}>
                                      {r.rank}
                                    </span>
                                  </Avatar>
                                </div>
                              </Grid.Col>
                              <Grid.Col md={9}>
                                <p
                                  style={{
                                    fontSize: "20px",
                                    margin: 0,
                                    fontWeight: "500",
                                  }}
                                >
                                  {r.title}
                                </p>
                                <Text size="sm" muted>
                                  {dateFormat(r.timestamp, "fullDate")} | Unread
                                </Text>
                              </Grid.Col>
                              <Grid.Col md={1}>
                                <div style={{ padding: "5px" }}>
                                  {messageID !== "" ? (
                                    <Button
                                      color="secondary"
                                      onClick={() => setMessageID("")}
                                    >
                                      Close
                                    </Button>
                                  ) : (
                                    <Button
                                      color="cyan"
                                      onClick={() =>
                                        setMessageID(r.id) || markAsRead(r.id)
                                      }
                                    >
                                      Read
                                    </Button>
                                  )}
                                </div>
                              </Grid.Col>
                            </Grid.Row>
                          </>
                        }
                      />
                    </Card.Body>
                    {messageID !== "" ? (
                      <Card.Body>
                        <div
                          dangerouslySetInnerHTML={createMarkUp(
                            rewards.find((r) => r.id === messageID).message
                          )}
                        />
                      </Card.Body>
                    ) : (
                      ""
                    )}
                  </>
                ))}
            </>
          ) : (
            ""
          )}

          {rewards.length == 0 ? (
            <Card.Body>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "40px",
                  color: "#b9b9b9",
                }}
              >
                <Icon prefix="fa" name="trophy" />
                <p
                  style={{
                    fontSize: "20px",
                    margin: 0,
                    fontWeight: "bold",

                    textTransform: "uppercase",
                  }}
                >
                  NO ACHIEVEMENTS YET
                </p>
              </div>
            </Card.Body>
          ) : (
            ""
          )}

          {rewards.filter((r) => r.status == 1).length != 0 ? (
            <>
              <div style={{ padding: "10px" }}>
                <hr style={{ margin: "5px" }} />
                <Form.Label>Achievements History</Form.Label>
              </div>
              {rewards
                .filter((r) => r.status == 1)
                .map((r, index) => (
                  <>
                    <Card.Body>
                      <Notification
                        message={
                          <>
                            <Grid.Row>
                              <Grid.Col md={1}>
                                <div style={{ padding: "5px", textAlign: "center" }}>
                                <span style={{ fontWeight: "bold", fontSize: "12px" }}>TOP</span>
                                  <Avatar
                                    size="md"
                                    imageURL={r.rank <= 3 ? badges[r.rank - 1] : badges[2]}
                                    className="d-block"
                                    style={{margin: "0 auto"}}
                                  >
                                    
                                    <span style={{ color: "white" }}>
                                    
                                      {r.rank}
                                    </span>
                                  </Avatar>
                                </div>
                              </Grid.Col>
                              <Grid.Col md={9}>
                                <p
                                  style={{
                                    fontSize: "20px",
                                    margin: 0,
                                    fontWeight: "500",
                                  }}
                                >
                                  {r.title}
                                </p>
                                <Text size="sm" muted>
                                  {dateFormat(r.timestamp, "fullDate")} | Read
                                </Text>
                              </Grid.Col>
                              <Grid.Col md={1}>
                                <div style={{ padding: "5px" }}>
                                  {messageID === r.id ? (
                                    <Button
                                      color="secondary"
                                      onClick={() => setMessageID("")}
                                    >
                                      Close
                                    </Button>
                                  ) : (
                                    <Button
                                      color="cyan"
                                      onClick={() => setMessageID(r.id)}
                                    >
                                      Open
                                    </Button>
                                  )}
                                </div>
                              </Grid.Col>
                            </Grid.Row>
                          </>
                        }
                      />
                    </Card.Body>
                    {r.id === messageID ? (
                      <Card.Body>
                        <div
                          dangerouslySetInnerHTML={createMarkUp(
                            rewards.find((r) => r.id === messageID).message
                          )}
                        />
                      </Card.Body>
                    ) : (
                      ""
                    )}
                  </>
                ))}
            </>
          ) : (
            <Card.Body>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "40px",
                  color: "#b9b9b9",
                }}
              >
                <Icon prefix="fa" name="trophy" />
                <p
                  style={{
                    fontSize: "20px",
                    margin: 0,
                    fontWeight: "bold",

                    textTransform: "uppercase",
                  }}
                >
                  PLEASE KEEP UP THE GOOD WORK
                </p>
              </div>
            </Card.Body>
          )}
        </Card>
      </div>
    </>
  );
};

export default Achievements;
