import { useState, useEffect } from "react";
import config from "../../../config";
import axios from "axios";
import dateFormat from "dateformat";

import useLogs from "../Logs/useLogs";

function useUsers() {
  let loginSession = window.localStorage.getItem("session")
    ? JSON.parse(window.atob(window.localStorage.getItem("session")))
    : [];

  const { login_logs, insert_login_logs } = useLogs();
  // const { apps} = useAppList()

  const [user, setUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [telemarketers, setTelemarketers] = useState([]);
  const [accountExecutives, setAccountExecutives] = useState([]);

  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      localStorage.removeItem("session");
      return null;
    }
    const item = JSON.parse(window.atob(itemStr));
    const now = dateFormat(new Date(), "paddedShortDate");
    // compare the expiry time of the item with the current time
    if (now !== item.expiry || !item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      localStorage.removeItem("session");
      return null;
    }
    return item.value;
  }

  function autoLogout(){
    localStorage.removeItem("session");
    localStorage.removeItem("login_session");
  }

  useEffect(() => {
    getWithExpiry("loginSession");
    // autoLogout()
  }, []);

  function login(data) {
    axios
      .post(`${config.host}:${config.port}/api/v2/user_login`, {
        username: data.username,
        password: data.password,
      })
      .then(function (response) {
        // console.log(response);
        let data = response.data[0];
        let session = {
          id: data.id,
          username: data.username,
          access_level: data.access_level,
        };

        login_logs(session, 0);

        window.localStorage.setItem(
          "session",
          window.btoa(JSON.stringify(session))
        );
        window.localStorage.setItem(
          "loginSession",
          window.btoa(
            JSON.stringify({
              value: "Log out your account everyday",
              expiry: dateFormat(new Date(), "paddedShortDate"),
            })
          )
        );

        window.location.replace("/");
      })
      .catch(function (error) {
        console.log(error);
        window.location.replace("/login?error_code=1");
      });
  }

  function getUser() {
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/get_user`, {
          id: loginSession.id,
        })
        .then(function (response) {
          let data = response.data.map((d) => ({
            ...d,
            apps_list: JSON.parse(d.apps_list),
          }));

          // console.log(data);
          setUser(data[0]);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getTelemarketers() {
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/telemarketers`, {
          id: loginSession.id,
        })
        .then(function (response) {
          let data = response.data;
          setTelemarketers(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getAccountExecutives() {
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/account_executives`, {
          id: loginSession.id,
        })
        .then(function (response) {
          let data = response.data;
          setAccountExecutives(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getUsers() {
    if (loginSession.length != 0) {
      if (loginSession.access_level === "Administrator") {
        axios
          .post(`${config.host}:${config.port}/api/v2/get_users`)
          .then(function (response) {
            let data = response.data.map((d) => ({
              ...d,
              apps_list: JSON.parse(d.apps_list),
            }));
            setUsers(data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }

  function updateUsers(data, actions) {
    if (loginSession.length != 0) {
      if (loginSession.access_level === "Administrator") {
        axios
          .post(`${config.host}:${config.port}/api/v2/update_users`, {
            id: data.id,
            full_name: data.full_name,
            username: data.username,
            role: data.role,
            apps_list: JSON.stringify(data.apps_list),
            department: data.department,
            access_level: data.access_level,
            last_edit: loginSession.username,
          })
          .then(function (response) {
            actions(true);
            getUsers();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }

  function addUsers(data, actions) {
    if (loginSession.length != 0) {
      if (loginSession.access_level === "Administrator") {
        axios
          .post(`${config.host}:${config.port}/api/v2/add_users`, {
            full_name: data.full_name,
            username: data.username,
            role: data.role,
            department: data.department,
            access_level: data.access_level,
            password: data.password,
          })
          .then(function (response) {
            insert_login_logs({ username: data.username });
            actions(true);
            getUsers();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }

  function resetPassword(data) {
    // console.log(data);
    axios
      .post(`${config.host}:${config.port}/api/v2/reset_password`, {
        id: data.id,
        password: data.password,
      })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getUser();
    getUsers();
    getTelemarketers();
    getAccountExecutives();
  }, []);

  return {
    loginSession,
    login,
    user,
    users,
    addUsers,
    updateUsers,
    resetPassword,
    telemarketers,
    accountExecutives,
  };
}

export default useUsers;
