import React, { useEffect, useState } from "react";
import { Card, Loader, Table, Button, Icon } from "tabler-react";
import Pagination from "../../../../components/Pagination";
import Filter from "./Filter";

//hooks
import useShopeeAPI from "../../../../hooks/api/ShopeeShop/useShopeeAPI";
import useQueryURL from "../../../../hooks/services/useQueryURL";
import useUsers from "../../../../hooks/api/Users/useUsers";

const PaymentRelease = () => {
  const { user } = useUsers();

  console.log(user);
  const { getQuery } = useQueryURL();

  const [month, setMonth] = useState(
    getQuery("month") ? getQuery("month") : "January"
  );

  const { paymentRelease } = useShopeeAPI(month, "", "");

  const [year, setYear] = useState(
    getQuery("year") ? getQuery("year") : "2021"
  );

  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");
  const [search4, setSearch4] = useState("");
  const [search5, setSearch5] = useState("");

  const [newPaymentRelease, setNewPaymentRelease] = useState([]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (paymentRelease.length !== 0) {
        setTimeout(() => {
          setLoading(false);
          setNewPaymentRelease(
            paymentRelease
              .filter((o) =>
                search !== ""
                  ? o.dr_number.replace("*", "").includes(search) ||
                    o.SI_number.replace("*", "").includes(search)
                  : o
              )
              .filter((o) =>
                search2 !== ""
                  ? o.order_id.replace("*", "").includes(search2)
                  : o
              )
          );
        }, 1000);
      }
    }, 1000);
  }, [paymentRelease, search, search2, search3]);

  return (
    <>
      <Card>
        <Card.Header>
          Payment Release
          {user &&
          user.apps_list &&
          user.apps_list.find(
            (app) => app.app === "shopee-shop" && (["edit", "admin"].includes(app.access))
          ) ? (
            <Card.Options>
              <a href="/ShopeeShop/UploadPaymentRelease">
                <Button color="cyan">
                  <Icon prefix="fa" name="upload" /> Upload Payment Release
                </Button>
              </a>
            </Card.Options>
          ) : (
            ""
          )}
        </Card.Header>

        <Filter
          data={{
            paymentRelease,
            newPaymentRelease,
            src: "PaymentRelease",
            month,
            year,
            search,
            setSearch,
            search2,
            setSearch2,
            search3,
            setSearch3,
            search4,
            setSearch4,
            search5,
            setSearch5,
          }}
        />
        <Card.Body>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Table
                responsive
                highlightRowOnHover
                hasOutline
                verticalAlign="center"
                cards
                className="text-nowrap"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Payout Date</Table.ColHeader>
                    <Table.ColHeader>Order Date</Table.ColHeader>
                    <Table.ColHeader>DR Number</Table.ColHeader>
                    <Table.ColHeader>SI/CI Number</Table.ColHeader>
                    <Table.ColHeader>Order ID</Table.ColHeader>
                    <Table.ColHeader>Release Amount</Table.ColHeader>
                    <Table.ColHeader>Original Price</Table.ColHeader>
                    <Table.ColHeader>Commission Fee</Table.ColHeader>
                    <Table.ColHeader>Transaction Fee</Table.ColHeader>
                    <Table.ColHeader>Refund Amount</Table.ColHeader>
                    <Table.ColHeader>Seller Promotion</Table.ColHeader>
                    <Table.ColHeader>Coins Cashback</Table.ColHeader>
                    <Table.ColHeader>Shipping Fee</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {newPaymentRelease
                    .sort(function (a, b) {
                      return b.id - a.id;
                    })
                    .slice(
                      activePage == 1 ? 0 : (activePage - 1) * 30,
                      activePage * 30
                    )
                    .map((order, index) => (
                      <Table.Row>
                        <Table.Col>{order.payout_date}</Table.Col>
                        <Table.Col>{order.order_date}</Table.Col>
                        <Table.Col>
                          {order.dr_number.replace("*", "")}
                        </Table.Col>
                        <Table.Col>
                          {order.SI_number.replace("*", "")}
                        </Table.Col>
                        <Table.Col>
                          <a
                            href={`/ShopeeShop/OrderItemList?order_id=${order.order_id}`}
                            target="_blank"
                            style={{
                              textDecoration: "none",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            {order.order_id.replace("*", "")}
                          </a>
                        </Table.Col>
                        <Table.Col>{order.release_amount}</Table.Col>
                        <Table.Col>{order.original_price}</Table.Col>
                        <Table.Col>{order.commission_fee}</Table.Col>
                        <Table.Col>{order.transaction_fee}</Table.Col>
                        <Table.Col>{order.refund_amount}</Table.Col>
                        <Table.Col>{order.seller_promotion}</Table.Col>
                        <Table.Col>{order.coin_cashback}</Table.Col>
                        <Table.Col>{order.paid_shipping_fee}</Table.Col>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </>
          )}
        </Card.Body>
        {newPaymentRelease.length <= 30 ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={30}
            totalItemsCount={newPaymentRelease.length}
            pageRangeDisplayed={pageRangeDisplayed}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default PaymentRelease;
