import React, { useState } from "react";
import { Card, Grid, Button, Icon, Table } from "tabler-react";
import { Helmet } from "react-helmet";
import MenuHeader from "../../components/MenuHeader";
import Tooltip from "react-simple-tooltip";
import dateFormat from "dateformat";
import NotificationForm from "./NotificationForm";

import useNotifications from "../../hooks/api/useNotifications";
import useUsers from "../../hooks/api/Users/useUsers";

const Index = () => {
  const { user } = useUsers();
  const { allNotifications } = useNotifications();

  const types = ["achievements", "announcements"];
  const [messageData, setMessageData] = useState({
    type: "",
    rank: "",
    message: "",
    title: "",
    user: "",
    expiration: new Date(),
    status: 1,
  });

  const [selectedNotifTab, setSelectedNotifTab] = useState("announcements");
  const [selectedAchievement, setSelectedAchievement] = useState([]);

  return (
    <>
      <Helmet>
        <title>GMS - Notification Manager</title>
      </Helmet>
      <MenuHeader data={{ user }} />
      <div style={{ margin: "5px" }}>
        {selectedAchievement.length != 0 ? (
          <NotificationForm
            data={{
              selectedAchievement,
              setSelectedAchievement,
              messageData,
              setMessageData,
            }}
          />
        ) : (
          ""
        )}

        <Grid.Row>
          <Grid.Col md={1}>
            <Card>
              <Card.Body>
                <Tooltip
                  content="Annoucements"
                  radius={5}
                  padding={5}
                  placement="right"
                  style={{ whiteSpace: "nowrap", width: "100%" }}
                  zIndex={2}
                >
                  <Button
                    className="menu-button"
                    color={
                      selectedNotifTab === "announcements" ? "cyan" : "teal"
                    }
                    onClick={() => setSelectedNotifTab("announcements")}
                  >
                    <Icon prefix="fa" name="bullhorn" />
                  </Button>
                </Tooltip>
                <Tooltip
                  content="Achievements & Awards"
                  radius={5}
                  padding={5}
                  placement="right"
                  style={{ whiteSpace: "nowrap", width: "100%" }}
                  zIndex={2}
                >
                  <Button
                    className="menu-button"
                    color={
                      selectedNotifTab === "achievements" ? "cyan" : "teal"
                    }
                    onClick={() => setSelectedNotifTab("achievements")}
                  >
                    <Icon prefix="fa" name="trophy" />
                  </Button>
                </Tooltip>
              </Card.Body>
            </Card>
          </Grid.Col>
          <Grid.Col md={11}>
            {types
              .map((t) => ({
                title: t,
                data: allNotifications.filter((a) => a.type === t),
              }))
              .map((notif) => (
                <>
                  {selectedNotifTab === notif.title ? (
                    <Card>
                      <Card.Header>
                        {notif.title.toUpperCase()}
                        <Card.Options>
                          <Button
                            icon="plus"
                            color="cyan"
                            onClick={() =>
                              setSelectedAchievement({
                                type: notif.title,
                                rank: "success",
                                message: "",
                                title: "",
                                user: "all",
                                expiration: new Date(),
                                active: 1,
                              })
                            }
                          >
                            NEW POST
                          </Button>
                        </Card.Options>
                      </Card.Header>
                      <Card.Body>
                        <Table
                          responsive
                          highlightRowOnHover
                          hasOutline
                          verticalAlign="center"
                          cards
                          className="text-nowrap"
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.ColHeader>Receipient</Table.ColHeader>
                              <Table.ColHeader>Title</Table.ColHeader>
                              <Table.ColHeader>Message</Table.ColHeader>
                              <Table.ColHeader>Message Type</Table.ColHeader>
                              <Table.ColHeader>
                                {notif.title === "achievements"
                                  ? "Rank"
                                  : "Message Color"}
                              </Table.ColHeader>
                              <Table.ColHeader>
                                {notif.title === "achievements"
                                  ? "Date Posted"
                                  : "Expiration"}
                              </Table.ColHeader>
                              <Table.ColHeader>Actions</Table.ColHeader>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {notif.data.map((n) => (
                              <Table.Row>
                                <Table.Col>{n.user}</Table.Col>
                                <Table.Col>{n.title}</Table.Col>
                                <Table.Col>
                                  {n.message.slice(0, 30)}...
                                </Table.Col>
                                <Table.Col>{n.type}</Table.Col>
                                <Table.Col>{n.rank}</Table.Col>
                                <Table.Col>
                                  {dateFormat(
                                    notif.title === "achievements"
                                      ? n.timestamp
                                      : n.expiration,
                                    "mmm d, yyyy h:MM:ss TT"
                                  )}
                                </Table.Col>

                                <Table.Col>
                                  <Button
                                    color="cyan"
                                    icon="edit"
                                    onClick={() => setSelectedAchievement(n)}
                                  ></Button>
                                </Table.Col>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      </Card.Body>
                    </Card>
                  ) : (
                    ""
                  )}
                </>
              ))}
          </Grid.Col>
        </Grid.Row>
      </div>
    </>
  );
};

export default Index;
