import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractPaymentRelease() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadPayments(order, month, year) {
    var headers = {
      order_id: "order_id",
      order_item_number: "order_item_number",
      product_name: "product_name",
      dr_number: "dr_number",
      SI_number: "SI_number",
      order_status: "order_status",
      fee_name: "fee_name",
      amount: "amount",
      paid_status: "paid_status",
      year: "year",
      month: "month",
    };

    var itemsNotFormatted = order;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        order_id: item.order_number,
        order_item_number: item.order_item_number,
        product_name: item.product_name,
        dr_number: item.dr_number,
        SI_number: item.SI_number,
        order_status: item.order_status,
        fee_name: item.fee_name,
        amount: item.amount,
        paid_status: item.paid_status,
        year: year,
        month: month,
      });
    });

    var fileTitle = `Lazada Payments_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }



  return { downloadPayments };
}

export default useExtractPaymentRelease;
