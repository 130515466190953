import { useLocation } from "react-router";

function useQueryURL() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  function getQuery(params) {
    return query.get(params);
  }

  function changeQueryParam(url, parameter, newValue) {
    var href = new URL(url);
    href.searchParams.set(parameter, newValue);
    return href.toString();
  }

  function changeQueryParams(url, parameters, newValues) {
    var href = new URL(url);

    for (let index = 0; index < parameters.length; index++) {
      href.searchParams.set(parameters[index], newValues[index]);
    }
    return href.toString();
  }

  return {
    getQuery,
    changeQueryParam,
    changeQueryParams,
  };
}

export default useQueryURL;
