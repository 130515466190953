import React, { useState } from "react";
import { Button, Card, Form, Grid } from "tabler-react";


const UserForm = (props) => {
  const {
    addUsers,
    openUserForm,
    setOpenUserForm,
    departmentList,
    roleList,
    accessLevelList,
  } = props.data;
  const [formData, setFormData] = useState({
    username: "",
    full_name: "",
    department: "",
    access_level: "",
    role: "",
    password: (Math.random() + 10).toString(36).substring(7),
  });
  return (
    <>
      <Card>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={2}>
              <Form.Group>
                <Form.Label>Username</Form.Label>
                <Form.Input
                  value={formData?.username}
                  onChange={(e) =>
                    setFormData({ ...formData, username: e.target.value })
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <Form.Input
                  value={formData?.full_name}
                  onChange={(e) =>
                    setFormData({ ...formData, full_name: e.target.value })
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Input
                  value={formData?.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Select
                  value={formData?.role}
                  onChange={(e) =>
                    setFormData({ ...formData, role: e.target.value })
                  }
                >
                  <option value="">SELECT ONE</option>
                  {roleList.map((option, index) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Group>
                <Form.Label>Department</Form.Label>
                <Form.Select
                  value={formData?.department}
                  onChange={(e) =>
                    setFormData({ ...formData, department: e.target.value })
                  }
                >
                  <option value="">SELECT ONE</option>
                  {departmentList.map((option, index) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Group>
                <Form.Label>Access Level</Form.Label>
                <Form.Select
                  value={formData?.access_level}
                  onChange={(e) =>
                    setFormData({ ...formData, access_level: e.target.value })
                  }
                >
                  <option value="">SELECT ONE</option>
                  {accessLevelList.map((option, index) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2}>
              <Button
                color="cyan"
                icon="save"
                onClick={() =>
                  addUsers(formData, () =>
                    alert("New USer Successfully Added!")
                  )
                }
              >
                Save
              </Button>
              <Button color="secondary" onClick={() => setOpenUserForm(false)}>
                Cancel
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserForm;
