import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Grid } from "tabler-react";
import Menu from "./menu/MainMenuSide";
import ShopeeTool from "./shopee/index";
import LazadaTool from "./lazada/index";
import SerialTool from "./serial/index";
import TiktokTook from "./tiktok/index";
import TiktokGOMO from "./tiktokGOMO/index"
import OnlineStore from "./gate_online_store/index";
import Loading from "../../components/Loading/Loading";

//hooks
import useUsers from "../../hooks/api/Users/useUsers";

const Index = () => {
  const { user, loginSession } = useUsers();

  const [accessLevel, setAccessLevel] = useState("none");
  const [subApp, setSubApps] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loginSession.length == 0) {
      window.location.replace("/");
    }
  }, []);

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "basic-tool").access
        : ""
    );

    setSubApps(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "basic-tool").subApps
          ? user.apps_list
              .find((user) => user.app === "basic-tool")
              .subApps.split(",")
          : ""
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Helmet>
        <title>GMS Basic Tools</title>
      </Helmet>
      {loading ? (
        <div style={{ paddingTop: "10%" }}>
          <Loading />
        </div>
      ) : (
        <div style={{ margin: "5px" }}>
          <Grid.Row>
            <Grid.Col md={1} sm={12}>
              {subApp.length != 0 ? (
                <>
                  <Menu data={{ user, subApp }} />
                </>
              ) : (
                ""
              )}
            </Grid.Col>
            {subApp.length != 0 ? (
              <Grid.Col md={11} sm={12}>
                <Router>
                  <Switch>
                    {subApp[0] === "shopee" ? (
                      <Route exact path="/GBT/" component={ShopeeTool} />
                    ) : (
                      ""
                    )}

                    {subApp[0] === "lazada" ? (
                      <Route exact path="/GBT/" component={LazadaTool} />
                    ) : (
                      ""
                    )}

                    {subApp[0] === "serial" ? (
                      <Route exact path="/GBT/" component={SerialTool} />
                    ) : (
                      ""
                    )}

                    <Route
                      exact
                      path="/GBT/shopee-report-generator"
                      component={ShopeeTool}
                    />

                    <Route
                      exact
                      path="/GBT/lazada-report-generator"
                      component={LazadaTool}
                    />

                    <Route
                      exact
                      path="/GBT/serial-generator"
                      component={SerialTool}
                    />
                    <Route
                      exact
                      path="/GBT/tiktok-report-generator"
                      component={TiktokTook}
                    />

                    <Route
                      exact
                      path="/GBT/tiktok-gomo-report-generator"
                      component={TiktokGOMO}
                    />

                    <Route
                      exact
                      path="/GBT/online-store"
                      component={OnlineStore}
                    />
                  </Switch>
                </Router>
              </Grid.Col>
            ) : (
              ""
            )}
          </Grid.Row>
        </div>
      )}
    </>
  );
};

export default Index;
