import React, { useState, useEffect } from "react";
import { Button, Card, Table, Loader, Icon, Grid } from "tabler-react";
import Pagination from "../../../../components/Pagination";
import Filter from "./Filter";
import Progress from "./Progress";
import CopyBTN from "../../../../components/CopyBTN";

//hooks
import useShopAPI from "../../../../hooks/api/TiktokGOMOShop/useShopAPI";
import useQueryURL from "../../../../hooks/services/useQueryURL";
import useUsers from "../../../../hooks/api/Users/useUsers";
import { CURRENT_YEAR } from "../../../../config";

const Dashboard = () => {
  const { getQuery } = useQueryURL();
  const { user } = useUsers();

  const [accessLevel, setAccessLevel] = useState("none");

  const [month, setMonth] = useState(
    getQuery("month") ? getQuery("month") : "January"
  );
  const [year, setYear] = useState(
    getQuery("year") ? getQuery("year") : CURRENT_YEAR
  );

  const { RTSList } = useShopAPI(month, "", "");

  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(
    getQuery("page") ? getQuery("page") : 1
  );
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");
  const [search4, setSearch4] = useState("");
  const [search5, setSearch5] = useState("");

  const [newList, setNewList] = useState([]);

  const changePage = (p) => {
    window.location.replace(
      `/TiktokGOMOShop/RTSDashboard?month=${month}&page=${p}`
    );
  };

  useEffect(() => {
    setLoading(true);
    if (RTSList.length !== 0) {
      setNewList(
        RTSList.filter((rts) =>
          search !== "" ? rts.dr_number.replace("*", "").includes(search2) : rts
        )
          .filter((rts) =>
            search3 !== ""
              ? rts.order_id.replace("*", "").includes(search3)
              : rts
          )
          .filter((rts) =>
            search4 !== ""
              ? rts.status.includes(search4) || rts.remarks.includes(search4)
              : rts
          )
          .filter((rts) =>
            search5 !== ""
              ? search5 === "Pending DR"
                ? rts.SI_number === "--"
                : rts.SI_number !== "--"
              : rts
          )
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [RTSList, search, search2, search3, search4, search5, month]);

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "lazada-shop").access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  return (
    <>
      <Grid.Row>
        <Grid.Col md={3}>
          <Progress
            header="ENDORSED TO MARKETING"
            content={
              RTSList.length != 0
                ? RTSList.filter((rts) => rts.remarks === "Endorsed to MKTG")
                    .length
                : 0
            }
            progressWidth={0}
            progressColor="cyan"
            color="#17a2b8"
          />
        </Grid.Col>
        <Grid.Col md={3}>
          <Progress
            header="Payment Waiting"
            content={
              RTSList.length != 0
                ? RTSList.filter((rts) => rts.remarks === "Payment Waiting")
                    .length
                : 0
            }
            progressWidth={0}
            progressColor="cyan"
            color="#17a2b8"
          />
        </Grid.Col>
        <Grid.Col md={3}>
          <Progress
            header="Cancelled"
            content={
              RTSList.length != 0
                ? RTSList.filter((rts) => rts.status === "Cancelled").length
                : 0
            }
            progressWidth={0}
            progressColor="cyan"
            color="#17a2b8"
          />
        </Grid.Col>
        <Grid.Col md={3}>
          <Progress
            header="Returned"
            content={
              RTSList.length != 0
                ? RTSList.filter((rts) => rts.status === "Returned").length
                : 0
            }
            progressWidth={0}
            progressColor="cyan"
            color="#17a2b8"
          />
        </Grid.Col>
      </Grid.Row>
      <Card>
        <Card.Header>RTS Dashboard</Card.Header>
        <Filter
          data={{
            src: "RTSDashboard",
            month,
            year,
            search,
            setSearch,
            search2,
            setSearch2,
            search3,
            setSearch3,
            search4,
            setSearch4,
            search5,
            setSearch5,
          }}
        />
        <Card.Body>
          {loading ? (
            <Loader />
          ) : (
            <>
              {newList.length != 0 ? (
                <Table
                  responsive
                  highlightRowOnHover
                  hasOutline
                  verticalAlign="center"
                  cards
                  className="text-nowrap"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>#</Table.ColHeader>
                      <Table.ColHeader>DR Number</Table.ColHeader>
                      <Table.ColHeader>SI/CI Number</Table.ColHeader>
                      <Table.ColHeader>Order ID</Table.ColHeader>
                      <Table.ColHeader>Status</Table.ColHeader>
                      <Table.ColHeader>Remarks</Table.ColHeader>
                      <Table.ColHeader>Actions</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {newList
                      .sort(function (a, b) {
                        return a.id - b.id;
                      })
                      .slice(
                        activePage == 1 ? 0 : (activePage - 1) * 30,
                        activePage * 30
                      )
                      .map((list, index) => (
                        <>
                          <Table.Row>
                            <Table.Col>
                              {activePage == 1
                                ? index + 1
                                : index + 1 + (activePage - 1) * 10}
                            </Table.Col>
                            <Table.Col>
                              {list.dr_number.replace("*", "")}
                            </Table.Col>
                            <Table.Col>
                              {list.SI_number.replace("*", "") === "--"
                                ? "Pending DR"
                                : list.SI_number.replace("*", "")}
                            </Table.Col>
                            <Table.Col>
                              {list.order_id.replace("*", "")}{" "}
                              <CopyBTN value={list.order_id.replace("*", "")} />
                            </Table.Col>
                            <Table.Col>{list.status}</Table.Col>
                            <Table.Col>{list.remarks}</Table.Col>
                            <Table.Col>
                              <a
                                href={`/TiktokGOMOShop/Orders?dr_number=${list.dr_number.replace(
                                  "*",
                                  ""
                                )}&month=${month}&year=${year}&order_id=${list.order_id.replace(
                                  "*",
                                  ""
                                )}`}
                                target="_blank"
                              >
                                <Button color="cyan">View</Button>
                              </a>
                            </Table.Col>
                          </Table.Row>
                        </>
                      ))}
                  </Table.Body>
                </Table>
              ) : (
                ""
              )}
            </>
          )}
          {newList.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          ) : (
            ""
          )}
        </Card.Body>
        {newList.length <= 30 || loading ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={30}
            totalItemsCount={newList.length}
            pageRangeDisplayed={pageRangeDisplayed}
            changePage={changePage}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default Dashboard;
