import React, { useState, useEffect } from "react";
import { Card, Grid, Button, Icon, Form } from "tabler-react";
import Pagination from "../../components/Pagination";
import useUsers from "../../hooks/api/Users/useUsers";

import usePreferences from "../../hooks/api/AMS/usePreferences";

const EmployeeList = (props) => {
  const { user } = useUsers();

  const { newList, addEmployees, updateEmployees } = props.data;
  const { preferences } = usePreferences();
  const [activePage, setActivePage] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  const [selectedID, setSelectedID] = useState("");

  const [formData, setFormData] = useState({
    id: "",
    employee_id: "",
    full_name: "",
    position: "",
    shift: "",
    type: "",
    active: "",
  });
  const [addForm, setAddForm] = useState(false);

  const reset = () => {
    setSelectedID("") ||
      setFormData({
        id: "",
        employee_id: "",
        full_name: "",
        position: "",
        shift: "",
        type: "",
        active: "",
      });
  };

  const [filtered, setFiltered] = useState([]);
  const [filter1, setFilter1] = useState("");

  const [access, setAccess] = useState("");

  useEffect(() => {
    setFiltered(
      newList.filter((n) =>
        filter1 !== ""
          ? n.employee_id.includes(filter1.toUpperCase()) ||
            n.full_name.toLowerCase().includes(filter1.toLowerCase())
          : n
      )
    );
  }, [newList, filter1]);

  useEffect(() => {
    if (user && user.length !== 0) {
      setAccess(user.apps_list.find((app) => app.app === "hr-tool").access);
    }
  }, [user]);

  return (
    <>
      <Card.Body>
        {addForm ? (
          <Grid.Row>
            <Grid.Col md={1}>
              <p
                style={{
                  fontSize: "10px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#B8B8B8",
                }}
              >
                Employee ID
              </p>
              <Form.Input
                value={formData.employee_id}
                onChange={(e) =>
                  setFormData({ ...formData, employee_id: e.target.value })
                }
                valid
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <p
                style={{
                  fontSize: "10px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#B8B8B8",
                }}
              >
                Full Name
              </p>
              <Form.Input
                value={formData.full_name}
                onChange={(e) =>
                  setFormData({ ...formData, full_name: e.target.value })
                }
                valid
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <p
                style={{
                  fontSize: "10px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#B8B8B8",
                }}
              >
                Position
              </p>

              <Form.Select
                placeholder="referrence / holiday id"
                value={formData.position}
                onChange={(e) =>
                  setFormData({ ...formData, position: e.target.value })
                }
                valid
              >
                <option value="">Select One</option>
                {preferences
                  .filter((p) => p.type === "role")
                  .map((p) => (
                    <option value={p.value}>{p.value}</option>
                  ))}
              </Form.Select>
            </Grid.Col>
            <Grid.Col md={2}>
              <p
                style={{
                  fontSize: "10px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#B8B8B8",
                }}
              >
                Shift
              </p>
              <Form.Input
                value={formData.shift}
                onChange={(e) =>
                  setFormData({ ...formData, shift: e.target.value })
                }
                valid
              />
            </Grid.Col>
            <Grid.Col md={1}>
              <p
                style={{
                  fontSize: "10px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#B8B8B8",
                }}
              >
                Type
              </p>

              <Form.Select
                value={formData.type}
                onChange={(e) =>
                  setFormData({ ...formData, type: e.target.value })
                }
                valid
              >
                <option value="">Select One</option>
                <option value="C">C</option>
                <option value="C1">C1</option>
                <option value="FF">FF</option>
              </Form.Select>
            </Grid.Col>
            <Grid.Col md={1}>
              <p
                style={{
                  fontSize: "10px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#B8B8B8",
                }}
              >
                Active
              </p>

              <Form.Select
                value={formData.active}
                onChange={(e) =>
                  setFormData({ ...formData, active: e.target.value })
                }
                valid
              >
                <option value="">Select One</option>
                <option value="1">YES</option>
                <option value="0">NO</option>
              </Form.Select>
            </Grid.Col>
            <Grid.Col md={2}>
              <Button
                color="cyan"
                icon="save"
                onClick={() =>
                  addEmployees(
                    formData,
                    () => alert("Data Successfully Saved!") || reset()
                  )
                }
              >
                Save
              </Button>
              <Button
                color="secondary"
                onClick={() => setAddForm(false) || reset()}
              >
                Cancel
              </Button>
            </Grid.Col>
          </Grid.Row>
        ) : (
          <>
            <Grid.Row>
              <Grid.Col md={2}>
                <Form.Input
                  placeholder="employee id/ name"
                  value={filter1}
                  onChange={(e) => setFilter1(e.target.value)}
                />
              </Grid.Col>
              {
                ["admin", "edit"].includes(access) ?
                <Grid.Col md={3}>
                <Button
                  color="cyan"
                  icon="plus"
                  onClick={() =>
                    setAddForm(true) || setSelectedID("") || reset()
                  }
                >
                  ADD NEW EMPLOYEES
                </Button>
              </Grid.Col> :""
              }
              
            </Grid.Row>
          </>
        )}
      </Card.Body>
      {filtered
        .slice(activePage == 1 ? 0 : (activePage - 1) * 15, activePage * 15)
        .map((e, i) => (
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={1}>
                <p
                  style={{
                    fontSize: "10px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#B8B8B8",
                  }}
                >
                  Employee ID
                </p>
                {selectedID === e.id ? (
                  <Form.Input
                    value={formData.employee_id}
                    onChange={(e) =>
                      setFormData({ ...formData, employee_id: e.target.value })
                    }
                    valid
                  />
                ) : (
                  <p
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                    }}
                  >
                    {e.employee_id}
                  </p>
                )}
              </Grid.Col>
              <Grid.Col md={2}>
                <p
                  style={{
                    fontSize: "10px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#B8B8B8",
                  }}
                >
                  Full Name
                </p>
                {selectedID === e.id ? (
                  <Form.Input
                    value={formData.full_name}
                    onChange={(e) =>
                      setFormData({ ...formData, full_name: e.target.value })
                    }
                    valid
                  />
                ) : (
                  <p
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                    }}
                  >
                    {e.full_name}
                  </p>
                )}
              </Grid.Col>
              <Grid.Col md={2}>
                <p
                  style={{
                    fontSize: "10px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#B8B8B8",
                  }}
                >
                  Position
                </p>

                {selectedID === e.id ? (
                  <Form.Select
                    placeholder="referrence / holiday id"
                    value={formData.position}
                    onChange={(e) =>
                      setFormData({ ...formData, position: e.target.value })
                    }
                    valid
                  >
                    <option value="">Select One</option>
                    {preferences
                      .filter((p) => p.type === "role")
                      .map((p) => (
                        <option value={p.value}>{p.value}</option>
                      ))}
                  </Form.Select>
                ) : (
                  <p
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                    }}
                  >
                    {e.position}
                  </p>
                )}
              </Grid.Col>
              <Grid.Col md={2}>
                <p
                  style={{
                    fontSize: "10px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#B8B8B8",
                  }}
                >
                  Shift
                </p>
                {selectedID === e.id ? (
                  <Form.Input
                    value={formData.shift}
                    onChange={(e) =>
                      setFormData({ ...formData, shift: e.target.value })
                    }
                    valid
                  />
                ) : (
                  <p
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                    }}
                  >
                    {e.shift}
                  </p>
                )}
              </Grid.Col>
              <Grid.Col md={1}>
                <p
                  style={{
                    fontSize: "10px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#B8B8B8",
                  }}
                >
                  Type
                </p>

                {selectedID === e.id ? (
                  <Form.Select
                    value={formData.type}
                    onChange={(e) =>
                      setFormData({ ...formData, type: e.target.value })
                    }
                    valid
                  >
                    <option value="">Select One</option>
                    <option value="C">C</option>
                    <option value="C1">C1</option>
                    <option value="FF">FF</option>
                  </Form.Select>
                ) : (
                  <p
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                    }}
                  >
                    {e.type}
                  </p>
                )}
              </Grid.Col>
              <Grid.Col md={1}>
                <p
                  style={{
                    fontSize: "10px",
                    margin: 0,
                    fontWeight: "bold",
                    color: "#B8B8B8",
                  }}
                >
                  Active
                </p>

                {selectedID === e.id ? (
                  <Form.Select
                    value={formData.active}
                    onChange={(e) =>
                      setFormData({ ...formData, active: e.target.value })
                    }
                    valid
                  >
                    <option value="">Select One</option>
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </Form.Select>
                ) : (
                  <p
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                    }}
                  >
                    {e.active === 1 ? "YES" : "NO"}
                  </p>
                )}
              </Grid.Col>

              <Grid.Col md={2}>
                {["admin", "edit"].includes(access) ? (
                  <>
                    {selectedID === e.id ? (
                      <>
                        <Button
                          color="cyan"
                          icon="save"
                          onClick={() =>
                            updateEmployees(
                              formData,
                              () => alert("Data Successfully Saved!") || reset()
                            )
                          }
                        >
                          Save
                        </Button>
                        <Button color="secondary" onClick={() => reset()}>
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        color="cyan"
                        icon="edit"
                        onClick={() => setSelectedID(e.id) || setFormData(e)}
                        disabled={addForm}
                      >
                        Edit
                      </Button>
                    )}
                  </>
                ) : (
                  ""
                )}
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        ))}
      <Pagination
        activePage={activePage}
        itemsCountPerPage={15}
        // changePage={changePage}
        totalItemsCount={filtered.length}
        pageRangeDisplayed={pageRangeDisplayed}
        setActivePage={setActivePage}
        setPageRangeDisplayed={setPageRangeDisplayed}
      />
    </>
  );
};

export default EmployeeList;
