import React, { useEffect } from "react";
import { Button, Card, Table, Icon, Grid, Form } from "tabler-react";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import useExtractReports from "../../../hooks/api/AMS_sales/extractions/useExtractReports";
import useQueryURL from "../../../hooks/services/useQueryURL";
import useOtherAccounts from "../../../hooks/api/AMS_sales/useOtherAccounts";

const CallFilter = (props) => {
  const { telemarketerOA } = useOtherAccounts();
  const { getQuery, changeQueryParam } = useQueryURL();

  console.log(telemarketerOA);

  const { downloadCallLogs } = useExtractReports();
  const {
    filtered,
    filter1,
    setFilter1,
    filter2,
    setFilter2,
    filter3,
    setFilter3,
    filter4a,
    setFilter4a,
    filter4b,
    setFilter4b,
    filter5,
    setFilter5,
    filter6,
    setFilter6,
    filter7,
    setFilter7,
    categoryList,
    filter8,
    setFilter8,
  } = props.data;

  const dates = getQuery("dates")
    ? getQuery("dates").split("|")
    : [new Date(), new Date()];
  const call_status = getQuery("call_status") || "";
  const ea_name = getQuery("ea_name") || "";

  useEffect(() => {
    setFilter4a(new Date(dates[0]));
    setFilter4b(new Date(dates[1]));
    setFilter2(call_status);
    setFilter5(ea_name);
  }, []);

  let REMARKS_LIST = [
    "DOCUMENTATION",
    "INTRO CALL",
    "PRESENTATION",
    "DOCUMENTATION",
    "CONTRACT SIGNING",
    "ACQUIRED",
    "NO LONGER INTERESTED",
    "NO ANSWER",
    "NOT INTERESTED",
    "FOLLOW UP PO",
    "INQUIRY",
    "RECEIVED PO",
    "FOLLOW UP",
  ];

  return (
    <Card.Body>
      <Grid.Row>
        {/* <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Client ID</Form.Label>
            <Form.Input
              value={filter1}
              onChange={(e) => setFilter1(e.target.value)}
              placeholder="Search by Client ID"
            />
          </Form.Group>
        </Grid.Col> */}
        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Client Name</Form.Label>
            <Form.Input
              value={filter3}
              onChange={(e) => setFilter3(e.target.value)}
              placeholder="Search by Client Name"
            />
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Select
              value={filter2}
              onChange={(e) => setFilter2(e.target.value)}
            >
              <option value="">SELECT ALL</option>
              <option value="Completed">Completed</option>
              <option value="Pending">Pending</option>
            </Form.Select>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Call Date</Form.Label>
            {/* <DatePicker
              selected={filter4a}
              onChange={(date) => {
                setFilter4a(date);
              }}
              //   onChange={(dates) => {
              //     const [start, end] = dates;
              //     setFilter4a(start);
              //     setFilter4b(end);
              //   }}
              //   startDate={filter4a}
              //   endDate={filter4b}
              minDate={new Date(`01/01/2022`)}
              maxDate={new Date()}
              className="form-control"
              //   selectsRange
              monthsShown={1}
            /> */}
            <DatePicker
              selected={filter4a}
              onChange={(dates) => {
                const [start, end] = dates;
                setFilter4a(start);
                setFilter4b(end);
                if (end !== null && start !== null) {
                  filter4a || filter4a === "" || filter4b || filter4b === ""
                    ? window.location.replace(
                        changeQueryParam(
                          window.location.href,
                          "dates",
                          `${dateFormat(start, "paddedShortDate")}|${dateFormat(
                            end,
                            "paddedShortDate"
                          )}`
                        )
                      )
                    : window.location.replace(
                        window.location.href +
                          `&dates=${dateFormat(
                            start,
                            "paddedShortDate"
                          )}|${dateFormat(end, "paddedShortDate")}`
                      );
                }
              }}
              startDate={filter4a}
              endDate={filter4b}
              className="form-control"
              selectsRange
              monthsShown={1}
            />
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Received By</Form.Label>
            <Form.Select
              value={filter5}
              onChange={(e) => setFilter5(e.target.value)}
            >
              <option value="">SELECT ALL</option>
              <option value="Butz De Castro">Butz De Castro</option>
              {telemarketerOA.map((ae) => (
                <option value={ae.full_name}>{ae.full_name}</option>
              ))}

              {/* <option value="Pamela Decendio">Pamela Decendio</option>
              <option value="Vanessa Lomibao">Vanessa Lomibao</option>
              <option value="Mary Anne">Mary Anne</option>
              <option value="Derick Flor De Lis">Derick Flor De Lis</option>
              
              <option value="John Paul Marquez">John Paul Marquez</option>
              <option value="Patrick Sayo">Patrick Sayo</option>
              <option value="Eldon Anselmo">Eldon Anselmo</option>
              <option value="Jobelyn Ocampo">Jobelyn Ocampo</option> */}
            </Form.Select>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Account</Form.Label>
            <Form.Select
              value={filter6}
              onChange={(e) => setFilter6(e.target.value)}
            >
              <option value="">SELECT ALL</option>
              <option value="NEW">NEW</option>
              <option value="OLD">OLD</option>
            </Form.Select>
          </Form.Group>
        </Grid.Col>

        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Remarks</Form.Label>
            <Form.Select
              value={filter7}
              onChange={(e) => setFilter7(e.target.value)}
            >
              <option value="">Choose One</option>
              {/* <option value="">{remark.value}</option> */}
              {REMARKS_LIST.map((remark, index) => (
                <>
                  <option value={remark} key={index}>
                    {remark}
                  </option>
                </>
              ))}
            </Form.Select>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={2}>
          <Form.Group>
            <Form.Label>Category</Form.Label>
            <Form.Input
              value={filter8}
              onChange={(e) => setFilter8(e.target.value)}
              placeholder="Search by Category"
            />
            {filter8 !== "" && !categoryList.find((l) => l === filter8) ? (
              <div
                className="dropdown-menu dropdown-menu-left show"
                data-placement="bottom"
                style={{
                  position: "absolute",
                  inset: "0px auto auto 0px",
                  transform: "translate(5px, 63px)",
                }}
              >
                {categoryList
                  .filter((l) =>
                    l.toLowerCase().includes(filter8.toLowerCase())
                  )
                  .map((l) => (
                    <Button
                      className="dropdown-item"
                      onClick={() => setFilter8(l)}
                    >
                      {l}
                    </Button>
                  ))}
              </div>
            ) : (
              ""
            )}
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        {filtered.length != 0 ? (
          <Grid.Col md={2}>
            <Button
              color="cyan"
              icon="download"
              onClick={() => downloadCallLogs(filtered)}
            >
              Extract
            </Button>
          </Grid.Col>
        ) : (
          ""
        )}
      </Grid.Row>
    </Card.Body>
  );
};

export default CallFilter;
