import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Text, Grid } from "tabler-react";

import useBoardsManager from "../../hooks/api/GPM/useBoardsManager";
import useDragNDrop from "../../hooks/api/GPM/useDragNDrop";
import EditTaskModal from "./EditTaskModal";
import useQueryURL from "../../hooks/services/useQueryURL"

const MyBoards = () => {
  const { getQuery } = useQueryURL()
  const { board, boardTask, getBoard, updateBoard } = useBoardsManager();
  const [items, setItems] = useState([]);
  const [boardID, setBoardID] = useState(getQuery("board_id") || "");

  const [category, setCategory] = useState([]);

  useEffect(() => {
    if (board.length != 0) {
      setItems(board[0].items);
      setCategory(board[0].categories);
    }
  }, [board, boardTask]);

  useEffect(() => {
    getBoard(boardID);
  }, []);

  const { getItemStyle, getListStyle, onDragEnd } = useDragNDrop(
    boardID,
    items,
    setItems,
    updateBoard
  );

  // console.log(items);

  const [selectedItemID, setSelectItemID] = useState("");
  return (
    <>
      {selectedItemID !== "" ? (
        <EditTaskModal data={{ items, setItems, selectedItemID }} />
      ) : (
        ""
      )}

      <div
        className="grid-row-css row"
        style={{ width: `${category.length * 320}px` }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          {category.map((c) => (
            <div style={{ width: "300px", margin: "8px" }}>
              <Droppable droppableId={c.name}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className="card"
                  >
                    <div className="card-header" style={{ padding: 0 }}>
                      <Button color={c.color} className="btn-css">
                        {c.header}
                      </Button>
                    </div>
                    <p></p>
                    {items
                      .filter((i) => i.category === c.name)
                      .sort((a, b) => a.index - b.index)
                      .map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={item.index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                category.find((c) => c.name === item.category)
                                  .color
                              )}
                              className="card"
                            >
                              <div
                                class={`card-status bg-${c.color} card-status-left`}
                              ></div>
                              <div className="card-body">
                                <Grid.Row>
                                  <Grid.Col md={10}>
                                    {item.name}
                                    <Text size="sm" muted>
                                      {item.description.slice(0, 25)}...
                                    </Text>
                                  </Grid.Col>
                                  <Grid.Col md={1}>
                                    <Button
                                      icon="edit"
                                      color="secondary"
                                      onClick={() => setSelectItemID(item.id)}
                                    ></Button>
                                  </Grid.Col>
                                </Grid.Row>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                    {items.filter((i) => i.category === c.name).length == 0
                      ? "No Task"
                      : ""}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </div>
    </>
  );
};

export default MyBoards;
