import React from "react";
import { Card, Table,Text, Grid, Avatar } from "tabler-react";

const StoreTeleListSchedule = (props) => {
  const { scheduleSummary } = props.data;
  return (
    <>
      <Card>
        <Card.Status color="cyan" side />
        <Card.Header>Telemarketer - Scheduled Store Summary</Card.Header>
        <Card.Body>
          <Table
            responsive
            highlightRowOnHover
            hasOutline
            verticalAlign="center"
            cards
            className="text-nowrap"
          >
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>Telemarketer</Table.ColHeader>
                <Table.ColHeader>Monday</Table.ColHeader>
                <Table.ColHeader>Tuesday</Table.ColHeader>
                <Table.ColHeader>Wednesday</Table.ColHeader>
                <Table.ColHeader>Thursday</Table.ColHeader>
                <Table.ColHeader>Friday</Table.ColHeader>
                <Table.ColHeader>Total</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {scheduleSummary
                .map((schedule) => ({
                  ...schedule,
                  initials: schedule.tele
                    .match(/\b(\w)/g).slice(0,2)
                }))
                .map((schedule) => (
                  <Table.Row>
                    <Table.Col>
                      <Grid.Row>
                        <Grid.Col md={3}>
                          <Avatar size="md" color="cyan">
                            {schedule.initials}
                          </Avatar>
                        </Grid.Col>
                        <Grid.Col md={9}>
                          <Text size="md">{schedule.tele}</Text>
                          {/* <Text size="sm" muted>
                            {tele.role}
                          </Text> */}
                        </Grid.Col>
                      </Grid.Row>
                    </Table.Col>
                    <Table.Col>{schedule.monday_count}</Table.Col>
                    <Table.Col>{schedule.tuesday_count}</Table.Col>
                    <Table.Col>{schedule.wednesday_count}</Table.Col>
                    <Table.Col>{schedule.thursday_count}</Table.Col>
                    <Table.Col>{schedule.friday_count}</Table.Col>
                    <Table.Col>{schedule.total_count}</Table.Col>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default StoreTeleListSchedule;
