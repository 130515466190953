import React from "react";
import Progress from "../../charts/Progress";
import { Card, Form, Grid } from "tabler-react";

const DashboardHeader = (props) => {
  const { data } = props;
  const { callSummary, rangeSelection, setRangeSelection } = data;
  const {
    active_stores,
    completed_calls,
    pending_calls,
    completed_calls_osa,
    completed_calls_serviceable,
    completed_ordered_calls,
    scheduled_stores,
  } = callSummary;

  return (
    <>
      <Grid.Row>
        <Grid.Col md={1} sm={12} className="col-header-dashboard">
          <Card>
            <Card.Body>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  lineHeight: "normal",
                }}
              >
                Select Report View
              </p>
              <Form.Select
                value={rangeSelection}
                onChange={(e) =>
                  setRangeSelection(e.target.value) ||
                  window.location.replace("?range=" + e.target.value)
                }
              >
                <option value="">SELECT ONE</option>
                <option value="Today">Today</option>
                <option value="Weekly">Weekly</option>
              </Form.Select>
            </Card.Body>
          </Card>
        </Grid.Col>
        <Grid.Col md={3} sm={12} className="col-header-dashboard">
          <Progress
            classname="progress"
            header="7-Eleven Stores"
            content={`${active_stores}`}
            progressColor="cyan"
            progressWidth={100}
          />
        </Grid.Col>
        <Grid.Col md={2} sm={12} className="col-header-dashboard">
          <Progress
            classname="progress"
            header={`${rangeSelection} - Completed Call Logs`}
            content={`${completed_calls}`}
            progressColor="cyan"
            progressWidth={(completed_calls / scheduled_stores) * 100}
          />
        </Grid.Col>
        <Grid.Col md={2} sm={12} className="col-header-dashboard">
          <Progress
            classname="progress"
            header={`${rangeSelection} - Pending Calls`}
            content={`${pending_calls}`}
            progressColor="cyan"
            progressWidth={(pending_calls / completed_calls) * 100}
          />
        </Grid.Col>

        <Grid.Col md={2} sm={12} className="col-header-dashboard">
          <Progress
            classname="progress"
            header={`${rangeSelection} - 711 OSA`}
            content={`${completed_calls_osa}`}
            progressColor="cyan"
            progressWidth={
              (completed_calls_osa / completed_ordered_calls) * 100
            }
          />
        </Grid.Col>

        <Grid.Col md={2} sm={12} className="col-header-dashboard">
          <Progress
            classname="progress"
            header={`${rangeSelection} - 711 SERVICEABLE`}
            content={`${completed_calls_serviceable}`}
            progressColor="cyan"
            progressWidth={
              (completed_calls_serviceable / completed_ordered_calls) * 100
            }
          />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default DashboardHeader;
