import React, { useState, useEffect } from "react";
import UploadForm from "../components/uploadForm";
import TableResults from "./components/tableResult";
import { Button, Card, Grid, Loader } from "tabler-react";
import dateFormat from "dateformat";
import useSerializationTool from "../../../hooks/api/GBT/useSerializationTool";
import { Helmet } from "react-helmet";

const Index = () => {
  function generateDate(date) {
    let d = new Date(date);
    return d;
  }
  const [extractedReport, setExtractedReport] = useState([]);
  const [listOfSerials, setListOfSerials] = useState([]);

  const { orders, orderSummary } = useSerializationTool(
    listOfSerials,
    extractedReport
  );

  const [fileName, setFileName] = useState("GATE GENERERATED SERIALS FOR ");
  const [customLabel, setCustomLabel] = useState(
    dateFormat(generateDate(Date()), "longDate")
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
    }, 1000);
  }, [extractedReport]);

  return (
    <>
      <Helmet>
        <title>GMS Basic Tool - Serial</title>
      </Helmet>
      <div>
        <Card>
          <Card.Header>
            <b>SERIALIZATION: </b> Upload Serial and Order Template
            <Card.Options>
              <a href="/template_forms/ORDERS TEMPLATE FORM.csv">
                <Button color="cyan" icon="download">
                  {" "}
                  Orders Template Form{" "}
                </Button>
              </a>
              <a href="/template_forms/SERIALS TEMPLATE FORM.csv">
                <Button color="teal" icon="download">
                  {" "}
                  Serials Template Form
                </Button>
              </a>
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={4} sm={12}>
                <UploadForm
                  onUpload={setListOfSerials}
                  label="UPLOAD LIST OF SERIALS"
                  type="serials"
                  value={listOfSerials}
                />
              </Grid.Col>
              {listOfSerials.length === 0 ? (
                ""
              ) : (
                <Grid.Col md={4} sm={12}>
                  <UploadForm
                    onUpload={setExtractedReport}
                    label="UPLOAD LIST OF ORDERS"
                    type="upload"
                    value={extractedReport}
                    fileName={fileName}
                    customLabel={customLabel}
                    setFileName={setFileName}
                    setCustomLabel={setCustomLabel}
                    shippingOptions={[]}
                  />
                </Grid.Col>
              )}
            </Grid.Row>
          </Card.Body>
        </Card>

        <div>
          {orders.length !== 0 ? (
            <>
              {loading ? (
                <div style={{ padding: "5px" }}>
                  <Loader className="loader" />
                </div>
              ) : (
                <TableResults
                  orders={orders}
                  orderSummary={orderSummary}
                  fileName={fileName}
                  customLabel={customLabel}
                />
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
