import React, { useState } from "react";
import { Card, Form } from "tabler-react";
import MonthReport from "./components/MonthReport";
import { useLocation } from "react-router";
import { CURRENT_YEAR } from "../../../../config";

const SummaryReport = () => {
  // const [year, setYear] = useState("2023");
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  let DR_month = query.get("month");
  let DR_year = query.get("year");

  const [month, setMonth] = useState(DR_month ? DR_month : "All");
  const [year, setYear] = useState(DR_year ? DR_year : CURRENT_YEAR);

  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <>
      <Card>
        <Card.Header>
          Tiktok Summary Report
          <Card.Options>
            <Form.Select
              value={month}
              onChange={(e) =>
                window.location.replace(
                  `/TiktokGOMOShop/Report/SummaryReport?month=${e.target.value}&year=${year}`
                )
              }
            >
              <option value="All">SELECT ALL</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </Form.Select>
            <Form.Select
              value={year}
              onChange={(e) =>
                window.location.replace(
                  `/TiktokGOMOShop/Report/SummaryReport?month=${month}&year=${e.target.value}`
                )
              }
            >
              <option value="">SELECT YEAR</option>
              <option value="2020">2020</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
            </Form.Select>
          </Card.Options>
        </Card.Header>
        {month !== "All" ? (
          <MonthReport data={{ month: `${month}-report`, year }} />
        ) : (
          <>
            {months.map((m, index) => (
              <MonthReport data={{ month: `${m}-report`, year }} />
            ))}
          </>
        )}
      </Card>
    </>
  );
};

export default SummaryReport;
