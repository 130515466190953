import { useState, useEffect } from "react";
import dateFormat from "dateformat";
import useDirectoryAPI from "../GVD/useDirectoryAPI";

function useShopeeTool(data, orderIDs, spx, jnt) {
  const { directory } = useDirectoryAPI();

  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [orderSummary, setOrderSummary] = useState([]);

  function getShipoutDate(shipping_options) {
    let d;

    if (shipping_options === "StandardLocalJTExpress") {
      d = new Date(jnt);
    } else if (shipping_options === "StandardLocalStandardDelivery") {
      d = new Date(spx);
    } else {
      d = new Date();
    }

    return d;
  }

  function generateOrders(data) {
    let excludeIDs = orderIDs.split("\n");

    console.log(data);
    console.log(directory);

    let list = [];
    let orderIds = [];

    //bundle fix temporary
    const generateBundle = (orderData, itemcodes) => {
      let itemCodes = itemcodes
        .split(",")
        .filter((v, i) => itemcodes.split(",").indexOf(v) == i);
      let items = [];

      let count = itemcodes.split(",");

      for (let index = 0; index < itemCodes.length; index++) {
        let gvdItem = directory.filter((v) => v.item_code === itemCodes[index]);

        if (gvdItem.length !== 0) {
          items.push({
            order_id: `*${orderData[0]}`,
            order_date: dateFormat(orderData[23], "longDate"),
            shipout_date: dateFormat(
              getShipoutDate(orderData[25].replace(/\s|-|&/g, "")),
              "longDate"
            ),
            tracking_number: `*${orderData[34]}`,
            shipping_option: orderData[36],
            product_name: `${orderData[7]} - ${gvdItem[0].variant_name}`,
            variation_name: orderData[8] ? orderData[8] : "--",
            gate_price: orderData[11].replace(/PHP |,/g, ""),
            shopee_price: orderData[11].replace(/PHP |,/g, ""),
            quantity:
              count.filter((c) => c === itemCodes[index]).length * orderData[9],
            product_subtotal: orderData[21].replace(/PHP |,/g, ""),
            buyer_paid_shipping_fee: orderData[19].replace(/PHP |,/g, ""),
            seller_voucher: orderData[14],
            product_sku: orderData[6] === "" ? orderData[5] : orderData[6],
            seller_coins: "",
            shopee_voucher: "",
            shopee_coins: "",
            variant_name: gvdItem[0].variant_name,
            item_code: itemCodes[index],
            aquisition: gvdItem[0].aquisition,
            gross_price: gvdItem[0].gross_price,
          });
        }
      }

      return items;
    };

    for (let index = 1; index < data.length; index++) {
      if (data[index][34] !== "") {
        if (!excludeIDs.find((id) => id.replace("*", "") === data[index][0])) {
          if (data[index][6] === "117010101116010103qlx") {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][9]; i++) {
              list.push(
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 100 Load - Smart Prepaid CallCard 100",
                  variation_name: "--",
                  gate_price: "100",
                  shopee_price: "100",
                  quantity: 1,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA SMART BUDDY GSM 100",
                  item_code: "116010103",
                  aquisition: "0.08",
                  gross_price: "100",
                },
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 100 Load - Smart 5G Tri-Cut Sim",
                  variation_name: "--",
                  gate_price: "40",
                  shopee_price: "40",
                  quantity: 1,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA SMART REGULAR SIM",
                  item_code: "117010101",
                  aquisition: "0.05",
                  gross_price: "40",
                }
              );
            }
          } else if (data[index][6] === "117010101116010103vho") {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][9]; i++) {
              list.push(
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 200 Load - Smart Prepaid CallCard 100",
                  variation_name: "--",
                  gate_price: "100",
                  shopee_price: "100",
                  quantity: 2,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA SMART BUDDY GSM 100",
                  item_code: "116010103",
                  aquisition: "0.08",
                  gross_price: "100",
                },
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 200 Load - Smart 5G Tri-Cut Sim",
                  variation_name: "--",
                  gate_price: "40",
                  shopee_price: "40",
                  quantity: 1,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA SMART REGULAR SIM",
                  item_code: "117010101",
                  aquisition: "0.05",
                  gross_price: "40",
                }
              );
            }
          } else if (data[index][6] === "117010101116010101cf9uq") {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][9]; i++) {
              list.push(
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 300 Load - Smart Prepaid CallCard 300",
                  variation_name: "--",
                  gate_price: "300",
                  shopee_price: "300",
                  quantity: 1,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA SMART BUDDY GSM 300",
                  item_code: "116010101",
                  aquisition: "0.08",
                  gross_price: "300",
                },
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "Smart Sim With Up To 21gb Data + LoadSIM + 300 Load - Smart 5G Tri-Cut Sim",
                  variation_name: "--",
                  gate_price: "40",
                  shopee_price: "40",
                  quantity: 1,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA SMART REGULAR SIM",
                  item_code: "117010101",
                  aquisition: "0.05",
                  gross_price: "40",
                }
              );
            }
          }
          //tnt
          else if (data[index][6] === "116050102117050101v751u") {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][9]; i++) {
              list.push(
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 100 Load - Talk 'n Text Prepaid Call Card 100",
                  variation_name: "--",
                  gate_price: "100",
                  shopee_price: "100",
                  quantity: 1,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA TALK 'N TEXT 100",
                  item_code: "116050102",
                  aquisition: "0.08",
                  gross_price: "100",
                },
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 100 Load - TNT 5G Ready Tri-Cut Sim	",
                  variation_name: "--",
                  gate_price: "30",
                  shopee_price: "30",
                  quantity: 1,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA TALK 'N TEXT SIMPACK",
                  item_code: "117050101",
                  aquisition: "0.05",
                  gross_price: "30",
                }
              );
            }
          } else if (data[index][6] === "116050102117050101dlwct") {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][9]; i++) {
              list.push(
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 200 Load - Talk 'n Text Prepaid Call Card 100",
                  variation_name: "--",
                  gate_price: "100",
                  shopee_price: "100",
                  quantity: 2,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA TALK 'N TEXT 100",
                  item_code: "116050102",
                  aquisition: "0.08",
                  gross_price: "100",
                },
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 200 Load - TNT 5G Ready Tri-Cut Sim	",
                  variation_name: "--",
                  gate_price: "30",
                  shopee_price: "30",
                  quantity: 1,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA TALK 'N TEXT SIMPACK",
                  item_code: "117050101",
                  aquisition: "0.05",
                  gross_price: "30",
                }
              );
            }
          } else if (data[index][6] === "116050101117050101qq29y") {
            orderIds.push(data[index][0]);
            for (let i = 0; i < data[index][9]; i++) {
              list.push(
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 300 Load - Talk 'n Text Prepaid Call Card 300",
                  variation_name: "--",
                  gate_price: "300",
                  shopee_price: "300",
                  quantity: 1,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA TALK 'N TEXT 300",
                  item_code: "116050101",
                  aquisition: "0.08",
                  gross_price: "300",
                },
                {
                  order_id: `*${data[index][0]}`,
                  order_date: dateFormat(data[index][23], "longDate"),
                  shipout_date: dateFormat(
                    getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                    "longDate"
                  ),
                  tracking_number: `*${data[index][34]}`,
                  shipping_option: data[index][36],
                  product_name:
                    "TNT Sim With Up To 21gb Data + LoadSIM + 300 Load - TNT 5G Ready Tri-Cut Sim	",
                  variation_name: "--",
                  gate_price: "30",
                  shopee_price: "30",
                  quantity: 1,
                  product_subtotal: data[index][21],
                  buyer_paid_shipping_fee: data[index][19],
                  product_sku: data[index][6],
                  seller_voucher: "",
                  seller_coins: "",
                  shopee_voucher: "",
                  shopee_coins: "",
                  variant_name: "OA TALK 'N TEXT SIMPACK",
                  item_code: "117050101",
                  aquisition: "0.05",
                  gross_price: "30",
                }
              );
            }
          } else {
            orderIds.push(data[index][0]);
            let bundle = directory.find(
              (v) =>
                v.item_sku ===
                (data[index][6] === "" ? data[index][5] : data[index][6])
            )
              ? directory.find(
                  (v) =>
                    v.item_sku ===
                    (data[index][6] === "" ? data[index][5] : data[index][6])
                ).item_code
              : "";
            if (bundle.split(",").length >= 2) {
              list.push(...generateBundle(data[index], bundle));
            } else {
              list.push({
                order_id: `*${data[index][0]}`,
                order_date: dateFormat(data[index][23], "longDate"),
                shipout_date: dateFormat(
                  getShipoutDate(data[index][25].replace(/\s|-|&/g, "")),
                  "longDate"
                ),
                tracking_number: `*${data[index][34]}`,
                shipping_option: data[index][36],
                product_name: `${data[index][7]}`,
                variation_name: data[index][8] ? data[index][8] : "--",
                gate_price: data[index][11].replace(/PHP |,/g, ""),
                shopee_price: data[index][11].replace(/PHP |,/g, ""),
                quantity: data[index][9],
                product_subtotal: data[index][21].replace(/PHP |,/g, ""),
                buyer_paid_shipping_fee: data[index][19].replace(/PHP |,/g, ""),
                seller_voucher: data[index][14],
                product_sku:
                  data[index][6] === "" ? data[index][5] : data[index][6],
                seller_coins: "",
                shopee_voucher: "",
                shopee_coins: "",
                variant_name: directory.find(
                  (v) =>
                    v.item_sku ===
                    (data[index][6] === "" ? data[index][5] : data[index][6])
                )
                  ? directory.find(
                      (v) =>
                        v.item_sku ===
                        (data[index][6] === ""
                          ? data[index][5]
                          : data[index][6])
                    ).variant_name
                  : "No Variant Name",
                item_code: directory.find(
                  (v) =>
                    v.item_sku ===
                    (data[index][6] === "" ? data[index][5] : data[index][6])
                )
                  ? directory.find(
                      (v) =>
                        v.item_sku ===
                        (data[index][6] === ""
                          ? data[index][5]
                          : data[index][6])
                    ).item_code
                  : "No Item Code",
                aquisition: directory.find(
                  (v) =>
                    v.item_sku ===
                    (data[index][6] === "" ? data[index][5] : data[index][6])
                )
                  ? directory.find(
                      (v) =>
                        v.item_sku ===
                        (data[index][6] === ""
                          ? data[index][5]
                          : data[index][6])
                    ).aquisition
                  : "No Aquisition",
                gross_price: directory.find(
                  (v) =>
                    v.item_sku ===
                    (data[index][6] === "" ? data[index][5] : data[index][6])
                )
                  ? directory.find(
                      (v) =>
                        v.item_sku ===
                        (data[index][6] === ""
                          ? data[index][5]
                          : data[index][6])
                    ).gross_price
                  : "No Gross Price",
              });
            }
          }
        }
      }
    }

    let orderCount = orderIds.filter((v, i) => orderIds.indexOf(v) == i).length;

    return { list, orderCount };
  }

  function generateSummaryReport(orders) {
    // console.log(orders);
    let item_codes = [];
    let summary = [];

    for (let index = 0; index < orders.length; index++) {
      if (parseInt(orders[index].quantity) > 1) {
        for (let index1 = 0; index1 < orders[index].quantity; index1++) {
          item_codes.push(orders[index].item_code);
        }
      } else {
        item_codes.push(orders[index].item_code);
      }
    }

    let filtered_item_codes = item_codes.filter(
      (v, i) => item_codes.indexOf(v) == i
    );

    for (let index = 0; index < filtered_item_codes.length; index++) {
      summary.push({
        variant_name: orders.filter(
          (order) => order.item_code === filtered_item_codes[index]
        )
          ? orders.filter(
              (order) => order.item_code === filtered_item_codes[index]
            )[0].variant_name
          : "--",
        item_code: filtered_item_codes[index],
        quantity: item_codes.filter(
          (order) => order === filtered_item_codes[index]
        ).length,
      });
    }

    // console.log(summary);

    return summary;
  }

  useEffect(() => {
    setOrders(generateOrders(data) ? generateOrders(data).list : "");
    setOrdersCount(generateOrders(data) ? generateOrders(data).orderCount : 0);
    setOrderSummary(
      generateOrders(data)
        ? generateSummaryReport(generateOrders(data).list)
        : ""
    );
  }, [data, orderIDs, spx, jnt]);

  return {
    orders,
    ordersCount,
    orderSummary,
  };
}

export default useShopeeTool;
