import React from "react";
import { Card, Form, Grid } from "tabler-react";
import DatePicker from "react-datepicker";

const Filter = (props) => {
  const { filter1, filter2, setFilter1, setFilter2 } = props.data;
  return (
    <>
      <Card>
        <Card.Status color="cyan" side />
        <Card.Body>
          <Grid.Row>
          <Grid.Col md={1}>Date Range</Grid.Col>
            <Grid.Col md={2}>
              <DatePicker
                selected={filter1}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setFilter1(start);
                  setFilter2(end);
                }}
                startDate={filter1}
                endDate={filter2}
                maxDate={new Date()}
                className="form-control"
                selectsRange
              />
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Filter;
