import { useState, useEffect } from "react";
import config from "./../../../config";
import axios from "axios";
import useUsers from "../Users/useUsers";

function usePreferences() {
  const { loginSession } = useUsers();

  const [preferences, setPreferences] = useState([]);
  const [selectedPreferences, setSelectedPreferences] = useState([]);

  function getPreferences() {
    if (loginSession) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/preferences-list/preferences`
        )
        .then(function (response) {
          let data = response.data;
          setPreferences(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function addPreferences(data, actions) {
    // console.log(data);
    if (loginSession) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/preferences-list/add_preferences`,
          {
            type: data.type,
            value: data.value,
            store_type: data.store_type,
            active: data.active,
            last_edit: loginSession.username,
          }
        )
        .then(function (response) {
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function updatePreferences(id, data, actions) {
    if (loginSession) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/preferences-list/update_preferences`,
          {
            id: id,
            type: data.type,
            value: data.value,
            store_type: data.store_type,
            active: data.active,
            last_edit: loginSession.username,
          }
        )
        .then(function (response) {
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getPreference(data) {
    if (loginSession) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/preferences-list/get_preferences`,
          {
            id: data.id,
          }
        )
        .then(function (response) {
          let data = response.data;
          setSelectedPreferences(data[0]);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    getPreferences();
  }, []);

  return {
    preferences,
    getPreferences,
    addPreferences,
    updatePreferences,
    getPreference,
    setSelectedPreferences,
    selectedPreferences,
  };
}

export default usePreferences;
