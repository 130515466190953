import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractReportShopee() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadOrders(order, fileName) {
    var headers = {
      order_date: "ORDER DATE",
      shipout_date: "SHIPOUT DATE",
      order_id: "ORDER ID",
      tracking_number: "TRACKING NUMBER",
      shipping_option: "SHIPPING OPTION",
      product_name: "	PRODUCT NAME",
      quantity: "QUANTITY",
      gate_price: "ORIGINAL PRICE",
      shopee_price: "DEAL PRICE",
      product_subtotal: "PRODUCT SUBTOTAL",
      buyer_paid_shipping_fee: "BUYER PAID SHIPPING FEE",
      seller_voucher: "SELLER VOUCHER(PHP)",
      seller_coins: "SELLER ABSORBED COIN CASHBACK",
      shopee_voucher: "SHOPEE VOUCHER(PHP)",
      shopee_coins: "SHOPEE COINS OFFSET(PHP)",
      variant_name: "GATE VARIANT NAME",
      gross_price: "GROSS",
      aquisition: "AQUISITION",
      item_code: "ITEM CODE",
      variation_name: "VARIATION",
    };

    var itemsNotFormatted = order;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        order_date: dateFormat(item.order_date, "paddedShortDate"),
        shipout_date: dateFormat(item.shipout_date, "paddedShortDate"),
        order_id: item.order_id,
        tracking_number: item.tracking_number,
        shipping_option: item.shipping_option,
        product_name: item.product_name.replace(/,/g, "-"),
        quantity: item.quantity,
        gate_price: item.gate_price,
        shopee_price: item.shopee_price,
        product_subtotal: item.product_subtotal,
        buyer_paid_shipping_fee: item.buyer_paid_shipping_fee,
        seller_voucher: item.seller_voucher
          ? parseFloat(item.seller_voucher.replace("PHP ", ""))
          : 0,
        seller_coins: item.seller_coins,
        shopee_voucher: item.shopee_voucher,
        shopee_coins: item.shopee_coins,
        variant_name: item.variant_name,
        gross_price: item.gross_price,
        aquisition: item.aquisition,
        item_code: item.item_code,
        variation_name: item.variation_name,
      });
    });

    var fileTitle = `${fileName}_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  function downloadSummary(summary, fileName) {
    var headers = {
      variant_name: "GATE VARIANT NAME",
      item_code: "ITEM CODE",
      quantity: "QUANTITY",
    };

    var itemsNotFormatted = summary;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        variant_name: item.variant_name,
        item_code: item.item_code,
        quantity: item.quantity,
      });
    });

    // var fileTitle = `${dateFormat(new Date())}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, `SUMMARY - ${fileName}`); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return {
    downloadOrders,
    downloadSummary,
  };
}

export default useExtractReportShopee;
