import { useState, useEffect } from "react";
import config from "../../../config";
import axios from "axios";
import dateFormat from "dateformat";

import useUsers from "../Users/useUsers";
import useQueryURL from "../../services/useQueryURL";
import useCustomFunctions from "./../../services/useCustomFunctions";

function useOtherAccountsCalls() {
  const { getQuery } = useQueryURL();
  const { loginSession, user } = useUsers();
  const { getOnePlusDay} = useCustomFunctions()
  

  const [callLogs, setCallLogs] = useState([]);
  const [allCallLogs, setAllCallLogs] = useState([])
  const dates = getQuery("dates") ? getQuery("dates").split("|") : [new Date(), new Date()]

  // console.log(dates);

  function getOtherAccountsCalls() {
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/other_accounts/oa_calls`, {
          start: new Date(dates[0]),
          end: new Date(getOnePlusDay(new Date(dates[1]))),
          year: dateFormat(new Date(), "yyyy")
        })
        .then(function (response) {
          let data = response.data;
          setCallLogs(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getOtherAccountsAllCalls() {
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/other_accounts/oa_all_calls`)
        .then(function (response) {
          let data = response.data;
          setAllCallLogs(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    getOtherAccountsCalls();
  }, []);

  return { callLogs, allCallLogs, getOtherAccountsAllCalls };
}
export default useOtherAccountsCalls;
