import React, { useState, useEffect } from "react";
import { Button, Card, Table, Loader, Form, Icon } from "tabler-react";
import Pagination from "../../../../components/Pagination";
import dateFormat from "dateformat";
import Filter from "./Filter";

import useQueryURL from "../../../../hooks/services/useQueryURL";
import useCustomFunctions from "../../../../hooks/services/useCustomFunctions";
import useLazadaAPI from "../../../../hooks/api/LazadaShop/useLazadaAPI";
import useExtractReport from "../../../../hooks/api/LazadaShop/extractions/useExtractReport";

const SIDashboard = () => {
  const { getQuery } = useQueryURL();
  const { formatNumberComma } = useCustomFunctions();
  const { downloadSISummary } = useExtractReport();

  let DR_month = getQuery("month");
  let page = getQuery("page");

  const [month, setMonth] = useState(DR_month ? DR_month : "January");
  const [year, setYear] = useState(
    getQuery("year") ? getQuery("year") : "2021"
  );

  const { sIList } = useLazadaAPI(month, "");

  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(page ? page : 1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);
  const [search, setSearch] = useState("");

  const [newDrList, setNewDrList] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      if (sIList.length !== 0) {
        setNewDrList(
          sIList
            .filter((o) => o.SI_number !== "--")
            .filter((o) =>
              search
                ? o.dr_number.includes(search) || o.SI_number.includes(search)
                : o
            )
            .filter((o) => (month ? o.DR_month.includes(month) : o))
            .filter((o) => (year ? o.DR_year.includes(year) : o))
        );
        setLoading(false);
      }
    }, 1000);
  }, [sIList, search, month]);

  return (
    <>
      <Card>
        <Card.Header>SI Dashboards</Card.Header>
        <Filter
          data={{
            src: "SIDashboard",
            month,
            year,
            search,
            setSearch,
            newDrList,
            downloadSISummary,
          }}
        />
        <Card.Body>
          {loading ? (
            <Loader />
          ) : (
            <>
              {newDrList.length != 0 ? (
                <Table
                  responsive
                  highlightRowOnHover
                  hasOutline
                  verticalAlign="center"
                  cards
                  className="text-nowrap"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>#</Table.ColHeader>
                      <Table.ColHeader>DR Month</Table.ColHeader>
                      <Table.ColHeader>DR Number</Table.ColHeader>
                      <Table.ColHeader>SI Number</Table.ColHeader>
                      <Table.ColHeader>SI Date</Table.ColHeader>
                      <Table.ColHeader>Gate Price Gross</Table.ColHeader>

                      <Table.ColHeader>Globe Discount</Table.ColHeader>
                      <Table.ColHeader>Smart Discount</Table.ColHeader>
                      <Table.ColHeader>Lazada Price</Table.ColHeader>
                      <Table.ColHeader>Payment Release</Table.ColHeader>
                      <Table.ColHeader>Commission Fee</Table.ColHeader>
                      <Table.ColHeader>Transaction Fee</Table.ColHeader>
                      <Table.ColHeader>Status</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {newDrList
                      .sort(function (a, b) {
                        return a.id - b.id;
                      })
                      .slice(
                        activePage == 1 ? 0 : (activePage - 1) * 30,
                        activePage * 30
                      )
                      .map((list, index) => (
                        <>
                          {list.SI_number !== "--" ? (
                            <Table.Row>
                              <Table.Col>
                                {activePage == 1
                                  ? index + 1
                                  : index + 1 + (activePage - 1) * 30}
                              </Table.Col>
                              <Table.Col>{list.DR_month}</Table.Col>
                              <Table.Col>
                                {list.dr_number.replace("*", "")}
                              </Table.Col>
                              <Table.Col>
                                {list.SI_number.replace("*", "")}
                              </Table.Col>
                              <Table.Col>
                                {list.SI_Date
                                  ? dateFormat(
                                      new Date(list.SI_Date),
                                      "fullDate"
                                    )
                                  : list.SI_Date}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.gate_price)}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.globe_discount)}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.smart_discount)}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(
                                  list.gate_price -
                                    (list.smart_discount + list.globe_discount)
                                )}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.amount_release)}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.commissionFee)}
                              </Table.Col>
                              <Table.Col>
                                {formatNumberComma(list.paymentFee)}
                              </Table.Col>
                              <Table.Col>
                                <a
                                  href={`/LazadaShop/Orders?dr_number=${list.dr_number.replace(
                                    "*",
                                    ""
                                  )}&SI_number=${
                                    list.SI_number
                                  }&month=${month}&year=${list.DR_year}`}
                                  target="_blank"
                                >
                                  <Button color="cyan">Completed</Button>
                                </a>
                              </Table.Col>
                            </Table.Row>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                  </Table.Body>
                </Table>
              ) : (
                ""
              )}
            </>
          )}
          {newDrList.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          ) : (
            ""
          )}
        </Card.Body>
        {newDrList.length <= 30 || loading ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={30}
            totalItemsCount={newDrList.length}
            pageRangeDisplayed={pageRangeDisplayed}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default SIDashboard;
