import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractDirectory() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadList(list) {
    var headers = {
      variant_name: "variant_name",
      item_code: "item_code",
      item_sku: "item_sku",
      telco: "telco",
      selling_price: "selling_price",
      shopee_price: "shopee_price",
      laz_price: "laz_price",
      gross_price: "gross_price",
      aquisition: "aquisition",
      shop_name: "shop_name",
    };

    var itemsNotFormatted = list;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        variant_name: item.variant_name,
        item_code: item.item_code,
        item_sku: item.item_sku,
        telco: item.telco,
        selling_price: item.selling_price,
        shopee_price: item.shopee_price,
        laz_price: item.laz_price,
        gross_price: item.gross_price,
        aquisition: item.aquisition,
        shop_name: item.shop_name,
      });
    });

    var fileTitle = `GATE_DIRECTORY_LIST_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, `${fileTitle} - `); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return {
    downloadList,
  };
}

export default useExtractDirectory;
