import { useState, useEffect } from "react";
import dateFormat from "dateformat";
import config from "./../../../config";
import axios from "axios";

//hooks
import useCustomFunctions from "./../../services/useCustomFunctions";
import use711Stores from "./use711Stores";
import useUsers from "../Users/useUsers";

function use711Statistics() {
  const { telemarketers, loginSession } = useUsers();

  const { stores } = use711Stores();
  const { generateMonth } = useCustomFunctions();

  const [calls, setCalls] = useState([]);
  const [summary, setSummary] = useState([]);
  const [scheduleSummary, setScheduleSummary] = useState([]);
  const [scheduleWeeklySummary, setScheduleWeeklySummary] = useState([]);

  function getStoreCallLogs() {
    if (loginSession) {
      axios
        .post(`${config.host}:${config.port}/api/v2/711statistics/calls`, {
          curMonth: dateFormat(new Date(), "m"),
          prevMonth: dateFormat(generateMonth(new Date(), 1, "prev"), "m"),
          curYear: dateFormat(new Date(), "yyyy"),
          user: loginSession.username,
        })
        .then(function (response) {
          let data = response.data;
          setCalls(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function generateSummary(calls) {
    // let receiverList = calls
    //   .filter(
    //     (call) => call.remarks === "Ordered" && call.call_status === "Completed"
    //   )
    //   .map((call) => call.received_by)
    // .filter(
    //   (v, i) =>
    //     calls
    //       .filter((call) => call.remarks === "Ordered")
    //       .map((call) => call.received_by)
    //       .indexOf(v) == i
    // );

    let receiverList = telemarketers
      .map((tele) => tele.full_name)
      .filter(
        (v, i) => telemarketers.map((tele) => tele.full_name).indexOf(v) == i
      );
    let summary1 = receiverList
      .map((r) => ({
        receiver: r,
        username: telemarketers.find((tele) => tele.full_name === r)
          ? telemarketers.find((tele) => tele.full_name === r).username
          : "",
        //calls
        ordered_calls_received: calls.filter(
          (call) =>
            call.received_by === r &&
            call.remarks === "Ordered" &&
            call.call_status === "Completed"
        ),

        ordered_calls: calls.filter(
          (call) =>
            call.received_by === r &&
            call.telemarketer !== r &&
            call.remarks === "Ordered" &&
            call.call_status === "Completed"
        ),

        ordered_calls_assigned_only: calls.filter(
          (call) =>
            call.received_by === r &&
            call.telemarketer === r &&
            call.remarks === "Ordered" &&
            call.call_status === "Completed"
        ),

        ordered_calls_by_assigned_store: calls.filter(
          (call) =>
            call.telemarketer === r &&
            call.remarks === "Ordered" &&
            call.call_status === "Completed"
        ),

        //amounts
        order_amount_recieved: calls
          .filter(
            (call) =>
              call.received_by === r &&
              call.remarks === "Ordered" &&
              call.call_status === "Completed"
          )
          .reduce((a, b) => +a + +b.amount, 0),
        order_amount: calls
          .filter(
            (call) =>
              call.received_by === r &&
              call.telemarketer !== r &&
              call.remarks === "Ordered" &&
              call.call_status === "Completed"
          )
          .reduce((a, b) => +a + +b.amount, 0),
        order_amount_assigned_only: calls
          .filter(
            (call) =>
              call.received_by === r &&
              call.telemarketer === r &&
              call.remarks === "Ordered" &&
              call.call_status === "Completed"
          )
          .reduce((a, b) => +a + +b.amount, 0),
        order_amount_by_assigned_store: calls
          .filter(
            (call) =>
              call.telemarketer === r &&
              call.remarks === "Ordered" &&
              call.call_status === "Completed"
          )
          .reduce((a, b) => +a + +b.amount, 0),

        //calledStores
        called_store: calls.filter(
          (call) => call.received_by === r && call.call_status === "Completed"
        ),
      }))

      .map((r) => ({
        ...r,
        ordered_store_count: r.ordered_calls_received
          .map((s) => s.store_number)
          .filter(
            (v, i) =>
              r.ordered_calls_received.map((s) => s.store_number).indexOf(v) ==
              i
          ),
        called_store_count: r.called_store
          .map((s) => s.store_number)
          .filter(
            (v, i) => r.called_store.map((s) => s.store_number).indexOf(v) == i
          ),
      }));

    setSummary(summary1);
  }

  function generateScheduleSummary(stores, telemarketers) {
    let teleList = telemarketers
      .filter(
        (tele) =>
          tele.role === "Telemarketer" &&
          tele.username !== "closed" &&
          tele.username !== "closed1" &&
          tele.username !== "adminTemp"
      )
      .map((tele) => ({ full_name: tele.full_name, username: tele.username }));

    let summary = teleList
      .map((t) => ({
        tele: t.full_name,
        username: t.username,
        monday: stores.filter(
          (store) =>
            store.schedule === "Monday" &&
            store.active === "Yes" &&
            store.telemarketer === t.full_name
        ),
        tuesday: stores.filter(
          (store) =>
            store.schedule === "Tuesday" &&
            store.active === "Yes" &&
            store.telemarketer === t.full_name
        ),
        wednesday: stores.filter(
          (store) =>
            store.schedule === "Wednesday" &&
            store.active === "Yes" &&
            store.telemarketer === t.full_name
        ),
        thursday: stores.filter(
          (store) =>
            store.schedule === "Thursday" &&
            store.active === "Yes" &&
            store.telemarketer === t.full_name
        ),
        friday: stores.filter(
          (store) =>
            store.schedule === "Friday" &&
            store.active === "Yes" &&
            store.telemarketer === t.full_name
        ),
      }))
      .map((t) => ({
        ...t,
        monday_count: t.monday.length,
        tuesday_count: t.tuesday.length,
        wednesday_count: t.wednesday.length,
        thursday_count: t.thursday.length,
        friday_count: t.friday.length,
        total_count:
          t.monday.length +
          t.tuesday.length +
          t.wednesday.length +
          t.thursday.length +
          t.friday.length,
      }));

    let weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

    let weeklySummary = weekDays.map((week) => ({
      day: week,
      count: stores.filter(
        (store) => store.schedule === week && store.active === "Yes"
      ).length,
    }));

    setScheduleSummary(summary);
    setScheduleWeeklySummary(weeklySummary);
  }

  useEffect(() => {
    getStoreCallLogs();
    generateScheduleSummary(stores, telemarketers);
  }, [stores, telemarketers]);

  return {
    calls,
    summary,
    generateSummary,
    scheduleSummary,
    scheduleWeeklySummary,
  };
}

export default use711Statistics;
