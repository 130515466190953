import { useEffect, useState } from "react";
import dateFormat from "dateformat";
import useOtherAccounts from "./useOtherAccounts";
import useOtherAccountsCalls from "./useOtherAccountsCalls";
import useUsers from "../Users/useUsers";

function useOADashboard() {
  const { loginSession, user } = useUsers();
  const { telemarketerOA, getScheduledToday, todayScheduled } =
    useOtherAccounts();
  const { allCallLogs, getOtherAccountsAllCalls } = useOtherAccountsCalls();

  const [dashboard, setDashboard] = useState([]);

  console.log(allCallLogs);

  function getDashboard(allCallLogs, telemarketerOA) {
    if (loginSession.length != 0) {
      let data = telemarketerOA
        .map((tele) => ({
          full_name: tele.full_name,
          username: tele.username,
          pending_calls: allCallLogs.filter(
            (call) =>
              call.call_status === "Pending" && call.ae_name === tele.full_name
          ),
          initials: tele.full_name.match(/\b(\w)/g).slice(0, 2),
          role: tele.role,
        }))
        .map((tele) => ({
          ...tele,
          dates: tele.pending_calls
            .map((p) => p.call_date)
            .sort((a, b) => new Date(a) - new Date(b))
            .map((p) => dateFormat(p, "paddedShortDate")),
        }))
        .map((tele) => ({
          ...tele,
          start_date: tele.dates.length != 0 ? tele.dates[0] : new Date(),
          end_date:
            tele.dates.length > 1
              ? tele.dates[tele.dates.length - 1]
              : tele.dates[0],
        }))
        .filter((tele) => tele.full_name === user.full_name);

      setDashboard(data);
    }
  }

  useEffect(() => {
    getOtherAccountsAllCalls();
  }, []);

  useEffect(() => {
    getDashboard(allCallLogs, telemarketerOA);
  }, [telemarketerOA, allCallLogs]);

  return {
    dashboard,
    todayScheduled: todayScheduled.filter(
      (account) =>
        !allCallLogs
          .filter(
            (call) =>
              dateFormat(call.call_date, "paddedShortDate") ===
              dateFormat(new Date(), "paddedShortDate")
          )
          .map((call) => call.client_id)
          .includes(account.client_id)
    ),
    getScheduledToday,
  };
}

export default useOADashboard;
