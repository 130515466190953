import React from "react";
import { Table, Card, Avatar, Text, Grid, Button, Icon } from "tabler-react";
import Tooltip from "react-simple-tooltip";
import useOADashboard from "../../../hooks/api/AMS_other/useOADashboard";

const Dashboard = () => {
  const { dashboard } = useOADashboard();
  // console.log(dashboard);
  return (
    <>
      <Card>
        <Card.Body>
          <Table
            responsive
            highlightRowOnHover
            hasOutline
            verticalAlign="center"
            cards
            className="text-nowrap"
          >
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>Telemarketer OA</Table.ColHeader>
                {/* <Table.ColHeader>Completed Stores</Table.ColHeader> */}
                <Table.ColHeader>Pending Calls</Table.ColHeader>
                <Table.ColHeader>Actions</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {dashboard
                .map((tele) => ({
                  ...tele,
                  initials: tele.full_name.match(/\b(\w)/g).slice(0, 2),
                }))
                .map((tele, index) => (
                  <Table.Row key={index}>
                    <Table.Col>
                      <Grid.Row>
                        <Grid.Col md={3}>
                          <Avatar size="md" color="cyan">
                            {tele.initials}
                          </Avatar>
                        </Grid.Col>
                        <Grid.Col md={9}>
                          <Text size="md">{tele.full_name}</Text>
                          <Text size="sm" muted>
                            {tele.role}
                          </Text>
                        </Grid.Col>
                      </Grid.Row>
                    </Table.Col>
                    <Table.Col>{tele.pending_calls.length}</Table.Col>
                    <Table.Col>
                      {tele.pending_calls.length == 0 ? (
                        ""
                      ) : (
                        <Tooltip
                          content="Pending Calls"
                          radius={5}
                          padding={5}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Button
                            color="teal"
                            onClick={() =>
                              window.location.replace(
                                `/AMS_other/AllCallLogs?call_status=Pending&dates=${tele.start_date}|${tele.end_date}`
                              )
                            }
                          >
                            <Icon prefix="fa" name="mail-reply" />
                          </Button>
                        </Tooltip>
                      )}
                    </Table.Col>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default Dashboard;
