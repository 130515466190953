import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Grid, Alert, Icon } from "tabler-react";
import { Helmet } from "react-helmet";
import MainMenuSide from "./menu/MainMenuSide";
import Dashboard from "./pages/subPages/dashboard/Dashboard";
import CompletedCalls from "./pages/subPages/calls/CompletedCalls";
import PendingCalls from "./pages/subPages/calls/PendingCalls";
import UncalledStores from "./pages/subPages/calls/UncalledStores";
import UncalledStoresLastWeek from "./pages/subPages/calls/UncalledStoresLastWeek";
import AllCallLogs from "./pages/subPages/calls/AllCallLogs";
import Profile from "./pages/subPages/profile";
import MasterList from "./pages/stores/MasterList";
import AddForm from "./pages/stores/Form";
import UpdateForm from "./pages/stores/Form";
import Preferences from "./pages/subPages/preferences";
import Statistics from "./pages/subPages/statistics";

//Bulk Edit
import BulkEdit from "./pages/subPages/edit/BulkEdit";

//hooks
import useUsers from "../../hooks/api/Users/useUsers";
import useNotifications from "../../hooks/api/useNotifications";

const Index = () => {
  const { loginSession, user } = useUsers();
  const {getNotifications, notifications, markAsRead} = useNotifications()

  const [accessLevel, setAccessLevel] = useState("none");

  useEffect(() => {
    if (loginSession.length == 0) {
      window.location.replace("/login");
    }
  });

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "711-account-management")
            .access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  const { announcements } = useNotifications();

  // console.log(announcements);

  return (
    <>
      <Helmet>
        <title>GMS - 711 Account Management Tool</title>
      </Helmet>
      {announcements.filter(
        (a) => a.status == 0 && new Date() < new Date(a.expiration)
      ).length != 0
        ? announcements
            .filter((a) => a.status == 0 && new Date() < new Date(a.expiration))
            .map((a) => (
              <Grid.Row>
                <Grid.Col md={12}>
                  <Alert type={a.rank} isDismissible className="alert-css">
                    <Icon
                      prefix="fa"
                      name={a.type === "announcements" ? "bullhorn" : "trophy"}
                    />
                    <b> {a.title}</b> <span>{a.message}</span>
                  </Alert>
                </Grid.Col>
              </Grid.Row>
            ))
        : ""}
      <div style={{ margin: "5px" }}>
        {user.length != 0 ? (
          <Grid.Row>
            <Grid.Col md={1} className="col-dashboard-container">
              <MainMenuSide
                data={{
                  accessLevel,
                  user,
                  rewards: notifications.filter(
                    (notif) => notif.type === "achievements"
                  ),
                  markAsRead,
                  getNotifications
                }}
              />
            </Grid.Col>
            <Grid.Col md={11} className="col-dashboard-container">
              <Router>
                <Switch>
                  {/*AMS Routes*/}
                  <Route exact path="/AMS" component={Dashboard} />
                  <Route
                    path="/AMS/CallLogs711History/AllCallLogs"
                    component={AllCallLogs}
                  />
                  <Route
                    path="/AMS/CallLogs711History/Completed"
                    component={CompletedCalls}
                  />
                  <Route
                    path="/AMS/CallLogs711History/Pending"
                    component={PendingCalls}
                  />
                  <Route
                    path="/AMS/CallLogs711History/Uncalled"
                    component={UncalledStores}
                  />
                  <Route
                    path="/AMS/CallLogs711History/UncalledLastWeek"
                    component={UncalledStoresLastWeek}
                  />
                  <Route path="/AMS/Account/711/Profile" component={Profile} />

                  {/* Store List */}
                  <Route path="/AMS/MasterList/711" component={MasterList} />
                  <Route path="/AMS/AddForm/711" component={AddForm} />
                  <Route path="/AMS/UpdateForm/711" component={UpdateForm} />

                  {/* Preferences */}
                  <Route path="/AMS/Preferences/711" component={Preferences} />

                  {/* Stats */}
                  <Route path="/AMS/Statistics/711" component={Statistics} />

                  {/* Bulk Edit */}
                  <Route path="/AMS/BulkEdit/711" component={BulkEdit} />
                </Switch>
              </Router>
            </Grid.Col>
          </Grid.Row>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Index;
