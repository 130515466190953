import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractReports() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadCallLogs(calls) {
    var headers = {
      id: "ID",
      client_id: "CLIENT ID",
      account_holder: "CLIENT NAME",
      ae_name: "AE NAME",
      amount: "AMOUNT",
      call_date: "CALL DATE",
      call_status: "CALL STATUS",
      notes: "NOTES",
      received_by: "RECIEVED BY",
      account: "ACCOUNT",
      nature_of_business: "CATEGORY",
      remarks: "REMARKS",
    };

    var itemsNotFormatted = calls;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        id: item.id,
        client_id: item.client_id,
        account_holder: item.account_holder,
        ae_name: item.ae_name,
        amount: item.amount.replace(/(\r\n|\n|\r|,)/gm, ""),
        call_date: item.call_date,
        call_status: item.call_status,
        notes: item.notes.replace(/(\r\n|\n|\r|,)/gm, ""),
        received_by: item.received_by,
        account: item.particulars,
        nature_of_business: item.nature_of_business,
        remarks: item.remarks,
      });
    });

    var fileTitle = `Other_Accounts_Calls_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

//   function downloadAccounts(accounts) {
//     var headers = {
//       id: "ID",
//       account_id: "ACCOUNT ID",
//       client_id: "CLIENT ID",
//       account_holder: "CLIENT NAME",
//     //   client_name: "",
//     //   location: "",
//       address: "ADDRESS",
//       contact_person: "ATTN: Ms. Athena Bromeo",
//       business_person: "",
//       mobile_phone: "",
//       fax_number: "",
//       designation: "",
//       email_address: "",
//       email_address_business: "",
//       tin_id: "123-379-255-000",
//       business_owner: "",
//       operating_hours: "",
//       platforms: "",
//       particulars: "",
//       no_of_branches: 0,
//       nature_of_business: "",
//       contract_date: "",
//       ae_name: "",
//       status: "",
//       date_forwarded_to_ae: "",
//       set_up_amount: "",
//       type_of_registratin: "",
//       terms: "COD",
//       remittance_license: "",
//       postal_code: 0,
//       active: 0,
//       ecash_scheme_type: "",
//       bir_form: "none",
//       price_class: "AlyannaFrnDsc",
//     };

//     var itemsNotFormatted = accounts;

//     var itemsFormatted = [];

//     // format the data
//     itemsNotFormatted.forEach((item) => {
//       itemsFormatted.push({
//         id: item.id,
//         client_id: item.client_id,
//         account_holder: item.account_holder,
//         ae_name: item.ae_name,
//         amount: item.amount.replace(/(\r\n|\n|\r|,)/gm, ""),
//         call_date: item.call_date,
//         call_status: item.call_status,
//         notes: item.notes.replace(/(\r\n|\n|\r|,)/gm, ""),
//         received_by: item.received_by,
//         remarks: item.remarks,
//       });
//     });

//     var fileTitle = `Other_Accounts_Calls_${dateFormat()}`; // or 'my-unique-title'

//     exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
//   }

  return { downloadCallLogs };
}

export default useExtractReports;
