import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { Button, Card, Grid, Icon, Table } from "tabler-react";
import useLazadaPaymentReleaseAPI from "../../../../hooks/api/LazadaShop/useLazadaPaymentReleaseAPI";

const UploadPaymentRelease = () => {
  const { uploadPaymentRelease } = useLazadaPaymentReleaseAPI();
  const [uploadedPayments, setUploadedPayments] = useState([]);
  const [files, setFiles] = useState([]);
  const [finalPaymentList, setFinalPaymentList] = useState([]);

  const [success, setSuccess] = useState(false);

  console.log(uploadedPayments);

  const toObject = (data) => {
    let list = data
      // .filter((d) =>
      //   ["Commission", "Payment Fee", "Item Price Credit"].includes(d[2])
      // )
      .map((d) => ({
        transaction_date: d[0],
        fee_name: d[2],
        transaction_number: `*${d[3]}`,
        details: d[4],
        amount: d[7].replace(/-/g, ""),
        vat_amount: d[8],
        paid_status: d[12],
        order_number: `*${d[13]}`,
        order_item_number: `*${d[14]}`,
        order_item_status: d[15],
        shipping_provider: d[16],
        referrence: d[22],
      }));

    setFinalPaymentList(list);
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={6}>
              <CSVReader
                disabled={files.reduce((a, b) => a + b.items, 0) >= 2000}
                onFileLoaded={(data, fileInfo) => {
                  files.find((file) => file.name === fileInfo.name)
                    ? alert("duplicate file!")
                    : setFiles([
                        ...files,
                        { ...fileInfo, items: data.length - 1 },
                      ]) ||
                      setUploadedPayments([
                        ...uploadedPayments,
                        ...data
                          .slice(1, data.length - 1)
                          .map((d) => [
                            ...d,
                            fileInfo.name.replace(/.csv/g, ""),
                          ]),
                      ]);
                }}
              />
              {files.reduce((a, b) => a + b.items, 0) >= 2000 ? (
                <p style={{ color: "red" }}>
                  Maximum payment release size reach. Please upload first the
                  current selection.
                </p>
              ) : (
                ""
              )}
            </Grid.Col>
            <Grid.Col md={6}>
              <p>Uploaded Files</p>
              {files.length !== 0 ? (
                <>
                  {files.map((file) => (
                    <p>
                      ({file.items} items) {file.name}
                    </p>
                  ))}

                  {finalPaymentList.length !== 0 ? (
                    <>
                      {success ? (
                        <p>UPLOADED TO DATABASE!</p>
                      ) : (
                        <Button
                          color="cyan"
                          onClick={() =>
                            uploadPaymentRelease(
                              finalPaymentList,
                              () => alert("uploaded!") || setSuccess(true)
                            )
                          }
                        >
                          <Icon prefix="fa" name="upload" />
                          Upload Generated Payments
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      color="teal"
                      onClick={() => toObject(uploadedPayments)}
                    >
                      <Icon prefix="fa" name="print" />
                      Generate
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          {finalPaymentList.length !== 0 ? (
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Transaction Date</Table.ColHeader>
                  <Table.ColHeader>Fee Name</Table.ColHeader>
                  <Table.ColHeader>Transaction No.</Table.ColHeader>
                  <Table.ColHeader>Details</Table.ColHeader>
                  <Table.ColHeader>Amount</Table.ColHeader>
                  <Table.ColHeader>Vat Amount</Table.ColHeader>
                  <Table.ColHeader>Status</Table.ColHeader>
                  <Table.ColHeader>Order Number</Table.ColHeader>
                  <Table.ColHeader>Order Item Number</Table.ColHeader>
                  <Table.ColHeader>Shipping Provider</Table.ColHeader>
                  <Table.ColHeader>referrence</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finalPaymentList.map((payment) => (
                  <Table.Row>
                    <Table.Col>{payment.transaction_date}</Table.Col>
                    <Table.Col>{payment.fee_name}</Table.Col>
                    <Table.Col>{payment.transaction_number}</Table.Col>
                    <Table.Col>{payment.details}</Table.Col>
                    <Table.Col>{payment.amount}</Table.Col>
                    <Table.Col>{payment.vat_amount}</Table.Col>
                    <Table.Col>{payment.paid_status}</Table.Col>
                    <Table.Col>{payment.order_number}</Table.Col>
                    <Table.Col>{payment.order_item_number}</Table.Col>
                    <Table.Col>{payment.shipping_provider}</Table.Col>
                    <Table.Col>{payment.referrence}</Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default UploadPaymentRelease;
