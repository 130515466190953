import React, { useState, useEffect } from "react";
import { Card, Grid, Button, Icon } from "tabler-react";
import SideMenu from "./SideMenu";
import EmployeeList from "./EmployeeList";
import HolidayList from "./HolidayList";

import useTimeKeeping from "../../hooks/api/HR/useTimeKeeping";

const Manager = () => {
  const { employees,addEmployees,updateEmployees, holidays, addHolidays, updateHoliday } = useTimeKeeping();
  const [tab, setTab] = useState(0);

  const [newList, setNewList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);

  useEffect(() => {
    setNewList(
      employees
        .map((e) => ({
          ...e,
          e_id: parseInt(e.employee_id.replace("G", "")),
        }))
        .sort((a, b) => a.e_id - b.e_id)
    );
  }, [employees]);

  useEffect(() => {
    setHolidayList(holidays);
  }, [holidays]);

  return (
    <>
      <div style={{ margin: "5px" }}>
        <Grid.Row>
          <Grid.Col md={2}>
            <SideMenu data={{ setTab }} />
          </Grid.Col>
          <Grid.Col md={10}>
            <Card>
              {tab === 0 ? <EmployeeList data={{ newList, addEmployees,updateEmployees }} /> : ""}
              {tab === 1 ? (
                <HolidayList
                  data={{ holidayList, addHolidays, updateHoliday }}
                />
              ) : (
                ""
              )}
            </Card>
          </Grid.Col>
        </Grid.Row>
      </div>
    </>
  );
};

export default Manager;
