import React from "react";
import { Button, Card, Grid } from "tabler-react";

const Pagination = (props) => {
  const {
    activePage,
    itemsCountPerPage,
    totalItemsCount,
    pageRangeDisplayed,
    // changePage,
    setActivePage,
    setPageRangeDisplayed,
  } = props;

  function pages() {
    let list = [];
    for (
      let index = 0;
      index < Math.ceil(totalItemsCount / itemsCountPerPage);
      index++
    ) {
      list.push(index + 1);
    }

    return list;
  }

  return (

      <Card.Body>
        <Grid.Row>
          <Grid.Col md={3} sm={12}>
            {`Showing ${activePage == 1 ? 1 : (activePage - 1) * itemsCountPerPage + 1} to ${
              activePage === Math.ceil(totalItemsCount / itemsCountPerPage)
                ? totalItemsCount
                : activePage * itemsCountPerPage
            } of ${totalItemsCount} entries`}
          </Grid.Col>

          <Grid.Col md={1} sm={12}>
            {pageRangeDisplayed >= itemsCountPerPage ? (
              <Button
                color="azure"
                onClick={() => setPageRangeDisplayed(pageRangeDisplayed - itemsCountPerPage)}
              >
                LESS
              </Button>
            ) : (
              ""
            )}
          </Grid.Col>

          <Grid.Col md={7} sm={12}>
            {pages()
              .slice(pageRangeDisplayed, pageRangeDisplayed + itemsCountPerPage)
              .map((p) => (
                <Button
                  color={activePage == p ? "teal" : "cyan"}
                  onClick={() => setActivePage(p)}
                >
                  {p}
                </Button>
              ))}
          </Grid.Col>

          <Grid.Col md={1} sm={12}>
            {pages().length - pageRangeDisplayed < itemsCountPerPage ? (
              ""
            ) : (
              <Button
                color="azure"
                onClick={() => setPageRangeDisplayed(pageRangeDisplayed + itemsCountPerPage)}
              >
                MORE
              </Button>
            )}
          </Grid.Col>
        </Grid.Row>
      </Card.Body>

  );
};

export default Pagination;
