function useDragNDrop(id, items, setItems, updateBoard) {
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: `0 0 8px 0`,

    // change background colour if dragging
    background: isDragging ? "#17a2b8" : "white",
    color: isDragging ? "white" : "black",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: 8,
    width: "100%",
    color: isDraggingOver ? "white" : "black",
  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((r, n) => ({
      ...r,
      index: n,
    }));
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.source.droppableId === result.destination.droppableId) {
      const items1 = reorder(
        items,
        result.source.index,
        result.destination.index
      );

      setItems(items1);
      updateBoard(
        {
          id: id,
          items: items1,
        },
        () => console.log("saved!")
      );
    } else {
      const items1 = reorder(
        items.map((i) =>
          i.id === result.draggableId
            ? {
                ...i,
                category: result.destination.droppableId,
              }
            : i
        ),
        result.source.index,
        result.destination.index
      );

      setItems(items1);
      updateBoard(
        {
          id: id,
          items: items1,
        },
        () => console.log("saved!")
      );
    }
  };

  return { getItemStyle, getListStyle, onDragEnd };
}

export default useDragNDrop;
