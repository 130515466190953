import React, { useState, useEffect } from "react";
import { Button, Card, Table, Loader, Icon, Alert } from "tabler-react";
import dateFormat from "dateformat";
import CopyBTN from "../../../../components/CopyBTN";

//hooks
import useExtractReportLazada from "../../../../hooks/api/GBT/extractions/useExtractLazadaOrders";
import useLazadaPending from "../../../../hooks/api/LazadaShop/useLazadaPending";

const TableResults = (props) => {
  const { orders, ordersCount, orderSummary, fileName } = props;
  const { downloadOrders, downloadSummary } = useExtractReportLazada();
  const { pendingCount, addPendingOrders } = useLazadaPending()

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");

  console.log(orderSummary);

  useEffect(() => {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
    }, 1000);
  }, [orders]);


  return (
    <>
      {pendingCount == 0 ? (
        ""
      ) : (
        <Alert
          type="warning"
          // isDismissible
          className="alert-css"
        >
          <Icon prefix="fa" name="warning" />
          <span>
            {" "}
            You can't upload these orders to Lazada Dashboard. You have{" "}
            {pendingCount} orders waiting for DR Approval.{" "}
            <a href="/LazadaShop/PendingDashboard" target="_blank">
              Pending Dashboard
            </a>{" "}
          </span>
        </Alert>
      )}

      <Card>
        <Card.Status color="cyan" side />
        <Card.Header>
          Generated Data
          <Card.Options>
            {ordersCount !== 0 ? (
              <>
                {orders.filter(
                  (order) => order.variant_name === "No Variant Name"
                ).length !== 0 ? (
                  <>
                    {filter === "" ? (
                      <Button
                        onClick={() => setFilter("No Variant Name")}
                        color="danger"
                      >
                        <Icon prefix="fa" name="eye" /> View Errors
                      </Button>
                    ) : (
                      <Button onClick={() => setFilter("")} color="cyan">
                        <Icon prefix="fa" name="eye" /> View All
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() =>
                        downloadOrders(
                          orders,
                          `${fileName} - ${dateFormat(new Date(), "longDate")}`
                        )
                      }
                      color="cyan"
                    >
                      <Icon prefix="fa" name="download" /> Extract Generated
                      Orders
                    </Button>
                    <Button
                      onClick={() =>
                        downloadSummary(
                          orderSummary,
                          `${fileName} - ${dateFormat(new Date(), "longDate")}`
                        )
                      }
                      color="teal"
                    >
                      <Icon prefix="fa" name="download" /> Extract Order Summary
                    </Button>
                    {pendingCount == 0 ? (
                      <Button
                        onClick={() =>
                          addPendingOrders(orders, () =>
                            alert("New Orders Added Successfully!")
                          )
                        }
                        color="orange"
                      >
                        <Icon prefix="fa" name="upload" /> Upload Orders
                      </Button>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <div style={{ padding: "5px" }}>
              <Loader className="loader" />
            </div>
          ) : (
            <div className="table-result-container">
              <Table
                id="table-to-xls"
                responsive
                highlightRowOnHover
                hasOutline
                verticalAlign="center"
                cards
                className="text-nowrap"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Order Date</Table.ColHeader>
                    <Table.ColHeader>Shipout Date</Table.ColHeader>
                    <Table.ColHeader>Order ID</Table.ColHeader>
                    <Table.ColHeader>Order Item ID</Table.ColHeader>
                    <Table.ColHeader>Tracking Number</Table.ColHeader>
                    <Table.ColHeader>Shipping Option</Table.ColHeader>
                    <Table.ColHeader>Product Name</Table.ColHeader>
                    <Table.ColHeader>Quantity</Table.ColHeader>
                    <Table.ColHeader>Original Price</Table.ColHeader>
                    <Table.ColHeader>Deal Price</Table.ColHeader>

                    <Table.ColHeader>Buyer Paid Shipping Fee</Table.ColHeader>
                    <Table.ColHeader>Gate Variant Name</Table.ColHeader>
                    <Table.ColHeader>Gross</Table.ColHeader>
                    <Table.ColHeader>Aquisition</Table.ColHeader>
                    <Table.ColHeader>Item Code</Table.ColHeader>
                    <Table.ColHeader>Voucher Discount</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                {orders
                  .filter((order) =>
                    filter ? order.variant_name === "No Variant Name" : order
                  )
                  .map((order, index) => (
                    <Table.Body>
                      <Table.Row>
                        <Table.Col>{order.order_date}</Table.Col>
                        <Table.Col>{order.shipout_date}</Table.Col>
                        <Table.Col>{order.order_id.replace("*", "")}</Table.Col>
                        <Table.Col>
                          {order.order_item_id.replace("*", "")}
                        </Table.Col>
                        <Table.Col>
                          {order.tracking_number.replace("*", "")}
                        </Table.Col>
                        <Table.Col>{order.shipping_option}</Table.Col>
                        <Table.Col>
                          {order.product_name}{" "}
                          <CopyBTN value={order.product_name} />
                        </Table.Col>
                        <Table.Col>{order.quantity}</Table.Col>
                        <Table.Col>{order.gate_price}</Table.Col>
                        <Table.Col>{order.laz_price}</Table.Col>

                        <Table.Col>{order.buyer_paid_shipping_fee}</Table.Col>
                        <Table.Col>{order.variant_name}</Table.Col>
                        <Table.Col>{order.gross_price}</Table.Col>
                        <Table.Col>{order.aquisition}</Table.Col>
                        <Table.Col>{order.item_code}</Table.Col>
                        <Table.Col>{order.voucher_discount}</Table.Col>
                      </Table.Row>
                    </Table.Body>
                  ))}
              </Table>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default TableResults;
