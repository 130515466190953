import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractReport() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadOrders(order, dr_number) {
    var headers = {
      order_date: "order_date",
      shipout_date: "shipout_date",
      order_id: "order_id",
      order_item_number: "order_item_number",
      tracking_number: "tracking_number",
      courier: "courier",
      product_name: "product_name",
      gate_price: "gate_price",
      lazada_price: "lazada_price",
      quantity: "quantity",
      globe_discount: "globe_discount",
      smart_discount: "smart_discount",
      dr_number: "dr_number",
      SI_number: "SI_number",
      status: "status",
      paymentRelease: "paymentRelease",
      commissionFee: "commissionFee",
      paymentFee: "paymentFee",
      deliveryStatus: "deliveryStatus",
      remarks: "remarks",
      supplier: "supplier",
      variant_name: "variant_name",
      item_code: "item_code",
    };

    var itemsNotFormatted = order;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        order_date: dateFormat(item.order_date, "paddedShortDate"),
        shipout_date: dateFormat(item.shipout_date, "paddedShortDate"),
        order_id: item.order_id,
        order_item_number: item.order_item_number,
        tracking_number: item.tracking_number,
        courier: item.courier,
        product_name: item.product_name.replace(/(\r\n|\n|\r|,)/gm, ""),
        gate_price: item.gate_price,
        lazada_price: item.lazada_price,
        quantity: item.quantity,
        globe_discount: item.globe_discount,
        smart_discount: item.smart_discount,
        dr_number: item.dr_number,
        SI_number: item.SI_number,
        status: item.status,
        paymentRelease: item.amount_release,
        commissionFee: item.commissionFee,
        paymentFee: item.paymentFee,
        deliveryStatus: item.order_status
          ? item.order_status
          : item.deliveryStatus,
        remarks: item.remarks,
        supplier: item.supplier,
        variant_name: item.variant_name,
        item_code: item.item_code,
      });
    });

    var fileTitle = `Lazada orders_${dr_number}_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  function downloadSISummary(data) {
    var headers = {
      dr_number: "dr_number",
      SI_number: "SI_number",
      SI_date: "SI_date",
      gate_gross: "gate_gross",
      globe_discount: "globe_discount",
      smart_discount: "smart_discount",
      lazada_price: "lazada_price",
      payment_release: "payment_release",
      commission_fee: "commission_fee",
      payment_fee: "payment_fee",
    };

    var itemsNotFormatted = data;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        dr_number: item.dr_number,
        SI_number: item.SI_number.replace(/(\r\n|\n|\r|,)/gm, ""),
        SI_date: item.SI_Date.replace(/(\r\n|\n|\r|,)/gm, ""),
        gate_gross: item.gate_price,
        globe_discount: item.globe_discount,
        smart_discount: item.smart_discount,
        lazada_price:
          item.gate_price - (item.globe_discount + item.smart_discount),
        payment_release: item.amount_release,
        commission_fee: item.commissionFee,
        payment_fee: item.paymentFee,
      });
    });

    var fileTitle = `Shopee_SI_Summary_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return { downloadOrders, downloadSISummary };
}

export default useExtractReport;
