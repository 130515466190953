import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import {
  Button,
  Card,
  Grid,
  Form,
  Icon,
} from "tabler-react";
import "draft-js/dist/Draft.css";
import useNotifications from "../../../../hooks/api/useNotifications";

const NotificationForm = (props) => {
  const { sendNotification } = useNotifications();

  const {
    type,
    rank,
    username,
    setRank,
    setUsername,
    fullName,
    filter1,
    filter2,
    amountReceive,
    amountReceiveNA
  } = props.data;

  // console.log(props.data);

  const [messageTitle, setMessageTitle] = useState("");
  const [message, setMessage] = useState("");
  const [messageTo, setMessageTo] = useState("");

  useEffect(() => {
    setMessageTitle(type === "achievements" ? "Top Order Contributor" : "");
    setMessageTo(type === "achievements" ? username : "");
    setMessage(type !== "achievements" ? "" : `Congratulations! ${fullName},\n\nYou are one of the Top Order Contributor from ${dateFormat(filter1, "mmmm d, yyyy")} to ${dateFormat(filter2, "mmmm d, yyyy")}.\nYour total Order Contribution is ${amountReceive}.\n\n----\nYour total Order Amount Received thru not Assigned Store is ${amountReceiveNA}.\n\nThank you!\n\n ~\nTeam Leader`);
  }, [type, username, filter1, filter2]);

  return (
    <>
      <div className="modal-achievement">
        <Card className="modal-achievement-content-container">
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={type === "achievements" ? 8 : 12}>
                <Form.Group>
                  <Form.Label>Message Type</Form.Label>
                  <Form.Select value={type} disabled={type === "achievements"}>
                    <option value="">Select One</option>
                    <option value="achievements">Achievement</option>
                    <option value="announcements">Announcement</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              {type === "achievements" ? (
                <Grid.Col md={4}>
                  <Form.Group>
                    <Form.Label>Rank</Form.Label>
                    <Form.Select value={rank} disabled>
                      <option value="">Select One</option>
                      <option value="1">1 - Gold</option>
                      <option value="2">2 - Silver</option>
                      <option value="3">3 - Bronze</option>
                      <option value="4">4 - Bronze</option>
                      <option value="5">5 - Bronze</option>
                      <option value="6">6 - Bronze</option>
                      <option value="7">7 - Bronze</option>
                    </Form.Select>
                  </Form.Group>
                </Grid.Col>
              ) : (
                ""
              )}
              <Grid.Col md={12}>
                <Form.Group>
                  <Form.Label>Message To</Form.Label>
                  <Form.Input
                    value={messageTo}
                    onChange={(e) => setMessageTo(e.target.value)}
                    placeholder="Message to"
                    disabled
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12}>
                <Form.Group>
                  <Form.Label>Message Title</Form.Label>
                  <Form.Input
                    value={messageTitle}
                    onChange={(e) => setMessageTitle(e.target.value)}
                    placeholder="Message Title"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12}>
                <Form.Group>
                  <Form.Textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={12}
                    placeholder="Message"
                  ></Form.Textarea>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12}>
                <Button
                  color="cyan"
                  onClick={() =>
                    sendNotification(
                      {
                        username: messageTo,
                        message: message.replace(/\n/g, "<br/>"),
                        title: messageTitle,
                        rank: rank,
                        type: type,
                        expiration: new Date()
                      },
                      () => setRank("") || setUsername("")
                    )
                  }
                >
                  <Icon prefix="fa" name="envelope" />{" "}
                  Send
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setRank("") || setUsername("")}
                >
                  Cancel
                </Button>
              </Grid.Col>
              {/* <Grid.Col md={12}>
                <Form.Textarea
                  value={editorState.replace(/\n/g, "<br/>")}
                  onChange={(e) => setEditorState(e.target.value)}
                  rows={12}
                ></Form.Textarea>
              </Grid.Col> */}
            </Grid.Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default NotificationForm;
