import React, { useState } from "react";
import MenuHeader from "../../components/MenuHeader";
import { Card, Form, Grid, Button } from "tabler-react";

//hooks
import useUsers from "../../hooks/api/Users/useUsers";

const Profile = () => {
  const { user, resetPassword } = useUsers();

  const [edit, setEdit] = useState(false);
  const [nPassword, setNPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  return (
    <>
      <MenuHeader data={{ user }} />
      <div style={{ margin: "5px" }}>
        {user.length != 0 ? (
          <Grid.Row>
            <Grid.Col md={6}>
              <Card>
                <Card.Header>
                  User Details
                  <Card.Options>
                    {edit ? (
                      <Button color="secondary" onClick={() => setEdit(false)}>
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        color="cyan"
                        onClick={() => setEdit(true)}
                        icon="edit"
                      >
                        Edit
                      </Button>
                    )}
                  </Card.Options>
                </Card.Header>
                <Card.Body>
                  <Form.Group>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Input value={user.full_name} readonly />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Department</Form.Label>
                    <Form.Input value={user.department} readonly />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Role/ Position</Form.Label>
                    <Form.Input value={user.role} readonly />
                  </Form.Group>
                </Card.Body>
              </Card>

              {edit ? (
                <Card>
                  <Card.Header>Change Password</Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <Form.Label>New Password</Form.Label>
                      <Form.Input
                        value={nPassword}
                        onChange={(e) => setNPassword(e.target.value)}
                        placeholder="new password"
                        type="password"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm New Password</Form.Label>
                      <Form.Input
                        value={cPassword}
                        onChange={(e) => setCPassword(e.target.value)}
                        placeholder="confirm new password"
                        type="password"
                        invalid={nPassword !== cPassword && cPassword !== ""}
                      />
                    </Form.Group>
                    {nPassword !== "" &&
                    nPassword !== "" &&
                    nPassword === cPassword ? (
                      <Form.Group>
                        <Button color="cyan" icon="save" onClick={() => resetPassword({
                          id: user.id,
                          password: nPassword
                        })}>
                          Proceed Changing Password
                        </Button>
                      </Form.Group>
                    ) : (
                      ""
                    )}
                  </Card.Body>
                </Card>
              ) : (
                ""
              )}
            </Grid.Col>
            <Grid.Col md={6}>
              <Card>
                <Card.Header>GMS APPs Access</Card.Header>
                {user.apps_list
                  .filter((app) => app.access !== "none")
                  .map((app) => (
                    <Card.Body>
                      <Grid.Row>
                        <Grid.Col md={5}>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              fontWeight: "bold",
                              color: "#B8B8B8",
                            }}
                          >
                            APP NAME
                          </p>
                          {app.app.replace(/-/g, " ").toUpperCase()}
                        </Grid.Col>
                        <Grid.Col md={4}>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              fontWeight: "bold",
                              color: "#B8B8B8",
                            }}
                          >
                            SUB APPS
                          </p>
                          {app.subApps ?? "None"}
                        </Grid.Col>
                        <Grid.Col md={3}>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              fontWeight: "bold",
                              color: "#B8B8B8",
                            }}
                          >
                            APP ACCESS
                          </p>
                          {app.access.replace(/-/g, " ").toUpperCase()}
                        </Grid.Col>
                      </Grid.Row>
                    </Card.Body>
                  ))}
              </Card>
            </Grid.Col>
          </Grid.Row>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Profile;
