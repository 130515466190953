import React, { useEffect, useState } from "react";
import { Card, Button, Table, Form, Grid, Icon } from "tabler-react";
import Pagination from "../../../../../components/Pagination";

//hooks

const PreferencesList = (props) => {
  const { data } = props;
  const {
    preferences,
    getQuery,
    changeQueryParam,
    type,
    getPreference,
    selectedPreferences,
    openForm,
    setOpenForm,
  } = data;

  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  const [filter1, setFilter1] = useState("");
  const [filter2, setFilter2] = useState("");

  const [newList, setNewList] = useState([]);

  useEffect(() => {
    setFilter1(type ? type : "");
  }, [preferences]);

  useEffect(() => {
    setNewList(
      preferences
        .filter((p) => (filter1 !== "" ? p.type === filter1 : p))
        .filter((p) =>
          filter2 !== ""
            ? p.value.toLowerCase().includes(filter2.toLowerCase())
            : p
        )
    );
  }, [preferences, filter1, filter2]);

  return (
    <>
      <Card>
        <Card.Header>
          711 Preferences
          <Card.Options>
            <Button
              icon="plus"
              color="cyan"
              onClick={() => setOpenForm(true)}
              disabled={selectedPreferences.length != 0 || openForm}
            >
              Add Preferences
            </Button>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={2}>
              <Form.Label>Type</Form.Label>
              <Form.Select
                value={filter1}
                onChange={(e) =>
                  filter1 || filter1 === ""
                    ? window.location.replace(
                        changeQueryParam(
                          window.location.href,
                          "type",
                          e.target.value
                        )
                      )
                    : window.location.replace(
                        window.location.href + `&type=${e.target.value}`
                      )
                }
              >
                <option value="">All</option>
                {preferences
                  .map((p) => p.type)
                  .filter(
                    (v, i) => preferences.map((p) => p.type).indexOf(v) == i
                  )
                  .map((pref) => (
                    <option value={pref}>
                      {pref.replace("_", " ").toUpperCase()}
                    </option>
                  ))}
              </Form.Select>
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Label>Search</Form.Label>
              <Form.Input
                value={filter2}
                onChange={(e) => setFilter2(e.target.value)}
                placeholder="Search ..."
              />
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        {newList.length != 0 ? (
          <Card.Body>
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Type</Table.ColHeader>
                  <Table.ColHeader>Value</Table.ColHeader>
                  <Table.ColHeader>Store type</Table.ColHeader>
                  <Table.ColHeader>Active</Table.ColHeader>
                  <Table.ColHeader colSpan={1}>Actions</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {newList
                  .slice(
                    activePage == 1 ? 0 : (activePage - 1) * pageLimit,
                    activePage * pageLimit
                  )
                  .map((p, index) => (
                    <Table.Row key={index}>
                      <Table.Col>
                        {p.type.replace("_", " ").toUpperCase()}
                      </Table.Col>
                      <Table.Col>{p.value}</Table.Col>
                      <Table.Col>{p.store_type == 0? "7/11" : "Other Accounts"}</Table.Col>
                      <Table.Col>
                        {p.active == 1 ? "Active" : "Disabled"}
                      </Table.Col>
                      <Table.Col>
                        <Button
                          icon="edit"
                          color="cyan"
                          onClick={() => getPreference({ id: p.id })}
                        >
                          Edit
                        </Button>
                        {/* {p.active == 1 ? (
                          <Button color="secondary">Deactivate</Button>
                        ) : (
                          <Button color="teal">Activate</Button>
                        )} */}
                      </Table.Col>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Card.Body>
        ) : (
          ""
        )}

        {newList.length === 0 ? (
          <Card.Body>
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          </Card.Body>
        ) : (
          <Card.Body>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={pageLimit}
              totalItemsCount={newList.length}
              pageRangeDisplayed={pageRangeDisplayed}
              setActivePage={setActivePage}
              setPageRangeDisplayed={setPageRangeDisplayed}
            />
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default PreferencesList;
