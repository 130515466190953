import React from "react";
import { Button, Card, Form } from "tabler-react";
import useLazadaEdit from "../../../../hooks/api/LazadaShop/useLazadaEdit";

const EditDetails = (props) => {
  const { updateOrderDetails } = useLazadaEdit()
  console.log(props.editForm);

  return (
    <div className="modal-summary">
      <div style={{ width: "50%", margin: "0 auto" }}>
        <Card className="modal-summary-content-container">
          <Card.Header>Edit Order Details (ID {props.editForm.id})</Card.Header>
          <Card.Body>
            <Form.Label>PRODUCT NAME</Form.Label>
            <Form.Input
              value={props.editForm.product_name}
              onChange={(e) =>
                props.setEditForm({
                  ...props.editForm,
                  product_name: e.target.value,
                })
              }
            />
          </Card.Body>
          <Card.Footer>
            <Button
              color="cyan"
              onClick={() => updateOrderDetails(props.editForm)}
            >
              Update
            </Button>
            <Button onClick={() => props.setEditForm([])}>Cancel</Button>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

export default EditDetails;
