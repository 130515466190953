import React from "react";
import { Card, Table, Button, Icon, Loader, Form, Grid } from "tabler-react";
import Tooltip from "react-simple-tooltip";
import Stats from "./Stats";

const StoreProfile = (props) => {
  const { data } = props;
  const { accessLevel, store, calls, backURL } = data;

  console.log(store.networkPreference);

  return (
    <>
      <Card>
        <Card.Header>
          <a
            href={`/AMS/Dashboard`}
            style={{ textDecoration: "none", padding: "5px" }}
          >
            Home
          </a>
          →
          <a
            href={backURL ? `${window.atob(backURL)}` : `/AMS/MasterList/711`}
            style={{ textDecoration: "none", padding: "5px" }}
          >
            List of 711 Store
          </a>
          → {store.store_name} - Call Logs
          <Card.Options>
            <a
              href={backURL ? `${window.atob(backURL)}` : `/AMS/MasterList/711`}
              style={{ textDecoration: "none" }}
            >
              <Button color="secondary">BACK</Button>
            </a>
          </Card.Options>
        </Card.Header>
      </Card>
      <Stats
        data={{
          completed_call_count: calls.filter(
            (call) => call.call_status === "Completed"
          ).length,
          pending_call_count: calls.filter(
            (call) => call.call_status === "Pending"
          ).length,
          ordered_Call_count: calls.filter((call) => call.remarks === "Ordered")
            .length,
          ordered_Call_amount: calls
            .filter((call) => call.remarks === "Ordered")
            .reduce(
              (a, b) => a + parseInt(b.amount.replace(/(\r\n|\n|\r|,)/gm, "")),
              0
            ),
        }}
      />
      <Card>
        <Card.Header>
          711 Store Profile
          <Card.Options>
            <Tooltip
              content="Edit Store Details"
              radius={5}
              padding={5}
              style={{ whiteSpace: "nowrap" }}
              placement="left"
            >
              <Button
                color="azure"
                onClick={() =>
                  window.location.replace(
                    `/AMS/UpdateForm/711?id=${store.store_number.replace(
                      "*",
                      ""
                    )}&backURL=${window.btoa(window.location.href)}`
                  )
                }
                disabled={accessLevel === "view" || accessLevel === "report"}
              >
                <Icon prefix="fa" name="edit" />
              </Button>
            </Tooltip>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={1} sm={12}>
              <Form.Group>
                <Form.Label>Store No.</Form.Label>
                <Form.Input
                  type="text"
                  value={store.store_number.replace("*", "")}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={5} sm={12}>
              <Form.Group>
                <Form.Label>Store Name</Form.Label>
                <Form.Input type="text" value={store.store_name} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} sm={12}>
              <Form.Group>
                <Form.Label>Store Manager</Form.Label>
                <Form.Input type="text" value={store.store_manager} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} sm={12}>
              <Form.Group>
                <Form.Label>Contact Number</Form.Label>
                <Form.Input type="text" value={store.contact_number} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <Form.Input type="text" value={store.location} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={4} sm={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Input type="text" value={store.full_address} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} sm={12}>
              <Form.Group>
                <Form.Label>Account Executive</Form.Label>
                <Form.Input type="text" value={store.account_executive} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} sm={12}>
              <Form.Group>
                <Form.Label>Assigned Telemarketer</Form.Label>
                <Form.Input type="text" value={store.telemarketer} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col md={1} sm={12}>
              <Form.Group>
                <Form.Label>Zone</Form.Label>
                <Form.Input type="text" value={store.zone} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={1} sm={12}>
              <Form.Group>
                <Form.Label>District</Form.Label>
                <Form.Input type="text" value={store.district} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={1} sm={12}>
              <Form.Group>
                <Form.Label>Area</Form.Label>
                <Form.Input type="text" value={store.area.replace("*", "")} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Cluster</Form.Label>
                <Form.Input type="text" value={store.cluster} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={1} sm={12}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Input type="text" value={store.status} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={1} sm={12}>
              <Form.Group>
                <Form.Label>Courier Status</Form.Label>
                <Form.Input type="text" value={store.courier_status} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Input
                  type="text"
                  value={
                    store.email_address
                      ? store.email_address
                      : `st${store.store_number.replace(
                          "*",
                          ""
                        )}@store.philseven.com`
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} sm={12}>
              <Form.Group>
                <Form.Label>Network preference</Form.Label>
                {store.networkPreference !== "" &&
                  JSON.parse(store.networkPreference).map((net) => (
                    <Form.Checkbox
                      label={net}
                      value={net}
                      checked={net}
                      className="network-selection"
                    />
                  ))}
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default StoreProfile;
