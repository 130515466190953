import React, { useEffect, useState } from "react";
import { Button, Card, Form, Grid } from "tabler-react";

//hooks
import useCustomFunctions from "../../hooks/services/useCustomFunctions";

const VariantForm = (props) => {
  const { data } = props;
  const {
    telcoList,
    getVariantList,
    seledtedVariant,
    setSelectedVariant,
    addVariant,
    updateVariant,
    openForm,
    setOpenForm,
    setSuccessForm,
  } = data;

  const { generateRandomCodes } = useCustomFunctions();

  const [randomCode, setRandomCode] = useState("");

  const [formData, setFormData] = useState([]);
  const [finalData, setFinalData] = useState([]);

  useEffect(() => {
    setFormData({
      telco: seledtedVariant.telco ? seledtedVariant.telco : "",
      item_code: seledtedVariant.item_code ? seledtedVariant.item_code : "",
      item_sku: seledtedVariant.item_sku ? seledtedVariant.item_sku : "",
      variant_name: seledtedVariant.variant_name
        ? seledtedVariant.variant_name
        : "",
      shop_name: seledtedVariant.shop_name ? seledtedVariant.shop_name : "",
      gross_price: seledtedVariant.gross_price
        ? seledtedVariant.gross_price
        : "",
      selling_price: seledtedVariant.selling_price
        ? seledtedVariant.selling_price
        : "",
      shopee_price: seledtedVariant.shopee_price
        ? seledtedVariant.shopee_price
        : "",
      laz_price: seledtedVariant.laz_price ? seledtedVariant.laz_price : "",
      aquisition: seledtedVariant.aquisition ? seledtedVariant.aquisition : "",
      active: seledtedVariant.active ? seledtedVariant.active : 1,
    });
  }, [seledtedVariant]);

  useEffect(() => {
    setFinalData({
      ...formData,
      item_sku:
        formData.item_sku === ""
          ? `${formData.item_code}_${formData.selling_price}_${randomCode}`
          : formData.item_sku,
    });
  }, [formData, randomCode]);

  useEffect(() => {
    setRandomCode(generateRandomCodes());
  }, []);

  return (
    <>
      <Card>
        <Card.Header>
          {seledtedVariant.length != 0 ? "Update " : "Add "}Variant Form
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={2}>
              <Form.Label>Telo/ Suplier</Form.Label>
              <Form.Select
                value={finalData.telco}
                onChange={(e) =>
                  setFormData({ ...formData, telco: e.target.value })
                }
              >
                <option value="">ALL</option>
                {telcoList.map((t, index) => (
                  <option value={t.brand} key={index}>
                    {t.brand}
                  </option>
                ))}
              </Form.Select>
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Label>Item Code</Form.Label>
              <Form.Input
                value={finalData.item_code}
                onChange={(e) =>
                  setFormData({ ...formData, item_code: e.target.value })
                }
                placeholder="insert item code"
              />
            </Grid.Col>

            <Grid.Col md={2}>
              <Form.Label>Item SKU</Form.Label>
              <Form.Input
                value={finalData.item_sku}
                onChange={(e) =>
                  setFormData({ ...formData, item_sku: e.target.value })
                }
                placeholder="insert item sku"
              />
            </Grid.Col>
            <Grid.Col md={3}>
              <Form.Label>Gate Variant Name</Form.Label>
              <Form.Input
                value={finalData.variant_name}
                onChange={(e) =>
                  setFormData({ ...formData, variant_name: e.target.value })
                }
                placeholder="insert gate variant name"
              />
            </Grid.Col>
            <Grid.Col md={3}>
              <Form.Label>Shop Name</Form.Label>
              <Form.Input
                value={finalData.shop_name}
                onChange={(e) =>
                  setFormData({ ...formData, shop_name: e.target.value })
                }
                placeholder="insert gate shop name"
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Label>Gross Price</Form.Label>
              <Form.Input
                value={finalData.gross_price}
                onChange={(e) =>
                  setFormData({ ...formData, gross_price: e.target.value })
                }
                placeholder="insert gross price"
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Label>Selling Price</Form.Label>
              <Form.Input
                value={finalData.selling_price}
                onChange={(e) =>
                  setFormData({ ...formData, selling_price: e.target.value })
                }
                placeholder="insert selling price"
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Label>Shopee Price</Form.Label>
              <Form.Input
                value={finalData.shopee_price}
                onChange={(e) =>
                  setFormData({ ...formData, shopee_price: e.target.value })
                }
                placeholder="insert shopee price"
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Label>Lazada Price</Form.Label>
              <Form.Input
                value={finalData.laz_price}
                onChange={(e) =>
                  setFormData({ ...formData, laz_price: e.target.value })
                }
                placeholder="insert laz price"
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Label>Aquisition</Form.Label>
              <Form.Input
                value={finalData.aquisition}
                onChange={(e) =>
                  setFormData({ ...formData, aquisition: e.target.value })
                }
                placeholder="insert aquisition"
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <Form.Label>Active</Form.Label>
              <Form.Select
                value={finalData.active}
                onChange={(e) =>
                  setFormData({ ...formData, active: e.target.value })
                }
              >
                <option value="">Select One</option>
                <option value="1">Active</option>
                <option value="0">Disabled</option>
              </Form.Select>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        <Card.Body>
          {seledtedVariant.length != 0 ? (
            <Button
              color="cyan"
              icon="save"
              onClick={() =>
                updateVariant(
                  seledtedVariant.id,
                  finalData,
                  () =>
                    setFormData({}) ||
                    setSuccessForm(true) ||
                    setOpenForm(false) ||
                    setSelectedVariant([]) ||
                    setTimeout(() => {
                      setSuccessForm(false);
                      getVariantList();
                    }, 2000)
                )
              }
            >
              Update
            </Button>
          ) : (
            <Button
              color="cyan"
              icon="save"
              onClick={() =>
                addVariant(
                  finalData,
                  () =>
                    setFormData({}) ||
                    setSuccessForm(true) ||
                    setOpenForm(false) ||
                    setTimeout(() => {
                      setSuccessForm(false);
                      getVariantList();
                    }, 2000)
                )
              }
            >
              Save
            </Button>
          )}
          <Button
            color="secodary"
            onClick={() => setOpenForm(false) || setSelectedVariant([])}
          >
            Cancel
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default VariantForm;
