import { useEffect, useState } from "react";
import useLazadaAPI from "./useLazadaAPI";

function useLazadaReportView(DR_month, year) {
  const { reports } = useLazadaAPI(DR_month, "");

  const [reports1, setReports1] = useState([]);

  function generateReport(data) {
    let DRs = data.filter((d) => d.dr_number !== "--").map((d) => d.dr_number);
    let SIs = data.filter((d) => d.SI_number !== "--").map((d) => d.SI_number);
    let PendingDR = data
      .filter((d) => d.SI_number === "--")
      .map((d) => d.dr_number);

    let report = {
      month: DR_month,
      drs: DRs.filter((v, i) => DRs.indexOf(v) == i),
      SI: SIs.filter((v, i) => SIs.indexOf(v) == i),
      pending: PendingDR.filter((v, i) => PendingDR.indexOf(v) == i),
      gross_price: data
        .map((d) => d.gate_price)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0),
      marketing_concerns: data.filter(
        (d) =>
          d.remarks === "Endorsed to MKTG" || d.remarks === "Payment Waiting"
      ).length,
    };

    return report;
  }

  useEffect(() => {
    setReports1(generateReport(reports.filter((d) => d.DR_year === year)));
  }, [DR_month, reports, year]);

  return {
    reports1,
  };
}

export default useLazadaReportView;
