import { useState, useEffect } from "react";
import config, { CURRENT_YEAR } from "../../../config";
import useDirectoryAPI from "../GVD/useDirectoryAPI";
import axios from "axios";
import dateFormat from "dateformat";
import useQueryURL from "../../services/useQueryURL";
import useCustomFunctions from "../../services/useCustomFunctions";
import useUsers from "../Users/useUsers";

function useShopeeAPI(month, dr_number, year1) {
  const { loginSession } = useUsers();

  const { getQuery } = useQueryURL();
  const { getDateDifference } = useCustomFunctions();

  //Constatnts values
  const COMMISSION_RATE = 0.03; //TO BE CHANGE

  //REMARKS & STATUS
  const CANCELLED = "Cancelled";
  const INVENTORY = "Inventory";
  const RETURNED = "Returned";
  const LOST_INVENTORY = "Lost Inventory";
  const CHARGE_TO_EMPLOYEE = "Charge to Employee";
  const REFUNDED = "Refunded";
  const PAID = "Paid";
  const DELIVERED = "Delivered";
  const DAYS_TO_RECEIVE_PAYMENT = 30;
  //END OF CONSTANTS
  
  let year = getQuery("year") ? getQuery("year") : CURRENT_YEAR;

  const { directory } = useDirectoryAPI();
  const [orders, setOrders] = useState([]);
  const [reports, setReports] = useState([]);
  const [rawOrders, setRawOrders] = useState([]);

  const [paymentRelease, setPaymentRelease] = useState([]);
  const [drList, setDrList] = useState([]);
  const [sIList, setSIList] = useState([]);
  const [RTSList, setRTSList] = useState([]);

  //USE THIS TO REFLECT CHANGES
  const [load, setLoad] = useState("");

  function assignedGatePrice(order_id, data) {
    let gatePrice = 0;

    let status = data ? data.find((n) => n.order_id === order_id).status : "--";
    let remarks = data
      ? data.find((n) => n.order_id === order_id).remarks
      : "--";

    let OREDER_TOTAL_SHOP = data
      .filter((p) => p.order_id === order_id)
      .map((d) => ({
        total: parseInt(d.shopee_price) * parseInt(d.quantity),
      }))
      .reduce((a, b) => parseInt(a) + parseInt(b.total), 0);

    let ORDER_TOTAL_GATE = data
      .filter((p) => p.order_id === order_id)
      .map((d) => ({ total: parseInt(d.gate_price) * parseInt(d.quantity) }))
      .reduce((a, b) => parseInt(a) + parseInt(b.total), 0);

    if (status === RETURNED && remarks === INVENTORY) {
      gatePrice = 0;
    } else if (status === CANCELLED && remarks === INVENTORY) {
      gatePrice = 0;
    } else if (
      (status === RETURNED || status === CANCELLED) &&
      remarks === LOST_INVENTORY
    ) {
      gatePrice = OREDER_TOTAL_SHOP;
    } else if (status === RETURNED && remarks === CHARGE_TO_EMPLOYEE) {
      gatePrice = OREDER_TOTAL_SHOP;
    } else if (status === REFUNDED && remarks === PAID) {
      gatePrice = ORDER_TOTAL_GATE;
    } else {
      gatePrice = ORDER_TOTAL_GATE;
    }

    return gatePrice;
  }

  function checkingBalanceCommissions(VALUES) {
    const {
      original_price,
      discount_rebate,
      seller_promotion,
      seller_voucher,
      commission_fee,
    } = VALUES;

    let COMPUTED_COMMISSION =
      (parseInt(original_price) -
        Math.abs(parseInt(discount_rebate) - parseInt(seller_promotion)) -
        parseInt(seller_voucher)) *
      COMMISSION_RATE;

      console.log(COMPUTED_COMMISSION);

    let IS_COMMISSION_BALANCE =
      commission_fee == Math.ceil(COMPUTED_COMMISSION);

    return { IS_COMMISSION_BALANCE, COMPUTED_COMMISSION };
  }

  function checkingBalance(
    data,
    release_amount,
    original_price,
    seller_promotion,
    seller_voucher,
    coin_cashback,
    commission_fee,
    transaction_fee,
    discount_rebate,
    shipping_sub_total
  ) {
    let total =
      parseInt(release_amount) +
      Math.abs(parseInt(discount_rebate) - parseInt(seller_promotion)) +
      parseInt(seller_voucher) +
      parseInt(coin_cashback) +
      parseInt(commission_fee) +
      parseInt(transaction_fee) +
      parseInt(shipping_sub_total);

    let gateTotal =
      parseInt(original_price) -
      Math.abs(parseInt(discount_rebate) - parseInt(seller_promotion)) -
      parseInt(coin_cashback) -
      Math.abs(Math.ceil(
        data.length != 0
          ? (data[0].status === "Cancelled" &&
              data[0].remarks === "Lost Inventory") ||
            (data[0].status === "Returned" &&
              data[0].remarks === "Lost Inventory") ||
            (data[0].status === "Returned" &&
              data[0].remarks === "Charge To Employee") ||
            (data[0].status === "Refunded" && data[0].remarks === "Paid")
            ? 0
            : checkingBalanceCommissions({
                original_price,
                seller_promotion,
                seller_voucher,
                commission_fee,
                transaction_fee,
                discount_rebate,
              }).COMPUTED_COMMISSION
          : commission_fee
      )) -
      parseInt(seller_voucher) -
      parseInt(transaction_fee);

    let gateBalance;
    if (gateTotal == parseInt(release_amount)) {
      gateBalance = true;
    } else {
      gateBalance = false;
    }

    let balance;
    if (total == parseInt(original_price)) {
      balance = true;
    } else {
      balance = false;
    }

    return { total, balance, gateBalance, gateTotal };
  }

  function generateRawOrders(data) {
    let list = [];
    for (let index = 0; index < data.length; index++) {
      list.push({
        ...data[index],
        supplier: directory.find((d) => d.item_sku === data[index].sku)
          ? directory.find((d) => d.item_sku === data[index].sku).telco
          : "--",
      });
    }
    return list;
  }

  function generateOrders(data) {
    let list = data.map((d) => d.order_id);

    function getShippingSubTotal(
      buyerPaidShippingFee,
      shippingFeeRebate,
      definedShippingFee
    ) {
      const subtotal =
        parseInt(shippingFeeRebate) +
        parseInt(buyerPaidShippingFee) -
        parseInt(Math.abs(definedShippingFee));

      return subtotal;
    }

    // let nOrders = [];
    let nList = list.filter((v, i) => list.indexOf(v) == i);

    function getDeliveryStatus(release, status, id) {
      let refund = release.release_amount - release.refund_amount;
      if (id.replace("*--", "--") !== "--") {
        return REFUNDED;
      } else if (refund != 0) {
        return DELIVERED;
      } else {
        return status;
      }
    }

    let nOrders = nList.map((order, index) => {
      let paymentReleaseValue = paymentRelease.find(
        (p) => p.order_id === nList[index]
      );
      let orderValue = data.filter((d) => d.order_id === nList[index]);

      let REMARKS_AND_STATUS = {
        LOST_INVENTORY_REMARK:
          (orderValue[0].status === RETURNED ||
            orderValue[0].status === CANCELLED) &&
          orderValue[0].remarks === LOST_INVENTORY,
        CHARGE_TO_EMPLOYEE_REMARK:
          orderValue[0].status === RETURNED &&
          orderValue[0].remarks === CHARGE_TO_EMPLOYEE,
        REFUNDED_REMARK:
          orderValue[0].status === REFUNDED && orderValue[0].remarks === PAID,
      };

      const {
        LOST_INVENTORY_REMARK,
        CHARGE_TO_EMPLOYEE_REMARK,
        REFUNDED_REMARK,
      } = REMARKS_AND_STATUS;

      let charges =
        LOST_INVENTORY_REMARK || CHARGE_TO_EMPLOYEE_REMARK || REFUNDED_REMARK
          ? orderValue
              .map((d) => ({
                total: parseInt(d.gate_price) * parseInt(d.quantity),
              }))
              .reduce((a, b) => parseInt(a) + parseInt(b.total), 0)
          : 0;

      let feeCharge =
        LOST_INVENTORY_REMARK || CHARGE_TO_EMPLOYEE_REMARK || REFUNDED_REMARK
          ? 0
          : 0;

      return {
        id: index,
        details: orderValue.map((order) => ({
          id: order.id,
          product_name: order.product_name,
          gate_price: order.gate_price,
          shopee_price: order.shopee_price,
          quantity: order.quantity,
          sku: order.sku,
        })),
        order_id: orderValue ? orderValue[0].order_id : "--",
        dr_number: orderValue ? orderValue[0].dr_number : "--",
        DR_month: orderValue ? orderValue[0].DR_month : "--",
        DR_year: orderValue ? orderValue[0].DR_year : "--",
        SI_number: orderValue ? orderValue[0].SI_number : "--",
        SI_Date: orderValue ? orderValue[0].SI_Date : "--",
        status: paymentReleaseValue ? "Paid" : "Unpaid",
        delivery_status: paymentReleaseValue
          ? getDeliveryStatus(
              paymentReleaseValue,
              orderValue[0].status,
              paymentReleaseValue.refund_id
            )
          : "--",
        order_date: orderValue ? orderValue[0].order_date : 0,
        original_price: paymentReleaseValue
          ? paymentReleaseValue.original_price
          : assignedGatePrice(orderValue[0].order_id, data),
        commission_fee: paymentReleaseValue
          ? paymentReleaseValue.commission_fee
          : feeCharge,
        transaction_fee: paymentReleaseValue
          ? paymentReleaseValue.transaction_fee
          : feeCharge,
        release_amount: paymentReleaseValue
          ? paymentReleaseValue.release_amount
          : charges,
        seller_promotion: paymentReleaseValue
          ? paymentReleaseValue.seller_promotion
          : feeCharge,
        coin_cashback: paymentReleaseValue
          ? paymentReleaseValue.coin_cashback
          : feeCharge,
        seller_voucher: paymentReleaseValue
          ? paymentReleaseValue.seller_voucher
          : feeCharge,
        discount_rebate: paymentReleaseValue
          ? paymentReleaseValue.discount_rebate
          : feeCharge,
        paid_shipping_fee: paymentReleaseValue
          ? paymentReleaseValue.paid_shipping_fee
          : feeCharge,
        shipping_sub_total: getShippingSubTotal(
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.paid_shipping_fee)
            : feeCharge,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.shipping_fee_rebate)
            : feeCharge,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.defined_shipping_fee)
            : feeCharge
        ),
        payment_waiting: paymentReleaseValue
          ? "--"
          : orderValue[0].status === "Returned" ||
            orderValue[0].status === "Cancelled" ||
            orderValue[0].status === "Refunded"
          ? "--"
          : DAYS_TO_RECEIVE_PAYMENT -
              Math.ceil(
                getDateDifference(
                  new Date(orderValue[0].order_date),
                  new Date()
                )
              ) <=
            0
          ? "Request for Payment"
          : `${
              DAYS_TO_RECEIVE_PAYMENT -
              Math.ceil(
                getDateDifference(
                  new Date(orderValue[0].order_date),
                  new Date()
                )
              )
            } DAYS LEFT`,
        payout_date: paymentReleaseValue
          ? paymentReleaseValue.payout_date
          : "unpaid",
        checkingBalance: checkingBalance(
          orderValue,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.release_amount)
            : charges,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.original_price)
            : charges,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.seller_promotion)
            : feeCharge,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.seller_voucher)
            : feeCharge,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.coin_cashback)
            : feeCharge,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.commission_fee)
            : feeCharge,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.transaction_fee)
            : feeCharge,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.discount_rebate)
            : feeCharge,
          getShippingSubTotal(
            paymentReleaseValue
              ? Math.abs(paymentReleaseValue.paid_shipping_fee)
              : feeCharge,
            paymentReleaseValue
              ? Math.abs(paymentReleaseValue.shipping_fee_rebate)
              : feeCharge,
            paymentReleaseValue
              ? Math.abs(paymentReleaseValue.defined_shipping_fee)
              : feeCharge
          )
        ),
        checkingBalanceCommissions: checkingBalanceCommissions(
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.original_price)
            : charges,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.seller_promotion)
            : feeCharge,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.seller_voucher)
            : feeCharge,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.commission_fee)
            : feeCharge,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.transaction_fee)
            : feeCharge,
          paymentReleaseValue
            ? Math.abs(paymentReleaseValue.discount_rebate)
            : feeCharge
        ),
        order_status: orderValue ? orderValue[0].status : "--",
        gate_status: orderValue[0].status,
        remarks: orderValue ? orderValue[0].remarks : "--",
      };
    });

    return nOrders;
  }

  function generateDR(data) {
    let newDRlist = data
      .filter((o) => o.SI_number === "--")
      .map((d) => d.dr_number);

    let filtered = newDRlist.filter((v, i) => newDRlist.indexOf(v) == i);

    let list = filtered.map((dr, index) => {
      let newData = data.filter((d) => d.dr_number === filtered[index]);

      return {
        id: index,
        dr_number: newData[0].dr_number,
        SI_number: newData[0].SI_number,
        SI_Date: newData[0].SI_Date,
        DR_month: newData[0].DR_month,
        DR_year: newData[0].DR_year,
        gate_price: newData.reduce(
          (a, b) =>
            parseInt(a) +
            parseInt(b.original_price) -
            parseInt(b.seller_promotion),
          0
        ),
        paymentRelease: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.release_amount),
          0
        ),
        commissionFee: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.commission_fee),
          0
        ),
        paymentFee: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.transaction_fee),
          0
        ),
        shipping_sub_total: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.shipping_sub_total),
          0
        ),
        totalGross: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.original_price),
          0
        ),
        totalShopee: newData.reduce(
          (a, b) =>
            parseInt(a) +
            parseInt(b.release_amount) +
            Math.abs(
              parseFloat(b.discount_rebate) +
                parseFloat(b.seller_promotion) +
                parseFloat(b.seller_voucher) +
                parseFloat(b.coin_cashback) +
                parseInt(b.commission_fee) +
                parseInt(b.transaction_fee) -
                parseInt(b.shipping_sub_total)
            ),
          0
        ),
        balance: {
          shopeeTotal: newData
            .map((n) => n.checkingBalance)
            .reduce((a, b) => parseInt(a) + parseInt(b.total), 0),
          shopeeBalance: checkingBalance(
            [],
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.release_amount),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.original_price),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_promotion),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_voucher),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.coin_cashback),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.commission_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.transaction_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.discount_rebate),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.shipping_sub_total),
              0
            )
          ).balance,
          gateBalance: checkingBalance(
            [],
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.release_amount),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.original_price),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_promotion),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_voucher),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.coin_cashback),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.commission_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.transaction_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.discount_rebate),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.shipping_sub_total),
              0
            )
          ).gateBalance,
          gateTotal: newData
            .map((n) => n.checkingBalance)
            .reduce((a, b) => parseInt(a) + parseInt(b.gateTotal), 0),
        },
        deliveredAll: newData.find((n) => n.delivery_status !== "Delivered")
          ? newData.find(
              (n) => n.order_status === "Returned" && n.remarks === "Inventory"
            )
            ? true
            : false
          : true,
      };
    });

    return list;
  }

  function generateSI(data) {
    let newDRlist = data
      .filter((o) => o.SI_number !== "--")
      .map((d) => d.dr_number);

    let filtered = newDRlist.filter((v, i) => newDRlist.indexOf(v) == i);

    let list = filtered.map((dr, index) => {
      let newData = data.filter((d) => d.dr_number === filtered[index]);

      return {
        id: index,
        dr_number: newData[0].dr_number,
        SI_number: newData[0].SI_number,
        SI_Date: newData[0].SI_Date,
        DR_month: newData[0].DR_month,
        DR_year: newData[0].DR_year,
        gate_price: newData.reduce(
          (a, b) =>
            parseInt(a) +
            parseInt(b.original_price) -
            (parseInt(b.seller_promotion) -
              parseInt(b.discount_rebate) +
              parseInt(b.seller_voucher)),
          0
        ),
        paymentRelease: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.release_amount),
          0
        ),
        commissionFee: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.commission_fee),
          0
        ),
        paymentFee: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.transaction_fee),
          0
        ),
        shipping_sub_total: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.shipping_sub_total),
          0
        ),
        seller_promotion_total: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.seller_promotion),
          0
        ),
        seller_voucher_total: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.seller_voucher),
          0
        ),
        coin_cashback_total: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.coin_cashback),
          0
        ),
        discount_rebate_total: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.discount_rebate),
          0
        ),
        original_price_total: newData.reduce(
          (a, b) => parseInt(a) + parseInt(b.original_price),
          0
        ),

        balance: {
          shopeeTotal: checkingBalance(
            newData,
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.release_amount),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.original_price),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_promotion),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_voucher),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.coin_cashback),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.commission_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.transaction_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.discount_rebate),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.shipping_sub_total),
              0
            )
          ).total,
          shopeeBalance: checkingBalance(
            newData,
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.release_amount),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.original_price),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_promotion),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_voucher),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.coin_cashback),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.commission_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.transaction_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.discount_rebate),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.shipping_sub_total),
              0
            )
          ).balance,
          gateBalance: checkingBalance(
            newData,
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.release_amount),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.original_price),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_promotion),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_voucher),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.coin_cashback),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.commission_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.transaction_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.discount_rebate),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.shipping_sub_total),
              0
            )
          ).gateBalance,
          gateTotal: checkingBalance(
            newData,
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.release_amount),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.original_price),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_promotion),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.seller_voucher),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.coin_cashback),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.commission_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.transaction_fee),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.discount_rebate),
              0
            ),
            newData.reduce(
              (a, b) => parseInt(a) + parseInt(b.shipping_sub_total),
              0
            )
          ).gateTotal,
        },
        deliveredAll: newData.find((n) => n.delivery_status !== "Delivered")
          ? newData.find(
              (n) => n.order_status === "Returned" && n.remarks === "Inventory"
            )
            ? true
            : false
          : true,
      };
    });

    return list;
  }

  function generateSummaryReport(dr_number) {
    let data = rawOrders;
    let orders = dr_number
      ? data.filter((d) => d.dr_number === dr_number)
      : data;

    let list = [];

    console.log(orders);
    console.log(directory);

    for (let index = 0; index < orders.length; index++) {
      let variant = directory.filter(
        (d) => d.item_sku === orders[index].sku.replace("*", "")
      );
      if (variant.length != 0) {
        if (
          orders[index].status === "Returned" &&
          orders[index].remarks === "Inventory"
        ) {
          if (variant.length !== 0) {
            for (let i = 0; i < orders[index].quantity; i++) {
              list.push(`FOR RETURN - ${variant[0].variant_name}`);
            }
          } else {
            list.push(`FOR RETURN - ${variant[0].variant_name}`);
          }
        } else if (
          orders[index].status === "Returned" &&
          orders[index].remarks === "Waiting"
        ) {
          if (variant.length !== 0) {
            for (let i = 0; i < orders[index].quantity; i++) {
              list.push(`FOR RETURN - ${variant[0].variant_name}`);
            }
          } else {
            list.push(`FOR RETURN - ${variant[0].variant_name}`);
          }
        } else if (
          orders[index].status === "Cancelled" &&
          orders[index].remarks === "Waiting"
        ) {
          if (variant.length !== 0) {
            for (let i = 0; i < orders[index].quantity; i++) {
              list.push(`FOR RETURN - ${variant[0].variant_name}`);
            }
          } else {
            list.push(`FOR RETURN - ${variant[0].variant_name}`);
          }
        } else if (
          orders[index].status === "Cancelled" &&
          orders[index].remarks === "Inventory"
        ) {
          if (variant.length !== 0) {
            for (let i = 0; i < orders[index].quantity; i++) {
              list.push(`FOR RETURN - ${variant[0].variant_name}`);
            }
          } else {
            list.push(`FOR RETURN - ${variant[0].variant_name}`);
          }
        } else if (
          (orders[index].status === "Returned" ||
            orders[index].status === "Cancelled") &&
          orders[index].remarks === "Lost Inventory"
        ) {
          if (variant.length !== 0) {
            for (let i = 0; i < orders[index].quantity; i++) {
              list.push(`LOST INVENTORY - ${variant[0].variant_name}`);
            }
          } else {
            list.push(`LOST INVENTORY - ${variant[0].variant_name}`);
          }
        } else if (
          orders[index].status === "Returned" &&
          orders[index].remarks === "Charge To Employee"
        ) {
          if (variant.length !== 0) {
            for (let i = 0; i < orders[index].quantity; i++) {
              list.push(`CHARGE TO EMPLOYEE - ${variant[0].variant_name}`);
            }
          } else {
            list.push(`CHARGE TO EMPLOYEE - ${variant[0].variant_name}`);
          }
        } else {
          if (variant.length !== 0) {
            for (let i = 0; i < orders[index].quantity; i++) {
              list.push(variant[0].variant_name);
            }
          } else {
            list.push(orders[index].product_name);
          }
        }
      }
    }

    let variant_list = list.filter((v, i) => list.indexOf(v) == i);

    let newList = [];
    for (let index = 0; index < variant_list.length; index++) {
      newList.push({
        name: variant_list[index],
        qty: list.filter((l) => l === variant_list[index]).length,
        code: directory.find(
          (d) =>
            d.variant_name ===
            variant_list[index]
              .replace("FOR RETURN - ", "")
              .replace("LOST INVENTORY - ", "")
              .replace("CHARGE TO EMPLOYEE - ", "")
        )
          ? directory.find(
              (d) =>
                d.variant_name ===
                variant_list[index]
                  .replace("FOR RETURN - ", "")
                  .replace("LOST INVENTORY - ", "")
                  .replace("CHARGE TO EMPLOYEE - ", "")
            ).item_code
          : "--",
      });
    }

    return newList;
  }

  function getOrders() {
    if (paymentRelease) {
      if (dr_number !== "") {
        axios
          .post(
            `${config.host}:${config.port}/api/v2/tiktok_gomo/get_orders_by_dr`,
            {
              month: month ? month : "January",
              year: year ? year : "2021",
              dr_number: dr_number,
            }
          )
          .then(function (response) {
            // handle success
            setRawOrders(response.data);
            setOrders(generateOrders(response.data));
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      } else if (month.includes("-report")) {
        axios
          .get(
            `${config.host}:${
              config.port
            }/api/v2/tiktok_gomo/get_all_orders?month=${month.replace(
              "-report",
              ""
            )}`
          )
          .then(function (response) {
            // handle success
            setReports(response.data);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      } else {
        axios
          .post(
            `${config.host}:${config.port}/api/v2/tiktok_gomo/get_orders_by_month`,
            {
              month: month ? month : "January",
              year: year ? year : 2021,
            }
          )
          .then(function (response) {
            // handle success
            setRawOrders(generateRawOrders(response.data));
            setOrders(generateOrders(response.data));
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      }
    }
  }

  function updateItemStatus(order_status, remarks, order_id, actions) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/tiktok_gomo/update_item_status`,
          {
            order_id: order_id,
            remarks: remarks,
            order_status: order_status,
            last_edit: loginSession.username,
          }
        )
        .then(function (response) {
          // handle success
          getOrders();
          actions();
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function assignedSINumber(dr_number, si_number, si_date) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/tiktok_gomo/assigned_si_number`,
          {
            dr_number: `*${dr_number}`,
            SI_number: si_number,
            SI_Date: dateFormat(si_date),
            last_edit: loginSession.username,
          }
        )
        .then(function (response) {
          getOrders();
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getPaymentRelease() {
    if (dr_number !== "") {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/tiktok_gomo/get_summarized_release_by_dr`,
          {
            month: month ? month : "January",
            year: year ? year : 2021,
            dr_number: dr_number,
          }
        )
        .then(function (response) {
          // handle success
          let data = response.data;
          setPaymentRelease(data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    } else if ((month !== "") & (year !== "")) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/tiktok_gomo/get_summarized_release`,
          {
            month: month ? month : "January",
            year: year ? year : 2021,
          }
        )
        .then(function (response) {
          // handle success
          let data = response.data;
          setPaymentRelease(data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    } else {
      axios
        .get(
          `${config.host}:${config.port}/api/v2/tiktok_gomo/get_shopee_payment_release`
        )
        .then(function (response) {
          // handle success
          let data = response.data;
          setPaymentRelease(data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getRTSList() {
    if ((month !== "") & (year !== "")) {
      axios
        .post(`${config.host}:${config.port}/api/v2/tiktok_gomo/get_rts`, {
          month: month ? month : "January",
          year: year ? year : 2021,
        })
        .then(function (response) {
          // handle success
          let data = response.data;
          setRTSList(data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  useEffect(() => {
    getPaymentRelease();
  }, [load]);

  useEffect(() => {
    getRTSList();
    getOrders();
  }, [paymentRelease, load, month, dr_number, year]);

  useEffect(() => {
    setDrList(generateDR(orders));
    setSIList(generateSI(orders));
  }, [rawOrders, orders, paymentRelease]);

  return {
    paymentRelease,
    rawOrders,
    orders,
    drList,
    sIList,
    setDrList,
    reports,
    updateItemStatus,
    generateSummaryReport,
    assignedSINumber,
    RTSList,
  };
}

export default useShopeeAPI;
