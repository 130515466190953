import React, { useEffect, useState } from "react";
import { Button, Card, Form, Grid, Table, Icon } from "tabler-react";
import dateFormat from "dateformat";

import ProfileForm from "../components/ProfileForm";
import CallLogForm from "../components/CallLogForm";
import CallLogHistory from "../components/CallLogHistory";

//hooks
import useOtherAccounts from "../../../hooks/api/AMS_other/useOtherAccounts";
import useQueryURL from "../../../hooks/services/useQueryURL";
import useUsers from "../../../hooks/api/Users/useUsers";

const Account = () => {
  const {
    otherAccount,
    getOtherAccountByID,
    callLogs,
    getOtherAccountCallByID,
    addCallLogs,
    updateCallLogs,
    updateAccount,
    deleteCallLogs,
  } = useOtherAccounts();
  const { getQuery } = useQueryURL();

  useEffect(() => {
    getOtherAccountByID(getQuery("id"));
  }, []);

  useEffect(() => {
    if (otherAccount.length != 0) {
      getOtherAccountCallByID(otherAccount.client_id);
    }
  }, [otherAccount]);

  const [addNewCall, setAddNewCall] = useState(false);
  const [selectedCallLog, setSelectedCallLog] = useState([]);
  const [selectType, setSelectType] = useState("");

  const { user } = useUsers();

  const [accessLevel, setAccessLevel] = useState("none");

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "ams-other-accounts")
            .access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  return (
    <>
      <ProfileForm
        data={{
          otherAccount,
          updateAccount,
          getOtherAccountByID,
          accessLevel,
          formType: "old"
        }}
      />
      <CallLogForm
        data={{
          accessLevel,
          otherAccount,
          deleteCallLogs,
          addCallLogs,
          addNewCall,
          setAddNewCall,
          getOtherAccountCallByID,
          client_id: otherAccount.client_id,
          setSelectedCallLog,
          selectedCallLog,
          updateCallLogs,
          selectType,
          setSelectType,
        }}
      />

      {otherAccount.length != 0 &&
      !addNewCall &&
      selectedCallLog.length == 0 ? (
        <CallLogHistory
          data={{
            accessLevel,
            callLogs,
            dateFormat,
            addNewCall,
            setAddNewCall,
            setSelectedCallLog,
            selectType,
            setSelectType,
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Account;
