import React, { useRef, useState } from "react";
import { Button, Card, Form, Grid, Table, Icon } from "tabler-react";
import ReactToPrint from "react-to-print";
import dateFormat from "dateformat";
import useUsers from "../../../../hooks/api/Users/useUsers";

const PrintDR = (props) => {
  const componentRef = useRef();

  const { user } = useUsers();

  const [courier, setCourier] = useState("");

  console.log(props.data.map((d) => d.assign_courier));

  return (
    <>
      {user.length === 0 ? (
        "Loading..."
      ) : (
        <div className="modal-summary">
          <Card ref={componentRef} className="modal-summary-content-container">
            <Card.Header>
              PRINT DR
              <Card.Options>
                <select
                  value={courier}
                  onChange={(e) => setCourier(e.target.value)}
                  className="form-control"
                >
                  <option value="">Select All Courier</option>
                  <option value="Standard Local-SPX Express">Shopee Express</option>
                  <option value="Standard Local-J&T Express">J&T Express</option>
                  <option value="Standard Local-Flash Express">Standard Local-Flash Express</option>
                </select>
              </Card.Options>
              <Card.Options>
                <ReactToPrint
                  trigger={() => (
                    <Button color="azure">
                      <Icon prefix="fa" name="print" />
                      Print
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
                <Button
                  color="secondary"
                  onClick={() => props.setViewPrintDR(false)}
                >
                  Close
                </Button>
              </Card.Options>
            </Card.Header>
            <Card.Body>
              <h3 style={{ margin: "0 auto", textAlign: "center" }}>
                {courier !== ""
                  ? courier
                  : "All Couriers"}{" "}
                - {dateFormat(new Date(), "mmmm yyyy")}
              </h3>
              <br />
              <Table
                responsive
                highlightRowOnHover
                hasOutline
                verticalAlign="center"
                cards
                className="text-nowrap"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>No.</Table.ColHeader>
                    <Table.ColHeader>Shipout Date</Table.ColHeader>
                    <Table.ColHeader>Order ID</Table.ColHeader>
                    <Table.ColHeader>Tracking #</Table.ColHeader>
                    <Table.ColHeader>Courier</Table.ColHeader>
                    <Table.ColHeader>Received By</Table.ColHeader>
                    <Table.ColHeader>Signature</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {props.data
                    .reduce(
                      (r, i) =>
                        !r.some((j) => i.order_id === j.order_id)
                          ? [...r, i]
                          : r,
                      []
                    )
                    .filter((p) =>
                      courier === "" ? p : p.courier === courier
                    )
                    .map((p, index) => (
                      <Table.Row key={index}>
                        <Table.Col>{index + 1}</Table.Col>
                        <Table.Col>{p.shipout_date}</Table.Col>
                        <Table.Col>{p.order_id}</Table.Col>
                        <Table.Col>{p.tracking_number}</Table.Col>
                        <Table.Col>{p.courier}</Table.Col>
                        <Table.Col> </Table.Col>
                        <Table.Col> </Table.Col>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
              <br />
              <br />
              <p style={{ margin: "0px auto", textAlign: "center" }}>
                -----------------------------------------------NOTHING
                FOLLOWS-------------------------------------------
              </p>
              <p
                style={{
                  margin: "0px auto",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                Printing Date:{" "}
                {dateFormat(new Date(), "ddd mmm dd yyyy h:MM TT")}
              </p>
              <br />
              <br />
              <br />
              <div style={{ marginLeft: "50px" }}>
                <p
                  style={{
                    margin: "0px auto",
                    fontSize: "15px",
                    margin: 0,
                  }}
                >
                  Prepared By:
                </p>
                <br />
                <br />
                <p
                  style={{
                    margin: "0px auto",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  ------------------------------ <br />
                  {user.full_name.toUpperCase()}
                </p>

                <p
                  style={{
                    margin: "0px auto",
                    fontSize: "15px",
                  }}
                >
                  {user.role.toUpperCase()}
                </p>
                <br />
                <br />
                <p style={{ margin: "0px auto", textAlign: "center" }}>
                  -------------------------------------------------------------------------------------------------------------------
                </p>
                <p
                  style={{
                    margin: "0px auto",
                    textAlign: "center",
                    fontSize: "15px",
                  }}
                >
                  Printing Date:{" "}
                  {dateFormat(new Date(), "ddd mmm dd yyyy h:MM TT")}
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default PrintDR;
