import React, {useState, useEffect} from "react";
import { Card, Button, Icon } from "tabler-react";
import Tooltip from "react-simple-tooltip";
import LazadaIcon from "../../../assets/icon/lazada.png";

import useLazadaPending from "../../../hooks/api/LazadaShop/useLazadaPending";
import useUsers from "../../../hooks/api/Users/useUsers";

const MainMenuSide = () => {
  const { pendingCount } = useLazadaPending();
  const { user, loginSession } = useUsers();
  useEffect(() => {
    if (loginSession.length == 0) {
      window.location.replace("/");
    }
  }, []);

  const [accessLevel, setAccessLevel] = useState("none");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "shopee-shop").access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="main-menu-container">
      <Card>
        <Card.Header>
          <img src={LazadaIcon} />
        </Card.Header>
        <Card.Body>
          <Tooltip
            content="Home"
            radius={5}
            padding={5}
            placement="right"
            style={{ whiteSpace: "nowrap", width: "100%" }}
            zIndex={2}
          >
            <Button
              className="menu-button"
              color="cyan"
              onClick={() => window.location.replace("/")}
            >
              <Icon prefix="fa" name="home" />
            </Button>
          </Tooltip>

          <hr />
          {["admin", "edit"].includes(accessLevel) ? (
            <Tooltip
              content={`${pendingCount} Pending/s`}
              radius={5}
              padding={5}
              placement="right"
              style={{ whiteSpace: "nowrap", width: "100%" }}
              zIndex={2}
            >
              <Button
                className="menu-button"
                color="orange"
                onClick={() =>
                  window.location.replace("/LazadaShop/PendingDashboard")
                }
              >
                <Icon prefix="fa" name="warning" />
              </Button>
              <span className="badge">{pendingCount}</span>
            </Tooltip>
          ) : (
            ""
          )}
          <Tooltip
            content="Report"
            radius={5}
            padding={5}
            placement="right"
            style={{ whiteSpace: "nowrap", width: "100%" }}
            zIndex={2}
          >
            <Button
              className="menu-button"
              color="teal"
              onClick={() =>
                window.location.replace("/LazadaShop/Report/SummaryReport")
              }
            >
              <Icon prefix="fa" name="print" />
            </Button>
          </Tooltip>

          <Tooltip
            content="DR Dashboard"
            radius={5}
            padding={5}
            placement="right"
            style={{ whiteSpace: "nowrap", width: "100%" }}
            zIndex={2}
          >
            <Button
              className="menu-button"
              color="teal"
              onClick={() => window.location.replace("/LazadaShop/Dashboard")}
            >
              <Icon prefix="fa" name="dashboard" />
            </Button>
          </Tooltip>

          <Tooltip
            content="RTS Dashboard"
            radius={5}
            padding={5}
            placement="right"
            style={{ whiteSpace: "nowrap", width: "100%" }}
            zIndex={2}
          >
            <Button
              className="menu-button"
              color="teal"
              onClick={() =>
                window.location.replace("/LazadaShop/RTSDashboard")
              }
            >
              RTS
            </Button>
          </Tooltip>

          <Tooltip
            content="SI Dashboard"
            radius={5}
            padding={5}
            placement="right"
            style={{ whiteSpace: "nowrap", width: "100%" }}
            zIndex={2}
          >
            <Button
              className="menu-button"
              color="teal"
              onClick={() => window.location.replace("/LazadaShop/SIDashboard")}
            >
              <Icon prefix="fa" name="list" />
            </Button>
          </Tooltip>

          <Tooltip
            content="Orders"
            radius={5}
            padding={5}
            placement="right"
            style={{ whiteSpace: "nowrap", width: "100%" }}
            zIndex={2}
          >
            <Button
              className="menu-button"
              color="teal"
              onClick={() =>
                window.location.replace(
                  "/LazadaShop/Orders?dr_number=&SI_number=--&month="
                )
              }
            >
              <Icon prefix="fa" name="shopping-cart" />
            </Button>
          </Tooltip>

          <Tooltip
            content="Payment Release"
            radius={5}
            padding={5}
            placement="right"
            style={{ whiteSpace: "nowrap", width: "100%" }}
            zIndex={2}
          >
            <Button
              className="menu-button"
              color="teal"
              onClick={() =>
                window.location.replace("/LazadaShop/PaymentRelease")
              }
            >
              <Icon prefix="fa" name="money" />
            </Button>
          </Tooltip>

          <Tooltip
            content="Help"
            radius={5}
            padding={5}
            placement="right"
            style={{ whiteSpace: "nowrap", width: "100%" }}
            zIndex={2}
          >
            <a href="/LazadaShop/Help" target="_blank">
              <Button className="menu-button" color="teal">
                <Icon prefix="fa" name="question" />
              </Button>
            </a>
          </Tooltip>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MainMenuSide;
