import { useState, useEffect } from "react";
import dateFormat from "dateformat";
import config from "../../../config";
import axios from "axios";

import useUsers from "../Users/useUsers";

function useOnlineStoreTool() {
  const { loginSession } = useUsers();

  const [pendingOrders, setPendingOrders] = useState([])

  function getStorePendingOrders() {
    if (loginSession.length != 0) {
      // console.log(orders);
      axios
        .post(
          `${config.host}:${config.port}/api/v2/gate_store/get_pending_orders`
        )
        .then(function (response) {
          // handle success
          //   actions();
          //   getPendingOrders();
          console.log(response);
          setPendingOrders(response.data)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function uploadOnlineOrders(orders) {
    if (loginSession.length != 0) {

        // console.log(orders);
      axios
        .post(
          `${config.host}:${config.port}/api/v2/gate_store/insert_pending_orders`,
          {
            orders,
            temp_dr_number: `*${dateFormat(new Date(), "mmddyyyy")}`,
            dr_month: dateFormat(new Date(), "mmmm"),
            dr_year: dateFormat(new Date(), "yyyy"),
            user: loginSession.username,
          }
        )
        .then(function (response) {
          // handle success
        //   actions();
        //   getPendingOrders();
        getStorePendingOrders()
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  return { uploadOnlineOrders, pendingOrders, getStorePendingOrders };
}

export default useOnlineStoreTool;
