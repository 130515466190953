import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractReportShopee() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadOrders(order, dr_number) {
    var headers = {
      id: "id",
      dr_number: "dr_number",
      SI_number: "SI_number",
      order_id: "order_id",
      original_price: "original_price",
      shopee_price: "shopee_price",
      coin_cashback: "coin_cashback",
      discount_rebate: "discount_rebate",
      seller_promotion: "seller_promotion",
      seller_voucher: "seller_voucher",
      commissionF_fee: "commission_fee",
      transaction_fee: "transaction_fee",
      paid_shipping_fee: "paid_shipping_fee",
      shipping_sub_total: "shipping_sub_total",
      release_amount: "release_amount",
      status: "status",
      remarks: "remarks",
      delivery_status: "delivery_status",
      order_status: "order_status",
      payout_date: "payout_date",
    };

    var itemsNotFormatted = order;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        id: item.id,
        dr_number: item.dr_number,
        SI_number: item.SI_number.replace(/(\r\n|\n|\r|,)/gm, ""),
        order_id: item.order_id,
        // tracking_number: item.tracking_number,
        original_price: item.original_price,
        shopee_price:
          item.original_price -
          Math.abs(
            parseFloat(item.discount_rebate) -
              parseFloat(item.seller_promotion) -
              parseFloat(item.seller_voucher) -
              parseFloat(item.coin_cashback)
          ),
        coin_cashback: item.coin_cashback,
        discount_rebate: item.discount_rebate,
        seller_promotion: item.seller_promotion,
        seller_voucher: item.seller_voucher,
        commissionF_fee: item.commission_fee,
        transaction_fee: item.transaction_fee,
        paid_shipping_fee: item.paid_shipping_fee,
        shipping_sub_total: item.shipping_sub_total,
        release_amount: item.release_amount,
        status: item.status,
        remarks: item.remarks,
        delivery_status: item.delivery_status,
        order_status: item.order_status,
        payout_date: item.payout_date,
      });
    });

    var fileTitle = `Tiktok orders_${dr_number}_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  function downloadSISummary(data) {
    var headers = {
      dr_number: "dr_number",
      SI_number: "SI_number",
      SI_date: "SI_date",
      gate_gross: "gate_gross",
      payment_release: "payment_release",
      commission_fee: "commission_fee",
      transaction_fee: "transaction_fee",
      coin_cashback_total: "coin_cashback_total",
      discount_rebate: "discount_rebate",
      seller_promotion: "seller_promotion",
      seller_voucher: "seller_voucher",
      shipping_sub_total: "shipping_sub_total",
    };

    var itemsNotFormatted = data;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        dr_number: item.dr_number,
        SI_number: item.SI_number.replace(/(\r\n|\n|\r|,)/gm, ""),
        SI_date: item.SI_Date,
        gate_gross: item.gate_price,
        payment_release: item.paymentRelease,
        commission_fee: item.commissionFee,
        transaction_fee: item.paymentFee,
        coin_cashback_total: item.coin_cashback_total,
        discount_rebate: item.discount_rebate_total,
        seller_promotion: item.seller_promotion_total,
        seller_voucher: item.seller_voucher_total,
        shipping_sub_total: item.shipping_sub_total,
      });
    });

    var fileTitle = `Tiktok_SI_Summary_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return { downloadOrders, downloadSISummary };
}

export default useExtractReportShopee;
