import { useState, useEffect } from "react";
import dateFormat from "dateformat";
import config, { CURRENT_YEAR } from "./../../../config";
import axios from "axios";
import useUsers from "../Users/useUsers";
import use711Stores from "./use711Stores";
import useCustomFunctions from "./../../services/useCustomFunctions";

function use711Dashboard(rangeSelection) {
  const { loginSession } = useUsers()

  const { getOneLessDay, getCurrentWeek } = useCustomFunctions();
  const schedules = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const { stores } = use711Stores();
  const [allCalls, setAllCalls] = useState([]);
  const [curCalls, setCurCalls] = useState([]);
  const [prevCalls, setPrevCalls] = useState([]);
  const [completedCalls, setCompletedCalls] = useState([]);
  const [callSummary, setCallSummary] = useState([]);
  const [curWeek, setCurWeek] = useState([]);
  const [prevWeek, setPrevWeek] = useState([]);
  const [telemarketers, setTelemarketers] = useState([]);


  function getTelemarketers(stores, completedCalls, prevCalls, rangeSelection) {
    if (loginSession) {
      // let curYear = dateFormat(new Date(), "yyyy");
      //   let prevYear = parseInt(curYear) - 1;
      axios
        .post(`${config.host}:${config.port}/api/v2/711dasboard/telemarketers`)
        .then(function (response) {
          // console.log(response);
          let data = response.data
            // .filter((tele) => tele.role === "Telemarketer")
            .map((tele) => ({
              ...tele,
              schedule: schedules[parseInt(dateFormat(new Date(), "N"))],
              curWeek: curWeek,
              all_stores: stores.filter(
                (store) =>
                  store.telemarketer === tele.full_name &&
                  store.active === "Yes"
              ),
              stores:
                rangeSelection === "Today"
                  ? stores
                      .filter(
                        (store) =>
                          store.telemarketer === tele.full_name &&
                          store.active === "Yes"
                      )
                      .filter(
                        (store) =>
                          store.schedule ===
                          schedules[parseInt(dateFormat(new Date(), "N"))]
                      )
                      .map((store) => store.store_number.replace("*", ""))
                  : stores
                      .filter(
                        (store) =>
                          store.telemarketer === tele.full_name &&
                          store.active === "Yes"
                      )
                      .map((store) => store.store_number.replace("*", "")),
              call_logs:
                rangeSelection === "Today"
                  ? completedCalls
                      .filter((call) => call.telemarketer === tele.full_name)
                      .filter(
                        (store) =>
                          dateFormat(store.date_order, "paddedShortDate") ===
                          dateFormat(new Date(), "paddedShortDate")
                      )
                  : completedCalls.filter(
                      (call) => call.telemarketer === tele.full_name
                    ),
              completed_calls:
                rangeSelection === "Today"
                  ? completedCalls
                      .filter(
                        (call) =>
                          call.telemarketer === tele.full_name &&
                          call.call_status === "Completed"
                      )
                      .filter(
                        (store) =>
                          dateFormat(store.date_order, "paddedShortDate") ===
                          dateFormat(new Date(), "paddedShortDate")
                      )
                  : completedCalls.filter(
                      (call) =>
                        call.telemarketer === tele.full_name &&
                        call.call_status === "Completed"
                    ),
              pending_calls:
                rangeSelection === "Today"
                  ? completedCalls
                      .filter(
                        (call) =>
                          call.telemarketer === tele.full_name &&
                          call.call_status === "Pending"
                      )
                      .filter(
                        (store) =>
                          dateFormat(store.date_order, "paddedShortDate") ===
                          dateFormat(new Date(), "paddedShortDate")
                      )
                  : completedCalls.filter(
                      (call) =>
                        call.telemarketer === tele.full_name &&
                        call.call_status === "Pending"
                    ),
              prevWeek: prevWeek,
              two_weeks: prevWeek.concat(curWeek),
              called_stores_last_week: prevCalls.filter(
                (call) =>
                  call.telemarketer === tele.full_name &&
                  prevWeek
                    .concat(curWeek)
                    .includes(dateFormat(call.date_order, "paddedShortDate"))
              ),
            }));

          setTelemarketers(
            data
              .map((tele) => ({
                ...tele,
                call_logs_count: tele.call_logs
                  .map((t) => parseInt(t.store_number))
                  .filter(
                    (v, i) =>
                      tele.call_logs
                        .map((t) => parseInt(t.store_number))
                        .indexOf(v) == i
                  ),
                received_by_list: completedCalls
                  .map((t) => t.received_by)
                  .filter(
                    (v, i) =>
                      completedCalls.map((t) => t.received_by).indexOf(v) == i
                  ),
                completed_count: tele.completed_calls
                  .map((t) => t.store_number)
                  .filter(
                    (v, i) =>
                      tele.completed_calls
                        .map((t) => t.store_number)
                        .indexOf(v) == i
                  ),
                pending_count: tele.pending_calls
                  .map((t) => t.store_number)
                  .filter(
                    (v, i) =>
                      tele.pending_calls
                        .map((t) => t.store_number)
                        .indexOf(v) == i
                  ),
                ordered_calls:
                  rangeSelection === "Today"
                    ? completedCalls
                        .filter(
                          (call) =>
                            call.received_by === tele.full_name &&
                            call.telemarketer !== tele.full_name &&
                            call.remarks === "Ordered" &&
                            call.call_status === "Completed"
                        )
                        .filter(
                          (store) =>
                            dateFormat(store.date_order, "paddedShortDate") ===
                            dateFormat(new Date(), "paddedShortDate")
                        )
                    : completedCalls.filter(
                        (call) =>
                          call.received_by === tele.full_name &&
                          call.telemarketer !== tele.full_name &&
                          call.remarks === "Ordered" &&
                          call.call_status === "Completed"
                      ),

                ordered_calls_assigned_only:
                  rangeSelection === "Today"
                    ? completedCalls
                        .filter(
                          (call) =>
                            call.received_by === tele.full_name &&
                            call.telemarketer === tele.full_name &&
                            call.remarks === "Ordered" &&
                            call.call_status === "Completed"
                        )
                        .filter(
                          (store) =>
                            dateFormat(store.date_order, "paddedShortDate") ===
                            dateFormat(new Date(), "paddedShortDate")
                        )
                    : completedCalls.filter(
                        (call) =>
                          call.received_by === tele.full_name &&
                          call.telemarketer === tele.full_name &&
                          call.remarks === "Ordered" &&
                          call.call_status === "Completed"
                      ),

                ordered_calls_by_assigned_store:
                  rangeSelection === "Today"
                    ? completedCalls
                        .filter(
                          (call) =>
                            call.telemarketer === tele.full_name &&
                            call.remarks === "Ordered" &&
                            call.call_status === "Completed"
                        )
                        .filter(
                          (store) =>
                            dateFormat(store.date_order, "paddedShortDate") ===
                            dateFormat(new Date(), "paddedShortDate")
                        )
                    : completedCalls.filter(
                        (call) =>
                          call.telemarketer === tele.full_name &&
                          call.remarks === "Ordered" &&
                          call.call_status === "Completed"
                      ),

                order_amount:
                  rangeSelection === "Today"
                    ? completedCalls
                        .filter(
                          (call) =>
                            call.received_by === tele.full_name &&
                            call.telemarketer !== tele.full_name &&
                            call.remarks === "Ordered" &&
                            call.call_status === "Completed"
                        )
                        .filter(
                          (store) =>
                            dateFormat(store.date_order, "paddedShortDate") ===
                            dateFormat(new Date(), "paddedShortDate")
                        )
                        .reduce((a, b) => +a + +b.amount, 0)
                    : completedCalls
                        .filter(
                          (call) =>
                            call.received_by === tele.full_name &&
                            call.telemarketer !== tele.full_name &&
                            call.remarks === "Ordered" &&
                            call.call_status === "Completed"
                        )
                        .reduce((a, b) => +a + +b.amount, 0),
                order_amount_assigned_only:
                  rangeSelection === "Today"
                    ? completedCalls
                        .filter(
                          (call) =>
                            call.received_by === tele.full_name &&
                            call.telemarketer === tele.full_name &&
                            call.remarks === "Ordered" &&
                            call.call_status === "Completed"
                        )
                        .filter(
                          (store) =>
                            dateFormat(store.date_order, "paddedShortDate") ===
                            dateFormat(new Date(), "paddedShortDate")
                        )
                        .reduce((a, b) => +a + +b.amount, 0)
                    : completedCalls
                        .filter(
                          (call) =>
                            call.received_by === tele.full_name &&
                            call.telemarketer === tele.full_name &&
                            call.remarks === "Ordered" &&
                            call.call_status === "Completed"
                        )
                        .reduce((a, b) => +a + +b.amount, 0),
                order_amount_by_assigned_store:
                  rangeSelection === "Today"
                    ? completedCalls
                        .filter(
                          (call) =>
                            call.telemarketer === tele.full_name &&
                            call.remarks === "Ordered" &&
                            call.call_status === "Completed"
                        )
                        .filter(
                          (store) =>
                            dateFormat(store.date_order, "paddedShortDate") ===
                            dateFormat(new Date(), "paddedShortDate")
                        )
                        .reduce((a, b) => +a + +b.amount, 0)
                    : completedCalls
                        .filter(
                          (call) =>
                            call.telemarketer === tele.full_name &&
                            // call.received_by === tele.full_name &&
                            call.remarks === "Ordered" &&
                            call.call_status === "Completed"
                        )
                        .reduce((a, b) => +a + +b.amount, 0),
              }))
              .map((tele) => ({
                ...tele,
                uncalled_stores: tele.all_stores.filter((store) =>
                  rangeSelection === "Today"
                    ? !tele.call_logs_count.includes(
                        parseInt(store.store_number.replace("*", ""))
                      ) && store.schedule === tele.schedule
                    : !tele.call_logs_count.includes(
                        parseInt(store.store_number.replace("*", ""))
                      )
                ),
                uncalled_stores_count: tele.stores.filter(
                  (store) => !tele.call_logs_count.includes(parseInt(store))
                ),
              }))
              .map((tele) => ({
                ...tele,
                called_stores_last_week_id: tele.called_stores_last_week
                  .map((t) => parseInt(t.store_number))
                  .filter(
                    (v, i) =>
                      tele.called_stores_last_week
                        .map((t) => parseInt(t.store_number))
                        .indexOf(v) == i
                  ),
              }))
              .map((tele) => ({
                ...tele,
                uncalled_called_stores_last_week: tele.all_stores.filter(
                  (store) =>
                    rangeSelection === "Today"
                      ? !tele.called_stores_last_week_id.includes(
                          parseInt(store.store_number.replace("*", ""))
                        ) && store.schedule === tele.schedule
                      : !tele.called_stores_last_week_id.includes(
                          parseInt(store.store_number.replace("*", ""))
                        )
                ),
              }))
              .map((tele) => ({
                ...tele,
                uncalled_called_stores_last_week_id:
                  tele.uncalled_called_stores_last_week
                    .map((t) => parseInt(t.store_number))
                    .filter(
                      (v, i) =>
                        tele.uncalled_called_stores_last_week
                          .map((t) => parseInt(t.store_number))
                          .indexOf(v) == i
                    ),
              }))
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getCalls() {
    if (loginSession && curWeek.length == 7) {
      // let curYear = dateFormat(new Date(), "yyyy");
      //   let prevYear = parseInt(curYear) - 1;
      axios
        .post(`${config.host}:${config.port}/api/v2/711dasboard/calls`, {
          curYear: parseInt(CURRENT_YEAR),
          start: new Date(prevWeek[0]),
          end: new Date(curWeek[6]),
        })
        .then(function (response) {
          let data = response.data;
          setAllCalls(data);
          setCurCalls(data);
          setCompletedCalls(
            data.filter((call) =>
              curWeek.includes(dateFormat(call.date_order, "paddedShortDate"))
            )
          );
          setPrevCalls(
            data.filter((call) =>
              prevWeek
                .concat(curWeek)
                .includes(dateFormat(call.date_order, "paddedShortDate"))
            )
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function generateCallSummary(data) {
    let weekLyCalls =
      rangeSelection === "Today"
        ? data.filter(
            (call) =>
              dateFormat(call.date_order, "paddedShortDate") ===
              dateFormat(new Date(), "paddedShortDate")
          )
        : data.filter((call) =>
            curWeek.includes(dateFormat(call.date_order, "paddedShortDate"))
          );
    setCallSummary({
      active_stores: stores.filter((store) => store.active === "Yes").length,
      scheduled_stores: stores.filter((s) =>
        rangeSelection === "Today"
          ? s.schedule === schedules[dateFormat(new Date(), "N")]
          : s
      ).length,
      completed_calls:
        // .filter(
        //   (v, i) =>
        //     weekLyCalls
        //       .filter((call) => call.call_status === "Completed")
        //       .map((t) => t.store_number)
        //       .indexOf(v) == i
        // )
        weekLyCalls
          .filter((call) => call.call_status === "Completed")
          .map((t) => t.store_number).length,
      pending_calls:
        // .filter(
        //   (v, i) =>
        //     weekLyCalls
        //       .filter((call) => call.call_status === "Pending")
        //       .map((t) => t.store_number)
        //       .indexOf(v) == i
        // )
        weekLyCalls
          .filter((call) => call.call_status === "Pending")
          .map((t) => t.store_number).length,
      completed_calls_osa: weekLyCalls
        .filter(
          (call) =>
            call.call_status === "Completed" &&
            call.remarks === "Ordered" &&
            call.courier_status === "OSA"
        )
        .map((t) => t.store_number)
        .filter(
          (v, i) =>
            weekLyCalls
              .filter(
                (call) =>
                  call.call_status === "Completed" &&
                  call.remarks === "Ordered" &&
                  call.courier_status === "OSA"
              )
              .map((t) => t.store_number)
              .indexOf(v) == i
        ).length,
      completed_calls_serviceable: weekLyCalls
        .filter(
          (call) =>
            call.call_status === "Completed" &&
            call.remarks === "Ordered" &&
            call.courier_status === "Serviceable"
        )
        .map((t) => t.store_number)
        .filter(
          (v, i) =>
            weekLyCalls
              .filter(
                (call) =>
                  call.call_status === "Completed" &&
                  call.remarks === "Ordered" &&
                  call.courier_status === "Serviceable"
              )
              .map((t) => t.store_number)
              .indexOf(v) == i
        ).length,
      completed_ordered_calls: weekLyCalls.filter(
        (call) => call.call_status === "Completed" && call.remarks === "Ordered"
      ).length,
    });
  }

  useEffect(() => {
    getCurrentWeek(new Date(), setCurWeek);
  }, [rangeSelection]);

  useEffect(() => {
    if (curWeek.length != 0) {
      getCurrentWeek(getOneLessDay(curWeek[0]), setPrevWeek);
    }
  }, [curWeek, rangeSelection]);

  // console.log(prevCalls);
  // console.log(telemarketers);

  useEffect(() => {
    if (stores.length != 0) {
      getCalls(stores);
    }
  }, [stores, rangeSelection]);

  useEffect(() => {
    getTelemarketers(stores, completedCalls, prevCalls, rangeSelection);
  }, [stores, allCalls, curCalls, completedCalls, rangeSelection, prevCalls]);

  useEffect(() => {
    generateCallSummary(completedCalls);
  }, [stores, allCalls, curCalls, completedCalls, rangeSelection, prevCalls]);

  return { stores, allCalls, curCalls, callSummary, telemarketers, curWeek };
}

export default use711Dashboard;
