import React, { useEffect, useState } from "react";
import { Card, Loader, Table, Grid, Form, Button, Icon } from "tabler-react";
import Pagination from "../../../../components/Pagination";
import Summary from "./Summary";
import Tooltip from "react-simple-tooltip";
import Filter1 from "./Filter1";
import CopyBTN from "../../../../components/CopyBTN";

//hooks
import useUsers from "../../../../hooks/api/Users/useUsers";
import useQueryURL from "../../../../hooks/services/useQueryURL";
import useLazadaAPI from "../../../../hooks/api/LazadaShop/useLazadaAPI";
import useExtractReport from "../../../../hooks/api/LazadaShop/extractions/useExtractReport";
import EditDetails from "./EditDetails";

const Orders = () => {
  const { getQuery } = useQueryURL();
  const { user } = useUsers();
  const { downloadOrders } = useExtractReport();

  let SI_Number = getQuery("SI_number");
  let DR_month = getQuery("month");
  let DR_number = getQuery("dr_number");
  let DR_year = getQuery("year");
  let page = getQuery("page");

  const [month, setMonth] = useState(DR_month !== "" ? DR_month : "null");
  const [year, setYear] = useState(DR_year !== "" ? DR_year : "null");

  const {
    orders,
    updateStatus,
    updateItemStatus,
    assignedSINumber,
    generateSummaryReport,
  } = useLazadaAPI(
    DR_month !== "" ? DR_month : "null",
    DR_number !== "" ? `*${DR_number}` : DR_number
  );

  const [accessLevel, setAccessLevel] = useState("none");
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(page ? page : 1);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  const [search, setSearch] = useState(
    getQuery("dr_number") ? getQuery("dr_number") : ""
  );

  const [search2, setSearch2] = useState(
    getQuery("order_id") ? getQuery("order_id") : ""
  );

  const [newOrders, setNewOrders] = useState([]);

  //update status
  const [remarks, setRemarks] = useState("");
  const [status, setStatus] = useState("");

  const [assignedSI, setAssignedSI] = useState(false);
  const [SINumber, setSINumber] = useState(SI_Number ? SI_Number : "*");
  const [SIDate, setSIDate] = useState(new Date());
  const [selectedItemNumber, setSelectedItemNumber] = useState("");

  //summary
  const [summaryReport, setSummaryReport] = useState([]);
  const [viewReport, setViewReport] = useState(false);
  const [readyStatus, setReadyStatus] = useState([]);

  //filters
  const [filter, setFilter] = useState(
    getQuery("month") ? getQuery("month") : "January"
  ); // Month
  const [filter1, setFilter1] = useState(
    getQuery("year") ? getQuery("year") : "2021"
  ); // Year
  const [filter2, setFilter2] = useState(
    getQuery("dr_number") ? getQuery("dr_number") : ""
  ); // DR Number
  const [filter3, setFilter3] = useState(
    getQuery("order_id") ? getQuery("order_id") : ""
  ); // Order Number
  const [filter4, setFilter4] = useState(""); // Remarks
  const [filter5, setFilter5] = useState(""); // Supplier
  const [filter6, setFilter6] = useState(
    getQuery("shop_status") ? getQuery("shop_status") : ""
  ); // Shop Status
  const [filter7, setFilter7] = useState(
    getQuery("gate_status") ? getQuery("gate_status") : ""
  ); // Inventory Status
  const [filter8, setFilter8] = useState(getQuery("product_name") ? getQuery("product_name") : ""); // Inventory Status

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "lazada-shop").access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  useEffect(() => {
    if (orders.length != 0) {
      // setActivePage(1);
      setNewOrders(
        orders
          .filter((o) =>
            filter2 !== "" ? o.dr_number.replace("*", "").includes(filter2) : o
          )
          .filter((o) =>
            filter3 !== ""
              ? o.order_id.replace("*", "") === filter3 ||
                o.order_item_number.replace("*", "") === filter3
              : o
          )
          .filter((o) => (filter4 !== "" ? o.remarks === filter4 : o))
          .filter((o) => (filter5 !== "" ? o.supplier === filter5 : o))
          .filter((o) =>
            filter6 !== ""
              ? o.deliveryStatus === filter6 || o.payment_waiting === filter6
              : o
          )
          .filter((o) => (filter7 !== "" ? o.gate_status === filter7 : o))
          .filter((o) => (filter8 !== "" ? o.product_name.toLowerCase().includes(filter8.toLowerCase()) : o))
      );
    }
  }, [
    orders,
    filter,
    filter1,
    filter2,
    filter3,
    filter4,
    filter5,
    filter6,
    filter7,
    filter8
  ]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [newOrders]);

  useEffect(() => {
    if (newOrders) {
      setSummaryReport(generateSummaryReport(newOrders));
    }
  }, [newOrders]);

  useEffect(() => {
    let data = {
      amount_release: orders.reduce((a, b) => a + b.amount_release, 0),
      gate_price: orders.reduce((a, b) => a + b.gate_price, 0),
      commissionFee: orders.reduce((a, b) => a + b.commissionFee, 0),
      paymentFee: orders.reduce((a, b) => a + b.paymentFee, 0),
    };
    setReadyStatus({
      lazada: data.amount_release + data.commissionFee + data.paymentFee,
      gate_gross: data.gate_price,
      SI_Number: orders
        ? orders
            .map((o) => o.SI_number)
            .filter((v, i) => orders.map((o) => o.SI_number).indexOf(v) == i)[0]
        : SI_Number,
      status:
        data.amount_release + data.commissionFee + data.paymentFee ===
        data.gate_price
          ? "Ready"
          : "Not Ready",
    });
  }, [orders]);

  const changePage = (p) => {
    window.location.replace(
      `/LazadaShop/Orders?dr_number=${search}&SI_number=${SINumber}&month=${month}&order_id=${search2}&page=${p}&year=${year}`
    );
  };

  const [editForm, setEditForm] = useState([]);

  return (
    <>
      {viewReport ? (
        <Summary
          data={{
            accessLevel,
            viewReport,
            setViewReport,
            summaryReport,
            DR_number,
            DR_month,
            DR_year,
            user,
          }}
        />
      ) : (
        ""
      )}

      {editForm.length !== 0 ? (
        <EditDetails editForm={editForm} setEditForm={setEditForm} />
      ) : (
        ""
      )}

      <Card>
        <Card.Header>
          Orders
          <Card.Options>
            {readyStatus.length != 0 ? (
              readyStatus.SI_Number === "" || readyStatus.SI_Number == "--" ? (
                <span
                  style={{
                    color:
                      readyStatus.lazada == readyStatus.gate_gross
                        ? "#17a2b8"
                        : "#cd201f",
                    padding: "8px",
                    borderRadius: "5px",
                    textTransform: "uppercase",
                    margin: "5px",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  {readyStatus.length != 0
                    ? readyStatus.lazada == readyStatus.gate_gross
                      ? "Ready"
                      : "Not Ready"
                    : ""}
                </span>
              ) : (
                <span
                  style={{
                    color: "#17a2b8",
                    padding: "8px",
                    borderRadius: "5px",
                    textTransform: "uppercase",
                    margin: "5px",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Completed
                </span>
              )
            ) : (
              ""
            )}
          </Card.Options>
        </Card.Header>
        <Filter1
          data={{
            readyStatus,
            accessLevel,
            orders,
            setNewOrders,
            setLoading,
            loading,
            search,
            downloadOrders,
            newOrders,
            assignedSI,
            SI_Number: orders
              ? orders
                  .map((o) => o.SI_number)
                  .filter(
                    (v, i) => orders.map((o) => o.SI_number).indexOf(v) == i
                  )[0]
              : SI_Number,
            SINumber,
            setSINumber,
            SIDate,
            setSIDate,
            setAssignedSI,
            viewReport,
            setViewReport,
            assignedSINumber,
            filter,
            setFilter,
            filter1,
            setFilter1,
            filter2,
            setFilter2,
            filter3,
            setFilter3,
            filter4,
            setFilter4,
            filter5,
            setFilter5,
            filter6,
            setFilter6,
            filter7,
            setFilter7,
            filter8,
            setFilter8
          }}
        />

        <Card.Body>
          {loading ? (
            <Loader />
          ) : (
            <>
              {newOrders.length == 0 ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "40px",
                    color: "#b9b9b9",
                  }}
                >
                  <Icon prefix="fa" name="folder-open" />
                  <p
                    style={{
                      fontSize: "20px",
                      margin: 0,
                      fontWeight: "bold",

                      textTransform: "uppercase",
                    }}
                  >
                    NO DATA AVAILABLE
                  </p>
                </div>
              ) : (
                <>
                  <>
                    <Table
                      responsive
                      highlightRowOnHover
                      hasOutline
                      verticalAlign="center"
                      cards
                      className="text-nowrap"
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.ColHeader>No.</Table.ColHeader>
                          <Table.ColHeader>DR Number</Table.ColHeader>
                          {readyStatus.SI_Number === "--" ||
                          readyStatus.SI_Number === "--" ? (
                            ""
                          ) : (
                            <Table.ColHeader>SI Number</Table.ColHeader>
                          )}
                          <Table.ColHeader>Order Date</Table.ColHeader>
                          <Table.ColHeader>Order ID</Table.ColHeader>
                          <Table.ColHeader>Order Item No.</Table.ColHeader>
                          <Table.ColHeader>Product Information</Table.ColHeader>
                          <Table.ColHeader>Gate Price</Table.ColHeader>
                          <Table.ColHeader>Payment Release</Table.ColHeader>
                          {readyStatus.status === "Ready" ? (
                            ""
                          ) : (
                            <Table.ColHeader>Payment</Table.ColHeader>
                          )}
                          {/* <Table.ColHeader>Commission</Table.ColHeader> */}
                          <Table.ColHeader>Payment Match</Table.ColHeader>
                          <Table.ColHeader>Delivery Status</Table.ColHeader>
                          <Table.ColHeader>Inventory Status</Table.ColHeader>
                          <Table.ColHeader>Remarks</Table.ColHeader>
                          <Table.ColHeader>Actions</Table.ColHeader>
                          <Table.ColHeader>Edi Details</Table.ColHeader>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {newOrders
                          .sort(function (a, b) {
                            return b.id - a.id;
                          })
                          .slice(
                            activePage == 1 ? 0 : (activePage - 1) * pageLimit,
                            activePage * pageLimit
                          )
                          .map((order, index) => (
                            <Table.Row>
                              <Table.Col>
                                <b>
                                  {activePage == 1
                                    ? index + 1
                                    : index + 1 + (activePage - 1) * pageLimit}
                                  .
                                </b>
                              </Table.Col>
                              <Table.Col>
                                <a
                                  href={`/LazadaShop/Orders?dr_number=${order.dr_number.replace(
                                    "*",
                                    ""
                                  )}&SI_number=${order.SI_number}&month=${
                                    order.DR_month
                                  }&year=${order.DR_year}&order_id=`}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                  target="_blank"
                                >
                                  {order.dr_number.replace("*", "")}
                                </a>{" "}
                                <CopyBTN
                                  value={order.dr_number.replace("*", "")}
                                />
                              </Table.Col>
                              {readyStatus.SI_Number === "--" ||
                              readyStatus.SI_Number === "--" ? (
                                ""
                              ) : (
                                <Table.Col>
                                  {order.SI_number.replace("*", "")}{" "}
                                  <CopyBTN
                                    value={order.SI_number.replace("*", "")}
                                  />
                                </Table.Col>
                              )}
                              <Table.Col>{order.order_date}</Table.Col>

                              <Table.Col>
                                {/* <Table.Col>{order.shipout_date}</Table.Col> */}
                                <a
                                  href={`/LazadaShop/Orders?dr_number=${order.dr_number.replace(
                                    "*",
                                    ""
                                  )}&SI_number=${order.SI_number}&month=${
                                    order.DR_month
                                  }&year=${
                                    order.DR_year
                                  }&order_id=${order.order_id.replace(
                                    "*",
                                    ""
                                  )}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                  target="_blank"
                                >
                                  {order.order_id.replace("*", "")}
                                </a>{" "}
                                <CopyBTN
                                  value={order.order_id.replace("*", "")}
                                />
                              </Table.Col>
                              <Table.Col>
                                {order.order_item_number.replace("*", "")}{" "}
                                <CopyBTN
                                  value={order.order_item_number.replace(
                                    "*",
                                    ""
                                  )}
                                />
                              </Table.Col>
                              {/* <Table.Col>{order.tracking_number}</Table.Col> */}
                              {/* <Table.Col>{order.courier}</Table.Col> */}
                              <Table.Col>{order.product_name}</Table.Col>
                              {/* <Table.Col>{order.gate_price}</Table.Col>
                        <Table.Col>{order.lazada_price}</Table.Col>
                        <Table.Col>{order.quantity}</Table.Col>
                        <Table.Col>{order.globe_discount}</Table.Col>
                        <Table.Col>{order.smart_discount}</Table.Col>
                        <Table.Col>{order.paymentRelease}</Table.Col>
                        <Table.Col>{order.commissionFee}</Table.Col>
                        <Table.Col>{order.paymentFee}</Table.Col> */}
                              {/* <Table.Col>{order.status}</Table.Col> */}
                              <Table.Col>{order.gate_price}</Table.Col>
                              <Table.Col>{order.amount_release}</Table.Col>
                              {readyStatus.status === "Ready" ? (
                                ""
                              ) : (
                                <Table.Col>
                                  <>
                                    {order.payment_waiting === "--" ? (
                                      ""
                                    ) : (
                                      <span
                                        style={{
                                          color:
                                            order.payment_waiting ===
                                            "Request for Payment"
                                              ? "#cd201f"
                                              : "#2bcbba",
                                          padding: "8px",
                                          borderRadius: "5px",
                                          textTransform: "uppercase",
                                          margin: "5px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {order.deliveryStatus === "Delivered"
                                          ? ""
                                          : (order.gate_status === "Returned" ||
                                              order.gate_status ===
                                                "Cancelled") &&
                                            (order.remarks === "Inventory" ||
                                              order.remarks ===
                                                "Lost Inventory")
                                          ? ""
                                          : order.payment_waiting}
                                      </span>
                                    )}
                                  </>
                                </Table.Col>
                              )}
                              {/* <Table.Col>{order.commissionFee}</Table.Col> */}
                              <Table.Col>
                                {order.payment_match !== "--" ? (
                                  <span
                                    style={{
                                      color:
                                        order.payment_match === "Matched"
                                          ? "#17a2b8"
                                          : "#cd201f",
                                      padding: "8px",
                                      borderRadius: "5px",
                                      textTransform: "uppercase",
                                      margin: "5px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {order.payment_match}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "#cd201f",
                                      padding: "8px",
                                      borderRadius: "5px",
                                      textTransform: "uppercase",
                                      margin: "5px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    UNPAID
                                  </span>
                                )}
                              </Table.Col>
                              <Table.Col>
                                {order.deliveryStatus === "" ||
                                order.deliveryStatus === "--" ? (
                                  <span
                                    style={{
                                      color: "#cd201f",
                                      padding: "8px",
                                      borderRadius: "5px",
                                      textTransform: "uppercase",
                                      margin: "5px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    UNPAID
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color:
                                        order.deliveryStatus === "Delivered"
                                          ? "#17a2b8"
                                          : order.deliveryStatus ===
                                              "Returned" ||
                                            order.deliveryStatus ===
                                              "Returned - PRV" ||
                                            order.deliveryStatus === "Cancelled"
                                          ? "#B7193D"
                                          : "#2bcbba",
                                      padding: "8px",
                                      borderRadius: "5px",
                                      textTransform: "uppercase",
                                      margin: "5px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {order.deliveryStatus}
                                  </span>
                                )}
                              </Table.Col>
                              <Table.Col>
                                {selectedItemNumber ===
                                order.order_item_number ? (
                                  <>
                                    <Form.Select
                                      value={status}
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                    >
                                      <option value="">Choose one</option>
                                      <option value="Cancelled">
                                        Cancelled
                                      </option>
                                      <option value="Returned">Returned</option>
                                      {/* <option value="Returned - PR">
                                        Returned - PR
                                      </option>
                                      <option value="Lost - PR">
                                        Lost - PR
                                      </option> */}
                                    </Form.Select>
                                  </>
                                ) : (
                                  <>
                                    {order.gate_status === "" ||
                                    order.gate_status === "--" ? (
                                      ""
                                    ) : (
                                      <span
                                        style={{
                                          color: "#cd201f",
                                          padding: "8px",
                                          borderRadius: "5px",
                                          textTransform: "uppercase",
                                          margin: "5px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {order.gate_status}
                                      </span>
                                    )}
                                  </>
                                )}
                              </Table.Col>
                              <Table.Col>
                                {selectedItemNumber ===
                                order.order_item_number ? (
                                  <>
                                    <Form.Select
                                      value={remarks}
                                      onChange={(e) =>
                                        setRemarks(e.target.value)
                                      }
                                    >
                                      <option value="">Choose one</option>

                                      {user.department ===
                                        "Operations Department" ||
                                      user.access_level === "Administrator" ? (
                                        <>
                                          <option value="Inventory">
                                            Inventory
                                          </option>
                                          <option value="Waiting">
                                            Waiting
                                          </option>
                                          <option value="Endorsed to MKTG">
                                            Endorsed to MKTG
                                          </option>
                                          <option value="Lost Inventory">
                                            Lost Inventory
                                          </option>
                                          <option value="Charge To Employee">
                                            Charge To Employee
                                          </option>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {user.department ===
                                        "Marketing Department" || user.department ===
                                        "Sales Department" ||
                                      user.access_level === "Administrator" ? (
                                        <>
                                          <option value="In Transit">
                                            In Transit
                                          </option>
                                          <option value="Paid">Paid</option>
                                          <option value="Payment Waiting">
                                            Payment Waiting
                                          </option>
                                          <option value="DIS - charge to Telco">
                                            DIS - charge to Telco
                                          </option>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Select>
                                  </>
                                ) : (
                                  <span
                                    style={{
                                      color: "#17a2b8",
                                      padding: "8px",
                                      borderRadius: "5px",
                                      textTransform: "uppercase",
                                      margin: "5px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {order.remarks}
                                  </span>
                                )}
                              </Table.Col>
                              <Table.Col>
                                {(order.deliveryStatus !== "Delivered" &&
                                  order.deliveryStatus !== "Lost - PR") ||
                                user.department === "Marketing Department" ||
                                user.access_level === "Administrator" ? (
                                  <>
                                    {selectedItemNumber ===
                                    order.order_item_number ? (
                                      <>
                                        <Tooltip
                                          content="Save"
                                          radius={5}
                                          padding={5}
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          <Button
                                            icon="save"
                                            color="azure"
                                            onClick={() =>
                                              updateItemStatus(
                                                status,
                                                remarks,
                                                order.order_item_number
                                              ) ||
                                              setSelectedItemNumber("") ||
                                              setRemarks("") ||
                                              setStatus("")
                                            }
                                            disabled={
                                              accessLevel === "view" ||
                                              accessLevel === "report"
                                            }
                                          ></Button>
                                        </Tooltip>
                                        <Tooltip
                                          content="Cancel"
                                          radius={5}
                                          padding={5}
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          <Button
                                            color="secondary"
                                            onClick={() =>
                                              setSelectedItemNumber("") ||
                                              setRemarks("") ||
                                              setStatus("")
                                            }
                                          >
                                            <Icon prefix="fa" name="close" />
                                          </Button>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {order.deliveryStatus !== "Delivered" &&
                                    selectedItemNumber === "" ? (
                                      <Tooltip
                                        content="Edit"
                                        radius={5}
                                        padding={5}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <Button
                                          icon="edit"
                                          color="azure"
                                          onClick={() =>
                                            setSelectedItemNumber(
                                              order.order_item_number
                                            ) ||
                                            setRemarks(order.remarks) ||
                                            setStatus(order.order_status)
                                          }
                                          disabled={
                                            accessLevel === "view" ||
                                            accessLevel === "report"
                                          }
                                        ></Button>
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </Table.Col>
                              <Table.Col>
                                {user.access_level === "Administrator" ? (
                                  <Button onClick={() => setEditForm({
                                    id: order.id,
                                    product_name: order.product_name
                                  })}>
                                    Edit Details
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </Table.Col>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  </>
                </>
              )}
            </>
          )}
        </Card.Body>
        {newOrders.length <= pageLimit || loading ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={pageLimit}
            totalItemsCount={newOrders.length}
            pageRangeDisplayed={pageRangeDisplayed}
            changePage={changePage}
            setActivePage={changePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default Orders;
