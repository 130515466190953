import React, { useState, useEffect } from "react";
import { Card, Table, Button, Icon, Loader, Form, Grid } from "tabler-react";
import DatePicker from "react-datepicker";

//hooks
import useUsers from "../../../../hooks/api/Users/useUsers";
import use711Stores from "../../../../hooks/api/AMS/use711Stores";
import usePreferences from "../../../../hooks/api/AMS/usePreferences";
import useQueryURL from "../../../../hooks/services/useQueryURL";

const StoreAddForm = () => {
  const { user } = useUsers();
  const { addStore, updateStore, store, getStore, stores } = use711Stores();
  const { telemarketers, accountExecutives } = useUsers();
  const { preferences } = usePreferences();
  const { getQuery } = useQueryURL();
  const q_store_number = getQuery("id");
  const q_backURL = getQuery("backURL");

  const [accessLevel, setAccessLevel] = useState("none");
  const [storeNumber, setStoreNumber] = useState("");
  const [storeName, setStoreName] = useState("");
  const [storeManager, setStoreManager] = useState("");
  const [schedule, setSchedule] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("");
  const [areaManager, setAreaManger] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [telemarketer, setTelemarketer] = useState("");
  const [accountExecutive, setAccountExecutive] = useState("");
  const [courierStatus, setCourierStatus] = useState("");
  const [area, setArea] = useState("");
  const [district, setDistrict] = useState("");
  const [cluster, setCluster] = useState("");
  const [zone, setZone] = useState("");
  const [monthlyTarget, setMonthlyTarget] = useState(0);
  const [dateOpen, setDateOpen] = useState(new Date());
  const [active, setActive] = useState("Yes");
  const [networkPreference, setNetworkPreference] = useState([]);

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "711-account-management")
            .access
        : "none"
    );
  }, [user]);

  useEffect(() => {
    if (q_store_number) {
      getStore(q_store_number, (id) => console.log(id));
    }
  }, [q_store_number]);

  useEffect(() => {
    if (store.length != 0) {
      setStoreNumber(store[0].store_number.replace("*", ""));
      setStoreName(store[0].store_name);
      setStoreManager(store[0].store_manager);
      setSchedule(store[0].schedule);
      setLocation(store[0].location);
      setStatus(store[0].status);
      setAreaManger(store[0].area_manager);
      setFullAddress(store[0].full_address);
      setEmailAddress(
        store[0].email_address
          ? store[0].email_address
          : `st${store[0].store_number.replace("*", "")}@store.philseven.com`
      );
      setContactNumber(store[0].contact_number);
      setTelemarketer(store[0].telemarketer);
      setAccountExecutive(store[0].account_executive);
      setCourierStatus(store[0].courier_status);
      setArea(store[0].area);
      setDistrict(store[0].district);
      setCluster(store[0].cluster);
      setZone(store[0].zone);
      setMonthlyTarget(store[0].monthly_target);
      setDateOpen(new Date(store[0].date_open));
      setActive(store[0].active);
      setNetworkPreference(
        store[0].networkPreference !== ""
          ? JSON.parse(store[0].networkPreference)
          : []
      );
    }
  }, [store]);

  const [isAvailable, setIsAvailable] = useState(true);

  useEffect(() => {
    let storeList =
      stores.length != 0
        ? stores.filter((store) => store.store_number === `*${storeNumber}`)
        : [];
    if (storeList.length == 0) {
      setIsAvailable(true);
    } else {
      setIsAvailable(false);
    }
  }, [storeNumber, stores]);

  const NETWORKS = ["smart", "globe", "dito", "none"];

  console.log(store);
  return (
    <div style={{ margin: "5px" }}>
      <Card>
        <Card.Header>
          711 Store - {q_store_number ? "Update Form" : "Add Form"}
          <Card.Options>
            <a href={window.atob(q_backURL)}>
              <Button color="secondary">
                <Icon prefix="fa" name="close" /> Close
              </Button>
            </a>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={1} sm={12}>
              <Form.Group>
                <Form.Label>Store No.</Form.Label>
                {q_store_number ? (
                  <Form.Input
                    type="number"
                    placeholder="store number"
                    value={storeNumber}
                    onChange={(e) => setStoreNumber(e.target.value)}
                    invalid={!isAvailable && q_store_number === ""}
                    disabled={!["admin", "edit"].includes(accessLevel)}
                  />
                ) : (
                  <Form.Input
                    type="number"
                    placeholder="store number"
                    value={storeNumber}
                    onChange={(e) => setStoreNumber(e.target.value)}
                    invalid={!isAvailable && q_store_number === ""}
                  />
                )}
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} sm={12}>
              <Form.Group>
                <Form.Label>Store Name</Form.Label>
                <Form.Input
                  type="text"
                  placeholder="store name"
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                  disabled={
                    !["admin", "edit"].includes(accessLevel)
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} sm={12}>
              <Form.Group>
                <Form.Label>Store Manager</Form.Label>
                <Form.Input
                  type="text"
                  placeholder="store manager"
                  value={storeManager}
                  onChange={(e) => setStoreManager(e.target.value)}
                  disabled={accessLevel === "view"}
                  valid={accessLevel !== "view" && q_store_number === ""}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} sm={12}>
              <Form.Group>
                <Form.Label>Contact Number</Form.Label>
                <Form.Input
                  type="text"
                  placeholder="store contact number/s"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  disabled={accessLevel === "view"}
                  valid={accessLevel !== "view" && q_store_number === ""}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Store Email Address</Form.Label>
                <Form.Input
                  type="email"
                  placeholder="store email address"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  disabled={accessLevel === "view"}
                  valid={accessLevel !== "view" && q_store_number === ""}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col md={3} sm={12}>
              <Form.Group>
                <Form.Label>Account Executive</Form.Label>
                <Form.Select
                  value={accountExecutive}
                  onChange={(e) => setAccountExecutive(e.target.value)}
                  disabled={
                    !["admin", "edit"].includes(accessLevel)
                  }
                >
                  <option value="">Select One</option>
                  {accountExecutives
                    ? accountExecutives.map((options, index) => (
                        <option value={options.full_name} key={index}>
                          {options.full_name}
                        </option>
                      ))
                    : ""}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} sm={12}>
              <Form.Group>
                <Form.Label>Assigned Telemarketer</Form.Label>
                <Form.Select
                  value={telemarketer}
                  onChange={(e) => setTelemarketer(e.target.value)}
                  disabled={accessLevel === "view"}
                  valid={accessLevel !== "view" && q_store_number === ""}
                >
                  <option value="">Select One</option>
                  {telemarketers
                    ? telemarketers.map((options, index) => (
                        <option value={options.full_name} key={index}>
                          {options.full_name}
                        </option>
                      ))
                    : ""}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={4} sm={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Input
                  type="text"
                  placeholder="store complete address"
                  value={fullAddress}
                  onChange={(e) => setFullAddress(e.target.value)}
                  disabled={
                    !["admin", "edit"].includes(accessLevel)
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <Form.Select
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  disabled={
                    !["admin", "edit"].includes(accessLevel)
                  }
                >
                  <option value="">Select One</option>
                  {preferences.length
                    ? preferences
                        .filter(
                          (pref) => pref.type === "location" && pref.active == 1
                        )
                        .map((pref) => pref.value)
                        .sort()
                        .map((options, index) => (
                          <option value={options} key={index}>
                            {options}
                          </option>
                        ))
                    : ""}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Zone</Form.Label>
                <Form.Select
                  value={zone}
                  onChange={(e) => setZone(e.target.value)}
                  disabled={
                    !["admin", "edit"].includes(accessLevel)
                  }
                >
                  <option value="">Select One</option>
                  {preferences.length
                    ? preferences
                        .filter(
                          (pref) => pref.type === "zone" && pref.active == 1
                        )
                        .map((pref) => pref.value)
                        .sort()
                        .map((options, index) => (
                          <option value={options} key={index}>
                            {options}
                          </option>
                        ))
                    : ""}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>District</Form.Label>
                <Form.Select
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  disabled={
                    !["admin", "edit"].includes(accessLevel)
                  }
                >
                  <option value="">Select One</option>
                  {preferences.length
                    ? preferences
                        .filter(
                          (pref) => pref.type === "district" && pref.active == 1
                        )
                        .map((pref) => pref.value)
                        .sort()
                        .map((options, index) => (
                          <option value={options} key={index}>
                            {options}
                          </option>
                        ))
                    : ""}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Area</Form.Label>
                <Form.Select
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                  disabled={
                    !["admin", "edit"].includes(accessLevel)
                  }
                >
                  <option value="">Select One</option>
                  {preferences.length
                    ? preferences
                        .filter(
                          (pref) => pref.type === "area" && pref.active == 1
                        )
                        .map((pref) => pref.value)
                        .sort()
                        .map((options, index) => (
                          <option value={options} key={index}>
                            {options}
                          </option>
                        ))
                    : ""}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Cluster</Form.Label>
                <Form.Select
                  value={cluster}
                  onChange={(e) => setCluster(e.target.value)}
                  disabled={
                    !["admin", "edit"].includes(accessLevel)
                  }
                >
                  <option value="">Select One</option>
                  {preferences.length
                    ? preferences
                        .filter(
                          (pref) => pref.type === "cluster" && pref.active == 1
                        )
                        .map((pref) => pref.value)
                        .sort()
                        .map((options, index) => (
                          <option value={options} key={index}>
                            {options}
                          </option>
                        ))
                    : ""}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  disabled={accessLevel === "view"}
                  valid={accessLevel !== "view" && q_store_number === ""}
                >
                  <option value="">Select One</option>
                  {preferences.length
                    ? preferences
                        .filter(
                          (pref) =>
                            pref.type === "status" &&
                            pref.active == 1 &&
                            pref.store_type == 0
                        )
                        .map((pref) => pref.value)
                        .sort()
                        .map((options, index) => (
                          <option value={options} key={index}>
                            {options}
                          </option>
                        ))
                    : ""}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Courier Status</Form.Label>
                <Form.Select
                  value={courierStatus}
                  onChange={(e) => setCourierStatus(e.target.value)}
                  disabled={accessLevel === "view"}
                  valid={accessLevel !== "view" && q_store_number === ""}
                >
                  <option value="">Select One</option>
                  {preferences.length
                    ? preferences
                        .filter(
                          (pref) =>
                            pref.type === "courier_status" && pref.active == 1
                        )
                        .map((pref) => pref.value)
                        .sort()
                        .map((options, index) => (
                          <option value={options} key={index}>
                            {options}
                          </option>
                        ))
                    : ""}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Area Manager</Form.Label>
                <Form.Input
                  type="text"
                  placeholder="store area manager"
                  value={areaManager}
                  onChange={(e) => setAreaManger(e.target.value)}
                  disabled={
                    !["admin", "edit"].includes(accessLevel)
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Schedule</Form.Label>
                <Form.Select
                  value={schedule}
                  onChange={(e) => setSchedule(e.target.value)}
                  disabled={
                    !["admin", "edit"].includes(accessLevel)
                  }
                >
                  <option value="">Select One</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Date Open</Form.Label>
                <DatePicker
                  selected={dateOpen}
                  onChange={(date) => setDateOpen(date)}
                  disabled={
                    !["admin", "edit"].includes(accessLevel)
                  }
                  className="form-control"
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={1} sm={12}>
              <Form.Group>
                <Form.Label>Monthly Target</Form.Label>
                <Form.Input
                  type="number"
                  placeholder="store monthly target"
                  value={monthlyTarget}
                  onChange={(e) => setMonthlyTarget(e.target.value)}
                  disabled={accessLevel === "view"}
                  valid={accessLevel !== "view" && q_store_number === ""}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} sm={12}>
              <Form.Group>
                <Form.Label>Active</Form.Label>
                <Form.Select
                  value={active}
                  onChange={(e) => setActive(e.target.value)}
                  disabled={accessLevel === "view"}
                  valid={accessLevel !== "view" && q_store_number === ""}
                >
                  <option value="">Select One</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>

            <Grid.Col md={3} sm={12}>
              <Form.Group>
                <Form.Label>Network preference</Form.Label>
                {NETWORKS.map((net) => (
                  <Form.Checkbox
                    label={net}
                    value={net}
                    onChange={() =>
                      setNetworkPreference(
                        networkPreference.includes(net)
                          ? networkPreference.filter((n) => n !== net)
                          : [...networkPreference, net]
                      )
                    }
                    checked={networkPreference.includes(net)}
                    disabled={accessLevel === "view"}
                    className="network-selection"
                  />
                ))}
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          {!["admin", "edit"].includes(accessLevel) ? (
            ""
          ) : (
            <Grid.Row>
              {storeNumber !== "" &&
              storeName !== "" &&
              storeManager !== "" &&
              schedule !== "" &&
              location !== "" &&
              status !== "" &&
              areaManager !== "" &&
              fullAddress !== "" &&
              contactNumber !== "" &&
              emailAddress !== "" &&
              telemarketer !== "" &&
              accountExecutive !== "" &&
              courierStatus !== "" &&
              area !== "" &&
              district !== "" &&
              cluster !== "" &&
              zone !== "" &&
              monthlyTarget !== "" &&
              dateOpen !== "" &&
              active !== "" ? (
                <Grid.Col md={12} sm={12}>
                  {q_store_number ? (
                    <Button
                      color="cyan"
                      onClick={() =>
                        updateStore(
                          {
                            id: q_store_number,
                            storeNumber,
                            storeName,
                            storeManager,
                            schedule,
                            location,
                            status,
                            areaManager,
                            fullAddress,
                            contactNumber,
                            emailAddress,
                            telemarketer,
                            accountExecutive,
                            courierStatus,
                            area,
                            district,
                            cluster,
                            zone,
                            monthlyTarget,
                            dateOpen,
                            networkPreference:
                              JSON.stringify(networkPreference),
                            active,
                          },
                          () =>
                            setTimeout(function () {
                              window.location.replace(window.atob(q_backURL));
                            }, 1000)
                        )
                      }
                    >
                      <Icon prefix="fa" name="save" /> UPDATE
                    </Button>
                  ) : (
                    <Button
                      color="cyan"
                      onClick={() =>
                        addStore(
                          {
                            storeNumber,
                            storeName,
                            storeManager,
                            schedule,
                            location,
                            status,
                            areaManager,
                            fullAddress,
                            contactNumber,
                            emailAddress,
                            telemarketer,
                            accountExecutive,
                            courierStatus,
                            area,
                            district,
                            cluster,
                            zone,
                            monthlyTarget,
                            dateOpen,
                            networkPreference:
                              JSON.stringify(networkPreference),
                            active,
                          },
                          () =>
                            setTimeout(function () {
                              window.location.replace(
                                `/AMS/Account/711/Profile?id=${storeNumber}&backURL=${q_backURL}`
                              );
                            }, 1000)
                        )
                      }
                      disabled={!isAvailable}
                    >
                      <Icon prefix="fa" name="save" /> Save
                    </Button>
                  )}
                </Grid.Col>
              ) : (
                ""
              )}
            </Grid.Row>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default StoreAddForm;
