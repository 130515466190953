import React from "react";
import Progress from "../../charts/Progress";
import { Card, Form, Grid } from "tabler-react";
import useCustomFunctions from "../../../../../hooks/services/useCustomFunctions"

const Stats = (props) => {
  const {
    completed_call_count,
    pending_call_count,
    ordered_Call_count,
    ordered_Call_amount,
  } = props.data;

  const { formatNumberComma } = useCustomFunctions()

  return (
    <>
      <Grid.Row>
        <Grid.Col md={3} sm={12}>
          <Progress
            classname="progress"
            header="Order Success Rate"
            content={`${(
              (ordered_Call_count / completed_call_count) *
              100
            ).toFixed(2)} %`}
            color="#17a2b8"
            progressWidth={0}
          />
        </Grid.Col>
        <Grid.Col md={3} sm={12}>
          <Progress
            classname="progress"
            header="Total Orders Amount"
            content={`PHP ${formatNumberComma(ordered_Call_amount)}`}
            color="#17a2b8"
            progressWidth={0}
          />
        </Grid.Col>
        <Grid.Col md={2} sm={12}>
          <Progress
            classname="progress"
            header="Ordered Calls"
            content={`${ordered_Call_count}`}
            color="#17a2b8"
            progressWidth={0}
          />
        </Grid.Col>
        <Grid.Col md={2} sm={12}>
          <Progress
            classname="progress"
            header="Completed Calls"
            content={`${completed_call_count}`}
            color="#17a2b8"
            progressWidth={0}
          />
        </Grid.Col>
        <Grid.Col md={2} sm={12}>
          <Progress
            classname="progress"
            header="Pending Calls"
            content={`${pending_call_count}`}
            color="#17a2b8"
            progressWidth={0}
          />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default Stats;
