import React, { useEffect, useState } from "react";
import { Card, Loader, Table, Grid, Form, Button, Icon } from "tabler-react";
import Pagination from "../../../../components/Pagination";
import Summary from "./Summary";
import CopyBTN from "../../../../components/CopyBTN";
import Filter1 from "./Filter1";
import OrderDetails from "./OrderDetails";
import dateFormat from "dateformat";

//hooks
import useExtractReportShopee from "../../../../hooks/api/ShopeeShop/extractions/useExtractShopeeReport";
import useShopeeAPI from "../../../../hooks/api/ShopeeShop/useShopeeAPI";
import useQueryURL from "../../../../hooks/services/useQueryURL";
import useUsers from "../../../../hooks/api/Users/useUsers";
import useCustomFunctions from "../../../../hooks/services/useCustomFunctions";

const Orders = () => {
  const { getQuery } = useQueryURL();
  const { user } = useUsers();
  const { downloadOrders } = useExtractReportShopee();
  const { getDateDifference } = useCustomFunctions();

  let SI_Number = getQuery("SI_number");
  let DR_month = getQuery("month");
  let DR_number = getQuery("dr_number");
  let DR_year = getQuery("year");
  let page = getQuery("page");

  const [month, setMonth] = useState(DR_month !== "" ? DR_month : "");
  const [year, setYear] = useState(DR_year !== "" ? DR_year : "");

  const { orders, updateItemStatus, generateSummaryReport, assignedSINumber } =
    useShopeeAPI(
      DR_month !== "" ? DR_month : "",
      DR_number !== "" ? DR_number : "",
      DR_year !== "" ? DR_year : ""
    );

  // const download = useExtractReport();
  const [accessLevel, setAccessLevel] = useState("none");
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(page ? page : 1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  const [newOrders, setNewOrders] = useState([]);

  //update status
  const [remarks, setRemarks] = useState("");
  const [status, setStatus] = useState("");

  const [assignedSI, setAssignedSI] = useState(false);
  const [SINumber, setSINumber] = useState(SI_Number ? SI_Number : "--");
  const [SIDate, setSIDate] = useState(new Date());
  const [selectedItemNumber, setSelectedItemNumber] = useState("");

  //summary
  const [summaryReport, setSummaryReport] = useState([]);
  const [viewReport, setViewReport] = useState(false);
  const [readyStatus, setReadyStatus] = useState([]);

  //filters
  const [filter, setFilter] = useState(
    getQuery("month") ? getQuery("month") : "January"
  ); // Month
  const [filter1, setFilter1] = useState(
    getQuery("year") ? getQuery("year") : "2021"
  ); // Year
  const [filter2, setFilter2] = useState(
    getQuery("dr_number") ? getQuery("dr_number") : ""
  ); // DR Number
  const [filter3, setFilter3] = useState(
    getQuery("order_id") ? getQuery("order_id") : ""
  ); // Order Number
  const [filter4, setFilter4] = useState(""); // Remarks
  const [filter5, setFilter5] = useState(""); // Supplier
  const [filter6, setFilter6] = useState(
    getQuery("shop_status") ? getQuery("shop_status") : ""
  ); // Shop Status
  const [filter7, setFilter7] = useState(
    getQuery("gate_status") ? getQuery("gate_status") : ""
  ); // Inventory Status
  const [filter8, setFilter8] = useState(
    getQuery("product_name") ? getQuery("product_name") : ""
  ); // Inventory Status

  const changePage = (p) => {
    window.location.replace(
      `/ShopeeShop/Orders?dr_number=${filter2}&SI_number=${SINumber}&month=${month}&order_id=${filter3}&year=${year}&page=${p}`
    );
  };

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "shopee-shop").access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  useEffect(() => {
    let data = {
      shopeeTotal: orders.reduce(
        (a, b) =>
          a +
          b.release_amount +
          Math.abs(
            parseFloat(b.discount_rebate) +
              parseFloat(b.seller_promotion) +
              parseFloat(b.seller_voucher) +
              parseFloat(b.coin_cashback) +
              parseInt(b.commission_fee) +
              parseInt(b.transaction_fee) -
              parseInt(b.shipping_sub_total)
          ),
        0
      ),
      gateTotal: orders.reduce((a, b) => a + b.original_price, 0),
    };
    setReadyStatus({
      shopee: data.shopeeTotal,
      gate_gross: data.gateTotal,
      SI_Number: orders
        ? orders
            .map((o) => o.SI_number)
            .filter((v, i) => orders.map((o) => o.SI_number).indexOf(v) == i)[0]
        : SI_Number,
      status: data.shopeeTotal == data.gateTotal ? "Ready" : "Not Ready",
    });
  }, [orders]);

  useEffect(() => {
    setLoading(true);
    if (orders.length !== 0) {
      // setActivePage(1);
      setNewOrders(
        orders
          .filter((o) =>
            filter2 !== "" ? o.dr_number.replace("*", "").includes(filter2) : o
          )
          .filter((o) =>
            filter3 ? o.order_id.replace("*", "").includes(filter3) : o
          )
          .filter((o) => (filter4 !== "" ? o.remarks === filter4 : o))
          .filter((o) => (filter5 !== "" ? o.supplier === filter5 : o))
          .filter((o) =>
            filter6 !== ""
              ? o.delivery_status === filter6 || o.payment_waiting === filter6
              : o
          )
          .filter((o) => (filter7 !== "" ? o.gate_status === filter7 : o))
          .filter((o) =>
            filter8 !== ""
              ? o.details
                  .map((d) => d.product_name.toLowerCase())
                  .includes(filter8.toLowerCase())
              : o
          )
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [
    orders,
    filter,
    filter1,
    filter2,
    filter3,
    filter4,
    filter5,
    filter6,
    filter7,
    filter8,
  ]);

  useEffect(() => {
    if (filter2 && newOrders) {
      setSummaryReport(generateSummaryReport(`*${filter2}`));
    }
  }, [filter2, newOrders]);

  const [selectedOrderID, setSelectedOrderID] = useState("");

  return (
    <>
      {selectedOrderID !== "" && newOrders.length != 0 ? (
        <OrderDetails
          data={{ selectedOrderID, setSelectedOrderID, newOrders, accessLevel }}
        />
      ) : (
        ""
      )}

      {viewReport ? (
        <Summary
          setViewReport={setViewReport}
          summaryReport={summaryReport}
          dr_number={DR_number}
          dr_month={DR_month}
          dr_year={DR_year}
          user={user}
        />
      ) : (
        ""
      )}

      <Card>
        <Card.Header>
          Orders
          <Card.Options>
            {readyStatus.length != 0 ? (
              readyStatus.SI_Number === "" || readyStatus.SI_Number == "--" ? (
                <span
                  style={{
                    color:
                      readyStatus.shopee == readyStatus.gate_gross
                        ? "#17a2b8"
                        : "#cd201f",
                    padding: "8px",
                    borderRadius: "5px",
                    textTransform: "uppercase",
                    margin: "5px",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  {readyStatus.length != 0
                    ? readyStatus.shopee == readyStatus.gate_gross
                      ? "Ready"
                      : "Not Ready"
                    : ""}
                </span>
              ) : (
                <span
                  style={{
                    color: "#17a2b8",
                    padding: "8px",
                    borderRadius: "5px",
                    textTransform: "uppercase",
                    margin: "5px",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Completed
                </span>
              )
            ) : (
              ""
            )}
          </Card.Options>
        </Card.Header>
        <Filter1
          data={{
            src: "ShopeeShop",
            accessLevel,
            orders,
            readyStatus,
            setNewOrders,
            setLoading,
            loading,
            downloadOrders,
            newOrders,
            assignedSI,
            SI_Number: readyStatus ? readyStatus.SI_Number : "--",
            SINumber,
            setSINumber,
            SIDate,
            setSIDate,
            setAssignedSI,
            viewReport,
            setViewReport,
            assignedSINumber,
            filter,
            setFilter,
            filter1,
            setFilter1,
            filter2,
            setFilter2,
            filter3,
            setFilter3,
            filter4,
            setFilter4,
            filter5,
            setFilter5,
            filter6,
            setFilter6,
            filter7,
            setFilter7,
            filter8,
            setFilter8,
          }}
        />
        <Card.Body>
          {loading ? (
            <>
              {DR_month !== "" ? (
                <Loader />
              ) : (
                "Please Select Month and DR or Order ID to search"
              )}
            </>
          ) : (
            <>
              <Table
                responsive
                highlightRowOnHover
                hasOutline
                verticalAlign="center"
                cards
                className="text-nowrap"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>No.</Table.ColHeader>
                    <Table.ColHeader>DR Number</Table.ColHeader>
                    {readyStatus.SI_Number === "" ||
                    readyStatus.SI_Number === "--" ? (
                      ""
                    ) : (
                      <Table.ColHeader>SI Number</Table.ColHeader>
                    )}
                    <Table.ColHeader>Order ID</Table.ColHeader>
                    <Table.ColHeader>TF</Table.ColHeader>
                    <Table.ColHeader>MCF</Table.ColHeader>
                    <Table.ColHeader>Shipping Sub.</Table.ColHeader>
                    <Table.ColHeader>Payment Release</Table.ColHeader>
                    <Table.ColHeader>Gate Comp.</Table.ColHeader>
                    {readyStatus.status === "Ready" ? (
                      ""
                    ) : (
                      <Table.ColHeader>Payment</Table.ColHeader>
                    )}
                    <Table.ColHeader>Shopee Status</Table.ColHeader>
                    <Table.ColHeader>Inv. Status</Table.ColHeader>
                    <Table.ColHeader>Remarks</Table.ColHeader>
                    <Table.ColHeader>Actions</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {newOrders
                    .sort(function (a, b) {
                      return a.id - b.id;
                    })
                    .slice(
                      activePage == 1 ? 0 : (activePage - 1) * 30,
                      activePage * 30
                    )
                    .map((order, index) => (
                      <Table.Row>
                        <Table.Col>
                          <b>
                            {activePage == 1
                              ? index + 1
                              : index + 1 + (activePage - 1) * 30}
                            .
                          </b>
                        </Table.Col>
                        <Table.Col>
                          {order.dr_number.replace("*", "")}{" "}
                          <CopyBTN value={order.dr_number.replace("*", "")} />
                        </Table.Col>
                        {readyStatus.SI_Number === "" ||
                        readyStatus.SI_Number === "--" ? (
                          ""
                        ) : (
                          <Table.Col>
                            {order.SI_number.replace("*", "")}{" "}
                            <CopyBTN value={order.SI_number.replace("*", "")} />
                          </Table.Col>
                        )}
                        <Table.Col>
                          <p
                            onClick={() =>
                              setSelectedOrderID(
                                order.order_id.replace("*", "")
                              )
                            }
                            style={{
                              fontWeight: "bold",
                              display: "inline-block",
                            }}
                          >
                            {order.order_id.replace("*", "")}{" "}
                          </p>{" "}
                          <CopyBTN value={order.order_id.replace("*", "")} />
                        </Table.Col>
                        <Table.Col>{order.transaction_fee}</Table.Col>
                        <Table.Col>{order.commission_fee}</Table.Col>
                        <Table.Col>{order.shipping_sub_total}</Table.Col>
                        <Table.Col>{order.release_amount}</Table.Col>
                        <Table.Col>
                          <span
                            style={{
                              color: order.checkingBalance.gateBalance
                                ? order.checkingBalance.gateTotal !== 0
                                  ? "#17a2b8"
                                  : "#B7193D"
                                : "#2bcbba",
                              padding: "8px",
                              borderRadius: "5px",
                              textTransform: "uppercase",
                              margin: "5px",
                              fontWeight: "bold",
                            }}
                          >
                            {order.checkingBalance.gateTotal}
                            {order.checkingBalance.gateTotal !=
                            order.release_amount
                              ? `(${
                                  order.release_amount -
                                  order.checkingBalance.gateTotal
                                })`
                              : ""}
                          </span>
                        </Table.Col>
                        {readyStatus.status === "Ready" ? (
                          ""
                        ) : (
                          <Table.Col>
                            <>
                              {order.payment_waiting === "--" ? (
                                ""
                              ) : (
                                <span
                                  style={{
                                    color:
                                      order.payment_waiting ===
                                      "Request for Payment"
                                        ? "#cd201f"
                                        : "#2bcbba",
                                    padding: "8px",
                                    borderRadius: "5px",
                                    textTransform: "uppercase",
                                    margin: "5px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {order.delivery_status === "Delivered"
                                    ? ""
                                    : (order.order_status === "Returned" ||
                                        order.order_status === "Cancelled") &&
                                      (order.remarks === "Inventory" ||
                                        order.remarks === "Lost Inventory")
                                    ? ""
                                    : order.payment_waiting}
                                </span>
                              )}
                            </>
                          </Table.Col>
                        )}
                        <Table.Col>
                          {order.delivery_status === "" ||
                          order.delivery_status === "--" ? (
                            <span
                              style={{
                                color: "#cd201f",
                                padding: "8px",
                                borderRadius: "5px",
                                textTransform: "uppercase",
                                margin: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              UNPAID
                            </span>
                          ) : (
                            <span
                              style={{
                                color:
                                  order.delivery_status === "Delivered"
                                    ? "#17a2b8"
                                    : order.delivery_status === "Returned" ||
                                      order.delivery_status === "Cancelled"
                                    ? "#B7193D"
                                    : "#2bcbba",
                                padding: "8px",
                                borderRadius: "5px",
                                textTransform: "uppercase",
                                margin: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              {order.delivery_status}
                            </span>
                          )}
                        </Table.Col>
                        <Table.Col>
                          {selectedItemNumber === order.order_id &&
                          order.delivery_status !== "Delivered" ? (
                            <>
                              <Form.Select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="--">Choose one</option>
                                <option value="Refunded">Refunded</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Returned">Returned</option>
                              </Form.Select>
                            </>
                          ) : (
                            <span
                              style={{
                                color: "#B7193D",
                                padding: "8px",
                                borderRadius: "5px",
                                textTransform: "uppercase",
                                margin: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              {order.order_status.replace("--", "")}
                            </span>
                          )}
                        </Table.Col>
                        <Table.Col>
                          {selectedItemNumber === order.order_id ? (
                            <>
                              <Form.Select
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                              >
                                <option value="--">Choose one</option>

                                {[
                                  "Operation Assistant",
                                  "Telemarketer OA",
                                  "Telemarketer OA - Online",
                                  "Administrator",
                                  "IT Specialist",
                                ].includes(user.role) ? (
                                  <>
                                    <option value="Inventory">Inventory</option>
                                    <option value="Waiting">Waiting</option>
                                    <option value="Endorsed to MKTG">
                                      Endorsed to MKTG
                                    </option>
                                    <option value="Lost Inventory">
                                      Lost Inventory
                                    </option>
                                    <option value="Charge To Employee">
                                      Charge To Employee
                                    </option>
                                  </>
                                ) : (
                                  ""
                                )}

                                {[
                                  "Marketing Assistant",
                                  "Administrator",
                                  "IT Specialist",
                                  "Account Executive"
                                ].includes(user.role) ? (
                                  <>
                                    <option value="In Transit">
                                      In Transit
                                    </option>
                                    <option value="Difference">
                                      Difference
                                    </option>
                                    <option value="Paid">Paid</option>
                                    <option value="Payment Waiting">
                                      Payment Waiting
                                    </option>
                                    <option value="DIS - charge to Telco">
                                      DIS - charge to Telco
                                    </option>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Form.Select>
                            </>
                          ) : (
                            <span
                              style={{
                                color: "#17a2b8",
                                padding: "8px",
                                borderRadius: "5px",
                                textTransform: "uppercase",
                                margin: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              {order.remarks.replace("--", "")}
                            </span>
                          )}
                        </Table.Col>
                        <Table.Col>
                          {order.delivery_status !== "Delivered" ||
                          !order.checkingBalance.gateBalance ? (
                            <>
                              {selectedItemNumber === order.order_id ? (
                                <>
                                  <Button
                                    icon="save"
                                    color="azure"
                                    onClick={() =>
                                      updateItemStatus(
                                        status,
                                        remarks,
                                        order.order_id,
                                        () => setFilter4("")
                                      ) ||
                                      setSelectedItemNumber("") ||
                                      setRemarks("") ||
                                      setStatus("")
                                    }
                                  ></Button>
                                  <Button
                                    color="danger"
                                    onClick={() =>
                                      setSelectedItemNumber("") ||
                                      setRemarks("") ||
                                      setStatus("")
                                    }
                                    disabled={
                                      accessLevel === "view" ||
                                      accessLevel === "report"
                                    }
                                  >
                                    <Icon prefix="fa" name="close" />
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {accessLevel === "admin" ? (
                                    <Button
                                      icon="edit"
                                      color="azure"
                                      onClick={() =>
                                        setSelectedItemNumber(order.order_id) ||
                                        setRemarks(order.remarks) ||
                                        setStatus(order.order_status)
                                      }
                                      disabled={
                                        accessLevel === "view" ||
                                        accessLevel === "report"
                                      }
                                    ></Button>
                                  ) : (
                                    <Button
                                      icon="edit"
                                      color="azure"
                                      onClick={() =>
                                        setSelectedItemNumber(order.order_id) ||
                                        setRemarks(order.remarks) ||
                                        setStatus(order.order_status)
                                      }
                                      disabled={
                                        accessLevel === "view" ||
                                        accessLevel === "report" ||
                                        readyStatus.SI_Number !== "--"
                                      }
                                    ></Button>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </Table.Col>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </>
          )}
          {newOrders.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          ) : (
            ""
          )}
        </Card.Body>

        {newOrders.length <= 30 ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={30}
            changePage={changePage}
            totalItemsCount={newOrders.length}
            pageRangeDisplayed={pageRangeDisplayed}
            setActivePage={changePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default Orders;
