import React, { useEffect, useState } from "react";
import { Button, Card, Grid, Table, Icon } from "tabler-react";
import UploadForm from "./components/uploadForm";
import dateFormat from "dateformat";

import useDirectoryAPI from "../../../hooks/api/GVD/useDirectoryAPI";
import useExtractOnlineStore from "../../../hooks/api/GBT/extractions/useExtractOnlineStore";
import useGateStoreAPI from "../../../hooks/api/GATESTORE/useGateStoreAPI";

const Index = () => {
  const { directory } = useDirectoryAPI();
  const { uploadOnlineOrders, pendingOrders, getStorePendingOrders } =
    useGateStoreAPI();

  const [flash, setFlash] = useState(new Date());
  const [extractedReport, setExtractedReport] = useState([]);
  const [fileName, setFileName] = useState("GATE ONLINE STORE ORDER");
  const [customLabel, setCustomLabel] = useState(new Date());
  const [orderIDs, setOrderIDs] = useState([]);

  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState([]);

  const [itemCode, setItemCode] = useState([]);
  const [orderSummary, setOrderSummary] = useState([]);

  const { downloadOrders, downloadSummary } = useExtractOnlineStore();

  console.log(pendingOrders);

  useEffect(() => {
    getStorePendingOrders();
  }, []);

  useEffect(() => {
    if (extractedReport.length !== 0) {
      setOrders(
        extractedReport.slice(1, extractedReport.length).map((order) => ({
          order_date: dateFormat(order[2], "mmmm dd, yyyy"),
          pick_up_date: dateFormat(flash, "mmmm dd, yyyy"),
          order_number: `*${order[0]}`,
          customer_name: `${order[14]} ${order[15]}`,
          address: `${order[17]}, ${order[22]}, ${order[18]} ${order[52]}`,
          postal_code: `${order[18]}`,
          phone_1: `${order[21]}`,
          phone_2: `${order[13]}`,
          delivery_option: "Flash Express",
          cod_amount:
            order[23] === "Flash Express - Cash on Delivery (COD)"
              ? order[29]
              : 0,
          type: "Others",
          weight: order[31],
          length: order[34],
          width: order[32],
          height: order[33],
          item_code: `*${order[35].split("_")[0]}`,
          sku: order[35],
          variant_name: directory.find((d) => d.item_sku === order[35])
            .variant_name,
          gate_price: directory.find((d) => d.item_sku === order[35])
            .selling_price,
          acquisition: directory.find((d) => d.item_sku === order[35])
            .aquisition,
          quantity: order[38],
          total_order_amount: order[29],
          payment_method: order[23],
        }))
      );
    }
  }, [extractedReport, flash]);

  useEffect(() => {
    setOrderIDs(
      orders
        .map((o) => o.order_number)
        .filter((v, i) => orders.map((o) => o.order_number).indexOf(v) == i)
    );

    setItemCode(
      orders
        .map((o) => o.sku)
        .filter((v, i) => orders.map((o) => o.sku).indexOf(v) == i)
    );
  }, [orders]);

  useEffect(() => {
    setOrderSummary(
      itemCode.map((item) => ({
        variant_name: directory.find((d) => d.item_sku === item).variant_name,
        item_code: `*${item.split("_")[0]}`,
        quantity: orders
          .filter((order) => order.sku === item)
          .reduce((a, b) => a + parseFloat(b.quantity), 0),
      }))
    );
  }, [itemCode]);

  useEffect(() => {
    setTotalOrders(
      orderIDs.map((order) => ({
        order_number: order,
        customer_name: orders.filter((o) => o.order_number === order)[0]
          .customer_name,
        address: orders.filter((o) => o.order_number === order)[0].address,
        postal_code: orders.filter((o) => o.order_number === order)[0]
          .postal_code,
        phone_1: orders.filter((o) => o.order_number === order)[0].phone_1
          ? orders.filter((o) => o.order_number === order)[0].phone_1
          : orders.filter((o) => o.order_number === order)[0].phone_2,
        phone_2: orders.filter((o) => o.order_number === order)[0].phone_2,
        cod_amount: orders.filter((o) => o.order_number === order)[0]
          .cod_amount,
        type: orders.filter((o) => o.order_number === order)[0].type,
        weight: orders
          .filter((o) => o.order_number === order)
          .reduce((a, b) => a + parseFloat(b.weight), 0)
          .toFixed(2),
        length: orders
          .filter((o) => o.order_number === order)
          .reduce((a, b) => a + parseFloat(b.length), 0)
          .toFixed(2),
        width: orders
          .filter((o) => o.order_number === order)
          .reduce((a, b) => a + parseFloat(b.width), 0)
          .toFixed(2),
        height: orders
          .filter((o) => o.order_number === order)
          .reduce((a, b) => a + parseFloat(b.height), 0)
          .toFixed(2),
      }))
    );
  }, [orderIDs]);

  return (
    <>
      <Grid.Col md={12}>
        {pendingOrders.length === 0 ? (
          <UploadForm
            onUpload={setExtractedReport}
            label="Extracted File as of Today"
            type="upload"
            value={extractedReport}
            fileName={fileName}
            customLabel={customLabel}
            setFileName={setFileName}
            setCustomLabel={setCustomLabel}
            shippingOptions={["flash"]}
            flash={flash}
            setFlash={setFlash}
          />
        ) : (
          ""
        )}
      </Grid.Col>
      <Grid.Col md={12}>
        <Card>
          <Card.Header>
            Order list
            {pendingOrders.length === 0 ? (
              <Card.Options>
                <Button
                  color="yellow"
                  onClick={() => downloadOrders(totalOrders, fileName)}
                >
                  <Icon prefix="fa" name="download" /> Flash Express File
                </Button>

                <Button
                  color="teal"
                  onClick={() => downloadSummary(orderSummary, fileName)}
                >
                  <Icon prefix="fa" name="download" /> Summary
                </Button>

                <Button color="cyan" onClick={() => uploadOnlineOrders(orders)}>
                  <Icon prefix="fa" name="upload" /> Upload Orders
                </Button>
              </Card.Options>
            ) : (
              <Card.Options>
                <p style={{ color: "red" }}>
                  You have {pendingOrders.length} pending order/s waiting for
                  approval before you can process again!
                </p>
              </Card.Options>
            )}
          </Card.Header>
          <Card.Body>
            <div className="table-result-container">
              <Table
                id="table-to-xls"
                responsive
                highlightRowOnHover
                hasOutline
                verticalAlign="center"
                cards
                className="text-nowrap"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Order Number</Table.ColHeader>
                    <Table.ColHeader>Consignee Name </Table.ColHeader>
                    <Table.ColHeader>Address</Table.ColHeader>
                    <Table.ColHeader>Postal Code</Table.ColHeader>
                    <Table.ColHeader>Phone_number 1</Table.ColHeader>
                    <Table.ColHeader>Phone_number 2</Table.ColHeader>
                    <Table.ColHeader>COD (Amount)</Table.ColHeader>
                    <Table.ColHeader>Item Type</Table.ColHeader>
                    <Table.ColHeader>Weight (kg)</Table.ColHeader>
                    <Table.ColHeader>Length</Table.ColHeader>
                    <Table.ColHeader>Width</Table.ColHeader>
                    <Table.ColHeader>Height</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {totalOrders.map((order) => (
                    <Table.Row>
                      <Table.Col>{order.order_number}</Table.Col>
                      <Table.Col>{order.customer_name}</Table.Col>
                      <Table.Col>{order.address}</Table.Col>
                      <Table.Col>{order.postal_code}</Table.Col>
                      <Table.Col>{order.phone_1}</Table.Col>
                      <Table.Col>{order.phone_2}</Table.Col>
                      <Table.Col>{order.cod_amount}</Table.Col>
                      <Table.Col>{order.type}</Table.Col>
                      <Table.Col>{order.weight}</Table.Col>
                      <Table.Col>{order.length}</Table.Col>
                      <Table.Col>{order.width}</Table.Col>
                      <Table.Col>{order.height}</Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Grid.Col>
    </>
  );
};

export default Index;
