import React from "react";
import { Grid, AccountDropdown, Button, Card } from "tabler-react";
import Tooltip from "react-simple-tooltip";

const MenuHeader = (props) => {
  const { data } = props;
  const { user } = data;
  return (
    <div>
      <Card>
        <Grid.Row>
          <Grid.Col md={1} sm={12}></Grid.Col>
          <Grid.Col md={3} sm={12}>
            <div
              style={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}
            >
              <AccountDropdown
                avatarURL="https://th.bing.com/th/id/R.db989291b2539b817e46ad20d4947c36?rik=5AQ%2b6OG1VA05yg&riu=http%3a%2f%2fgetdrawings.com%2ffree-icon%2fcool-profile-icons-70.png&ehk=qe8q701EM70pD%2b3qlduqUPsiVZbx8Uqjo%2fE5hU%2f9G%2fc%3d&risl=&pid=ImgRaw&r=0"
                name={user.full_name}
                description={`${user.role} ▼`}
                options={[
                  "profile",
                  // { icon: "bell", value: "Notification", to: "/notification" },
                  // "message",
                  // {
                  //   icon: "settings",
                  //   value: "Settings",
                  //   to: "/settings",
                  // },
                  { icon: "log-out", value: "Log Out", to: "/Logout" },
                ]}
              />
            </div>
          </Grid.Col>
          <Grid.Col md={6} sm={12}>
            <Tooltip
              content="Home"
              radius={5}
              padding={5}
              style={{ whiteSpace: "nowrap" }}
              zIndex={100}
              placement="bottom"
            >
              <Button
                color="secondary"
                icon="home"
                onClick={() => window.location.replace("/")}
                className="header-menu-btn"
              ></Button>
            </Tooltip>
          </Grid.Col>
          <Grid.Col md={2} sm={12}></Grid.Col>
        </Grid.Row>
      </Card>
    </div>
  );
};

export default MenuHeader;
