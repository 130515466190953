import dateFormat from "dateformat";
import useConvertToCSV from "../../../services/useConvertToCSV";

function useExtractSerials() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadOrders(order, fileName) {
    var headers = {
      order_id: "ORDER ID",
      tracking_number: "TRACKING NUMBER",
      variant_name: "GATE VARIANT NAME",
      quantity: "QUANTITY",
      serials: "SERIALS",
    };

    var itemsNotFormatted = order;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        order_id: item.order_id,
        tracking_number: item.tracking_number,
        variant_name: item.variant_name,
        quantity: item.quantity,
        serials: item.serials.replace(/,/g, ' | '),
      });
    });

    // var fileTitle = fileName; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileName); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  function downloadSummary(summary, fileName) {
    var headers = {
      variant_name: "GATE VARIANT NAME",
      item_code: "ITEM CODE",
      quantity: "QUANTITY",
    };

    var itemsNotFormatted = summary;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        variant_name: item.variant_name,
        item_code: item.item_code,
        quantity: item.totalSerials,
      });
    });

    var fileTitle = `${fileName}_${dateFormat(new Date())}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return {
    downloadOrders,
    downloadSummary,
  };
}

export default useExtractSerials;
