import React from "react";
import { Loader, Table } from "tabler-react";

const TagList = (props) => {
  const { list } = props.data;

  return (
    <>
      {list.length == 0 ? (
        <Loader />
      ) : (
        <Table
          responsive
          highlightRowOnHover
          hasOutline
          verticalAlign="center"
          cards
          className="text-wrap"
        >
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>Shop Status</Table.ColHeader>
              <Table.ColHeader>Inventory/ Gate Status to use</Table.ColHeader>
              <Table.ColHeader>Remarks to use</Table.ColHeader>
              <Table.ColHeader>
                Example Status & Remarks Combination
              </Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {list.map((l, index) => (
              <Table.Row>
                <Table.Col>
                  <span
                    style={{
                      color: l.color,
                      padding: "8px",
                      borderRadius: "5px",
                      textTransform: "uppercase",
                      margin: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    {l.shop_status}
                  </span>
                </Table.Col>
                <Table.Col>
                  <ul>
                    {l.gate_status.map((s) => (
                      <li>{s}</li>
                    ))}
                  </ul>
                </Table.Col>
                <Table.Col>
                  <ul>
                    {l.remarks.map((s) => (
                      <li>{s}</li>
                    ))}
                  </ul>
                </Table.Col>
                <Table.Col>
                  <ul>
                    {l.example.map((s) => (
                      <li>{s}</li>
                    ))}
                  </ul>
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

export default TagList;
