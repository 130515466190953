import { useState, useEffect } from "react";
import dateFormat from "dateformat";
import useDirectoryAPI from "../GVD/useDirectoryAPI";

function useLazadaTool(data, orderIDs, customLabel) {
  const { directory } = useDirectoryAPI();

  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [orderSummary, setOrderSummary] = useState([]);

  function generateOrders(data) {
    let excludeIDs = orderIDs.split("\n");

    let list = [];
    let orderIds = [];

    //bundle fix temporary
    const generateBundle = (orderData, itemcodes) => {
      let itemCodes = itemcodes
        .split(",")
        .filter((v, i) => itemcodes.split(",").indexOf(v) == i);
      let items = [];

      let count = itemcodes.split(",");

      for (let index = 0; index < itemCodes.length; index++) {
        let gvdItem = directory.filter((v) => v.item_code === itemCodes[index]);

        if (gvdItem.length !== 0) {
          items.push({
            order_id: `*${orderData[12]}`,
            order_item_id: `*${orderData[4]}`,
            order_date: dateFormat(orderData[8], "longDate"),
            shipout_date: dateFormat(customLabel, "longDate"),
            tracking_number: `*${orderData[58]}`,
            shipping_option: orderData[54],
            product_name: `${orderData[51]} - ${gvdItem[0].variant_name}`,
            gate_price: orderData[47],
            laz_price: orderData[46],
            voucher_discount: orderData[48].replace("-", ""),
            buyer_paid_shipping_fee: orderData[49],
            quantity: count.filter((c) => c === itemCodes[index]).length,
            product_sku: orderData[6] === "" ? orderData[5] : orderData[6],
            variant_name: gvdItem[0].variant_name,
            item_code: itemCodes[index],
            aquisition: gvdItem[0].aquisition,
            gross_price: gvdItem[0].gross_price,
          });
        }
      }

      return items;
    };

    for (let index = 1; index < data.length; index++) {
      if (data[index][58] !== "") {
        if (!excludeIDs.find((id) => id.replace("*", "") === data[index][12])) {
          if (data[index][51] === "SMART BTS Special Edition Prepaid Kit") {
            orderIds.push(data[index][12]);
            list.push(
              {
                order_id: `*${data[index][12]}`,
                order_item_id: `*${data[index][4]}`,
                order_date: dateFormat(data[index][8], "longDate"),
                shipout_date: dateFormat(customLabel, "longDate"),
                tracking_number: `*${data[index][58]}`,
                shipping_option: data[index][54],
                product_name:
                  "SMART BTS Special Edition Prepaid Kit - 	Smart Prepaid CallCard 100",
                gate_price: "100",
                laz_price: "100",
                quantity: 1,
                buyer_paid_shipping_fee: data[index][49],
                variant_name: "OA SMART BUDDY GSM 100",
                item_code: "116010103",
                aquisition: "0.08",
                gross_price: "100",
                voucher_discount: "0",
              },
              {
                order_id: `*${data[index][12]}`,
                order_item_id: `*${data[index][4]}`,
                order_date: dateFormat(data[index][8], "longDate"),
                shipout_date: dateFormat(customLabel, "longDate"),
                tracking_number: `*${data[index][58]}`,
                shipping_option: data[index][54],
                product_name:
                  "SMART BTS Special Edition Prepaid Kit - 	Smart Prepaid CallCard 100",
                gate_price: "100",
                laz_price: "100",
                quantity: 1,
                buyer_paid_shipping_fee: data[index][49],
                variant_name: "OA SMART BUDDY GSM 100",
                item_code: "116010103",
                aquisition: "0.08",
                gross_price: "100",
                voucher_discount: "0",
              },
              {
                order_id: `*${data[index][12]}`,
                order_item_id: `*${data[index][4]}`,
                order_date: dateFormat(data[index][8], "longDate"),
                shipout_date: dateFormat(customLabel, "longDate"),
                tracking_number: `*${data[index][58]}`,
                shipping_option: data[index][54],
                product_name:
                  "SMART BTS Special Edition Prepaid Kit - 	Smart Prepaid CallCard 100",
                gate_price: "100",
                laz_price: "100",
                quantity: 1,
                buyer_paid_shipping_fee: data[index][49],
                variant_name: "OA SMART BUDDY GSM 100",
                item_code: "116010103",
                aquisition: "0.08",
                gross_price: "100",
                voucher_discount: "0",
              },
              {
                order_id: `*${data[index][12]}`,
                order_item_id: `*${data[index][4]}`,
                order_date: dateFormat(data[index][8], "longDate"),
                shipout_date: dateFormat(customLabel, "longDate"),
                tracking_number: `*${data[index][58]}`,
                shipping_option: data[index][54],
                product_name:
                  "SMART BTS Special Edition Prepaid Kit - 	Smart Prepaid CallCard 100",
                gate_price: "100",
                laz_price: "100",
                quantity: 1,
                buyer_paid_shipping_fee: data[index][49],
                variant_name: "OA SMART BUDDY GSM 100",
                item_code: "116010103",
                aquisition: "0.08",
                gross_price: "100",
                voucher_discount: "0",
              },
              {
                order_id: `*${data[index][12]}`,
                order_item_id: `*${data[index][4]}`,
                order_date: dateFormat(data[index][8], "longDate"),
                shipout_date: dateFormat(customLabel, "longDate"),
                tracking_number: `*${data[index][58]}`,
                shipping_option: data[index][54],
                product_name:
                  "SMART BTS Special Edition Prepaid Kit - 	Smart 5G Tri-Cut Sim",
                gate_price: "40",
                laz_price: "40",
                quantity: 1,
                buyer_paid_shipping_fee: data[index][49],
                variant_name: "OA SMART REGULAR SIM",
                item_code: "117010101",
                aquisition: "0.08",
                gross_price: "40",
                voucher_discount: "0",
              }
            );
          } else {
            orderIds.push(data[index][12]);

            let bundle = directory.find(
              (v) =>
                `${v.shop_name} - ${parseInt(v.laz_price)}` ===
                `${data[index][51]}${data[index][52]} - ${parseInt(
                  data[index][47]
                )}`
            )
              ? directory.find(
                  (v) =>
                    `${v.shop_name} - ${parseInt(v.laz_price)}` ===
                    `${data[index][51]}${data[index][52]} - ${parseInt(
                      data[index][47]
                    )}`
                ).item_code
              : "";
            if (bundle.split(",").length >= 2) {
              list.push(...generateBundle(data[index], bundle));
            } else {
              list.push({
                order_id: `*${data[index][12]}`,
                order_item_id: `*${data[index][4]}`,
                order_date: dateFormat(data[index][8], "longDate"),
                shipout_date: dateFormat(customLabel, "longDate"),
                tracking_number: `*${data[index][58]}`,
                shipping_option: data[index][54],
                product_name: data[index][51],
                gate_price: data[index][47],
                laz_price: data[index][46],
                voucher_discount: data[index][48].replace("-", ""),
                quantity: 1,
                buyer_paid_shipping_fee: data[index][49],
                variant_name: directory.find(
                  (v) =>
                    `${v.shop_name} - ${parseInt(v.laz_price)}` ===
                    `${data[index][51]}${data[index][52]} - ${parseInt(
                      data[index][47]
                    )}`
                )
                  ? directory.find(
                      (v) =>
                        `${v.shop_name} - ${parseInt(v.laz_price)}` ===
                        `${data[index][51]}${data[index][52]} - ${parseInt(
                          data[index][47]
                        )}`
                    ).variant_name
                  : "No Variant Name",
                item_code: directory.find(
                  (v) =>
                    `${v.shop_name} - ${parseInt(v.laz_price)}` ===
                    `${data[index][51]}${data[index][52]} - ${parseInt(
                      data[index][47]
                    )}`
                )
                  ? directory.find(
                      (v) =>
                        `${v.shop_name} - ${parseInt(v.laz_price)}` ===
                        `${data[index][51]}${data[index][52]} - ${parseInt(
                          data[index][47]
                        )}`
                    ).item_code
                  : "No Item Code",
                aquisition: directory.find(
                  (v) =>
                    `${v.shop_name} - ${parseInt(v.laz_price)}` ===
                    `${data[index][51]}${data[index][52]} - ${parseInt(
                      data[index][47]
                    )}`
                )
                  ? directory.find(
                      (v) =>
                        `${v.shop_name} - ${parseInt(v.laz_price)}` ===
                        `${data[index][51]}${data[index][52]} - ${parseInt(
                          data[index][47]
                        )}`
                    ).aquisition
                  : "No Aquisition",
                gross_price: directory.find(
                  (v) =>
                    `${v.shop_name} - ${parseInt(v.laz_price)}` ===
                    `${data[index][51]}${data[index][52]} - ${parseInt(
                      data[index][47]
                    )}`
                )
                  ? directory.find(
                      (v) =>
                        `${v.shop_name} - ${parseInt(v.laz_price)}` ===
                        `${data[index][51]}${data[index][52]} - ${parseInt(
                          data[index][47]
                        )}`
                    ).gross_price
                  : "No Gross Price",
              });
            }
          }
        }
      }
    }
    let orderCount = orderIds.filter((v, i) => orderIds.indexOf(v) == i).length;

    return { list, orderCount };
  }

  function generateSummaryReport(orders) {
    let item_codes = [];
    let summary = [];
    for (let index = 0; index < orders.length; index++) {
      item_codes.push(orders[index].item_code);
    }

    let filtered_item_codes = item_codes.filter(
      (v, i) => item_codes.indexOf(v) == i
    );

    for (let index = 0; index < filtered_item_codes.length; index++) {
      summary.push({
        variant_name: orders.filter(
          (order) => order.item_code === filtered_item_codes[index]
        )
          ? orders.filter(
              (order) => order.item_code === filtered_item_codes[index]
            )[0].variant_name
          : "--",
        item_code: filtered_item_codes[index],
        quantity: orders.filter(
          (order) => order.item_code === filtered_item_codes[index]
        ).length,
      });
    }

    return summary;
  }

  useEffect(() => {
    setOrders(generateOrders(data) ? generateOrders(data).list : "");
    setOrdersCount(generateOrders(data) ? generateOrders(data).orderCount : 0);
    setOrderSummary(
      generateOrders(data)
        ? generateSummaryReport(generateOrders(data).list)
        : ""
    );

    console.table(generateOrders(data).list);
  }, [data, orderIDs]);

  return {
    orders,
    ordersCount,
    orderSummary,
  };
}

export default useLazadaTool;
