import React from "react";
import { Loader } from "tabler-react";
import LoadingLogo from "./gms-loading.gif"

const Loading = () => {
  return (
    <>
      <div style={{ padding: "5px", textAlign: "center" }}>
        {/* <Loader/> */}
        <img src={LoadingLogo} style={{width: "200px"}}/>
      </div>
    </>
  );
};

export default Loading;
