import React from "react";
import { Form, Button, Header, Grid, Icon } from "tabler-react";

const LoginForm = (props) => {
  const { loginDetails } = props;
  const { data, setData, login, ERROR_CODE } = loginDetails;

  return (
    <div style={{ margin: "0 auto", width: "70%", marginTop: "20%" }}>
      <div className="login-form-container">
        <Form.FieldSet>
          <Header.H3 className="login-header">
            GATE MANAGEMENT SYSTEMS
          </Header.H3>
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Input
              icon="user"
              name="username"
              type="text"
              placeholder="Username"
              value={data.username}
              onChange={(e) => setData({ ...data, username: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Input
              icon="lock"
              name="password"
              type="password"
              placeholder="Password"
              value={data.password}
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            {ERROR_CODE == 1 ? (
              <p
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  color: "red",
                }}
              >
                USERNAME AND PASSWORD NOT MATCH
              </p>
            ) : (
              ""
            )}

            <Button
              color="teal"
              className="login-button"
              onClick={() => login(data)}
            >
              Login <Icon prefix="fa" name="sign-in" />
            </Button>
          </Form.Group>
          <Form.Group>
            <p style={{ textAlign: "center", fontSize: "12px" }}>
              Please contact your administrator if you forgot your password.
            </p>
          </Form.Group>
        </Form.FieldSet>
      </div>
    </div>
  );
};

export default LoginForm;
