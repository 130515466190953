import React, { useState } from "react";
import { Card, Table, Button, Form } from "tabler-react";
import useShopEdit from "../../../../hooks/api/TiktokGOMOShop/useShopEdit";

const OrderDetails = (props) => {
  const { selectedOrderID, setSelectedOrderID, newOrders, accessLevel } = props.data;
  const { updateOrderDetails } = useShopEdit();

  const [editForm, setEditForm] = useState([]);

  return (
    <>
      <div className="modal-summary">
        <Card className="modal-summary-content-container">
          <Card.Header>
            Order Details {selectedOrderID}
            <Card.Options>
              <Button color="secondary" onClick={() => setSelectedOrderID("")}>
                Close
              </Button>
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-wrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>ID</Table.ColHeader>
                  <Table.ColHeader>Product Name</Table.ColHeader>
                  <Table.ColHeader>SKU ID</Table.ColHeader>
                  <Table.ColHeader>Gate Price</Table.ColHeader>
                  <Table.ColHeader>Tiktok Price</Table.ColHeader>
                  <Table.ColHeader>Quantity</Table.ColHeader>
                  {accessLevel === "admin" ? <Table.ColHeader>Edit</Table.ColHeader> : "" }
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {newOrders
                  .find(
                    (order) =>
                      order.order_id.replace("*", "") === selectedOrderID
                  )
                  .details.map((order) => (
                    <Table.Row>
                      <Table.Col>{order.id}</Table.Col>
                      <Table.Col>{order.product_name}</Table.Col>
                      <Table.Col>
                        {editForm.length !== 0 && editForm.id === order.id ? (
                          <Form.Input
                            value={editForm.sku}
                            onChange={(e) =>
                              setEditForm({
                                ...editForm,
                                sku: e.target.value,
                              })
                            }
                          />
                        ) : (
                          order.sku
                        )}
                      </Table.Col>
                      <Table.Col>{order.gate_price}</Table.Col>
                      <Table.Col>{order.shopee_price}</Table.Col>
                      <Table.Col>{order.quantity}</Table.Col>
                      {
                        accessLevel === "admin" ?
                        <Table.Col>
                        {editForm.length !== 0  && editForm.id === order.id? (
                          <>
                            <Button
                              color="cyan"
                              onClick={() => updateOrderDetails(editForm)}
                            >
                              UPDATE DETAILS
                            </Button>{" "}
                            <Button onClick={() => setEditForm([])}>
                              CANCEL
                            </Button>
                          </>
                        ) : (
                          <Button onClick={() => setEditForm(order)}>
                            EDIT DETAILS
                          </Button>
                        )}
                      </Table.Col> : ""
                      }
                      
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default OrderDetails;
