import React, { useState, useEffect } from "react";
import { Card, Grid, Button, Icon, Form } from "tabler-react";
import Pagination from "../../components/Pagination";
import dateFormat from "dateformat";

import useUsers from "../../hooks/api/Users/useUsers";

const EmployeeList = (props) => {
  const { user } = useUsers();
  const { holidayList, addHolidays, updateHoliday } = props.data;
  const [activePage, setActivePage] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);

  const [selectedID, setSelectedID] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    date: "",
    type: "",
  });
  const [addForm, setAddForm] = useState(false);

  const reset = () => {
    setSelectedID("") ||
      setFormData({
        id: "",
        title: "",
        date: "",
        type: "",
      });
  };

  const [filtered, setFiltered] = useState([]);
  const [filter1, setFilter1] = useState("");

  useEffect(() => {
    setFiltered(
      holidayList.filter((n) =>
        filter1 !== ""
          ? n.title.toLowerCase().includes(filter1.toLowerCase())
          : n
      )
    );
  }, [holidayList, filter1]);

  const [access, setAccess] = useState("");

  useEffect(() => {
    if (user && user.length !== 0) {
      setAccess(user.apps_list.find((app) => app.app === "hr-tool").access);
    }
  }, [user]);

  return (
    <>
      {addForm ? (
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={1}>
              <p
                style={{
                  fontSize: "10px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#B8B8B8",
                }}
              >
                Holiday ID
              </p>
              <Form.Select
                placeholder="referrence / holiday id"
                value={formData.type}
                onChange={(e) =>
                  setFormData({ ...formData, type: e.target.value })
                }
                valid
              >
                <option value="">Select One</option>
                <option value="SH">Special Non-Working Holiday</option>
                <option value="NH">National Holiday</option>
                <option value="RH">Regular Holiday</option>
                <option value="CO">Company Outing</option>
              </Form.Select>
            </Grid.Col>
            <Grid.Col md={2}>
              <p
                style={{
                  fontSize: "10px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#B8B8B8",
                }}
              >
                Title
              </p>
              <Form.Input
                placeholder="name of the holiday/ event"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                valid
              />
            </Grid.Col>
            <Grid.Col md={3}>
              <p
                style={{
                  fontSize: "10px",
                  margin: 0,
                  fontWeight: "bold",
                  color: "#B8B8B8",
                }}
              >
                Date
              </p>
              <Form.Input
                placeholder="date of the holiday/ event"
                value={formData.date}
                onChange={(e) =>
                  setFormData({ ...formData, date: e.target.value })
                }
                valid
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <>
                <Button
                  color="cyan"
                  icon="save"
                  onClick={() =>
                    addHolidays(
                      formData,
                      () => alert("Data Successfully Saved!") || reset()
                    )
                  }
                >
                  Save
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setAddForm(false) || reset()}
                >
                  Cancel
                </Button>
              </>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      ) : (
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={2}>
              <Form.Input
                placeholder="holiday title/ name"
                value={filter1}
                onChange={(e) => setFilter1(e.target.value)}
              />
            </Grid.Col>
            {["admin", "edit"].includes(access) ? (
              <Grid.Col md={2}>
                <Button
                  color="cyan"
                  icon="plus"
                  onClick={() => setAddForm(true)}
                >
                  ADD NEW HOLIDAYS
                </Button>
              </Grid.Col>
            ) : (
              ""
            )}
          </Grid.Row>
        </Card.Body>
      )}

      {filtered
        .slice(activePage == 1 ? 0 : (activePage - 1) * 15, activePage * 15)
        .map((e, i) => (
          <>
            <Card.Body>
              <Grid.Row>
                <Grid.Col md={1}>
                  <p
                    style={{
                      fontSize: "10px",
                      margin: 0,
                      fontWeight: "bold",
                      color: "#B8B8B8",
                    }}
                  >
                    Holiday ID
                  </p>
                  {selectedID === e.id ? (
                    <Form.Select
                      placeholder="referrence / holiday id"
                      value={formData.type}
                      onChange={(e) =>
                        setFormData({ ...formData, type: e.target.value })
                      }
                      valid
                    >
                      <option value="">Select One</option>
                      <option value="SH">Special Non-Working Holiday</option>
                      <option value="NH">National Holiday</option>
                      <option value="RH">Regular Holiday</option>
                      <option value="CO">Company Outing</option>
                    </Form.Select>
                  ) : (
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                      }}
                    >
                      {e.type}
                    </p>
                  )}
                </Grid.Col>
                <Grid.Col md={2}>
                  <p
                    style={{
                      fontSize: "10px",
                      margin: 0,
                      fontWeight: "bold",
                      color: "#B8B8B8",
                    }}
                  >
                    Title
                  </p>
                  {selectedID === e.id ? (
                    <Form.Input
                      value={formData.title}
                      onChange={(e) =>
                        setFormData({ ...formData, title: e.target.value })
                      }
                      valid
                    />
                  ) : (
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                      }}
                    >
                      {e.title}
                    </p>
                  )}
                </Grid.Col>
                <Grid.Col md={3}>
                  <p
                    style={{
                      fontSize: "10px",
                      margin: 0,
                      fontWeight: "bold",
                      color: "#B8B8B8",
                    }}
                  >
                    Date
                  </p>
                  {selectedID === e.id ? (
                    <Form.Input
                      value={formData.date}
                      onChange={(e) =>
                        setFormData({ ...formData, date: e.target.value })
                      }
                      valid
                    />
                  ) : (
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                      }}
                    >
                      {dateFormat(
                        new Date(`${e.date}/2022`),
                        "dddd - mmmm d, yyyy"
                      )}
                    </p>
                  )}
                </Grid.Col>

                <Grid.Col md={2}>
                  {["admin", "edit"].includes(access) ? (
                    <>
                      {selectedID === e.id ? (
                        <>
                          <Button
                            color="cyan"
                            icon="save"
                            onClick={() =>
                              updateHoliday(
                                formData,
                                () =>
                                  alert("Data Successfully Saved!") || reset()
                              )
                            }
                          >
                            Save
                          </Button>
                          <Button color="secondary" onClick={() => reset()}>
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          color="cyan"
                          icon="edit"
                          onClick={() => setSelectedID(e.id) || setFormData(e)}
                          disabled={addForm}
                        >
                          Edit
                        </Button>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </>
        ))}
      <Pagination
        activePage={activePage}
        itemsCountPerPage={15}
        // changePage={changePage}
        totalItemsCount={holidayList.length}
        pageRangeDisplayed={pageRangeDisplayed}
        setActivePage={setActivePage}
        setPageRangeDisplayed={setPageRangeDisplayed}
      />
    </>
  );
};

export default EmployeeList;
