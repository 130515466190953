import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Loader,
  Avatar,
  Grid,
  Text,
  Icon,
} from "tabler-react";
import Gold from "../../../../../assets/icon/gold.png";
import Silver from "../../../../../assets/icon/silver.png";
import Bronze from "../../../../../assets/icon/bronze.png";
import NotificationForm from "../../components/NotificationForm";
import Tooltip from "react-simple-tooltip";

//hooks

const StoreOrderedList = (props) => {
  const { summary, formatNumberComma, accessLevel, filter1, filter2 } =
    props.data;
  // let colorBadge = ["yellow", "grey", "orange", "red"];
  let badges = [Gold, Silver, Bronze];

  const [rank, setRank] = useState("");
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [amountReceive, setAmountReceive] = useState(0);
  const [amountReceiveNA, setAmountReceiveNA] = useState(0);

  const [noDataLoading, setNoDataLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setNoDataLoading(true);
    }, 4000);
  }, []);

  return (
    <>
      {rank !== "" && username !== "" ? (
        <NotificationForm
          data={{
            type: "achievements",
            rank: rank,
            username: username,
            fullName,
            setRank,
            setUsername,
            filter1,
            filter2,
            amountReceive: `PHP ${formatNumberComma(amountReceive)}`,
            amountReceiveNA: `PHP ${formatNumberComma(amountReceiveNA)}`,
          }}
        />
      ) : (
        ""
      )}

      <Card.Status color="cyan" side />
      <Card.Body>
        {summary.length != 0 ? (
          <Table
            responsive
            highlightRowOnHover
            hasOutline
            verticalAlign="center"
            cards
            className="text-nowrap"
          >
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>Telemarketer</Table.ColHeader>
                <Table.ColHeader>
                  Ordered Received - Not Assigned
                </Table.ColHeader>
                <Table.ColHeader>Amount</Table.ColHeader>
                <Table.ColHeader>Ordered Received - Assigned</Table.ColHeader>
                <Table.ColHeader>Amount</Table.ColHeader>
                <Table.ColHeader>Total Ordered Assigned</Table.ColHeader>
                <Table.ColHeader>Amount</Table.ColHeader>
                <Table.ColHeader>Called Stores</Table.ColHeader>
                {accessLevel === "admin" ? (
                  <Table.ColHeader>Actions</Table.ColHeader>
                ) : (
                  ""
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {summary
                .filter(
                  (tele) =>
                    tele.order_amount_recieved != 0 ||
                    tele.order_amount_by_assigned_store != 0
                )
                .sort(
                  (a, b) =>
                    b.order_amount_by_assigned_store -
                    a.order_amount_by_assigned_store
                )
                .map((s, index) => (
                  <Table.Row>
                    <Table.Col>
                      <Grid.Row>
                        <Grid.Col md={3}>
                          <Avatar
                            size="md"
                            imageURL={index < 2 ? badges[index] : badges[2]}
                          >
                            <span style={{ color: "white" }}>{index + 1}</span>
                          </Avatar>
                        </Grid.Col>
                        <Grid.Col md={9}>
                          <Text size="md">
                            {s.receiver}
                          </Text>
                          <Text size="md">@{s.username}</Text>
                        </Grid.Col>
                      </Grid.Row>
                    </Table.Col>
                    <Table.Col>
                      {formatNumberComma(s.ordered_calls.length)}
                    </Table.Col>
                    <Table.Col>
                      Php {formatNumberComma(s.order_amount)}
                    </Table.Col>
                    <Table.Col>
                      {formatNumberComma(s.ordered_calls_assigned_only.length)}
                    </Table.Col>
                    <Table.Col>
                      PHP {formatNumberComma(s.order_amount_assigned_only)}
                    </Table.Col>
                    <Table.Col>
                      {formatNumberComma(
                        s.ordered_calls_by_assigned_store.length
                      )}
                    </Table.Col>
                    <Table.Col>
                      PHP {formatNumberComma(s.order_amount_by_assigned_store)}
                    </Table.Col>
                    <Table.Col>
                      {formatNumberComma(s.called_store.length)}
                    </Table.Col>
                    {accessLevel === "admin" ? (
                      <Table.Col>
                        <Tooltip
                          content="Send Notification"
                          radius={5}
                          padding={5}
                          placement="left"
                          style={{ whiteSpace: "nowrap", width: "100%" }}
                          zIndex={2}
                        >
                          <Button
                            color="cyan"
                            onClick={() =>
                              setRank(index + 1) ||
                              setUsername(s.username) ||
                              setFullName(s.receiver) ||
                              setAmountReceive(s.order_amount_by_assigned_store) ||
                              setAmountReceiveNA(s.order_amount)
                            }
                          >
                            <Icon prefix="fa" name="trophy" />
                          </Button>
                        </Tooltip>
                      </Table.Col>
                    ) : (
                      ""
                    )}
                  </Table.Row>
                ))}
              <Table.Row>
                <Table.Col>Total</Table.Col>
                <Table.Col>
                  <b>
                    {formatNumberComma(
                      summary.reduce(
                        (a, b) => a + parseInt(b.ordered_calls.length),
                        0
                      )
                    )}
                  </b>
                </Table.Col>
                <Table.Col>
                  <b>
                    PHP{" "}
                    {formatNumberComma(
                      summary.reduce((a, b) => a + parseInt(b.order_amount), 0)
                    )}
                  </b>
                </Table.Col>
                <Table.Col>
                  <b>
                    PHP{" "}
                    {formatNumberComma(
                      summary.reduce(
                        (a, b) =>
                          a + parseInt(b.ordered_calls_assigned_only.length),
                        0
                      )
                    )}
                  </b>
                </Table.Col>

                <Table.Col>
                  <b>
                    PHP{" "}
                    {formatNumberComma(
                      summary.reduce(
                        (a, b) => a + parseInt(b.order_amount_assigned_only),
                        0
                      )
                    )}
                  </b>
                </Table.Col>
                <Table.Col>
                  <b>
                    {formatNumberComma(
                      summary.reduce(
                        (a, b) =>
                          a +
                          parseInt(b.ordered_calls_by_assigned_store.length),
                        0
                      )
                    )}
                  </b>
                </Table.Col>
                <Table.Col>
                  <b>
                    PHP
                    {formatNumberComma(
                      summary.reduce(
                        (a, b) =>
                          a + parseInt(b.order_amount_by_assigned_store),
                        0
                      )
                    )}
                  </b>
                </Table.Col>
                <Table.Col>
                  <b>
                    {formatNumberComma(
                      summary.reduce(
                        (a, b) => a + parseInt(b.called_store.length),
                        0
                      )
                    )}
                  </b>
                </Table.Col>
                {accessLevel === "admin" ? <Table.Col></Table.Col> : ""}
              </Table.Row>
            </Table.Body>
          </Table>
        ) : (
          <>
            {noDataLoading ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "40px",
                  color: "#b9b9b9",
                }}
              >
                <Icon prefix="fa" name="folder-open" />
                <p
                  style={{
                    fontSize: "20px",
                    margin: 0,
                    fontWeight: "bold",

                    textTransform: "uppercase",
                  }}
                >
                  NO DATA AVAILABLE
                </p>
              </div>
            ) : (
              <Loader />
            )}
          </>
        )}
      </Card.Body>
    </>
  );
};

export default StoreOrderedList;
