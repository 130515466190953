import { useState, useEffect } from "react";
import config from "./../../config";
import axios from "axios";
import dateFormat from "dateformat";

function useNotifications() {
  let loginSession = window.localStorage.getItem("session")
    ? JSON.parse(window.atob(window.localStorage.getItem("session")))
    : [];

  const [notifications, setNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  function getAllAnnouncements() {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/notifications/get_all_notif`
        )
        .then(function (response) {
          let data = response.data;
          setAllNotifications(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getAnnouncements() {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/notifications/get_notif_by_user`,
          {
            user: "all",
          }
        )
        .then(function (response) {
          let data = response.data;
          setAnnouncements(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getNotifications() {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/notifications/get_notif_by_user`,
          {
            user: loginSession.username,
          }
        )
        .then(function (response) {
          let data = response.data;

          if(data.length !== 0){
            setNotifications(data.map(d => ({
              ...d,
              message: window.atob(d.message),
              title: window.atob(d.title)
            })));
          }
          
          
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function markAsRead(id) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/notifications/mark_as_read`,
          {
            id: id,
          }
        )
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function sendNotification(data, actions) {
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/notifications/send_notif`, {
          username: data.username,
          message: window.btoa(data.message),
          title: window.btoa(data.title),
          rank: data.rank,
          type: data.type,
          expiration: dateFormat(new Date(data.expiration), "isoUtcDateTime"),
        })
        .then(function (response) {
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function updateNotification(data, actions) {
    if (loginSession.length != 0) {
      // console.log(data);
      axios
        .post(
          `${config.host}:${config.port}/api/v2/notifications/update_notif`,
          {
            id: data.id,
            username: data.user,
            message: data.message,
            title: data.title,
            rank: data.rank,
            type: data.type,
            expiration: dateFormat(new Date(data.expiration), "isoUtcDateTime"),
            status: data.status,
          }
        )
        .then(function (response) {
          // console.log(response);
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    getNotifications();
    getAnnouncements();
    getAllAnnouncements();
  }, []);

  return {
    allNotifications,
    getNotifications,
    notifications,
    markAsRead,
    sendNotification,
    updateNotification,
    announcements,
  };
}

export default useNotifications;
