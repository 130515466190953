import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { Button, Card, Form, Grid, Icon, Table } from "tabler-react";
import dateFormat from "dateformat";
import useShopeePaymentReleaseAPI from "../../../../hooks/api/ShopeeShop/useShopeePaymentReleaseAPI";

const UploadPaymentRealease = () => {
    const { uploadPaymentRelease} = useShopeePaymentReleaseAPI()
  const [uploadedPayments, setUploadedPayments] = useState([]);
  const [files, setFiles] = useState([]);
  const [finalPaymentList, setFinalPaymentList] = useState([]);

  const [success, setSuccess] = useState(false);

  const [month, setMonth] = useState(dateFormat(new Date(), "mmmm"));
  const [year, setYear] = useState(2022);

  console.log(uploadedPayments);
  console.log(finalPaymentList);

  const toObject = (data) => {
    let list = data
      // .filter((d) =>
      //   ["Commission", "Payment Fee", "Item Price Credit"].includes(d[2])
      // )
      .map((d) => ({
        order_id: `*${d[0]}`,
        refund_id: d[1],
        username: d[2],
        order_date: d[3],
        payout_date: d[5],
        original_price: d[6],
        seller_promotion: Math.abs(d[7]),
        refund_amount: Math.abs(d[8]),
        discount_rebate: Math.abs(d[9]),
        seller_voucher: Math.abs(d[10]),
        coin_cashback: Math.abs(d[11]),
        paid_shipping_fee: Math.abs(d[12]),
        shipping_fee_rebate: Math.abs(d[13]),
        defined_shipping_fee: Math.abs(d[14]),
        commission_fee: Math.abs(d[18]),
        service_fee: Math.abs(d[19]),
        transaction_fee: Math.abs(d[21]),
        withholding_tax: Math.abs(d[22]),
        release_amount: Math.abs(d[23]),
        voucher_code: d[24],
        lost_compensation: Math.abs(d[25]),
        seller_shipping_fee: Math.abs(d[26]),
        shipping_provider: d[27],
        month_release: month,
        year_release: year,
      }));

    setFinalPaymentList(list);
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={6}>
              <Form.Group>
                <CSVReader
                  disabled={files.reduce((a, b) => a + b.items, 0) >= 2000}
                  onFileLoaded={(data, fileInfo) => {
                    files.find((file) => file.name === fileInfo.name)
                      ? alert("duplicate file!")
                      : setFiles([
                          ...files,
                          { ...fileInfo, items: data.length - 1 },
                        ]) ||
                        setUploadedPayments([
                          ...uploadedPayments,
                          ...data
                            .slice(1, data.length - 1)
                            .map((d) => [
                              ...d,
                              fileInfo.name.replace(/.csv/g, ""),
                            ]),
                        ]);
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Select
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="">Select Month of Release</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Select
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="">Select Year of Release</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                </Form.Select>
              </Form.Group>

              {files.reduce((a, b) => a + b.items, 0) >= 2000 ? (
                <p style={{ color: "red" }}>
                  Maximum payment release size reach. Please upload first the
                  current selection.
                </p>
              ) : (
                ""
              )}
            </Grid.Col>
            <Grid.Col md={6}>
              <p>Uploaded Files</p>
              {files.length !== 0 ? (
                <>
                  {files.map((file) => (
                    <p>
                      ({file.items} items) {file.name}
                    </p>
                  ))}

                  {finalPaymentList.length !== 0 ? (
                    <>
                      {success ? (
                        <p>UPLOADED TO DATABASE!</p>
                      ) : (
                        <Button
                          color="cyan"
                          onClick={() =>
                            uploadPaymentRelease(
                              finalPaymentList,
                              () => alert("uploaded!") || setSuccess(true)
                            )
                          }
                        >
                          <Icon prefix="fa" name="upload" />
                          Upload Generated Payments
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      color="teal"
                      onClick={() => toObject(uploadedPayments)}
                    >
                      <Icon prefix="fa" name="print" />
                      Generate
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          {finalPaymentList.length !== 0 ? (
            <Table
              responsive
              highlightRowOnHover
              hasOutline
              verticalAlign="center"
              cards
              className="text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Order ID</Table.ColHeader>
                  <Table.ColHeader>Order Date</Table.ColHeader>
                  <Table.ColHeader>Payout Date</Table.ColHeader>
                  <Table.ColHeader>Orginal Price</Table.ColHeader>
                  <Table.ColHeader>Seller Promotion</Table.ColHeader>
                  <Table.ColHeader>Release Amount</Table.ColHeader>
                  <Table.ColHeader>Withholding Tax</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finalPaymentList.map((payment) => (
                  <Table.Row>
                    <Table.Col>{payment.order_id}</Table.Col>
                    <Table.Col>{payment.order_date}</Table.Col>
                    <Table.Col>{payment.payout_date}</Table.Col>
                    <Table.Col>{payment.original_price}</Table.Col>
                    <Table.Col>{payment.seller_promotion}</Table.Col>
                    <Table.Col>{payment.release_amount}</Table.Col>
                    <Table.Col>{payment.withholding_tax}</Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default UploadPaymentRealease;
