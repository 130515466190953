import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Grid } from "tabler-react";
import { Helmet } from "react-helmet";
import Menu from "./menu/MainMenuSide";
import Help from "./pages/Help";
import Dashboard from "./pages/Dashboard/Dashboard";
import SIDashboard from "./pages/Dashboard/SIDashboard";
import RTSDashboard from "./pages/Dashboard/RTSDashboard";
import PendingDashboard from "./pages/Dashboard/PendingDashboard";
import Orders from "./pages/Orders/Orders";
import PaymentRelease from "./pages/PaymentRelease/PaymentRelease";
import Loading from "../../components/Loading/Loading";
// import Settings from "../../pages/Setting"
import SummaryReport from "./pages/Reports/SummaryReport";

//hooks
import useUsers from "../../hooks/api/Users/useUsers";
import UploadPaymentRelease from "./pages/PaymentRelease/UploadPaymentRelease";

const Index = () => {
  const { user, loginSession } = useUsers();

  useEffect(() => {
    if (loginSession.length == 0) {
      window.location.replace("/");
    }
  }, []);

  const [accessLevel, setAccessLevel] = useState("none");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setAccessLevel(
      user.length != 0
        ? user.apps_list.find((user) => user.app === "lazada-shop").access
        : ""
    );
  }, [user]);

  useEffect(() => {
    if (user.length != 0 && accessLevel === "none") {
      window.location.replace("/");
    }
  }, [user, accessLevel]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Helmet>
        <title>GMS - Lazada Shop</title>
      </Helmet>
      <div style={{ margin: "5px" }}>
        {loading ? (
          <div style={{ paddingTop: "10%" }}>
            <Loading />
          </div>
        ) : (
          <Grid.Row>
            <Grid.Col md={1} sm={12}>
              <Menu user={user} />
            </Grid.Col>
            <Grid.Col md={11} sm={12}>
              <Router>
                <Switch>
                  <Route exact path="/" component={SummaryReport} />
                  <Route exact path="/LazadaShop/" component={SummaryReport} />
                  <Route
                    exact
                    path="/LazadaShop/Dashboard"
                    component={Dashboard}
                  />
                  <Route
                    exact
                    path="/LazadaShop/SIDashboard"
                    component={SIDashboard}
                  />
                  <Route
                    exact
                    path="/LazadaShop/RTSDashboard"
                    component={RTSDashboard}
                  />
                  <Route
                    exact
                    path="/LazadaShop/PendingDashboard"
                    component={PendingDashboard}
                  />
                  <Route
                    exact
                    path="/LazadaShop/Report/SummaryReport"
                    component={SummaryReport}
                  />
                  <Route path="/LazadaShop/Orders" component={Orders} />
                  <Route
                    path="/LazadaShop/PaymentRelease"
                    component={PaymentRelease}
                  />
                  <Route
                    path="/LazadaShop/UploadPaymentRelease"
                    component={UploadPaymentRelease}
                  />
                  {/* <Route path="/LazadaShop/Settings" component={Settings} /> */}
                  <Route path="/LazadaShop/Help" component={Help} />
                </Switch>
              </Router>
            </Grid.Col>
          </Grid.Row>
        )}
      </div>
    </>
  );
};

export default Index;
