import React from "react";
import { Card, Button, Icon, Header } from "tabler-react";
import Tooltip from "react-simple-tooltip";

const SideBarMenu = () => {
  return (
    <>
      {" "}
      <div className="main-menu-container">
        <Card>
          <Card.Header>
            <p className="menu-header">Sales Monitoring</p>
          </Card.Header>
          <Card.Body>
            <Tooltip
              content="Home"
              radius={5}
              padding={5}
              placement="right"
              style={{ whiteSpace: "nowrap", width: "100%" }}
              zIndex={2}
            >
              <Button
                className="menu-button"
                color="cyan"
                onClick={() => window.location.replace("/")}
              >
                <Icon prefix="fa" name="home" />
              </Button>
            </Tooltip>
            <hr />

            <Tooltip
              content="Dashboard"
              radius={5}
              padding={5}
              placement="right"
              style={{ whiteSpace: "nowrap", width: "100%" }}
            >
              <Button
                className="menu-button"
                color="teal"
                onClick={() => window.location.replace("/AMS_sales/Dashboard")}
              >
                <Icon prefix="fa" name="dashboard" />
              </Button>
            </Tooltip>

            <Tooltip
              content="Other Accounts Master List"
              radius={5}
              padding={5}
              placement="right"
              style={{ whiteSpace: "nowrap", width: "100%" }}
            >
              <Button
                className="menu-button"
                color="teal"
                onClick={() => window.location.replace("/AMS_sales/Accounts")}
              >
                <Icon prefix="fa" name="list" />
              </Button>
            </Tooltip>
            <Tooltip
              content="Other Accounts Call History"
              radius={5}
              padding={5}
              placement="right"
              style={{ whiteSpace: "nowrap", width: "100%" }}
            >
              <Button
                className="menu-button"
                color="teal"
                onClick={() =>
                  window.location.replace("/AMS_sales/AllCallLogs")
                }
              >
                <Icon prefix="fa" name="phone" />
              </Button>
            </Tooltip>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default SideBarMenu;
