import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import { Button, Card, Table, Loader, Icon } from "tabler-react";
import Pagination from "../../../../components/Pagination";
import Filter from "./Filter";

//hooks
import useShopeeAPI from "../../../../hooks/api/ShopeeShop/useShopeeAPI";
import useQueryURL from "../../../../hooks/services/useQueryURL";
import useCustomFunctions from "../../../../hooks/services/useCustomFunctions";
import useExtractReportShopee from "../../../../hooks/api/ShopeeShop/extractions/useExtractShopeeReport";

const SIDashboard = () => {
  const { getQuery } = useQueryURL();
  const { formatNumberComma } = useCustomFunctions();
  const { downloadSISummary } = useExtractReportShopee()

  const [month, setMonth] = useState(
    getQuery("month") ? getQuery("month") : "January"
  );
  const [year, setYear] = useState(
    getQuery("year") ? getQuery("year") : "2021"
  );

  const { sIList } = useShopeeAPI(month, "", "");

  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(
    getQuery("page") ? getQuery("page") : 1
  );
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");

  const [newDrList, setNewDrList] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (sIList.length !== 0) {
      setLoading(false);
      // setActivePage(1);
      setNewDrList(
        sIList.filter((o) =>
          search
            ? o.dr_number.includes(search) || o.SI_number.includes(search)
            : o
        )
      );
    } else {
      setNewDrList([]);
      setLoading(false);
    }
  }, [sIList, search, search2, month]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [month]);

  const changePage = (p) => {
    window.location.replace(`/ShopeeShop/SIDashboard?month=${month}&page=${p}`);
  };
  return (
    <>
      <Card>
        <Card.Header>SI List</Card.Header>
        <Filter
          data={{
            src: "SIDashboard",
            month,
            year,
            search,
            setSearch,
            search2,
            setSearch2,
            downloadSISummary,
            newDrList
          }}
        />
        <Card.Body>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Table
                responsive
                highlightRowOnHover
                hasOutline
                verticalAlign="center"
                cards
                className="text-nowrap"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>#</Table.ColHeader>
                    <Table.ColHeader>DR Number</Table.ColHeader>
                    <Table.ColHeader>SI Number</Table.ColHeader>
                    <Table.ColHeader>SI Date</Table.ColHeader>
                    <Table.ColHeader>Gate Price Gross</Table.ColHeader>
                    {/* 
                <Table.ColHeader>Globe Discount</Table.ColHeader>
                <Table.ColHeader>Smart Discount</Table.ColHeader> */}
                    <Table.ColHeader>Payment Release</Table.ColHeader>
                    <Table.ColHeader>Commission Fee</Table.ColHeader>
                    <Table.ColHeader>Transaction Fee</Table.ColHeader>
                    <Table.ColHeader>Seller Discount</Table.ColHeader>
                    <Table.ColHeader>Seller Voucher</Table.ColHeader>
                    <Table.ColHeader>Coins Cashback</Table.ColHeader>
                    <Table.ColHeader>Shipping Subtotal</Table.ColHeader>
                    <Table.ColHeader>Computation</Table.ColHeader>
                    <Table.ColHeader>Status</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {newDrList
                    .sort(function (a, b) {
                      return a.id - b.id;
                    })
                    .slice(
                      activePage == 1 ? 0 : (activePage - 1) * 10,
                      activePage * 10
                    )
                    .map((list) => ({
                      ...list,
                      balance_release:
                        list.paymentRelease +
                        list.commissionFee +
                        list.paymentFee - list.shipping_sub_total,
                    }))
                    .map((list, index) => (
                      <>
                        {list.SI_number !== "--" ? (
                          <Table.Row>
                            <Table.Col>
                              {activePage == 1
                                ? index + 1
                                : index + 1 + (activePage - 1) * 10}
                            </Table.Col>
                            <Table.Col>
                              {list.dr_number.replace("*", "")}
                            </Table.Col>
                            <Table.Col>
                              {list.SI_number.replace("*", "")}
                            </Table.Col>
                            <Table.Col>
                              {list.SI_Date
                                ? dateFormat(new Date(list.SI_Date), "fullDate")
                                : list.SI_Date}
                            </Table.Col>
                            <Table.Col>
                              {formatNumberComma(list.original_price_total)}
                            </Table.Col>
                            {/* <Table.Col>
                      {formatNumberComma(list.globe_discount)}
                    </Table.Col>
                    <Table.Col>
                      {formatNumberComma(list.smart_discount)}
                    </Table.Col> */}
                            <Table.Col>
                              {formatNumberComma(list.paymentRelease)}
                            </Table.Col>
                            <Table.Col>
                              {formatNumberComma(list.commissionFee)}
                            </Table.Col>
                            <Table.Col>
                              {formatNumberComma(list.paymentFee)}
                            </Table.Col>
                            <Table.Col>
                              {formatNumberComma(
                                list.seller_promotion_total -
                                  list.discount_rebate_total
                              )}
                            </Table.Col>
                            <Table.Col>
                              {formatNumberComma(list.seller_voucher_total)}
                            </Table.Col>
                            <Table.Col>
                              {formatNumberComma(list.coin_cashback_total)}
                            </Table.Col>
                            <Table.Col>
                              {formatNumberComma(list.shipping_sub_total)}
                            </Table.Col>
                            <Table.Col>
                              {list.gate_price === list.balance_release
                                ? `Balance`
                                : `Not Balance`}
                            </Table.Col>
                            <Table.Col>
                              <a
                                href={`/ShopeeShop/Orders?dr_number=${list.dr_number.replace(
                                  "*",
                                  ""
                                )}&SI_number=${list.SI_number}&month=${
                                  list.DR_month
                                }&year=${list.DR_year}`}
                                target="_blank"
                              >
                                <Button color="cyan">Completed</Button>
                              </a>
                            </Table.Col>
                          </Table.Row>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                </Table.Body>
              </Table>
            </>
          )}
          {newDrList.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: "#b9b9b9",
              }}
            >
              <Icon prefix="fa" name="folder-open" />
              <p
                style={{
                  fontSize: "20px",
                  margin: 0,
                  fontWeight: "bold",

                  textTransform: "uppercase",
                }}
              >
                NO DATA AVAILABLE
              </p>
            </div>
          ) : (
            ""
          )}
        </Card.Body>
        {newDrList.length <= 10 ? (
          ""
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={newDrList.length}
            pageRangeDisplayed={pageRangeDisplayed}
            changePage={changePage}
            setActivePage={setActivePage}
            setPageRangeDisplayed={setPageRangeDisplayed}
          />
        )}
      </Card>
    </>
  );
};

export default SIDashboard;
